define("mvp/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    } // serialize() {
    //
    //   const result = this._super(...arguments),
    //     attr = result.data.attributes || {},
    //     rel = result.data.relationships || {};
    //
    //   return Object.keys(rel).reduce(function(acc, elem) {
    //     const data = rel[elem].data;
    //     if (data) {
    //       acc[elem + "_id"] = data.id;
    //     }
    //     if (data && data.type) {
    //       acc[elem + "_type"] = data.type[0].toUpperCase() + data.type.slice(1, -1);
    //     }
    //     return acc;
    //
    //   }, attr);
    // }

  });

  _exports.default = _default;
});