define("mvp/helpers/get-index-of-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getIndexOfArray = getIndexOfArray;
  _exports.default = void 0;

  function getIndexOfArray(inputs) {
    var array = inputs[0];
    var index = inputs[1];
    return array[index];
  }

  var _default = Ember.Helper.helper(getIndexOfArray);

  _exports.default = _default;
});