define("mvp/pods/site-management/objectives/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UmQZxEJo",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[7,\"h1\"],[9],[0,\"Edit Objective\"],[10],[0,\"\\n\\n\"],[4,\"with\",[[27,\"changeset\",[[23,[\"model\",\"objective\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"form\"],[11,\"class\",\"ui form\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"ui grid\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"eight wide column\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"field\"],[9],[0,\"\\n          \"],[7,\"label\"],[9],[0,\"Name:\"],[10],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"value\"],[[22,1,[\"name\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"eight wide column\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"field\"],[9],[0,\"\\n          \"],[7,\"label\"],[9],[0,\"Description:\"],[10],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"value\"],[[22,1,[\"description\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"floated right\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"site-management.objectives.index\"],[[\"class\"],[\"ui button\"]],{\"statements\":[[0,\"        Cancel\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"button\"],[11,\"class\",\"ui primary button\"],[11,\"type\",\"submit\"],[9],[0,\"Submit\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"handleSubmit\",[22,1,[]]],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/site-management/objectives/edit/template.hbs"
    }
  });

  _exports.default = _default;
});