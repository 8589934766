define("mvp/pods/surveillance/show/publications/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service('store'),
    model: function model(params) {
      return this.store.findRecord("project-publication", params.id);
    },
    afterModel: function afterModel(model) {
      var ajax = this.ajax;
      var self = this;
      var store = this.store;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var url = 'api/project-publications/' + model.id + '/filters';
        ajax.request(url).then(self._updateFilters.bind(self, resolve, store), self._rejectPromise.bind(self, reject));
      });
    },
    _updateFilters: function _updateFilters(resolve, store, payload) {
      store.pushPayload(payload);
      var filters = [];
      payload.data.forEach(function (filter) {
        filters.pushObject(store.peekRecord('filter', filter.id));
      });
      var showController = this.controllerFor('surveillance.show.publications.show');
      Ember.set(showController, 'filters', filters);
      resolve();
    },
    _rejectPromise: function _rejectPromise(reject) {
      reject();
    }
  });

  _exports.default = _default;
});