define("mvp/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    email: attr("string"),
    app_metadata: attr("json"),
    user_metadata: attr("json"),
    is_jmi_user: attr("boolean"),
    organization: belongsTo("organization"),
    roles: hasMany("role"),
    app_metadata_str: Ember.computed('app_metadata', function () {
      return JSON.stringify(this.app_metadata);
    }),
    user_metadata_str: Ember.computed('user_metadata', function () {
      return JSON.stringify(this.user_metadata);
    })
  });

  _exports.default = _default;
});