define("mvp/pods/components/ui-surveillance/filter-criteria-labels/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterDescription: Ember.computed("criteria", function () {
      var description_parts = [];
      var criteria = JSON.parse(this.criteria);

      var _self = this; // For each key, value pair within the criteria produce the
      // 'description_part' corresponding to that key value


      Ember.$.each(criteria, function (key, value) {
        switch (key) {
          case "project":
            description_parts.pushObject({
              removeAction: "removeAction",
              key: "project",
              name: "Project",
              value: value
            });
            break;

          case "genus":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "genus",
                name: "Genus",
                value: _self.toSentence(value.include.sort())
              });
            } else {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "genus",
                name: "Genus exclude",
                value: _self.toSentence(value.exclude.sort())
              });
            }

            break;

          case "group":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "group",
                name: "Group",
                value: _self.toSentence(value.include.sort())
              });
            } else {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "group",
                name: "Group exclude",
                value: _self.toSentence(value.exclude.sort())
              });
            }

            break;

          case "organism":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "organism",
                name: "Organisms",
                value: _self.toSentence(value.include.sort())
              });
            } else {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "organism",
                name: "Organisms exclude",
                value: _self.toSentence(value.exclude.sort())
              });
            }

            break;

          case "organism_code":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "organism_code",
                name: "Organism Code",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "year":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "year",
                name: "Year",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "continent":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "continent",
                name: "Geographic Regions",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "country":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "country",
                name: "Countries",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "us_census_region":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "us_census_region",
                name: "US Census Regions",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "european_region":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "european_region",
                name: "European Regions",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "site":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "site",
                name: "Site Codes",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "client_category_value":
            if (value.include !== undefined) {
              var category_parts = [];
              value.include.forEach(function (item) {
                Ember.$.each(JSON.parse(item), function (category, values) {
                  category_parts.pushObject(values);
                });
              });
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "client_category_value",
                name: "Categories",
                value: _self.toSentence(category_parts.sort())
              });
            }

            break;

          case "client_infection_type":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "client_infection_type",
                name: "Infection Types",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "nosocomial":
            if (value.include !== undefined) {
              if (value.include.length === 1) {
                if (value.include[0] === "Nosocomial") {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "nosocomial",
                    name: "Nosocomial",
                    value: ""
                  });
                } else {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "nosocomial",
                    name: "Community-acquired",
                    value: ""
                  });
                }
              } else {
                description_parts.pushObject({
                  removeAction: "removeAction",
                  key: "nosocomial",
                  name: "Nosocomial and Community-acquired",
                  value: ""
                });
              }
            }

            break;

          case "diagnosis":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "diagnosis",
                name: "Diagnoses",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "gender":
            if (value.include !== undefined) {
              var readable_value = value.include.map(function (val) {
                if (val === 'm') {
                  return "Male";
                } else {
                  return "Female";
                }
              });
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "gender",
                name: "Gender",
                value: _self.toSentence(readable_value.sort())
              });
            }

            break;

          case "icu":
            if (value.include !== undefined) {
              if (value.include.length === 1) {
                if (value.include[0] === "t") {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "icu",
                    name: "ICU",
                    value: ""
                  });
                } else {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "icu",
                    name: "non-ICU",
                    value: ""
                  });
                }
              } else {
                description_parts.pushObject({
                  removeAction: "removeAction",
                  key: "icu",
                  name: "ICU and non-ICU",
                  value: ""
                });
              }
            }

            break;

          case "vap":
            if (value.include !== undefined) {
              if (value.include.length === 1) {
                if (value.include[0] === "t") {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "vap",
                    name: "VAP",
                    value: ""
                  });
                } else {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "vap",
                    name: "non-VAP",
                    value: ""
                  });
                }
              } else {
                description_parts.pushObject({
                  removeAction: "removeAction",
                  key: "vap",
                  name: "VAP and non-VAP",
                  value: ""
                });
              }
            }

            break;

          case "objective":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "objective",
                name: "Objectives",
                value: _self.toSentence(value.include.sort())
              });
            }

            break;

          case "age_range":
            if (value.include !== undefined) {
              var minAge = value.include.min;
              var maxAge = value.include.max;
              var human_readable;

              if (minAge !== "" && maxAge !== "") {
                human_readable = "between " + minAge + " and " + maxAge + " years old";
              } else if (minAge !== "") {
                human_readable = "greater than or equal to " + minAge + " years old";
              } else {
                human_readable = "less than or equal to " + maxAge + " years old";
              }

              description_parts.pushObject({
                removeAction: "removeAction",
                key: "age_range",
                name: "Age",
                value: human_readable
              });
            }

            break;

          case "reacts_like_to":
            if (value !== undefined) {
              var parts = [];
              value.forEach(function (rlt) {
                var interps = rlt["interpretations"].map(function (interp) {
                  switch (interp) {
                    case "S":
                      return "Susceptible";

                    case "I":
                      return "Intermediate";

                    case "R":
                      return "Resistant";

                    case "NS":
                      return "Non-Susceptible";
                  }
                });

                var human_readable = _self.toSentence(interps).replace("and", "or");

                human_readable += " to " + rlt["drug"];

                if (rlt["delivery_mechanism"] !== "") {
                  human_readable += " when delivered via " + rlt["delivery_mechanism"];
                }

                if (rlt["infection_type"] !== "") {
                  human_readable += " for " + rlt["infection_type"];
                }

                human_readable += " when interpreted by ";
                human_readable += rlt["authority"] + " " + rlt["publication"];
                parts.addObject(human_readable);
              });
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "reacts_like_to",
                name: "Resistance Profiles",
                value: _self.toSentence(parts)
              });
            }

            break;

          case "custom_mic_profile":
            if (value !== undefined) {
              var _parts = [];
              var exists_drugs = [];
              value.forEach(function (cmp) {
                var comp;

                switch (cmp["comparison"]) {
                  case "<":
                    comp = "less than";
                    break;

                  case "≤":
                    comp = "less than or equal to";
                    break;

                  case "=":
                    comp = "equal to";
                    break;

                  case "≠":
                    comp = "not equal to";
                    break;

                  case "≥":
                    comp = "greater than or equal to";
                    break;

                  case ">":
                    comp = "greater than";
                    break;
                }

                if (cmp["dilution"] !== undefined) {
                  var _human_readable = "";
                  _human_readable += "MIC is " + comp + " " + cmp["dilution"] + " µg/mL";
                  _human_readable += " when tested against " + cmp["drug"];

                  _parts.addObject(_human_readable);
                } else {
                  exists_drugs.pushObject(cmp["drug"]);
                }
              });

              if (exists_drugs.length > 0) {
                var _human_readable2 = "";
                _human_readable2 += "Isolates tested against " + _self.toSentence(exists_drugs);

                _parts.addObject(_human_readable2);
              }

              description_parts.pushObject({
                removeAction: "removeAction",
                key: "custom_mic_profile",
                name: "Custom Resistance Profiles",
                value: _self.toSentence(_parts)
              });
            }

            break;
        }
      }); // Order the parts, join with comma, and output

      return description_parts;
    }),
    // Convert an array to a sentence
    toSentence: function toSentence(array) {
      var arr = array.slice(0);
      var lastWord = "";

      if (arr.length > 1) {
        lastWord = " and " + arr.pop();
      }

      return arr.join(", ") + lastWord;
    }
  });

  _exports.default = _default;
});