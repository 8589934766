define("mvp/pods/components/ui-filter-result/charts/frequency-distribution/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals c3 */
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service('store'),
    observeDrugsDropdown: function () {
      if (this.drugsDropdown !== undefined && Ember.get(this, 'drugs.length') > 0) {
        var selected = [];
        this.drugs.forEach(function (drug) {
          selected.addObject(encodeURIComponent(drug));
        });
        this.drugsDropdown.send('set-selected', selected);
      }
    }.observes('drugsDropdown'),
    drugAliases: Ember.computed("availableDrugs.length", function () {
      var output = [];
      this.availableDrugs.forEach(function (drug) {
        output.addObject({
          name: Ember.get(drug, 'alias'),
          value: Ember.get(drug, 'alias')
        });
      });
      return output;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.drugs = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'mic-distribution-charts', undefined);

      if (Ember.get(this, 'drugs.length') > 0) {
        Ember.set(this, 'loading', true);
        Ember.run.debounce(this, this.loadChart, 250);
      }
    },
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'drugs.length') === 0) {
        Ember.$('body').scrollTop(0);
      }
    },
    actions: {
      'drugs-changed': function drugsChanged(drugs) {
        if (drugs === "") {
          this['drugs-changed']([]);
        } else {
          this['drugs-changed'](decodeURIComponent(drugs).split(","));
        }
      },
      renderChart: function renderChart(chart, elementId) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var chart_id = "#chart_" + elementId;
          c3.generate({
            bindto: chart_id,
            onrendered: function onrendered() {
              Ember.$(chart_id).removeClass("hidden");
              Ember.$(chart_id).next().addClass("hidden");
            },
            padding: {
              top: 15,
              left: 50,
              right: 220,
              bottom: 30
            },
            data: chart.data,
            axis: chart.axis,
            legend: chart.legend,
            line: {
              connectNull: true
            },
            color: {
              pattern: ['#5b8cff', '#d0da41', '#324986', '#85c1ec', '#9fb36d', '#1482be']
            }
          });
        });
      }
    },
    loadChart: function loadChart() {
      var self = this;
      var ajax = this.ajax;
      var store = this.store;
      var filter = this.filter;
      var url = "api/filters/" + filter.id + '/mic-distribution-charts';
      store.unloadAll('mic-distribution-chart');
      ajax.request(url, {
        data: {
          drugs: this.drugs,
          finlandogram: this.finlandogram
        }
      }).then(self._updateMicDistributionChart.bind(self), self._alertError.bind(self));
    },
    _updateMicDistributionChart: function _updateMicDistributionChart(payload) {
      var store = this.store;
      var data = store.push(payload);
      Ember.set(this, 'mic-distribution-charts', data);
      Ember.set(this, 'loading', false);
    },
    _alertError: function _alertError(error) {
      alert(error);
    }
  });

  _exports.default = _default;
});