define("mvp/pods/surveillance/show/results/overview/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service('ajax'),
    model: function model() {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var ajax = Ember.get(self, 'ajax');
        var filter = self.modelFor("surveillance.show.results");
        var url = "api/filters/".concat(filter.id, "/web-table");
        self.store.unloadAll('web-table');
        ajax.request(url).then(self._savePayload.bind(self, resolve), reject);
      });
    },
    _savePayload: function _savePayload(resolve, payload) {
      this.store.pushPayload(payload);
      var id = payload.data.id;
      resolve(this.store.peekRecord('web-table', id));
    }
  });

  _exports.default = _default;
});