define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/years/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    playing: false,
    step: 1,
    min: null,
    max: null,
    timer: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupSlider();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupSlider();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.StopPlay(); // console.log("willDestroyElement");
    },
    // handleResistanceRateGroupChange: Ember.observer('resistance-rate-group', function() {
    //   console.log('res rate change');
    //   if ( get(this, "playing") ) {
    //     console.log('res rate make timer');
    //     set(this, "timer",setTimeout( window._stepSlider, 1000 ));
    //   }
    // }),
    actions: {
      'update-selected-year': function updateSelectedYear(year, playing) {
        this['update-selected-year'](year, playing);
      },
      'pause': function pause() {
        this.StopPlay();
      },
      'undo': function undo() {
        this.StopPlay();
        this.SetSlider(this.min);
      },
      'play': function play() {
        if (this['selected-year'] < this.max) {
          this.StartPlay();
        }
      }
    },
    StartPlay: function StartPlay() {
      this.StopPlay();
      Ember.set(this, "timer", setTimeout(window._stepSlider, 1500));
      Ember.set(this, "playing", true);
    },
    StopPlay: function StopPlay() {
      clearInterval(this.timer);
      Ember.set(this, "playing", false);
    },
    SetSlider: function SetSlider(newValue) {
      Ember.set(this, 'selected-year', newValue);
      Ember.$("#slider").slider("value", newValue);
      this.send("update-selected-year", newValue, this.playing);
    },
    StepSlider: function StepSlider() {
      // console.log("stepping slider");
      if (this['selected-year'] < this.max) {
        this.SetSlider(this['selected-year'] + this.step);
        Ember.set(this, "playing", true);
      } else {
        this.StopPlay();
      }
    },
    setupSlider: function setupSlider() {
      // console.log("setup slider, playing: " + get(this, "selected-playing"));
      var self = this;

      if (this.years) {
        var years = [];
        this.years.forEach(function (year) {
          years.push(parseInt(year['year']));
        });

        window._stepSlider = function () {
          self.StepSlider();
        };

        if (this["selected-playing"]) {
          self.StartPlay();
        }

        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.set(self, 'min', years[0]);
          Ember.set(self, 'max', years[years.length - 1]);
          Ember.$(function () {
            Ember.$("#slider").slider({
              step: Ember.get(self, 'step'),
              min: Ember.get(self, 'min'),
              max: Ember.get(self, 'max'),
              value: Ember.get(self, 'selected-year'),
              stop: function stop(event, ui) {
                self.StopPlay();
                self.SetSlider(ui.value);
              }
            }).slider("pips", {
              rest: "label"
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});