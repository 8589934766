define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/usa-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pKBvAKcy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"component\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"map_displayed\"]]],null,{\"statements\":[[0,\"\\n\"],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"chartdiv\"],[9],[10],[0,\"\\n\\n\"],[4,\"unless\",[[27,\"eq\",[[23,[\"selected-year\"]],0],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"ammap/resistance-rates/years\",null,[[\"years\",\"selected-playing\",\"selected-year\",\"resistance-rate-group\",\"update-selected-year\"],[[23,[\"years\"]],[23,[\"selected-playing\"]],[23,[\"selected-year\"]],[23,[\"model\",\"resistance_rate_group\"]],[27,\"action\",[[22,0,[]],\"update-selected-year\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"info\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/usa-map/template.hbs"
    }
  });

  _exports.default = _default;
});