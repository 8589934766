define("mvp/pods/components/export-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQY4ag61",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"export-dropdown\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"download icon\"],[9],[10],[0,\"\\n  Export\\n  \"],[7,\"i\"],[11,\"class\",\"dropdown icon\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"menu\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"item\"],[9],[0,\"\\n    Excel\\n  \"],[3,\"action\",[[22,0,[]],\"export-by-type\",\"xlsx\"]],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"item\"],[9],[0,\"\\n    Word\\n  \"],[3,\"action\",[[22,0,[]],\"export-by-type\",\"doc\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/export-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});