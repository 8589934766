define("mvp/models/resistance-rate-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    year: _emberData.default.attr("number"),
    name: _emberData.default.attr("string"),
    drug_name: _emberData.default.attr("string"),
    authority: _emberData.default.attr("string"),
    delivery_mechanism: _emberData.default.attr("string"),
    infection_type: _emberData.default.attr("string"),
    created_at: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date"),
    resistance_rates: _emberData.default.hasMany("resistance-rates", {
      async: true
    })
  });

  _exports.default = _default;
});