define("mvp/pods/site-management/sites/show/contacts/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/sites/show/contacts/site_contacts"], function (_exports, _emberApolloClient, _site_contacts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var site_vars = this.modelFor("site-management.sites.show");
      var variables = {
        siteId: site_vars.id,
        page: params.page,
        perPage: 6,
        order: 'primary_contact DESC'
      };
      return this.apollo.watchQuery({
        query: _site_contacts.default,
        variables: variables,
        fetchPolicy: 'no-cache'
      }, "siteContacts");
    },
    queryParams: {
      page: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});