define("mvp/pods/site-management/site-contacts/index/controller", ["exports", "ember-cli-pagination/computed/paged-array", "mvp/config/environment"], function (_exports, _pagedArray, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'perPage', 'siteCode'],
    page: 1,
    perPage: 25,
    recruited: false,
    pagedContent: (0, _pagedArray.default)('content', {
      page: Ember.computed.alias("parent.page"),
      perPage: Ember.computed.alias("parent.perPage"),
      totalPages: Ember.computed.alias("parent.model.pageInfo.totalPages")
    }),
    actions: {
      filterByString: function filterByString(string) {
        var val = event.target.value;

        if (val) {
          this.set(string, event.target.value);
        } else {
          this.set(string, null);
        }

        this.set("page", 1);
      },
      clearFilters: function clearFilters() {
        var _this = this;

        event.preventDefault();
        Object.entries({
          'siteCode': null
        }).forEach(function (v) {
          _this.set(v[0], v[1]);
        });
      },
      exportAllSiteContacts: function exportAllSiteContacts() {
        window.open("".concat(_environment.default.APP.sm_host, "/downloads/site_contacts?recruited=false"));
      },
      exportRecruitedSiteContacts: function exportRecruitedSiteContacts() {
        window.open("".concat(_environment.default.APP.sm_host, "/downloads/site_contacts?recruited=true"));
      }
    }
  });

  _exports.default = _default;
});