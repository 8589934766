define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/all-years/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    firstYear: Ember.computed('yearsArray', function () {
      var years = this.yearsArray;
      return years[0];
    }),
    lastYear: Ember.computed('yearsArray', function () {
      var years = this.yearsArray;
      return years[years.length - 1];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.yearsArray = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupComponent();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupComponent();
    },
    actions: {
      'update-selected-year': function updateSelectedYear(year, playing) {
        //console.log(`update selected year ${year}`);
        this['update-selected-year'](year, playing);
      }
    },
    setupComponent: function setupComponent() {
      this._super.apply(this, arguments);

      var years = [];
      this.years.forEach(function (year) {
        years.push(parseInt(year['year']));
      });
      Ember.set(this, 'yearsArray', years);
    }
  });

  _exports.default = _default;
});