define("mvp/pods/surveillance/show/results/charts/cross-tabs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service("ajax"),
    synthEdges: true,
    surveillanceShowController: Ember.inject.controller("surveillance.show"),
    project: Ember.computed.alias("surveillanceShowController.model"),
    isolatesController: Ember.inject.controller("isolates"),
    init: function init() {
      this._super.apply(this, arguments);

      this.group1Drugs = [];
      this.group2Drugs = [];
    },
    actions: {
      "view-isolate": function viewIsolate(isolate, isolates) {
        Ember.set(this.isolatesController, "isolates", isolates.content);
        Ember.set(this.isolatesController, "isolate", isolate);
        Ember.set(this.isolatesController, "backRoute", "surveillance.show.results.frequency-distributions.charts.cross-tabs");
        Ember.set(this.isolatesController, "backFilter", this.model);
        Ember.set(this.isolatesController, "backText", "Back to cross-tabs");
        this.transitionToRoute("surveillance.show.isolates.show", this.project, isolate);
      },
      leftDrugSelected: function leftDrugSelected(drug_names) {
        if (drug_names === "") {
          Ember.set(this, "group1Drugs", []);
        } else {
          Ember.set(this, "group1Drugs", drug_names.split(","));
        }
      },
      bottomDrugSelected: function bottomDrugSelected(drug_names) {
        if (drug_names === "") {
          Ember.set(this, "group2Drugs", []);
        } else {
          Ember.set(this, "group2Drugs", drug_names.split(","));
        }
      },
      getCrossTabData: function getCrossTabData(synthEdges) {
        var url = "/api/filters/" + Ember.get(this, "model.id") + "/cross-tab-table";
        var ajax = this.ajax;

        var _self = this;

        var group1Drugs = this.group1Drugs;
        var group2Drugs = this.group2Drugs;
        Ember.set(_self, "data", undefined);
        ajax.request(url, {
          data: {
            group1Drugs: group1Drugs,
            group2Drugs: group2Drugs,
            synthEdges: synthEdges
          }
        }).then(_self._saveDataAndScroll.bind(_self));
      }
    },
    _saveDataAndScroll: function _saveDataAndScroll(data) {
      Ember.set(this, "data", data);
      Ember.run.later(this, function () {
        Ember.$("html, body").animate({
          scrollTop: Ember.$(document).height()
        }, 1000);
      }, 100);
    }
  });

  _exports.default = _default;
});