define("mvp/pods/site-management/sites/show/objectives/new/route", ["exports", "ember-apollo-client"], function (_exports, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var site_obj_key = _.filter(Object.keys(this.apollo.apollo.cache.data.data), function (el) {
        return el.indexOf('Site:') !== -1;
      })[0];

      var site_id = this.apollo.apollo.cache.data.data[site_obj_key].id;
      return Ember.RSVP.hash({
        params: params,
        siteId: site_id,
        changeset: {
          objective: {}
        }
      });
    }
  });

  _exports.default = _default;
});