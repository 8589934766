define("mvp/pods/site-management/shipments/index/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/shipments/index/shipments"], function (_exports, _emberApolloClient, _shipments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var variables = {
        order: "log_date DESC, send_date DESC",
        page: params.page,
        country: params.country,
        continent: params.continent,
        siteCode: params.siteCode,
        siteName: params.siteName,
        addressee: params.addressee,
        perPage: params.perPage
      };
      return this.apollo.watchQuery({
        query: _shipments.default,
        variables: variables,
        fetchPolicy: "no-cache"
      }, "shipments");
    },
    queryParams: {
      page: {
        refreshModel: true
      },
      siteCode: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      country: {
        refreshModel: true
      },
      continent: {
        refreshModel: true
      },
      siteName: {
        refreshModel: true
      },
      addressee: {
        refreshModel: true
      }
    },
    boolConverter: function boolConverter(bool) {
      if (bool === true) return true;

      try {
        return JSON.parse(bool.toLowerCase());
      } catch (_unused) {
        return null;
      }
    }
  });

  _exports.default = _default;
});