define("mvp/pods/site-management/shipments/index/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),
    page: 1,
    perPage: 25,
    pagedContent: (0, _pagedArray.default)('content', {
      page: Ember.computed.alias("parent.page"),
      perPage: Ember.computed.alias("parent.perPage"),
      totalPages: Ember.computed.alias("parent.model.pageInfo.totalPages")
    }),
    actions: {
      editShipment: function editShipment(shipment) {
        this.transitionToRoute('site-management.shipments.edit', shipment.id);
      },
      viewDelivery: function viewDelivery(ss) {
        window.open('https://www.fedex.com/apps/fedextrack/?action=track&action=track&tracknumbers=' + ss.trackingNum, '_blank');
      },
      toggleShipmentDetails: function toggleShipmentDetails(shipment) {
        var shipment_id = shipment.id;
        var button = Ember.$(event.target);
        var row = Ember.$("#shipment-details-id-" + shipment_id);
        button.toggleClass("active");
        row.toggleClass("active");

        if (button.hasClass("active")) {
          button.html("<i class='minus icon'></i> Hide");
          row.show();
        } else {
          button.html("<i class='plus icon'></i> View");
          row.hide();
        }
      },
      toggleAllShipmentDetails: function toggleAllShipmentDetails() {
        var row = Ember.$(".shipment-details");
        row.addClass("active");

        if (Ember.$(".shipment-details:visible").length == 0) {
          Ember.$(".shipment-item-details-toggle").addClass("active").html("<i class='minus icon'></i> Hide");
          Ember.$(".shipment-details").show();
        } else {
          row.removeClass("active");
          Ember.$(".shipment-item-details-toggle").removeClass("active").html("<i class='plus icon'></i> View");
          Ember.$(".shipment-details").hide();
        }
      },
      filterByString: function filterByString(string) {
        var val = event.target.value;

        if (val) {
          this.set(string, event.target.value);
        } else {
          this.set(string, null);
        }

        this.set("page", 1);
      },
      clearFilters: function clearFilters() {
        var _this = this;

        event.preventDefault();
        Object.entries({
          'country': null,
          'continent': null,
          'addressee': null,
          'siteCode': null,
          'siteName': null
        }).forEach(function (v) {
          _this.set(v[0], v[1]);
        });
      },
      newShipment: function newShipment() {
        this.transitionToRoute('site-management.shipments.new');
      }
    }
  });

  _exports.default = _default;
});