define("mvp/pods/site-management/canisters/edit/route", ["exports", "ember-apollo-client"], function (_exports, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model() {
      return Ember.RSVP.hash({
        canister: {},
        changeset: {}
      });
    }
  });

  _exports.default = _default;
});