define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/antibiotics/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    availableAntibiotics: Ember.computed('pathogens.length', 'selected-pathogen', function () {
      var pathogens = Ember.get(this, 'pathogens.content');
      var selectedPathogenName = this['selected-pathogen'];
      var selectedPathogen;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = pathogens[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var pathogen = _step.value;

          if (pathogen._data.name === selectedPathogenName) {
            selectedPathogen = pathogen;
            break;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return selectedPathogen._data.drugs.split(',');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._selectAntibiotic();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._selectAntibiotic();
    },
    actions: {
      'update-selected-antibiotic': function updateSelectedAntibiotic(antibiotic) {
        this['update-selected-antibiotic'](antibiotic);
      }
    },
    _selectAntibiotic: function _selectAntibiotic() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$("#".concat(_this.elementId, " .ui.dropdown")).dropdown();
        Ember.$("#".concat(_this.elementId, " .ui.dropdown")).dropdown('set selected', _this['selected-antibiotic']);
      });
    }
  });

  _exports.default = _default;
});