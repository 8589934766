define("mvp/pods/components/ui-surveillance/adhoc/molecular/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eBWpYxLw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"authorization\",\"is_jmi_user\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ui-surveillance/adhoc/molecular/nested\",null,[[\"updateProfileAt\",\"updateMolecularProfile\",\"molecular-profile\",\"project\",\"topLevel\",\"inclusion\"],[[27,\"action\",[[22,0,[]],\"updateProfileAt\"],null],[27,\"action\",[[22,0,[]],\"updateMolecularProfile\"],null],[23,[\"molecular-profile\"]],[23,[\"project\"]],true,[23,[\"inclusion\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"ui-surveillance/adhoc/molecular/simple\",null,[[\"project\",\"updateFilterCriteria\"],[[23,[\"project\"]],[23,[\"update-filter-criteria\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/adhoc/molecular/new/template.hbs"
    }
  });

  _exports.default = _default;
});