define("mvp/pods/components/ui-admin/invite-user/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    newUserEmail: undefined,
    "organization_selected": undefined,
    observeNewUserEmail: function () {
      if (this.newUserEmail !== undefined && this.newUserEmail.length > 0 && this.organization_selected !== undefined) {
        Ember.$("button.invite").removeClass("disabled");
      } else {
        Ember.$("button.invite").addClass("disabled");
      }
    }.observes("newUserEmail"),
    observeOrganizationSelected: function () {
      if (this.newUserEmail !== undefined && this.newUserEmail.length > 0 && this.organization_selected !== undefined) {
        Ember.$("button.invite").removeClass("disabled");
      } else {
        Ember.$("button.invite").addClass("disabled");
      }
    }.observes("organization_selected"),
    didInsertElement: function didInsertElement() {
      var _self = this;

      Ember.$(".dropdown.organization").dropdown({
        fullTextSearch: true,
        onChange: function onChange(value) {
          Ember.set(_self, "controller.organization_selected", value);
        }
      });
    },
    actions: {
      invite: function invite() {
        var email = this.newUserEmail;
        var organization = this.organization_selected;
        this.invite(email, organization);
      }
    }
  });

  _exports.default = _default;
});