define("mvp/pods/admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IbXqRMIl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pagehead slim\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"ui grid container\"],[9],[0,\"\\n    \"],[2,\" <img class=\\\"jmi-icon\\\" src=\\\"assets/images/JMI 4C Stacked REVERSE RGB.png\\\" height='80'> \"],[0,\"\\n    \"],[1,[27,\"ui-application/logo\",null,[[\"height\",\"logoSize\",\"cssClasses\"],[\"80\",\"normal\",\"jmi-icon\"]]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"sixteen wide column header-container\"],[9],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"ui grid container sticky-context main-content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"four wide column\"],[9],[0,\"\\n    \"],[1,[21,\"ui-admin/nav\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"id\",\"context\"],[11,\"class\",\"twelve wide column main-content\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/admin/template.hbs"
    }
  });

  _exports.default = _default;
});