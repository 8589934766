define("mvp/pods/surveillance/show/heatmap/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "owHdz+6W",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ui-surveillance/heatmap\",null,[[\"project\",\"pathogens\",\"phenotypes\",\"years\"],[[23,[\"project\"]],[23,[\"model\",\"pathogens\"]],[23,[\"model\",\"phenotypes\"]],[23,[\"model\",\"years\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/heatmap/template.hbs"
    }
  });

  _exports.default = _default;
});