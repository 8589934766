define("mvp/adapters/run", ["exports", "mvp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(type, id, snapshot) {
      return this.namespace + '/projects/' + Ember.get(snapshot.record, 'project.id') + '/runs/' + id;
    }
  });

  _exports.default = _default;
});