define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/all-maps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nDegTVdl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"selected-map-type\"]],\"World\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ammap/resistance-rates/map\",null,[[\"resistance-rate-group\",\"years\",\"selected-playing\",\"selected-year\",\"selected-map-type\",\"update-selected-year\"],[[23,[\"resistance-rate-group\"]],[23,[\"years\"]],[23,[\"selected-playing\"]],[23,[\"selected-year\"]],[23,[\"selected-map-type\"]],[27,\"action\",[[22,0,[]],\"update-selected-year\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"selected-map-type\"]],\"Europe\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ammap/resistance-rates/map\",null,[[\"resistance-rate-group\",\"years\",\"selected-playing\",\"selected-year\",\"selected-map-type\",\"update-selected-year\"],[[23,[\"resistance-rate-group\"]],[23,[\"years\"]],[23,[\"selected-playing\"]],[23,[\"selected-year\"]],[23,[\"selected-map-type\"]],[27,\"action\",[[22,0,[]],\"update-selected-year\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"selected-map-type\"]],\"US\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ammap/resistance-rates/usa-map\",null,[[\"resistance-rate-group\",\"years\",\"selected-playing\",\"selected-year\",\"selected-map-type\",\"update-selected-year\"],[[23,[\"resistance-rate-group\"]],[23,[\"years\"]],[23,[\"selected-playing\"]],[23,[\"selected-year\"]],[23,[\"selected-map-type\"]],[27,\"action\",[[22,0,[]],\"update-selected-year\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/all-maps/template.hbs"
    }
  });

  _exports.default = _default;
});