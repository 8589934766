define("mvp/pods/components/ui-sentry/demo-entry/choose-objective/component", ["exports", "lodash", "mvp/config/environment"], function (_exports, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    demoEntryYear: Ember.computed("", function () {
      return _environment.default.APP.SETTINGS.DEMO_ENTRY_YEAR;
    }),
    acceptedObjectives: Ember.computed("siteRecruitments.@each.status", function () {
      var accepted = [];
      this.siteRecruitments.forEach(function (siteRecruitment) {
        if (siteRecruitment.status == "accepted") {
          siteRecruitment.siteObjectives.forEach(function (siteObjective) {
            accepted.addObject(siteObjective.objective);
          });
        }
      });
      return _lodash.default.sortBy(accepted, ["name"]);
    })
  });

  _exports.default = _default;
});