define("mvp/pods/site-management/sites/show/recruitments/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/sites/show/recruitments/site_recruitments"], function (_exports, _emberApolloClient, _site_recruitments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var site_vars = this.modelFor("site-management.sites.show");
      var variables = {
        siteId: site_vars.id,
        page: params.page,
        perPage: 10,
        order: 'year DESC'
      };
      return this.apollo.watchQuery({
        query: _site_recruitments.default,
        variables: variables,
        fetchPolicy: 'cache-and-network'
      }, "siteRecruitments");
    },
    queryParams: {
      page: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});