define("mvp/pods/components/ui-filter-result/cross-tabs/isolates-viewer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    classNames: ["ui", "modal", "small"],
    page: 1,
    perPage: 10,
    selector: Ember.computed("elementId", function () {
      return "#" + this.elementId;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setup();
    },
    actions: {
      showModal: function showModal() {
        var _self = this;

        var selector = this.selector;
        Ember.run.schedule("afterRender", function () {
          Ember.$(selector).modal({
            blurring: true,
            observeChanges: true,
            onHidden: function onHidden() {
              Ember.set(_self, "page", 1);
              Ember.set(_self, "isolates", undefined);
            }
          }).modal("show");

          _self.getIsolates(Ember.get(_self, "page"), Ember.get(_self, "perPage"));
        });
      },
      "isolates-page-clicked": function isolatesPageClicked(page) {
        Ember.set(this, "page", page);
        Ember.set(this, "isolates", undefined);
        this.getIsolates(this.page, this.perPage);
      },
      viewIsolate: function viewIsolate(isolate) {
        var selector = this.selector;
        Ember.$(selector).modal("hide dimmer");
        this.viewIsolate(isolate);
      }
    },
    setup: function setup() {
      var _self = this;

      Ember.run.schedule('afterRender', this, function () {
        Ember.set(_self, "register-as", _self);
      });
    }
  });

  _exports.default = _default;
});