define("mvp/pods/manage/users/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8zYP9DBs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui grid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"four.wide.column\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"Options\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"ui.vertical.pointing.menu\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"manage.users.edit.settings\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"settings icon\"],[9],[10],[0,\"\\n        Settings\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"twelve.wide.column\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/manage/users/edit/template.hbs"
    }
  });

  _exports.default = _default;
});