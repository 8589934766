define("mvp/pods/manage/projects/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pIB99Ads",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[7,\"h2\"],[9],[0,\"Manage Projects\"],[10],[0,\"\\n\\n\"],[7,\"table\"],[11,\"class\",\"ui celled table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Project\"],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"collapsing\"],[9],[0,\"Actions\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"collapsing\"],[9],[0,\"\\n          \"],[7,\"button\"],[11,\"class\",\"ui icon button\"],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"edit.icon\"],[9],[10],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],\"edit\",[22,1,[]]]],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/manage/projects/index/template.hbs"
    }
  });

  _exports.default = _default;
});