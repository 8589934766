define("mvp/pods/surveillance/show/results/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N5B1HNQ7",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ui-surveillance/loading\",null,[[\"without-steps\"],[true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/results/loading/template.hbs"
    }
  });

  _exports.default = _default;
});