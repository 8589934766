define("mvp/pods/manage/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate: function activate() {
      this._super.apply(this, arguments);

      this.transitionToManageUsers();
    },
    transitionToManageUsers: function transitionToManageUsers() {
      this.controllerFor("manage").transitionToRoute("manage.users.index");
    }
  });

  _exports.default = _default;
});