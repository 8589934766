define("mvp/pods/components/ui-program/report-distribution/chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals d3 */
  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var data = this._generateBarChartData(this);

      var values = this._getValuesList(this);

      var labels = this._getLabelsList(this);

      var areaName = this._getAreaName(this);

      var max = d3.max(values);
      var viewBoxWidth = 300;
      var viewBoxHeight = 300;

      this._setUpResponsiveSVG(this, viewBoxWidth, viewBoxHeight);

      var svg = d3.select("#".concat(this.elementId, " svg")),
          margin = {
        top: 20,
        right: 20,
        bottom: 70,
        left: 60
      },
          width = viewBoxWidth - margin.left - margin.right,
          height = viewBoxHeight - margin.top - margin.bottom; // x axis scale

      var xScale = d3.scaleBand().domain(labels).range([0, width]).padding(0.3); // y axis scale

      var yScale = d3.scaleLinear().domain([0, max]).range([height, 0]);
      var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
      var tooltip = d3.select("body").append("div").style("position", "absolute").style("opacity", 0);

      this._drawXAxis(g, margin, width, height, xScale);

      this._drawYAxis(g, margin, height, yScale);

      this._drawBarChart(this, g, data, height, xScale, yScale, tooltip, areaName);
    },
    _generateBarChartData: function _generateBarChartData(self) {
      var raw_data = Ember.get(self, 'data');
      var raw_labels = raw_data[0].slice(1);
      var raw_values = raw_data[1].slice(1);
      return raw_values.map(function (obj, index) {
        return {
          label: raw_labels[index],
          value: obj
        };
      });
    },
    _getValuesList: function _getValuesList(self) {
      var raw_data = Ember.get(self, 'data');
      var raw_values = raw_data[1].slice(1);
      return raw_values;
    },
    _getLabelsList: function _getLabelsList(self) {
      var raw_data = Ember.get(self, 'data');
      var raw_labels = raw_data[0].slice(1);
      return raw_labels;
    },
    _getAreaName: function _getAreaName(self) {
      var raw_data = Ember.get(self, 'data');
      var area_name = raw_data[1][0];
      return area_name;
    },
    _setUpResponsiveSVG: function _setUpResponsiveSVG(self, viewBoxWidth, viewBoxHeight) {
      // set up svg and container divs for responsive design
      d3.select("#".concat(self.elementId, " div#chartId")).append("div").classed("svg-container", true).append("svg").attr("preserveAspectRatio", "xMinYMin meet").attr("viewBox", "0 0 ".concat(viewBoxWidth, " ").concat(viewBoxHeight)).classed("svg-content-responsive", true);
    },
    _drawXAxis: function _drawXAxis(g, margin, width, height, xScale) {
      // x axis
      g.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + height + ")").call(d3.axisBottom(xScale)).selectAll("text").attr("y", 7).attr("x", 7).attr("dy", ".35em").attr("transform", "rotate(45)").style("text-anchor", "start"); // text label for the x axis

      g.append("text").attr("transform", "translate(" + width / 2 + "," + (height + margin.top + 30) + ")").style("text-anchor", "middle").text("Dilution (MIC µg/mL)");
    },
    _drawYAxis: function _drawYAxis(g, margin, height, yScale) {
      // y axis
      g.append("g").attr("class", "axis axis--y").call(d3.axisLeft(yScale).ticks(10)); // text label for the y axis

      g.append("text").attr("transform", "rotate(-90)").attr("y", 0 - margin.left).attr("x", 0 - height / 2).attr("dy", "1em").style("text-anchor", "middle").text("Frequency");
    },
    _drawBarChart: function _drawBarChart(self, g, data, height, xScale, yScale, tooltip, areaName) {
      // bar chart
      g.selectAll(".bar").data(data).enter().append("rect").attr("class", "bar").attr("fill", "#1f69e0").attr("x", function (d) {
        return xScale(d.label);
      }).attr("width", xScale.bandwidth()).attr("height", 0).attr("y", height).on("mouseover", function (d) {
        self._barMouseOver(self, g, d, tooltip, areaName);
      }).on("mousemove", function () {
        self._barMouseMove(tooltip);
      }).on("mouseleave", function (d) {
        self._barMouseLeave(g, d, tooltip);
      }); // animate in appearance of bars

      g.selectAll(".bar").transition().attr("height", function (d) {
        return height - yScale(d.value);
      }).attr("y", function (d) {
        return yScale(d.value);
      }).delay(function (d, i) {
        return i * 40;
      }).duration(1000).ease(d3.easeBounceOut);
    },
    _barMouseOver: function _barMouseOver(self, g, d, tooltip, areaName) {
      tooltip.transition().style("opacity", 0.95);
      tooltip.html(self._tooltipHTML(d, areaName)).style('left', d3.event.pageX + 25 + "px").style('top', d3.event.pageY - 80 + "px");
      g.selectAll(".bar").filter(function (barData) {
        return barData.value === d.value;
      }).style("fill", "#e21f57");
    },
    _tooltipHTML: function _tooltipHTML(d, areaName) {
      return "\n      <div class=\"d3ChartTooltip\">\n        <table class=\"d3ChartTooltip-table\">\n          <thead>\n            <th colspan=\"2\" class=\"d3ChartTooltip-th\">\n              ".concat(d.label, "\n            </th>\n          </thead>\n          <tr>\n            <td class=\"d3ChartTooltip-td\">\n              ").concat(areaName, "\n            </td>\n            <td class=\"d3ChartTooltip-td\">\n              ").concat(d.value, "\n            </td>\n          </tr>\n        </table>\n      </div>");
    },
    _barMouseMove: function _barMouseMove(tooltip) {
      tooltip.style('left', d3.event.pageX + 25 + "px").style('top', d3.event.pageY - 80 + "px");
    },
    _barMouseLeave: function _barMouseLeave(g, d, tooltip) {
      tooltip.transition().style("opacity", 0);
      g.selectAll(".bar").filter(function (barData) {
        return barData.value === d.value;
      }).style("fill", "#1f69e0");
    }
  });

  _exports.default = _default;
});