define("mvp/pods/components/ui-application/footer/copyright/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['invertedClass'],
    inverted: false,
    invertedClassName: Ember.computed('inverted', function () {
      return this.inverted ? 'inverted' : '';
    }),
    year: Ember.computed("", function () {
      return new Date().getFullYear();
    })
  });

  _exports.default = _default;
});