define("mvp/models/demo", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    year: _emberData.default.attr("number"),
    objective: _emberData.default.attr("string"),
    lines: _emberData.default.attr("array"),
    organization: _emberData.default.belongsTo("organization", {
      async: true
    })
  });

  _exports.default = _default;
});