define("mvp/services/lock", ["exports", "auth0-lock"], function (_exports, _auth0Lock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    lock: Ember.computed("", function () {
      return new _auth0Lock.default('CL1tnoMzDkfDI13LFabRdNpipo6XkfKl', 'auth.jmilabs.com', {
        configurationBaseUrl: 'https://cdn.auth0.com',
        auth: {
          responseType: 'token id_token'
        },
        container: 'root',
        avatar: null,
        allowSignUp: true,
        allowedConnections: ['Automatic-migration-db', 'jmilabs-waad', 'google-oauth2']
      });
    })
  });

  _exports.default = _default;
});