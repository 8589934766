define("mvp/pods/components/ui-demo-data/hrls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = ["Alert Manager, Do Not Process", "Discard (do not log)"]; // These are the strings in the highrisklevel column that are to trigger the alerts and red bug icons and text. 

  _exports.default = _default;
});