define("mvp/pods/surveillance/show/publications/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    surveillanceShowController: Ember.inject.controller("surveillance.show"),
    project: Ember.computed.alias("surveillanceShowController.model"),
    actions: {
      "filter-selected": function filterSelected(filter) {
        var self = this;
        this.transitionToRoute("surveillance.loading");
        filter.reload().then(self._updateRoute.bind(self));
      }
    },
    _updateRoute: function _updateRoute(filter) {
      this.replaceRoute("surveillance.show.results.overview", Ember.get(this, 'project.slug'), filter.id);
    }
  });

  _exports.default = _default;
});