define("mvp/pods/components/ui-admin/sentry-compliance/status-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1C8E4KMU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"status\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,[\"status\",\"isolates_viable_count\"]],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[1,[23,[\"status\",\"isolates_promised_count\"]],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[1,[23,[\"status\",\"isolates_pending_count\"]],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"show_modal_link\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[9],[0,\"\\n        \"],[1,[23,[\"status\",\"isolates_non_viable_count\"]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"view-non-viable\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,[\"status\",\"isolates_non_viable_count\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-admin/sentry-compliance/status-cell/template.hbs"
    }
  });

  _exports.default = _default;
});