define("mvp/helpers/json-stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.jsonStringify = jsonStringify;
  _exports.default = void 0;

  function jsonStringify(params
  /*, hash*/
  ) {
    return encodeURIComponent(JSON.stringify(params[0]));
  }

  var _default = Ember.Helper.helper(jsonStringify);

  _exports.default = _default;
});