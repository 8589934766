define("mvp/pods/save-svg/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals download, SecurityError */

  /* jshint loopfunc: true */
  var _default = Ember.Service.extend({
    doctype: '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
    isExternal: function isExternal(url) {
      return url && url.lastIndexOf('http', 0) === 0 && url.lastIndexOf(window.location.host) === -1;
    },
    inlineImages: function inlineImages(el, callback) {
      var images = el.querySelectorAll('image');
      var left = images.length;

      if (left === 0) {
        callback();
      }

      for (var i = 0; i < images.length; i++) {
        (function (image) {
          var href = image.getAttributeNS("http://www.w3.org/1999/xlink", "href");

          if (href) {
            if (this.isExternal(href.value)) {
              return;
            }
          }

          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          var img = new Image();
          href = href || image.getAttribute('href');
          img.src = href;

          img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            image.setAttributeNS("http://www.w3.org/1999/xlink", "href", canvas.toDataURL('image/png'));
            left--;

            if (left === 0) {
              callback();
            }
          };

          img.onerror = function () {
            left--;

            if (left === 0) {
              callback();
            }
          };
        })(images[i]);
      }
    },
    styles: function styles(el, selectorRemap) {
      var css = "";
      var sheets = document.styleSheets;

      for (var i = 0; i < sheets.length; i++) {
        var rules;

        try {
          rules = sheets[i].cssRules;
        } catch (e) {
          continue;
        }

        if (rules !== null) {
          for (var j = 0; j < rules.length; j++) {
            var rule = rules[j];

            if (typeof rule.style !== "undefined") {
              var match = null;
              match = el.querySelector(rule.selectorText);

              if (match) {
                var selector = selectorRemap ? selectorRemap(rule.selectorText) : rule.selectorText;
                css += selector + " { " + rule.style.cssText + " }\n";
              } else if (rule.cssText.match(/^@font-face/)) {
                css += rule.cssText + '\n';
              }
            }
          }
        }
      }

      css += "svg {font: 10px sans-serif; }";
      return css;
    },
    getDimension: function getDimension(el, clone, dim) {
      var v = el.viewBox && el.viewBox.baseVal && el.viewBox.baseVal[dim] || clone.getAttribute(dim) !== null && !clone.getAttribute(dim).match(/%$/) && parseInt(clone.getAttribute(dim)) || el.getBoundingClientRect()[dim] || parseInt(clone.style[dim]) || parseInt(window.getComputedStyle(el).getPropertyValue(dim));
      return typeof v === 'undefined' || v === null || isNaN(parseFloat(v)) ? 0 : v;
    },
    reEncode: function reEncode(data) {
      data = encodeURIComponent(data);
      data = data.replace(/%([0-9A-F]{2})/g, function (match, p1) {
        var c = String.fromCharCode('0x' + p1);
        return c === '%' ? '%25' : c;
      });
      return decodeURIComponent(data);
    },
    svgAsDataUri: function svgAsDataUri(el, options, cb) {
      options = options || {};
      options.scale = options.scale || 1;
      var xmlns = "http://www.w3.org/2000/xmlns/";

      var _self = this;

      this.inlineImages(el, function () {
        var outer = document.createElement("div");
        var clone = el.cloneNode(true);
        var svg;
        var width, height;

        if (el.tagName === 'svg') {
          width = options.width || _self.getDimension(el, clone, 'width');
          height = options.height || _self.getDimension(el, clone, 'height');
        } else if (el.getBBox) {
          var box = el.getBBox();
          width = box.x + box.width;
          height = box.y + box.height;
          clone.setAttribute('transform', clone.getAttribute('transform').replace(/translate\(.*?\)/, ''));
          svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          svg.appendChild(clone);
          clone = svg;
        } else {
          return;
        }

        clone.setAttribute("version", "1.1");
        clone.setAttributeNS(xmlns, "xmlns", "http://www.w3.org/2000/svg");
        clone.setAttributeNS(xmlns, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        clone.setAttribute("width", width * options.scale);
        clone.setAttribute("height", height * options.scale);
        clone.setAttribute("viewBox", [options.left || 0, options.top || 0, width, height].join(" "));
        outer.appendChild(clone);

        var css = _self.styles(el, options.selectorRemap);

        var s = document.createElement('style');
        s.setAttribute('type', 'text/css');
        s.innerHTML = "<![CDATA[\n" + css + "\n]]>";
        var defs = document.createElement('defs');
        defs.appendChild(s);
        clone.insertBefore(defs, clone.firstChild);
        svg = Ember.get(_self, "doctype") + outer.innerHTML;
        var uri = 'data:image/svg+xml;base64,' + window.btoa(_self.reEncode(svg));

        if (cb) {
          cb(uri);
        }
      });
    },
    svgAsPngUri: function svgAsPngUri(el, options, cb) {
      this.svgAsDataUri(el, options, function (uri) {
        var image = new Image();

        image.onload = function () {
          var canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext('2d');

          if (options && options.backgroundColor) {
            context.fillStyle = options.backgroundColor;
            context.fillRect(0, 0, canvas.width, canvas.height);
          }

          context.drawImage(image, 0, 0);
          document.createElement('a');
          var png;

          try {
            png = canvas.toDataURL('image/png');
          } catch (e) {
            if (typeof SecurityError !== 'undefined' && e instanceof SecurityError || e.name === "SecurityError") {
              return;
            } else {
              throw e;
            }
          }

          cb(png);
        };

        image.src = uri;
      });
    },
    saveSvgAsPng: function saveSvgAsPng(el, name, options) {
      options = options || {};
      this.svgAsPngUri(el, options, function (uri) {
        download(uri, name, "image/png");
      });
    }
  });

  _exports.default = _default;
});