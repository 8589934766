define("mvp/pods/site-management/compliance-summary/global/controller", ["exports", "lodash", "mvp/config/environment"], function (_exports, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['sentryYear'],
    sentryYear: _environment.default.APP.SETTINGS.DEMO_ENTRY_YEAR,
    yearOptions: [{
      name: 2022,
      value: 2022
    }, {
      name: 2021,
      value: 2021
    }, {
      name: 2020,
      value: 2020
    }],
    totalCounts: Ember.computed("globalCounts", function () {
      var receivedTotal = _lodash.default.sumBy(this.globalCounts, "receivedTotal");

      var targetTotal = _lodash.default.sumBy(this.globalCounts, "targetTotal");

      var targetPct = _lodash.default.round(receivedTotal / targetTotal * 100, 2);

      var sitesSentCount = _lodash.default.sumBy(this.globalCounts, "sitesSentCount");

      var sitesTotal = _lodash.default.sumBy(this.globalCounts, "sitesTotal");

      var sitesCompleteCount = _lodash.default.sumBy(this.globalCounts, "sitesCompleteCount");

      return {
        receivedTotal: receivedTotal,
        targetTotal: targetTotal,
        targetPct: targetPct,
        sitesSentCount: sitesSentCount,
        sitesTotal: sitesTotal,
        sitesCompleteCount: sitesCompleteCount
      };
    }),
    globalCounts: Ember.computed("model", function () {
      var _this = this;

      var site_recruitments = this.get("model").allSiteRecruiments;

      var recruitment_groups = _lodash.default.sortedUniq(_lodash.default.map(site_recruitments, function (sr) {
        return sr.recruitmentGroup;
      }));

      var rec_group_array = [];

      _lodash.default.forEach(recruitment_groups, function (rec_group) {
        rec_group_array.push({
          "name": rec_group,
          "siteCounts": {},
          "sites": [],
          "sitesSentArray": [],
          "sitesSentCount": 0,
          "sitesCompleteArray": [],
          "sitesCompleteCount": 0,
          "sitesTotal": 0,
          "receivedTotal": 0,
          "targetTotal": 0,
          "targetPct": 0
        });
      });

      _lodash.default.forEach(site_recruitments, function (sr) {
        _lodash.default.map(rec_group_array, function (rga) {
          if (rga.name == sr.recruitmentGroup) {
            var site_rec_total = _this.siteReceivedTotal(sr.siteObjectives);

            var site_target_total = _this.siteTargetTotal(sr.siteObjectives);

            rga.siteCounts[sr.siteId] = rga.siteCounts[sr.siteId] || 0;
            rga.siteCounts[sr.siteId] += site_rec_total;
            rga.sitesTotal = _lodash.default.keys(rga.siteCounts).length;
            var site_comp_pct = site_rec_total / site_target_total || 0;

            var site_code_country = _this.siteCodeCountry(sr.site.code, sr.site.country);

            if (site_comp_pct > .95) {
              if (!rga.sitesCompleteArray.includes(site_code_country)) {
                rga.sitesCompleteArray.push(site_code_country);
                rga.sitesCompleteCount++;
              }
            }

            rga.receivedTotal += site_rec_total;
            rga.targetTotal += site_target_total;
            rga.sites.push(site_code_country);

            if (rga.siteCounts[sr.siteId] > 0) {
              if (!rga.sitesSentArray.includes(site_code_country)) {
                rga.sitesSentArray.push(site_code_country);
                rga.sitesSentCount++;
              }
            }

            rga.sites = _lodash.default.sortedUniq(rga.sites);
            rga.targetPct = _this.siteTargetPercent(rga.receivedTotal, rga.targetTotal);
          }
        });
      });

      return rec_group_array;
    }),
    siteCodeCountry: function siteCodeCountry(code, country) {
      return "".concat(code, " (").concat(country, ")");
    },
    siteTargetPercent: function siteTargetPercent(rec_total, target_total) {
      return ((rec_total / target_total || 0) * 100).toFixed(2);
    },
    siteReceivedTotal: function siteReceivedTotal(siteObjectives) {
      return _lodash.default.reduce(siteObjectives, function (accu, so) {
        return accu + JSON.parse(so.lvCounts).receivedCount;
      }, 0);
    },
    siteTargetTotal: function siteTargetTotal(siteObjectives) {
      return _lodash.default.reduce(siteObjectives, function (accu, so) {
        return accu + so.targetCount;
      }, 0);
    },
    actions: {
      yearChanged: function yearChanged(sentryYear) {
        Ember.set(this, 'sentryYear', sentryYear);
      },
      toggleGlobalCountDetails: function toggleGlobalCountDetails(name) {
        var button = Ember.$(event.target);
        var dash_name = name.replace(/\s+/g, '-').toLowerCase();
        var row = Ember.$("#global-count-name-" + dash_name);
        button.toggleClass("active");
        row.toggleClass("active");

        if (button.hasClass("active")) {
          button.html("<i class='minus icon'></i> Hide");
          row.show();
        } else {
          button.html("<i class='plus icon'></i> View");
          row.hide();
        }
      }
    },
    requiredObjTotalReceivedCount: Ember.computed("model", function () {
      var _this2 = this;

      var site_total_count_array = [];

      _lodash.default.map(this.get("model").sites.records, function (site) {
        var site_count_details = {
          site: site.code,
          total_count: 0
        };

        _lodash.default.map(site.siteObjectives, function (site_obj) {
          if (_this2.requiredObjectives.includes(site_obj.objective.name)) {
            var received_count = JSON.parse(site_obj.lvCounts).receivedCount;
            site_count_details.total_count += received_count;
          }
        });

        site_total_count_array.push(site_count_details);
      }).filter(function (n) {
        return n;
      });

      return site_total_count_array;
    }),
    optionalObjectives: Ember.computed("model", function () {
      return _lodash.default.map(this.get("model").objs, function (el) {
        if (!el.requiredForSentryYear) {
          return el.name;
        }
      }).filter(function (n) {
        return n;
      });
    })
  });

  _exports.default = _default;
});