define("mvp/pods/components/ui-admin/sentry-compliance/non-viable-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DprREIGc",
    "block": "{\"symbols\":[\"isolate-demo-detail\"],\"statements\":[[7,\"i\"],[11,\"class\",\"close icon\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n  Viewing \"],[1,[21,\"year\"],false],[0,\" Objective \"],[1,[21,\"objective\"],false],[0,\"\\n  Non-Viable Isolates for \"],[1,[23,[\"site\",\"name\"]],false],[0,\" (\"],[1,[23,[\"site\",\"site_code\"]],false],[0,\")\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"image content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"description\"],[9],[0,\"\\n    \"],[7,\"table\"],[11,\"class\",\"ui compact table\"],[9],[0,\"\\n      \"],[7,\"thead\"],[9],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Collection No\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Site Code\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Demo ID\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Line Number\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Objective\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Site Org Code\"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"isolate-demo-details\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,1,[\"collection_no\"]],false],[10],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,1,[\"site_code\"]],false],[10],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"\\n            \"],[7,\"a\"],[9],[0,\"\\n              \"],[1,[22,1,[\"demo_id\"]],false],[0,\"\\n            \"],[3,\"action\",[[22,0,[]],\"view-demo-page\",[22,1,[]]]],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,1,[\"line_number\"]],false],[10],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,1,[\"objective\"]],false],[10],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,1,[\"site_org_code\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-admin/sentry-compliance/non-viable-modal/template.hbs"
    }
  });

  _exports.default = _default;
});