define("mvp/pods/site-management/sites/show/contacts/new/route", ["exports", "ember-apollo-client"], function (_exports, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var site_vars = this.modelFor("site-management.sites.show");
      return Ember.RSVP.hash({
        roles: this.roles(),
        statuses: this.statuses(),
        params: params,
        siteId: site_vars.id,
        changeset: {
          contact: {}
        }
      });
    },
    roles: function roles() {
      return ["guest", "registered", "co_investigator", "micro_contact", "pharm_contact", "site_director", "jmi"];
    },
    statuses: function statuses() {
      return ["active", "pending", "revoked"];
    }
  });

  _exports.default = _default;
});