define("mvp/pods/components/ui-demo-data/date-input/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals moment */
  var _default = Ember.Component.extend({
    classNames: ["ui", "grid"],
    months: [{
      "value": "01",
      "name": "01 - JAN"
    }, {
      "value": "02",
      "name": "02 - FEB"
    }, {
      "value": "03",
      "name": "03 - MAR"
    }, {
      "value": "04",
      "name": "04 - APR"
    }, {
      "value": "05",
      "name": "05 - MAY"
    }, {
      "value": "06",
      "name": "06 - JUN"
    }, {
      "value": "07",
      "name": "07 - JUL"
    }, {
      "value": "08",
      "name": "08 - AUG"
    }, {
      "value": "09",
      "name": "09 - SEP"
    }, {
      "value": "10",
      "name": "10 - OCT"
    }, {
      "value": "11",
      "name": "11 - NOV"
    }, {
      "value": "12",
      "name": "12 - DEC"
    }],
    month: undefined,
    day: undefined,
    year: undefined,
    monthUrl: "/api/demos/months",
    isValid: Ember.computed("month", "day", "year", function () {
      var month = this.month;
      var day = this.day;
      var year = this.year;
      return moment(year + "-" + month + "-" + this.pad(day, 2), 'YYYY-MM-DD', true).isValid();
    }),
    observeMonthDropdown: function () {
      if (this.month !== undefined) {
        this.monthDropdown.send("set-selected", this.month);
      }

      var dropdownId = "#" + this.monthDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("monthDropdown"),
    years: Ember.computed(function () {
      return [this.fixedYear, this.fixedYear - 1];
    }),
    updateDate: function updateDate(date) {
      var _this = this;

      if (this.onUpdate) {
        this.onUpdate(date !== "" ? date : undefined);
      } else {
        Ember.run.scheduleOnce('actions', this, function () {
          Ember.set(_this, "date", date);
        });
      }
    },
    observeVals: function () {
      if (this.isValid) {
        var month = this.month;
        var day = this.day;
        var year = this.year; // can't do this in the current render loop so delay it to later
        // set(this, "date",
        // year + "-" + month + "-" + get(this, "pad")(day,2)
        // );

        this.updateDate(year + "-" + month + "-" + this.pad(day, 2));
      } else if (this.date !== "") {
        // can't do this in the current render loop so delay it to later
        // set(this, "date", "");
        this.updateDate("");
      }
    }.observes("month", "day", "year"),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.date !== undefined) {
        var _moment = moment(this.date.split(" ")[0], "YYYY-MM-DD", true);

        if (_moment.isValid()) {
          this.setProperties({
            year: _moment.year(),
            month: this.pad(_moment.month() + 1, 2),
            day: _moment.date()
          });
        } else {
          if (this.fixedYear !== undefined) {
            Ember.set(this, "year", this.fixedYear);
          }
        }
      } else {
        this.setProperties({
          year: _environment.default.APP.SETTINGS.DEMO_ENTRY_YEAR
        });
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.$("#" + this.elementId + " .month.dropdown").dropdown({
        onChange: function onChange(val) {
          Ember.set(self, 'month', val);
        }
      });
      Ember.$("#" + this.elementId + " .day.input input").keypress(this["only-numeric-input"]); // Ember.$("#" + this.elementId + " .year.input input").keypress(get(this, "only-numeric-input"));

      var self = this;
      Ember.$("#" + this.elementId + " .year.dropdown").dropdown({
        onChange: function onChange(year) {
          Ember.set(self, 'year', year);
        }
      });
    },
    actions: {
      "update-month": function updateMonth(val) {
        Ember.set(this, "month", val);
      },
      "update-year": function updateYear() {}
    },
    "only-numeric-input": function onlyNumericInput(evt) {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    },
    pad: function pad(num, size) {
      var s = num + "";

      while (s.length < size) {
        s = "0" + s;
      }

      return s;
    }
  });

  _exports.default = _default;
});