define("mvp/pods/components/ui-filter-result/mic-distribution/table/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service('store'),
    breakpointYear: _environment.default.APP.SETTINGS.BREAKPOINT_YEAR,
    sortedIsolates: Ember.computed.sort('isolates', 'sortDefinition'),
    init: function init() {
      this._super.apply(this, arguments);

      this.visibleRows = [];
      this.sortDefinition = ['accession_number'];
      this.visibleGroupings = {};
      this.expandedItems = {};
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.get_colorize_breakpoint_info();
      Ember.set(this, 'visibleGroupings', {});
      Ember.set(this, 'expandedItems', {});
      this.showRows();
    },
    didInsertElement: function didInsertElement() {
      Ember.$('body').scrollTop(0);
      Ember.$("#" + this.elementId + " .table-wrapper-scroll-x").stickyTableHeaders({
        fixedOffset: 0
      });
    },
    get_colorize_breakpoint_info: function get_colorize_breakpoint_info() {
      var _this = this;

      var ajax = this.ajax;
      var self = this;
      self.set('colorize_lookup', {});
      self.set('drug_alias_lookup', {});
      var table_drugs = new Set(this.get('table.rows').map(function (row) {
        return row.drug;
      }));
      table_drugs = Array.from(table_drugs).join(',');
      var url = "/api/project-drugs/breakpoint_drug_alias?" + "multiple_alias=" + encodeURIComponent(table_drugs);
      ajax.request(url).then(function (response) {
        // console.log(response);
        self.set('drug_alias_lookup', response);

        var post_orgs = _this.organismCounts.map(function (val) {
          return val["organism-code"];
        });

        var post_drugs = response.map(function (val) {
          return val["name"];
        }); // old docker swarm elixir
        // let colorize_url = "https://sentry-api.jmilabs.com/api/colorize";
        // new ec2 elixir

        var colorize_url = "https://interpretations.jmilabs.com/api/colorize"; // for local running of elixir
        // let colorize_url = "http://localhost:2998/api/colorize";

        /* eslint-disable ember/jquery-ember-run */

        Ember.$.ajax({
          url: colorize_url,
          method: 'POST',
          data: {
            organisms: post_orgs,
            drugs: post_drugs
          },
          crossDomain: true
        }).then(function (response) {
          // console.log(response);
          self.set('colorize_lookup', response);
        });
        /* eslint-enable ember/jquery-ember-run */
      });
    },
    actions: {
      'view-isolates-at': function viewIsolatesAt(drug, groupings, dilution) {
        this['view-isolates-at'](drug, groupings, dilution);
      },
      contract: function contract(row, key) {
        var drug = row.drug;
        var visibleGroupings = this.visibleGroupings;
        var actual_groupings = {};
        row.groupings.forEach(function (item) {
          if (item.value !== null) {
            actual_groupings[item.key] = item.value;
          }
        });
        var expandedItems = this.expandedItems;
        expandedItems[row.drug][key].removeObject(JSON.stringify(row.groupings));
        Ember.set(this, 'expandedItems', expandedItems);
        this.notifyPropertyChange('expandedItems');
        var temp = Ember.$.extend(true, {}, actual_groupings);
        delete temp[key];
        visibleGroupings[drug][key].removeObject(JSON.stringify(temp));
        Ember.set(this, 'visibleGroupings', visibleGroupings);
        this.showRows();
      },
      expand: function expand(row, key) {
        var drug = row.drug;
        var visibleGroupings = this.visibleGroupings;
        var actual_groupings = {};
        row.groupings.forEach(function (item) {
          if (item.value !== null) {
            actual_groupings[item.key] = item.value;
          }
        });
        var expandedItems = this.expandedItems;

        if (expandedItems[row.drug] === undefined) {
          expandedItems[row.drug] = {};
        }

        if (expandedItems[row.drug][key] === undefined) {
          expandedItems[row.drug][key] = [];
        }

        expandedItems[row.drug][key].addObject(JSON.stringify(row.groupings));
        Ember.set(this, 'expandedItems', expandedItems);
        this.notifyPropertyChange('expandedItems');
        var temp = Ember.$.extend(true, {}, actual_groupings);
        delete temp[key];

        if (visibleGroupings[drug] === undefined) {
          visibleGroupings[drug] = {};
        }

        if (visibleGroupings[drug][key] === undefined) {
          visibleGroupings[drug][key] = [];
        }

        visibleGroupings[drug][key].addObject(JSON.stringify(temp));
        Ember.set(this, 'visibleGroupings', visibleGroupings);
        this.showRows();
      }
    },
    showRows: function showRows() {
      var rowsToAdd = [];
      var visibleGroupings = this.visibleGroupings;
      Ember.get(this, 'table.rows').forEach(function (row) {
        var shouldAddRow = false;
        var actual_groupings = {};
        row.groupings.forEach(function (item) {
          if (item.value !== null) {
            actual_groupings[item.key] = item.value;
          }
        });

        if (Object.keys(actual_groupings).length === 0) {
          shouldAddRow = true;
        } else if (visibleGroupings[row.drug]) {
          var expandDrugBy = visibleGroupings[row.drug];
          Object.keys(visibleGroupings[row.drug]).forEach(function (key) {
            if (actual_groupings[key]) {
              var temp = Ember.$.extend(true, {}, actual_groupings);
              delete temp[key];

              if (expandDrugBy !== undefined && expandDrugBy[key] !== undefined && expandDrugBy[key].includes(JSON.stringify(temp))) {
                shouldAddRow = true;
              }
            }
          });
        }

        if (shouldAddRow) {
          rowsToAdd.addObject(row);
        }
      });
      Ember.set(this, 'visibleRows', rowsToAdd);
    }
  });

  _exports.default = _default;
});