define("mvp/pods/components/ui-surveillance/adhoc/demographic/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //////////////
    // Services //
    //////////////
    authorization: Ember.inject.service(),
    // Provide access to the current signed in user
    currentUser: Ember.inject.service("current-user"),
    // Provide a pubsub mechanism to listen to and invoke events
    eventBus: Ember.inject.service("event-bus"),
    // Args
    prevalenceMode: false,
    // what to show
    showMedicalService: Ember.computed('authorization.role', function () {
      return Ember.get(this, 'authorization.role') === 'jmi';
    }),
    showObjective: Ember.computed('authorization.isPublicUser', function () {
      return !Ember.get(this, 'authorization.isPublicUser');
    }),
    ////////////////
    // Properties //
    ////////////////
    criteriaHoldback: false,
    // Contains the current filter as built in the ui-surveillance/adhoc component
    filter: undefined,
    // Stores a referenence to the organism dropdown component, the component
    // will register to this values through the `register-as` attribute.
    infectionTypeDropdown: undefined,
    infectionSourceDropdown: undefined,
    nosocomialDropdown: undefined,
    vapDropdown: undefined,
    medicalServiceDropdown: undefined,
    genderDropdown: undefined,
    icuDropdown: undefined,
    objectiveDropdown: undefined,
    categoryDropdown: undefined,
    coreSiteDropdown: undefined,
    diagnosisDropdown: undefined,
    //////////////
    // category //
    //////////////
    initializeCategoryDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.client_category_value.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.client_category_value.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(encodeURIComponent(option));
        });
      }

      if (selected.length > 0) {
        this.categoryDropdown.send("set-selected", selected);
      }
    }.observes("categoryDropdown"),
    // Observe changes to organism
    observeCategory: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var category;

      if (Ember.get(this, "category.values.length") > 0) {
        category = {};
        category[Ember.get(this, "category.inclusion").toLowerCase()] = Ember.$.extend(true, [], Ember.get(this, "category.values"));
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("client_category_value", category);
      });
    }.observes("category"),
    observeCriteriaForCategoryReset: function () {
      if (Ember.get(this, "filter.criteria.client_category_value") === undefined && Ember.get(this, "category.values.length") > 0) {
        this.resetCategory();
      } else {
        if (this.criteriaHoldback === false && Ember.get(this, "filter.criteria.client_category_value") !== undefined && Ember.get(this, "filter.criteria.client_category_value")[Ember.get(this, 'category.inclusion').toLowerCase()].length !== Ember.get(this, "category.values").length) {
          var values = Ember.get(this, "filter.criteria.client_category_value")[Ember.get(this, 'category.inclusion').toLowerCase()];
          var selected = [];
          values.forEach(function (option) {
            selected.addObject(encodeURIComponent(option));
          });
          Ember.set(this, 'category.values', Ember.$.extend(true, [], values));
          Ember.set(this, 'criteriaHoldback', true);

          if (selected.length > 0) {
            Ember.run.scheduleOnce('afterRender', this, function () {
              Ember.$("#" + Ember.get(this, "categoryDropdown.elementId")).dropdown("set exactly", selected);

              var _self = this;

              Ember.run.later(function () {
                Ember.set(_self, 'criteriaHoldback', false);
              }, 100);
            });
          }
        }
      }
    }.observes("filter.criteria"),
    categoryDefinitionsRoute: Ember.computed("project.slug", function () {
      return "/app/" + Ember.get(this, "project.slug") + "/category-definitions";
    }),
    categoryEnabled: Ember.computed("filter.criteria", function () {
      // dropdown should be enabled if we have selected a project
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    categoryUrl: Ember.computed("filter.criteria", function () {
      var criteria = {}; // Don't reset category anomore if key changed
      // if (get(this, "filter.criteria.year") !== undefined) {
      //   criteria["year"] = get(this, "filter.criteria.year");
      // }
      // if (get(this, "filter.criteria.continent") !== undefined) {
      //   criteria["continent"] = get(this, "filter.criteria.continent");
      // }
      // if (get(this, "filter.criteria.genus") !== undefined) {
      //   criteria["genus"] = get(this, "filter.criteria.genus");
      // }
      // if (get(this, "filter.criteria.organisms") !== undefined) {
      //   criteria["organisms"] = get(this, "filter.criteria.organisms");
      // }
      // if (get(this, "filter.criteria.group") !== undefined) {
      //   criteria["group"] = get(this, "filter.criteria.group");
      // }
      // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/categories" + "?project_name=" + encodeURIComponent(Ember.get(this, "filter.criteria.project")) + "&criteria=" + encodeURIComponent(JSON.stringify(criteria));
    }),
    ////////////////////
    // Infection Type //
    ////////////////////
    initializeInfectionTypeDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.client_infection_type.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.client_infection_type.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.infectionTypeDropdown.send("set-selected", selected);
      }
    }.observes("infectionTypeDropdown"),
    // Observe changes to organism
    observeInfectionType: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var infectionType;

      if (Ember.get(this, "infectionType.values.length") > 0) {
        infectionType = {};
        infectionType[Ember.get(this, "infectionType.inclusion").toLowerCase()] = Ember.get(this, "infectionType.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("client_infection_type", infectionType);
      });
    }.observes("infectionType"),
    observeCriteriaForInfectionTypeReset: function () {
      if (Ember.get(this, "filter.criteria.client_infection_type") === undefined && Ember.get(this, "infectionType.values.length") > 0) {
        this.resetInfectionType();
      }
    }.observes("filter.criteria"),
    infectionTypeEnabled: Ember.computed("filter.criteria", function () {
      // dropdown should be enabled if we have selected a project
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    infectionTypeUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/infection-types" + "?project_name=" + encodeURIComponent(project_name);
    }),
    //////////////////////
    // Infection Source //
    //////////////////////
    initializeInfectionSourceDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.client_infection_source.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.client_infection_source.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.infectionSourceDropdown.send("set-selected", selected);
      }
    }.observes("infectionSourceDropdown"),
    // Observe changes to organism
    observeInfectionSource: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var infectionSource;

      if (Ember.get(this, "infectionSource.values.length") > 0) {
        infectionSource = {};
        infectionSource[Ember.get(this, "infectionSource.inclusion").toLowerCase()] = Ember.get(this, "infectionSource.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("client_infection_source", infectionSource);
      });
    }.observes("infectionSource"),
    observeCriteriaForInfectionSourceReset: function () {
      if (Ember.get(this, "filter.criteria.client_infection_source") === undefined && Ember.get(this, "infectionSource.values.length") > 0) {
        this.resetInfectionSource();
      }
    }.observes("filter.criteria"),
    infectionSourceEnabled: Ember.computed("filter.criteria", function () {
      // dropdown should be enabled if we have selected a project
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    infectionSourceUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/infection-sources" + "?project_name=" + encodeURIComponent(project_name);
    }),
    ////////////////
    // Nosocomial //
    ////////////////
    initializeNosocomialDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.nosocomial.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.nosocomial.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.nosocomialDropdown.send("set-selected", selected);
      }
    }.observes("nosocomialDropdown"),
    // Observe changes to organism
    observeNosocomial: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var nosocomial;

      if (Ember.get(this, "nosocomial.values.length") > 0) {
        nosocomial = {};
        nosocomial[Ember.get(this, "nosocomial.inclusion").toLowerCase()] = Ember.get(this, "nosocomial.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("nosocomial", nosocomial);
      });
    }.observes("nosocomial"),
    observeCriteriaForNosocomialReset: function () {
      if (Ember.get(this, "filter.criteria.nosocomial") === undefined && Ember.get(this, "nosocomial.values.length") > 0) {
        this.resetNosocomial();
      }
    }.observes("filter.criteria"),
    nosocomialEnabled: Ember.computed("filter.criteria", function () {
      // dropdown should be enabled if we have selected a project
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    nosocomialUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/nosocomial-statuses" + "?project_name=" + encodeURIComponent(project_name);
    }),
    ////////////
    // Gender //
    ////////////
    initializeGenderDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.gender.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.gender.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.genderDropdown.send("set-selected", selected);
      }
    }.observes("genderDropdown"),
    // Observe changes to organism
    observeGender: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var gender;

      if (Ember.get(this, "gender.values.length") > 0) {
        gender = {};
        gender[Ember.get(this, "gender.inclusion").toLowerCase()] = Ember.get(this, "gender.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("gender", gender);
      });
    }.observes("gender"),
    observeCriteriaForGenderReset: function () {
      if (Ember.get(this, "filter.criteria.gender") === undefined && Ember.get(this, "gender.values.length") > 0) {
        this.resetGender();
      }
    }.observes("filter.criteria"),
    genderEnabled: Ember.computed("filter.criteria", function () {
      // dropdown should be enabled if we have selected a project
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    genderUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/gender" + "?project_name=" + encodeURIComponent(project_name);
    }),
    /////////
    // ICU //
    /////////
    initializeIcuDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.icu.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.icu.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.icuDropdown.send("set-selected", selected);
      }
    }.observes("icuDropdown"),
    // Observe changes to organism
    observeIcu: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var icu;

      if (Ember.get(this, "icu.values.length") > 0) {
        icu = {};
        icu[Ember.get(this, "icu.inclusion").toLowerCase()] = Ember.get(this, "icu.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("icu", icu);
      });
    }.observes("icu"),
    observeCriteriaForIcuReset: function () {
      if (Ember.get(this, "filter.criteria.icu") === undefined && Ember.get(this, "icu.values.length") > 0) {
        this.resetIcu();
      }
    }.observes("filter.criteria"),
    icuEnabled: Ember.computed("filter.criteria", function () {
      // dropdown should be enabled if we have selected a project
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    icuUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/icu" + "?project_name=" + encodeURIComponent(project_name);
    }),
    /////////
    // VAP //
    /////////
    initializeVapDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.vap.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.vap.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.vapDropdown.send("set-selected", selected);
      }
    }.observes("vapDropdown"),
    // Observe changes to organism
    observeVap: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var vap;

      if (Ember.get(this, "vap.values.length") > 0) {
        vap = {};
        vap[Ember.get(this, "vap.inclusion").toLowerCase()] = Ember.get(this, "vap.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("vap", vap);
      });
    }.observes("vap"),
    observeCriteriaForVapReset: function () {
      if (Ember.get(this, "filter.criteria.vap") === undefined && Ember.get(this, "vap.values.length") > 0) {
        this.resetVap();
      }
    }.observes("filter.criteria"),
    vapEnabled: Ember.computed("filter.criteria", function () {
      // dropdown should be enabled if we have selected a project
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    vapUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/vap" + "?project_name=" + encodeURIComponent(project_name);
    }),
    ///////////////
    // Objective //
    ///////////////
    initializeObjectiveDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.objective.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.objective.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.objectiveDropdown.send("set-selected", selected);
      }
    }.observes("objectiveDropdown"),
    observeObjective: function () {
      var objective;

      if (Ember.get(this, "objective.values.length") > 0) {
        objective = {};
        objective[Ember.get(this, "objective.inclusion").toLowerCase()] = Ember.get(this, "objective.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("objective", objective);
      });
    }.observes("objective"),
    observeCriteriaForObjectiveReset: function () {
      if (Ember.get(this, "filter.criteria.objective") === undefined && Ember.get(this, "objective.values.length") > 0) {
        this.resetObjective();
      }
    }.observes("filter.criteria"),
    objectiveEnabled: Ember.computed("filter.criteria", function () {
      // dropdown should be enabled if we have selected a project
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    objectiveUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/objectives" + "?project_name=" + encodeURIComponent(project_name);
    }),
    //////////////////////
    // Medical Service  //
    //////////////////////
    initializeMedicalServiceDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.service.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.service.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.medicalServiceDropdown.send("set-selected", selected);
      }
    }.observes("medicalServiceDropdown"),
    // Observe changes to organism
    observeMedicalService: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var medicalService;

      if (Ember.get(this, "medicalService.values.length") > 0) {
        medicalService = {};
        medicalService[Ember.get(this, "medicalService.inclusion").toLowerCase()] = Ember.get(this, "medicalService.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("service", medicalService);
      });
    }.observes("medicalService"),
    observeCriteriaForMedicalServiceReset: function () {
      if (Ember.get(this, "filter.criteria.service") === undefined && Ember.get(this, "medicalService.values.length") > 0) {
        this.resetMedicalService();
      }
    }.observes("filter.criteria"),
    medicalServiceEnabled: Ember.computed("filter.criteria", function () {
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    medicalServiceUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/medical-services" + "?project_name=" + encodeURIComponent(project_name);
    }),
    ///////////////
    // Diagnosis //
    ///////////////
    initializeDiagnosisDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.diagnosis.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.diagnosis.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.diagnosisDropdown.send("set-selected", selected);
      }
    }.observes("diagnosisDropdown"),
    // Observe changes to diagnosis
    observeDiagnosis: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var diagnosis;

      if (Ember.get(this, "diagnosis.values.length") > 0) {
        diagnosis = {};
        diagnosis[Ember.get(this, "diagnosis.inclusion").toLowerCase()] = Ember.get(this, "diagnosis.values");
      } // Send the selected diagnosis to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("diagnosis", diagnosis);
      });
    }.observes("diagnosis"),
    observeCriteriaForDiagnosisReset: function () {
      if (Ember.get(this, "filter.criteria.diagnosis") === undefined && Ember.get(this, "diagnosis.values.length") > 0) {
        this.resetDiagnosis();
      }
    }.observes("filter.criteria"),
    diagnosisEnabled: Ember.computed("filter.criteria", function () {
      // dropdown should be enabled if we have selected a project
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    diagnosisUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/diagnoses" + "?project_name=" + encodeURIComponent(project_name);
    }),
    ////////////////
    // Age Ranges //
    ////////////////
    observeCriteriaForAgeRangeReset: function () {
      if (Ember.get(this, "filter.criteria.age_range") === undefined && (Ember.get(this, "age-range.values.min") !== "" || Ember.get(this, "age-range.values.max") !== "")) {
        this.resetAgeRange();
      }
    }.observes("filter.criteria"),
    observeAgeRangeMinValue: function () {
      var min_age = Ember.get(this, "age-range.values.min");

      if (min_age === "") {
        min_age = 0;
      } else {
        min_age = Number(min_age);
      }

      var max_age = Ember.get(this, "age-range.values.max");

      if (max_age === "") {
        max_age = 125;
      } else {
        max_age = Number(max_age);
      }

      if ((min_age ^ 0) !== min_age) {
        Ember.$(".age-range.field").addClass("error");
        Ember.$(".min-age.pointing.prompt").html("Minimum age must be an integer.");
        Ember.$(".min-age.pointing.prompt").removeClass("hidden");
        Ember.$(".min-age.pointing.prompt").addClass("visible transition");
      } else if (min_age < 0) {
        Ember.$(".age-range.field").addClass("error");
        Ember.$(".min-age.pointing.prompt").html("Minimum age must be > 0.");
        Ember.$(".min-age.pointing.prompt").removeClass("hidden");
        Ember.$(".min-age.pointing.prompt").addClass("visible transition");
      } else if (min_age > 125) {
        Ember.$(".age-range.field").addClass("error");
        Ember.$(".min-age.pointing.prompt").html("Minimum age must be &#8804; 125.");
        Ember.$(".min-age.pointing.prompt").removeClass("hidden");
        Ember.$(".min-age.pointing.prompt").addClass("visible transition");
      } else if (min_age > max_age) {
        Ember.$(".age-range.field").addClass("error");
        Ember.$(".min-age.pointing.prompt").html("Minimum age must be &#8804; maximum age.");
        Ember.$(".min-age.pointing.prompt").removeClass("hidden");
        Ember.$(".min-age.pointing.prompt").addClass("visible transition");
      } else {
        Ember.$(".min-age.pointing.prompt").addClass("hidden");
        Ember.$(".min-age.pointing.prompt").removeClass("visible transition");

        if ((max_age ^ 0) !== max_age) {
          Ember.$(".age-range.field").addClass("error");
          Ember.$(".max-age.pointing.prompt").html("Maximum age must be an integer.");
          Ember.$(".max-age.pointing.prompt").removeClass("hidden");
          Ember.$(".max-age.pointing.prompt").addClass("visible transition");
        } else if (max_age < 0) {
          Ember.$(".age-range.field").addClass("error");
          Ember.$(".max-age.pointing.prompt").html("Maximum age must be > 0.");
          Ember.$(".max-age.pointing.prompt").removeClass("hidden");
          Ember.$(".max-age.pointing.prompt").addClass("visible transition");
        } else if (max_age > 125) {
          Ember.$(".age-range.field").addClass("error");
          Ember.$(".max-age.pointing.prompt").html("Maximum age must be &#8804; 125.");
          Ember.$(".max-age.pointing.prompt").removeClass("hidden");
          Ember.$(".max-age.pointing.prompt").addClass("visible transition");
        } else {
          Ember.$(".age-range.field").removeClass("error");
          Ember.$(".max-age.pointing.prompt").addClass("hidden");
          Ember.$(".max-age.pointing.prompt").removeClass("visible transition");
          this.observeAgeRange();
        }
      }
    }.observes("age-range.values.min"),
    observeAgeRangeMaxValue: function () {
      var min_age = Number(Ember.get(this, "age-range.values.min"));
      var max_age = Number(Ember.get(this, "age-range.values.max"));

      if ((max_age ^ 0) !== max_age) {
        Ember.$(".age-range.field").addClass("error");
        Ember.$(".max-age.pointing.prompt").html("Maximum age must be an integer.");
        Ember.$(".max-age.pointing.prompt").removeClass("hidden");
        Ember.$(".max-age.pointing.prompt").addClass("visible transition");
      } else if (max_age < 0) {
        Ember.$(".age-range.field").addClass("error");
        Ember.$(".max-age.pointing.prompt").html("Maximum age must be > 0.");
        Ember.$(".max-age.pointing.prompt").removeClass("hidden");
        Ember.$(".max-age.pointing.prompt").addClass("visible transition");
      } else if (max_age > 125) {
        Ember.$(".age-range.field").addClass("error");
        Ember.$(".max-age.pointing.prompt").html("Maximum age must be &#8804; 125.");
        Ember.$(".max-age.pointing.prompt").removeClass("hidden");
        Ember.$(".max-age.pointing.prompt").addClass("visible transition");
      } else {
        Ember.$(".max-age.pointing.prompt").addClass("hidden");
        Ember.$(".max-age.pointing.prompt").removeClass("visible transition");

        if ((min_age ^ 0) !== min_age) {
          Ember.$(".age-range.field").addClass("error");
          Ember.$(".min-age.pointing.prompt").html("Minimum age must be an integer.");
          Ember.$(".min-age.pointing.prompt").removeClass("hidden");
          Ember.$(".min-age.pointing.prompt").addClass("visible transition");
        } else if (min_age < 0) {
          Ember.$(".age-range.field").addClass("error");
          Ember.$(".min-age.pointing.prompt").html("Minimum age must be > 0.");
          Ember.$(".min-age.pointing.prompt").removeClass("hidden");
          Ember.$(".min-age.pointing.prompt").addClass("visible transition");
        } else if (min_age > 125) {
          Ember.$(".age-range.field").addClass("error");
          Ember.$(".min-age.pointing.prompt").html("Minimum age must be &#8804; 125.");
          Ember.$(".min-age.pointing.prompt").removeClass("hidden");
          Ember.$(".min-age.pointing.prompt").addClass("visible transition");
        } else if (min_age > max_age) {
          Ember.$(".age-range.field").addClass("error");
          Ember.$(".min-age.pointing.prompt").html("Minimum age must be &#8804; maximum age.");
          Ember.$(".min-age.pointing.prompt").removeClass("hidden");
          Ember.$(".min-age.pointing.prompt").addClass("visible transition");
        } else {
          Ember.$(".age-range.field").removeClass("error");
          Ember.$(".min-age.pointing.prompt").addClass("hidden");
          Ember.$(".min-age.pointing.prompt").removeClass("visible transition");
          this.observeAgeRange();
        }
      }
    }.observes("age-range.values.max"),
    init: function init() {
      this._super.apply(this, arguments);

      this.setupInit();
      this.infectionType = {
        inclusion: "include",
        values: []
      };
      this.infectionSource = {
        inclusion: "include",
        values: []
      };
      this.nosocomial = {
        inclusion: "include",
        values: []
      };
      this.vap = {
        inclusion: "include",
        values: []
      };
      this.medicalService = {
        inclusion: "include",
        values: []
      };
      this.gender = {
        inclusion: "include",
        values: []
      };
      this.icu = {
        inclusion: "include",
        values: []
      };
      this.objective = {
        inclusion: "include",
        values: []
      };
      Ember.set(this, "age-range", {
        inclusion: "include",
        values: {
          min: "",
          max: ""
        }
      });
      this.category = {
        inclusion: "include",
        values: []
      };
      this.coreSite = {
        inclusion: "include",
        values: []
      };
      this.diagnosis = {
        inclusion: "include",
        values: []
      };
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var isPublicUser = Ember.get(this, 'authorization.isPublicUser');

      if (isPublicUser) {
        this.$('.ui.prevalence.checkbox').checkbox({
          onChange: function onChange() {
            var mode = _this.toggleProperty('prevalenceMode');

            _this._updatePevelanceMode(mode);
          }
        });
      }
    },
    observeCriteriaForPrevalenceReset: function () {
      if (Ember.get(this, 'authorization.isPublicUser') && Ember.get(this, "filter.criteria.prevalence") === undefined) {
        this.$('.ui.prevalence.checkbox').checkbox('uncheck');
      }
    }.observes("filter.criteria"),
    _updatePevelanceMode: function _updatePevelanceMode(mode) {
      var _this2 = this;

      var setMode = mode ? true : undefined; // Send the selected organism to the parent component action

      Ember.run.scheduleOnce('actions', this, function () {
        _this2["update-filter-criteria"]("prevalence", setMode);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.cleanup();
    },
    /////////////
    // Actions //
    /////////////
    actions: {
      // Handle logic when the slected organism has changed. The values will be a
      // CSV string represention the selected values.
      "update-selected-category": function updateSelectedCategory(values) {
        if (this.criteriaHoldback === false) {
          // Parse the CSV string to pull out the selected organisms.
          var categoryValues;

          if (values !== "") {
            categoryValues = values.split(",").map(function (item) {
              return decodeURIComponent(item);
            });
          } else {
            categoryValues = [];
          } // Update the slected organism values


          Ember.set(this, "category.values", categoryValues); // Notify that observers (within this component) that the organism has
          // been updated.

          this.notifyPropertyChange("category");
        }
      },
      "update-selected-infection-type": function updateSelectedInfectionType(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "infectionType.values", selection); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("infectionType");
      },
      "update-selected-infection-source": function updateSelectedInfectionSource(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "infectionSource.values", selection); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("infectionSource");
      },
      "update-selected-nosocomial": function updateSelectedNosocomial(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "nosocomial.values", selection); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("nosocomial");
      },
      "update-selected-gender": function updateSelectedGender(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "gender.values", selection); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("gender");
      },
      "update-selected-icu": function updateSelectedIcu(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "icu.values", selection); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("icu");
      },
      "update-selected-vap": function updateSelectedVap(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "vap.values", selection); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("vap");
      },
      "update-selected-objective": function updateSelectedObjective(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "objective.values", selection); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("objective");
      },
      "update-selected-medical-service": function updateSelectedMedicalService(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "medicalService.values", selection); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("medicalService");
      },
      "update-selected-core-site": function updateSelectedCoreSite(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return item === 'true';
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "coreSite.values", selection); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("coreSite");
      },
      "update-selected-diagnosis": function updateSelectedDiagnosis(values) {
        // Parse the CSV string to pull out the selected diagnoses.
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected diagnosis values


        Ember.set(this, "diagnosis.values", selection); // Notify that observers (within this component) that the diagnosis has
        // been updated.

        this.notifyPropertyChange("diagnosis");
      }
    },
    ///////////////
    // Functions //
    ///////////////
    // Reset the state of this component, usually called when the selected
    // project changes or the user clears the filter.
    reset: function reset() {
      Ember.set(this, "category", {
        inclusion: "include",
        values: []
      });
      Ember.set(this, "infectionType", {
        inclusion: "include",
        values: []
      });
      Ember.set(this, "infectionSource", {
        inclusion: "include",
        values: []
      });
      Ember.set(this, "medicalService", {
        inclusion: "include",
        values: []
      });
      Ember.set(this, "nosocomial", {
        inclusion: "include",
        values: []
      });
      Ember.set(this, "gender", {
        inclusion: "include",
        values: []
      });
      Ember.set(this, "age-range", {
        inclusion: "include",
        values: {
          min: "",
          max: ""
        }
      });
      Ember.set(this, "diagnosis", {
        inclusion: "include",
        values: []
      });

      if (this.categoryDropdown !== undefined) {
        this.categoryDropdown.send("reset");
      }

      if (this.infectionSourceDropdown !== undefined) {
        this.infectionSourceDropdown.send("reset");
      }

      if (this.medicalServiceDropdown !== undefined) {
        this.medicalServiceDropdown.send("reset");
      }

      if (this.nosocomialDropdown !== undefined) {
        this.nosocomialDropdown.send("reset");
      }

      if (this.genderDropdown !== undefined) {
        this.genderDropdown.send("reset");
      }

      if (this.diagnosisDropdown !== undefined) {
        this.diagnosisDropdown.send("reset");
      }
    },
    resetCategory: function resetCategory() {
      // Reset the dropdown
      var dropdown = this.categoryDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "category", {
        inclusion: "include",
        values: []
      });
    },
    // Perform necessary actions when adhoc filter is reset
    resetAdhocFilter: function resetAdhocFilter() {
      this.reset();
    },
    resetInfectionType: function resetInfectionType() {
      // Reset the dropdown
      var dropdown = this.infectionTypeDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "infectionType", {
        inclusion: "include",
        values: []
      });
    },
    resetInfectionSource: function resetInfectionSource() {
      // Reset the dropdown
      var dropdown = this.infectionSourceDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "infectionSource", {
        inclusion: "include",
        values: []
      });
    },
    resetNosocomial: function resetNosocomial() {
      // Reset the dropdown
      var dropdown = this.nosocomialDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "nosocomial", {
        inclusion: "include",
        values: []
      });
    },
    resetGender: function resetGender() {
      // Reset the dropdown
      var dropdown = this.genderDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "gender", {
        inclusion: "include",
        values: []
      });
    },
    resetIcu: function resetIcu() {
      // Reset the dropdown
      var dropdown = this.icuDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "icu", {
        inclusion: "include",
        values: []
      });
    },
    resetVap: function resetVap() {
      // Reset the dropdown
      var dropdown = this.vapDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "vap", {
        inclusion: "include",
        values: []
      });
    },
    resetObjective: function resetObjective() {
      // Reset the dropdown
      var dropdown = this.objectiveDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "objective", {
        inclusion: "include",
        values: []
      });
    },
    resetAgeRange: function resetAgeRange() {
      // Reset the actual values
      Ember.set(this, "age-range", {
        inclusion: "include",
        values: {
          min: "",
          max: ""
        }
      });
    },
    resetMedicalService: function resetMedicalService() {
      // Reset the dropdown
      var dropdown = this.medicalServiceDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "medicalService", {
        inclusion: "include",
        values: []
      });
    },
    resetDiagnosis: function resetDiagnosis() {
      // Reset the dropdown
      var dropdown = this.diagnosisDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "diagnosis", {
        inclusion: "include",
        values: []
      });
    },
    //////////////////////
    // Core Site        //
    //////////////////////
    initializecoreSiteDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.core_sites.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.core_sites.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option.toString());
        });
      }

      if (selected.length > 0) {
        this.coreSiteDropdown.send("set-selected", selected);
      }
    }.observes("coreSiteDropdown"),
    // Observe changes to organism
    observeCoreSite: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var coreSite;

      if (Ember.get(this, "coreSite.values.length") > 0) {
        coreSite = {};
        coreSite[Ember.get(this, "coreSite.inclusion").toLowerCase()] = Ember.get(this, "coreSite.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("core_sites", coreSite);
      });
    }.observes("coreSite"),
    observeCriteriaForCoreSiteReset: function () {
      if (Ember.get(this, "filter.criteria.core_sites") === undefined && Ember.get(this, "coreSite.values.length") > 0) {
        this.resetCoreSite();
      }
    }.observes("filter.criteria"),
    resetCoreSite: function resetCoreSite() {
      // Reset the dropdown
      var dropdown = this.coreSiteDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "coreSite", {
        inclusion: "include",
        values: []
      });
    },
    coreSiteEnabled: Ember.computed('filter.criteria', function () {
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    coreSiteUrl: Ember.computed('filter.criteria.project', function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/core-sites" + "?project_name=" + encodeURIComponent(project_name);
    }),
    // Notify observers within this component that the filter criteria has
    // changed. This will be invoked through the global event bus.
    filterCriteriaChanged: function filterCriteriaChanged() {
      this.notifyPropertyChange("filter.criteria"); // Don't reset category anomore if key changed
      // if (
      //   key === "project" ||
      //   key === "year" ||
      //   key === "continent" ||
      //   key === "genus" ||
      //   key === "organisms" ||
      //   key === "group"
      // ) {
      //   set(this, "category", {inclusion: "include", values: []});
      //   get(this, "categoryDropdown").send("reset");
      // }
    },
    // Observe changes to organism
    observeAgeRange: function observeAgeRange() {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var ageRange;

      if (Ember.get(this, "age-range.values.min") !== "" || Ember.get(this, "age-range.values.max") !== "") {
        ageRange = {};
        ageRange[Ember.get(this, "age-range.inclusion").toLowerCase()] = Ember.get(this, "age-range.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("age_range", ageRange);
      });
    },
    setupInit: function setupInit() {
      // Subscribe to the event bus
      this.eventBus.on('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.on('resetAdhocFilter', this, 'resetAdhocFilter');
    },
    // Performs cleanup of the component after the element is destroyed
    cleanup: function cleanup() {
      // Destroy ties to the event bus
      this.eventBus.off('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.off('resetAdhocFilter', this, 'resetAdhocFilter');
    }
  });

  _exports.default = _default;
});