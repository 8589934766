define("mvp/pods/components/ui-surveillance/adhoc/molecular/simple/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    schema: Ember.inject.service(),
    classNames: ["ui", "form"],
    inclusion: 'include',
    'exact-enzyme-match': false,
    'include-likes': false,
    watchForProfileChange: function () {
      Ember.run.debounce(this, this._updateFilterCriteria, 50);
    }.observes('profile'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, "spectrums", undefined);
      Ember.set(this, "gene-groups", undefined);
      Ember.set(this, "genes", undefined);
    },
    didInsertElement: function didInsertElement() {
      this._setupSpectrumDropdown();

      this._setupGeneGroupDropdown();

      this._setupGeneDropdown();

      this._setupToggle();
    },
    _setupToggle: function _setupToggle() {
      var _this = this;

      this.$('.ui.inclusion.toggle').checkbox({
        onChecked: function onChecked() {
          Ember.set(_this, 'inclusion', 'exclude');
          Ember.run.scheduleOnce('actions', _this, _this._updateMolecularProfile.bind(_this));
        },
        onUnchecked: function onUnchecked() {
          Ember.set(_this, 'inclusion', 'include');
          Ember.run.scheduleOnce('actions', _this, _this._updateMolecularProfile.bind(_this));
        }
      });
      this.$('.ui.exact.toggle').checkbox({
        onChange: function onChange() {
          _this.toggleProperty('exact-enzyme-match');

          Ember.run.scheduleOnce('actions', _this, _this._updateMolecularProfile.bind(_this));
        }
      });
      this.$('.likes.checkbox').checkbox({
        onChange: function onChange() {
          _this.toggleProperty('include-likes');

          Ember.run.scheduleOnce('actions', _this, _this._updateMolecularProfile.bind(_this));
        }
      }).checkbox('set unchecked');
    },
    _updateFilterCriteria: function _updateFilterCriteria() {
      this.updateFilterCriteria('new_molecular_test_results', JSON.stringify(this.profile));
    },
    _updateMolecularProfile: function _updateMolecularProfile() {
      var spectrums = this.spectrums;
      var geneGroups = this['gene-groups'];
      var genes = this.genes;
      var inclusion = this.inclusion;
      var alleles = {};

      if (spectrums !== undefined) {
        alleles['spectrum'] = {
          include: spectrums
        };
      }

      if (geneGroups !== undefined) {
        alleles['level_5'] = {
          include: geneGroups
        };
      }

      if (genes !== undefined) {
        alleles['level_6'] = {
          include: genes
        };
      }

      var profile;

      if (Object.keys(alleles).length > 0) {
        var tmp = {};
        tmp[inclusion] = {
          alleles: alleles,
          reactions: ['pos']
        };

        if (this['exact-enzyme-match']) {
          tmp[inclusion]['exact_enzyme_match'] = true;
        }

        if (!this['include-likes']) {
          tmp[inclusion]['exact_match'] = true;
        }

        profile = [tmp];
      }

      Ember.set(this, 'profile', profile);
    },
    _setupSpectrumDropdown: function _setupSpectrumDropdown() {
      this._setupDropdownAPI("#".concat(this.elementId, " .spectrum .dropdown"), '/api/adhoc/molecular-level-3?project={project}', {
        project: Ember.get(this, "project.slug")
      }, this._spectrumSelected.bind(this));
    },
    _spectrumSelected: function _spectrumSelected(selected) {
      if (selected !== "") {
        Ember.set(this, 'spectrums', selected.split(","));
      } else {
        Ember.set(this, 'spectrums', undefined);
      }

      Ember.set(this, "gene-groups", undefined);
      Ember.set(this, "genes", undefined);
      Ember.run.scheduleOnce('actions', this, this._updateMolecularProfile.bind(this));
      Ember.run.scheduleOnce('afterRender', this, this._setupGeneGroupDropdown.bind(this));
    },
    _setupGeneGroupDropdown: function _setupGeneGroupDropdown() {
      var selector = "#".concat(this.elementId, " .gene-group .dropdown");
      var url = '/api/adhoc/molecular-level-5?project={project}';
      var urlData = {
        project: Ember.get(this, "project.slug")
      };

      if (this.spectrums !== undefined) {
        url = url + '&spectrums={/spectrums}';
        urlData['spectrums'] = JSON.stringify(this.spectrums);
      }

      this._setupDropdownAPI(selector, url, urlData, this._geneGroupSelected.bind(this));
    },
    _geneGroupSelected: function _geneGroupSelected(selected) {
      if (selected !== "") {
        Ember.set(this, 'gene-groups', selected.split(","));
      } else {
        Ember.set(this, 'gene-groups', undefined);
      }

      Ember.set(this, "genes", undefined);
      Ember.run.scheduleOnce('actions', this, this._updateMolecularProfile.bind(this));
      Ember.run.scheduleOnce('afterRender', this, this._setupGeneDropdown.bind(this));
    },
    _setupGeneDropdown: function _setupGeneDropdown() {
      var selector = "#".concat(this.elementId, " .gene .dropdown");
      var url = '/api/adhoc/molecular-level-6?project={project}';
      var urlData = {
        project: Ember.get(this, "project.slug")
      };

      if (this.spectrums !== undefined) {
        url = url + '&spectrums={/spectrums}';
        urlData['spectrums'] = JSON.stringify(this.spectrums);
      }

      if (this['gene-groups'] !== undefined) {
        url = url + '&gene_groups={/gene_groups}';
        urlData['gene_groups'] = JSON.stringify(this["gene-groups"]);
      }

      this._setupDropdownAPI(selector, url, urlData, this._geneSelected.bind(this));
    },
    _geneSelected: function _geneSelected(selected) {
      if (selected !== "") {
        Ember.set(this, 'genes', selected.split(","));
      } else {
        Ember.set(this, 'genes', undefined);
      }

      Ember.run.scheduleOnce('actions', this, this._updateMolecularProfile.bind(this));
    },
    _setupDropdownAPI: function _setupDropdownAPI(selector, url, urlData, onChange) {
      var session = this.session;
      var schema = this.schema; // Empty the menu to force another api request

      Ember.$("".concat(selector, " .menu")).empty(); // Remove any previously selected data
      // $(selector).dropdown("refresh");

      Ember.$(selector).dropdown("restore defaults");
      Ember.$(selector).dropdown("refresh"); // Setup the dropdown

      Ember.$(selector).dropdown({
        saveRemoteData: false,
        onChange: onChange,
        filterRemoteData: true,
        apiSettings: {
          cache: false,
          url: "".concat(_environment.default.APP.host).concat(url),
          urlData: urlData,
          beforeXHR: function beforeXHR(xhr) {
            session.authorize('authorizer:jwt', function (headerName, headerValue) {
              xhr.setRequestHeader(headerName, headerValue);
            });
            xhr.setRequestHeader('Schema', Ember.get(schema, 'header'));
          }
        }
      });
    }
  });

  _exports.default = _default;
});