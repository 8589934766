define("mvp/pods/manage/users/invite/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service("ajax"),
    sortedOrganizations: Ember.computed.sort("model.all_organizations", "organizationsSorting"),
    init: function init() {
      this._super.apply(this, arguments);

      this.organizationsSorting = ["site_code", "name"];
    },
    actions: {
      invite: function invite(email, organization) {
        var ajax = this.ajax;

        var _self = this;

        Ember.$("form.invite").addClass("loading");
        this.store.find("organization", organization).then(_self._sendInvite.bind(_self, email, organization, ajax));
      }
    },
    _createInviteRecord: function _createInviteRecord(email, organization) {
      this.store.createRecord('user', {
        email: email,
        organization: organization
      });
      this.transitionToRoute("manage.users");
    },
    _sendInvite: function _sendInvite(email, organization, ajax) {
      ajax.post("/api/users/invitation", {
        data: {
          user: {
            email: email,
            organization_id: organization.id
          }
        }
      }).then(this._createInviteRecord.bind(this, email, organization));
    }
  });

  _exports.default = _default;
});