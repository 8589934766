define("mvp/pods/components/d3-charts/choropleth-world/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var INSTANCE = _environment.default.EmberENV.INSTANCE;
  /* globals d3, topojson */

  var _default = Ember.Component.extend({
    eventTracker: Ember.inject.service(),
    ajax: Ember.inject.service(),
    svgExport: Ember.inject.service('svg-export'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$(window).on('resize', this._handleResize.bind(this));
      Ember.run.scheduleOnce('afterRender', this, this._loadGeoJSON);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._colorizeCountries();
    },
    actions: {
      'download': function download() {
        // inner function to clone an svg selection
        function clone(selector) {
          var node = d3.select(selector).node();
          return d3.select(node.parentNode.insertBefore(node.cloneNode(true), node.nextSibling));
        }

        var initialHeight = this._viewBoxHeight();

        var initialWidth = this._viewBoxHeight();

        var copy = clone("#".concat(this.elementId, " svg"));
        copy // .attr("viewBox", `0 0 ${newWidth} ${newHeight}`)
        .style("fill", "black");
        var headerString = this.exportTitle();

        if (INSTANCE === 'mvppublic') {
          copy.append("svg:image").attr('x', 350).attr('y', 50).attr('height', initialWidth * 0.7).attr('opacity', 0.075).attr("xlink:href", Ember.get(this, 'svgExport').jmi_logo());
        }

        copy.append("svg:image").attr('x', 50).attr('y', initialHeight * 0.62 + 30).attr('width', 160).attr('height', 120).attr("xlink:href", this.svgExport.jmi_logo());
        copy.selectAll(".g_main").attr("transform", "translate(180,20)scale(.69,.65)").attr("width", initialWidth); // copy.append('rect')
        //   .attr("x", 0)
        //   .attr("y", 0)
        //   .attr("width", newWidth)
        //   .attr("height", newHeight)
        // .attr("fill", "rgba(0, 0, 0, 1.0)");
        // add header text

        var textNode = copy.append('text').attr("x", 240).attr("y", initialHeight * 0.62 + 125).text(null).style("font-family", "Arial").style("fill", "#d8d8d8").style("text-anchor", "left").attr("font-size", 24);
        this.svgExport.wrap_text({
          textNode: textNode,
          text: headerString,
          maxWidth: initialWidth + 150,
          lineHeight: 30,
          styleFn: function styleFn(node) {
            return node;
          }
        });
        textNode = copy.append('text').attr("x", 50).attr("y", initialHeight * 0.62 + 180).text(null).style("font-family", "Arial").style("fill", "#d8d8d8").style("text-anchor", "left").attr("font-size", "8px");
        this.svgExport.wrap_text({
          textNode: textNode,
          text: this.svgExport.disclaimer(),
          maxWidth: initialWidth * 2 - 200,
          lineHeight: 9,
          styleFn: function styleFn(node) {
            return node;
          }
        }); // export png of svg copy

        this.svgExport.export(copy, "heatmap"); // remove svg node copy from DOM

        copy.remove();

        this._recordEvent('download');
      }
    },
    _height: function _height() {
      return Ember.$("#".concat(this.elementId, " svg")).height();
    },
    _width: function _width() {
      return Ember.$("#".concat(this.elementId, " svg")).width();
    },
    _handleResize: function _handleResize() {
      var _this = this;

      Ember.run.debounce(this, function () {
        var width = Ember.$("#".concat(_this.elementId, " .svg-container")).width();
        Ember.$("#".concat(_this.elementId, " svg")).height(width / 1.88);
      }, 100);
    },
    _loadGeoJSON: function _loadGeoJSON() {
      d3.json( // "https://unpkg.com/world-atlas@1.1.4/world/50m.json",
      "https://unpkg.com/world-atlas@1.1.4/world/110m.json", this._handleGeoJSON.bind(this));
    },
    _handleGeoJSON: function _handleGeoJSON(error, world) {
      if (error) {
        throw error;
      }

      Ember.set(this, 'world', world);
      Ember.run.scheduleOnce('afterRender', this, this._renderChart);
    },
    _renderChart: function _renderChart() {
      this._setDynamicHeight();

      this._renderWorld();
    },
    _setDynamicHeight: function _setDynamicHeight() {
      var width = this._width();

      Ember.$("#".concat(this.elementId, " svg")).height(width / 1.722 + 3);
    },
    _viewBoxWidth: function _viewBoxWidth() {
      return 1200;
    },
    _viewBoxHeight: function _viewBoxHeight() {
      return this._viewBoxWidth() / 1.88;
    },
    _setUpResponsiveSVG: function _setUpResponsiveSVG(self, viewBoxWidth, viewBoxHeight) {
      // set up svg and container divs for responsive design
      d3.select("#".concat(self.elementId, " div#chartId")).append("div").classed("svg-container", true).append("svg").attr("preserveAspectRatio", "xMinYMin meet").attr("viewBox", "0 0 ".concat(viewBoxWidth, " ").concat(viewBoxHeight)).classed("svg-content-responsive", true);
    },
    _renderWorld: function _renderWorld() {
      var world = this.world;

      var viewBoxWidth = this._viewBoxWidth();

      var viewBoxHeight = this._viewBoxHeight();

      this._setUpResponsiveSVG(this, viewBoxWidth, viewBoxHeight);

      var svg = d3.select("#".concat(this.elementId, " svg")); // used for ie 11

      var width = Ember.$("#".concat(this.elementId, " .svg-container")).width();
      Ember.$("#".concat(this.elementId, " svg")).height(width / 1.88); // projection to use on the map
      // const projection = d3.geoNaturalEarth1()
      // geoKavrayskiy7

      var projection = d3.geoNaturalEarth2().scale(viewBoxWidth / 2 / Math.PI + 20).translate([viewBoxWidth / 2, viewBoxHeight / 2]); // .scale((this._width() / 2 / Math.PI) + 20)
      // .translate([this._width() / 2, this._height() / 2]);
      // svg map path

      var map = d3.geoPath().projection(projection); // lat / long lines on the map
      // const graticule = d3.geoGraticule();

      var g = svg.append("g").classed("g_main", true); // g.append("use")
      //   .attr("class", "stroke")
      //   .attr("fill", "#fff")
      //   .attr("opacity", 0.9)
      //   .attr("stroke", "#555")
      //   .attr("stroke-width", "1px")
      //   .attr("stroke-opacity", 0.5)
      //   .attr("xlink:href", "#sphere")
      //   .style("fill", "#fff")
      //   .style("opacity", 0.9);

      g.append("path").datum({
        type: "Sphere"
      }).attr("id", "sphere").attr("d", map).style("fill", "#fff").style("opacity", 0.9); // svg.append("path")
      //     .datum(graticule)
      //     .attr("class", "graticule")
      //     .attr("d", map);

      g.selectAll(".country").data(topojson.feature(world, world.objects.countries).features).enter().append("path").attr("class", "country").attr("fill", "rgba(0,0,0,.2)") // .attr("stroke", "rgba(0,0,0,.5)")
      .attr("stroke-width", ".2").attr("d", map).on("mousemove", mousemove).on("mouseenter", mouseenter).on("mouseleave", mouseleave); // add legend to map

      var legend = this.legend;
      legend(g, viewBoxHeight);

      function mouseleave() {
        svg.selectAll(".guide").remove();
        d3.select(this).transition().duration(50).attr("stroke-width", ".2");
      }

      function mouseenter() {
        d3.select(this).transition().duration(50).attr("stroke-width", ".8");
      }

      var self = this;

      function mousemove(d) {
        svg.selectAll(".guide").remove();
        var guide = svg.append("g").attr("class", "guide");
        var guideBckgd = guide.append("rect").attr("class", "guide-text-bkgd").attr("fill", "rgba(0,0,0,.8)").attr("pointer-events", "none");
        Ember.get(self, 'guide')(d3.mouse(this), d, guide);
        var bbox = guide.node().getBBox();
        guideBckgd.attr("x", bbox.x - 5).attr("y", bbox.y - 5).attr("rx", 5).attr("ry", 5).attr("width", bbox.width + 10).attr("height", bbox.height + 10);

        if (bbox.x + bbox.width + 50 > self._width()) {
          guide.attr("transform", "translate(-".concat(bbox.width + 50, ", 0)"));
        }
      }

      this._colorizeCountries();
    },
    _colorizeCountries: function _colorizeCountries() {
      // send the coloring action up to parent
      var colorize = this.colorize;
      var countries = d3.selectAll("#".concat(this.elementId, " .country"));
      colorize(countries);

      this._recordEvent('view');
    },
    _recordEvent: function _recordEvent(type) {
      var attrs = this.parentView.attrs;
      var payload = Object.keys(attrs).reduce(function (accu, k) {
        var val = attrs[k].value;

        if (_typeof(val) === "object" && val._internalModel && val._internalModel.modelName && val._internalModel.modelName === 'project') {
          accu[k] = Ember.get(val, 'name');
        } else if (_typeof(val) === "object" && val.name) {
          accu[k] = val.name;
        } else if (typeof val === "string" || typeof val === 'number') {
          accu[k] = val;
        }

        return accu;
      }, {});
      this.eventTracker.record("choropleth-world-".concat(type), payload);
    }
  });

  _exports.default = _default;
});