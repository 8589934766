define("mvp/pods/components/ui-site-management/form/site-address/contact-multiselect/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedOptions: null,
    selectedCount: Ember.computed.reads('selectedOptions.length'),
    actions: {
      setSelection: function setSelection(event) {
        var selectedOptions = Ember.$(event.target).val();
        this.set('selectedOptions', selectedOptions || []);
        this.set("changeset." + this.name, this.get('selectedOptions'));
      }
    }
  });

  _exports.default = _default;
});