define("mvp/pods/components/inline-loader/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    })
  });

  _exports.default = _default;
});