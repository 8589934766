define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/pathogens/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "799vzsZ6",
    "block": "{\"symbols\":[\"pathogen\"],\"statements\":[[0,\"\\n\\n\\n\"],[7,\"div\"],[11,\"class\",\"ui fluid search selection dropdown\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"name\",\"pathogen\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"update-selected-pathogen\"],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"hidden\"],[9],[10],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"dropdown icon\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"default text\"],[9],[0,\"Select Pathogen\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"menu\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"pathogens\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"item\"],[12,\"data-value\",[28,[[22,1,[\"name\"]]]]],[9],[1,[22,1,[\"name\"]],true],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"selection_title\"],[9],[0,\"Pathogen\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/pathogens/template.hbs"
    }
  });

  _exports.default = _default;
});