define("mvp/pods/components/d3-charts/infection-types/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals d3*/
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$(window).on('resize', this._handleResize.bind(this));
      Ember.set(this, "margin", {
        top: 0,
        right: 10,
        bottom: 50,
        left: 10
      });
    },
    infectionTypes: Ember.computed('data.length', function () {
      var data = this.data;
      var temp = [];
      data.forEach(function (i) {
        if (!temp.includes(i.infection_type)) {
          temp.addObject(i.infection_type);
        }
      });
      return temp;
    }),
    maxCount: Ember.computed('data.length', function () {
      var max = 0;
      var data = this.data;
      data.forEach(function (i) {
        if (i.count > max) {
          max = i.count;
        }
      });
      return max;
    }),
    totalCount: Ember.computed('data.length', function () {
      var total = 0;
      var data = this.data;
      data.forEach(function (i) {
        total = total + i.count;
      });
      return total;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._requestData();
    },
    _handleResize: function _handleResize() {
      var _this = this;

      Ember.run.debounce(this, function () {
        Ember.$("#".concat(_this.elementId, " svg")).html("");
        Ember.run.scheduleOnce('afterRender', _this, _this._renderChart);
      }, 100);
    },
    _requestData: function _requestData() {
      var ajax = this.ajax;
      var url = '/api/infection_sources/counts';
      ajax.request(url).then(this._handleResponse.bind(this)).catch();
    },
    _handleResponse: function _handleResponse(payload) {
      Ember.set(this, 'data', payload);
      Ember.run.scheduleOnce('afterRender', this, this._renderChart);
    },
    _height: function _height() {
      return this._width();
    },
    _width: function _width() {
      var _this$margin = this.margin,
          left = _this$margin.left,
          right = _this$margin.right;
      return Ember.$("#".concat(this.elementId, " svg")).width() - left - right;
    },
    _renderChart: function _renderChart() {
      this._setSize(); // 360 degrees in a circle


      var svg = d3.select("#".concat(this.elementId, " svg"));
      var margin = this.margin;
      var g = svg.append("g").attr("class", "infection-burst").attr("transform", "translate(".concat(this._width() / 2 + margin.left, ",").concat(this._height() / 2 + margin.top, ")"));
      var internal_donut_radius = this._width() * 0.18;
      var arc_radius_len = this._width() / 2 - internal_donut_radius;
      var infection_sources = this.data;
      g.selectAll(".infection-type").data(infection_sources).enter().append("g").attr("class", "infection-type");
      var angleWidth = (Math.PI * 2 - 0.3) / infection_sources.length;
      var countScale = d3.scaleLog().rangeRound([internal_donut_radius, arc_radius_len]).domain([1, this.maxCount]);
      var totalCount = this.totalCount;
      var colorScale = d3.scaleOrdinal().domain(this.infectionTypes).range(['#5b8cff', '#d0da41', '#324986', '#85c1ec', '#9fb36d', '#1482be']);
      g.append("g").attr("class", "axis").attr("transform", "rotate(-90)").call(d3.axisTop(countScale).tickSizeOuter(0).ticks(4, ",.1r"));
      g.selectAll(".infection-type").each(function (d, i) {
        var g = d3.select(this);
        var angle = {
          start: angleWidth * i + 0.10,
          end: angleWidth * (i + 1) + 0.10
        };
        var arc = d3.arc().innerRadius(internal_donut_radius).outerRadius(countScale(d.count)).startAngle(angle.start).endAngle(angle.end);
        g.append("path").attr("class", "my-arc").attr("d", arc).attr("fill", colorScale(d.infection_type));
        var arcOutline = d3.arc().innerRadius(internal_donut_radius).outerRadius(arc_radius_len).startAngle(angle.start).endAngle(angle.end);
        g.append("path").attr("class", "my-arc").attr("d", arcOutline).attr("fill", "transparent").attr("stroke", "rgba(255,255,255,.5)").attr("stroke-width", 0.1);
        var centroid_text = (angle.end - angle.start) / 2 + angle.start;
        centroid_text = centroid_text * (180 / Math.PI) - 90;
        var flip = false;

        if (centroid_text > 90) {
          flip = true;
        }

        g.append("text").attr("class", "info-text").attr("x", arc_radius_len + 60).attr("transform", function () {
          if (flip) {
            return "rotate(".concat(centroid_text - 180, "),translate(-").concat(arc_radius_len * 2 + 120, ", 0)");
          } else {
            return "rotate(" + centroid_text + ")";
          }
        }).attr("text-anchor", function () {
          if (flip) {
            return 'end';
          } else {
            return 'start';
          }
        }).attr("dy", ".35em").text(d.infection_source);
        var pct = (d.count / totalCount * 100).toFixed(2);
        g.append("text").attr("class", "pct-text").attr("x", arc_radius_len + 10).attr("transform", function () {
          if (flip) {
            return "rotate(".concat(centroid_text - 180, "),translate(-").concat(arc_radius_len * 2 + 20, ", 0)");
          } else {
            return "rotate(" + centroid_text + ")";
          }
        }).attr("text-anchor", function () {
          if (flip) {
            return 'end';
          } else {
            return 'start';
          }
        }).attr("dy", ".35em").text("".concat(pct, " %"));
      });
    },
    _setSize: function _setSize() {
      var _this$margin2 = this.margin,
          top = _this$margin2.top,
          bottom = _this$margin2.bottom;
      Ember.$("#".concat(this.elementId, " svg")).height(this._width() + top + bottom);
    }
  });

  _exports.default = _default;
});