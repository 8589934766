define("mvp/pods/surveillance/show/results/tables/mic-distributions/frequency/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rlPnjnZG",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[27,\"ui-filter-result/mic-distribution\",null,[[\"mic-distribution\",\"display\",\"organismCounts\",\"view-isolate\"],[[23,[\"model\"]],\"frequency\",[23,[\"organismCounts\"]],[27,\"action\",[[22,0,[]],\"view-isolate\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/results/tables/mic-distributions/frequency/template.hbs"
    }
  });

  _exports.default = _default;
});