define("mvp/pods/components/ui-surveillance/project-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r3xwQDBL",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"input\"],[11,\"name\",\"project\"],[12,\"value\",[23,[\"selectedProject\",\"attributes\",\"slug\"]]],[11,\"type\",\"hidden\"],[9],[10],[0,\"\\n\"],[7,\"i\"],[11,\"class\",\"dropdown icon\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"default text\"],[9],[0,\"Select a project...\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"menu\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"projectOptions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"item\"],[12,\"data-value\",[22,1,[\"value\"]]],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/project-selection/template.hbs"
    }
  });

  _exports.default = _default;
});