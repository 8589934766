define("mvp/pods/components/ui-surveillance/heatmap-old/ammap-world-low-susceptibility-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r9ZKOhbs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"chartdiv\"],[9],[0,\"CHART LOADING...\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/heatmap-old/ammap-world-low-susceptibility-map/template.hbs"
    }
  });

  _exports.default = _default;
});