define("mvp/svgs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "assets": {
      "images": {
        "dna-icon": "<svg version=\"1.0\" xmlns=\"http://www.w3.org/2000/svg\" width=\"170.667\" height=\"170.667\" viewBox=\"0 0 128 128\"><path d=\"M26.7.6c-1.6 1.6-.5 6.6 3 13.5 2.1 4.2 12.2 18.8 23.5 33.6L73 73.9l-5.8.3c-4.9.3-5.7.6-5.7 2.3 0 1.8.8 2 7.5 2.3l7.5.3 4.7 7.2 4.8 7.2-13.9.3c-10.8.2-14.2.6-15.1 1.7-1 1.2-1 1.8 0 3s4.2 1.5 17 1.5h15.8l2.6 6.5c1.4 3.6 2.6 7 2.6 7.5 0 .6-9.4 1.1-24.2 1.2-23.5.3-24.3.4-24.3 2.3s.8 2 25.2 2.3l25.2.2.3 3.8c.2 2.9.7 3.7 2.3 3.7 4.7 0 2-14.6-5.5-29.5-5.2-10.4-13.6-22.8-28.4-42.3C58.7 46.5 53 38.8 53 38.6c0-.3 1.7-.6 3.8-.8 2.9-.2 3.7-.7 3.7-2.3 0-1.8-.8-2-6.3-2.3l-6.2-.3-5.1-7.2-5-7.2 18.8-.5c18.6-.5 18.8-.5 19.1-2.8l.3-2.2H34.2l-1.6-4.3c-.8-2.3-1.6-5.1-1.8-6.2C30.5.5 28-.7 26.7.6z\"/><path d=\"M97.4 1.4c-.5 1.4-3.8 1.6-26 1.6C47.1 3 46 3.1 46 4.9c0 1 .7 2.2 1.6 2.5.9.3 12.1.6 25 .6h23.5l-1.7 3.2c-.9 1.8-3 5.2-4.6 7.5l-2.9 4.2-15.2.3c-14.4.3-15.2.4-15.2 2.3s.8 2 13.4 2.5l13.3.5-5.4 7c-11.9 15.5-11.1 14-8.9 15.9 1.9 1.8 2.3 1.4 14-14.4C96 19.5 102.4 8.1 101.8 3.4c-.3-3.2-3.4-4.6-4.4-2zM48.5 74.5c-4.4 6.3-10.1 15.4-12.8 20.3-8.6 15.8-12.3 32.7-7.2 32.7 1.7 0 2.1-.9 2.6-5.1.4-2.8 1.2-6.7 1.8-8.7l1.2-3.7 20.7-.2c20.6-.3 20.7-.3 21-2.6l.3-2.2H56.6c-15 0-19.6-.3-19.6-1.3 0-.6 1.7-4.1 3.8-7.7l3.8-6.5 10.8-.3c10.4-.3 10.7-.3 10.4-2.5-.3-2-.9-2.2-8.5-2.5-4.6-.2-8.3-.5-8.3-.9 0-.3 2.5-4.2 5.6-8.6 4.3-6 5.4-8.4 4.7-9.6-.5-.8-1.4-1.6-1.9-1.8-.5-.2-4.5 4.9-8.9 11.2z\"/></svg>",
        "puff": "<svg width=\"44\" height=\"44\" xmlns=\"http://www.w3.org/2000/svg\" stroke=\"#8fab68\"><g fill=\"none\" fill-rule=\"evenodd\" stroke-width=\"2\"><circle cx=\"22\" cy=\"22\" r=\"1\"><animate attributeName=\"r\" begin=\"0s\" dur=\"1.8s\" values=\"1; 20\" calcMode=\"spline\" keyTimes=\"0; 1\" keySplines=\"0.165, 0.84, 0.44, 1\" repeatCount=\"indefinite\"/><animate attributeName=\"stroke-opacity\" begin=\"0s\" dur=\"1.8s\" values=\"1; 0\" calcMode=\"spline\" keyTimes=\"0; 1\" keySplines=\"0.3, 0.61, 0.355, 1\" repeatCount=\"indefinite\"/></circle><circle cx=\"22\" cy=\"22\" r=\"1\"><animate attributeName=\"r\" begin=\"-0.9s\" dur=\"1.8s\" values=\"1; 20\" calcMode=\"spline\" keyTimes=\"0; 1\" keySplines=\"0.165, 0.84, 0.44, 1\" repeatCount=\"indefinite\"/><animate attributeName=\"stroke-opacity\" begin=\"-0.9s\" dur=\"1.8s\" values=\"1; 0\" calcMode=\"spline\" keyTimes=\"0; 1\" keySplines=\"0.3, 0.61, 0.355, 1\" repeatCount=\"indefinite\"/></circle></g></svg>"
      }
    }
  };
  _exports.default = _default;
});