define("mvp/pods/components/d3-charts/filter-overview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals d3 */
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$(window).on('resize', this._handleResize.bind(this)); // set(this, 'margin', {
      //   top: 30, right: 50, bottom: 70, left: 70
      // });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.scheduleOnce('afterRender', this, this._renderRows);
    },
    _handleResize: function _handleResize() {
      var _this = this;

      Ember.run.debounce(this, function () {
        Ember.$("#".concat(_this.elementId, " svg")).html("");
        Ember.run.scheduleOnce('afterRender', _this, _this._renderRows);
      }, 100);
    },
    _width: function _width() {
      return Ember.$("#".concat(this.elementId, " svg")).width();
    },
    _xScale: function _xScale(dilutions, x0, x1) {
      return d3.scalePoint().rangeRound([x0, x1]).padding(0).domain(dilutions);
    },
    _renderRows: function _renderRows() {
      var svg = d3.select("#".concat(this.elementId, " svg"));
      var rows = Ember.get(this, 'web-table.rows');
      var self = this;
      svg.selectAll("g").data(rows).enter().append("g").attr("class", "row");
      d3.selectAll("#".concat(this.elementId, " .row")).each(function (d, i) {
        var g = d3.select(this);

        self._renderRow(g, d, i);
      });
      var bbox = svg.node().getBBox();
      Ember.$("#".concat(this.elementId, " svg")).height(bbox.height + 50);
    },
    _renderRow: function _renderRow(g, d, i) {
      var width = this._width();

      var x0 = Math.round(width * 0.2);
      var x1 = Math.round(width * 0.8); // each 'row' will be 70px tall and with margin of 10px on top of bottom

      var height = 60;
      var y0 = height * i + 30 * i + 15;
      var y1 = y0 + 60;
      var y = y0 + (y1 - y0) / 2;
      var dilutions = Ember.get(this, 'web-table.dilutions');

      var xScale = this._xScale(dilutions[d.unit], x0, x1);

      g.append("g").attr("class", "x--axis").attr("transform", "translate(0," + (y + 30) + ")").call(d3.axisBottom(xScale).tickSizeOuter(0));
      g.append("line").attr("x1", 0).attr("x2", x0).attr("y1", y + 0.5 + 30).attr("y2", y + 0.5 + 30).attr("stroke", "rgba(255,255,255,.3)");
      var drugLen = d.drug.length;
      var size;

      if (drugLen > 26) {
        size = 14;
      } else if (drugLen > 20) {
        size = 17;
      } else {
        size = 24;
      }

      g.append("text").attr("class", "drug-name").attr("x", 0).attr("y", y + 5).attr("font-size", size).text(d.drug);
      g.append("text").attr("x", 0).attr("y", y + 20).attr("font-size", 12).attr("fill", "rgba(255,255,255,.75)").text("n=".concat(d["total-isolates"])); // console.log(d["raw-range"])

      g.append("rect").attr("x", xScale(d["raw-range"]["low"])).attr("width", xScale(d["raw-range"]["high"]) - xScale(d["raw-range"]["low"])).attr("y", y0 + 25).attr("rx", 5).attr("ry", 5).attr("height", y1 - 25 - (y0 + 25)).attr("fill", "rgba(255, 255, 255, 0.25)");
      g.append("rect").attr("x", xScale(d["raw-mics"]["mic_25"])).attr("width", xScale(d["raw-mics"]["mic_75"]) - xScale(d["raw-mics"]["mic_25"])).attr("y", y0 + 20).attr("rx", 5).attr("ry", 5).attr("height", y1 - 20 - (y0 + 20)).attr("fill", "rgba(80, 128, 175, 0.95)");
      g.append("line").attr("x1", xScale(d["raw-mics"]["mic_50"]) + 0.5).attr("x2", xScale(d["raw-mics"]["mic_50"]) + 0.5).attr("y1", y0 + 10).attr("y2", y1 - 10).attr("ry", 2).attr("stroke-width", 7).attr("stroke", "#7e8a4a");
      var mic90 = d["raw-mics"]["mic_90"]; // console.log(d["raw-mics"]["mic_90_edge"]);

      if (d["raw-mics"]["mic_90_edge"] === 1) {
        // console.log(mic90);
        var index = dilutions[d.unit].indexOf(mic90.toString());
        mic90 = dilutions[d.unit][index + 1]; // console.log(mic90);
      } // console.log(dilutions[d.unit]);
      // console.log(d["raw-mics"]["mic_90"]);
      // console.log(index);
      // g.append("line")
      //   .attr("x1", xScale(mic90) + 0.5)
      //   .attr("x2", xScale(mic90) + 0.5)
      //   .attr("y1", y0 + 10)
      //   .attr("y2", y1 - 10)
      //   .attr("stroke-width", 7)
      //   .attr("stroke", "#7e8a4a");


      g.append("circle").attr("cx", xScale(mic90)).attr("cy", y).attr("r", 10).attr("fill", "#7e8a4a");
    }
  });

  _exports.default = _default;
});