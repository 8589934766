define("mvp/models/isolate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    accession_number: _emberData.default.attr("number"),
    surveillance_year: _emberData.default.attr("number"),
    gender: _emberData.default.attr("string"),
    age: _emberData.default.attr("number"),
    infection_type: _emberData.default.attr("string"),
    infection_source: _emberData.default.attr("string"),
    bank_no: _emberData.default.attr("number"),
    bank_type: _emberData.default.attr("string"),
    objective: _emberData.default.attr("string"),
    specimen_number: _emberData.default.attr("string"),
    nosocomial: _emberData.default.attr('boolean'),
    qc_organism_code: _emberData.default.attr("string"),
    site_organism_code: _emberData.default.attr("string"),
    historical_info: _emberData.default.attr("json"),
    isolate_categories: _emberData.default.hasMany("isolate-category", {
      async: true
    }),
    molecular_test_results: _emberData.default.hasMany("molecular-test-result", {
      async: true
    }),
    susceptibility_results: _emberData.default.hasMany("susceptibility-result", {
      async: true
    }),
    project: _emberData.default.belongsTo("project"),
    organism: _emberData.default.belongsTo("organism", {
      async: true
    }),
    organization: _emberData.default.belongsTo("organization", {
      async: true
    })
  });

  _exports.default = _default;
});