define("mvp/pods/admin/users/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bv3Vm1/f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui two column grid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"ui icon input fluid\"],[9],[0,\"\\n      \"],[7,\"input\"],[12,\"onkeydown\",[27,\"action\",[[22,0,[]],\"search\"],null]],[11,\"placeholder\",\"search\"],[12,\"value\",[21,\"q\"]],[9],[10],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"search icon\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"column\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"ui primary button labeled icon right\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"edit user icon\"],[9],[10],[0,\"\\n      Manage User\\n    \"],[3,\"action\",[[22,0,[]],\"addUser\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"ui-admin/users\",null,[[\"users\",\"page\",\"pageChanged\",\"addUser\",\"editUser\"],[[23,[\"model\",\"content\"]],[23,[\"page\"]],[27,\"action\",[[22,0,[]],\"pageChanged\"],null],[27,\"action\",[[22,0,[]],\"addUser\"],null],[27,\"action\",[[22,0,[]],\"editUser\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/admin/users/index/template.hbs"
    }
  });

  _exports.default = _default;
});