define("mvp/pods/components/ui-admin/sentry-containers/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupTable();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupTable();
    },
    actions: {
      'show-site-objective-modal': function showSiteObjectiveModal(site) {
        // console.log("sending action to parent");
        this['show-site-objective-modal'](site);
      }
    },
    setupTable: function setupTable() {// console.log("setting up table");
      // console.log(get(this, 'containers'));
    }
  });

  _exports.default = _default;
});