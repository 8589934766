define("mvp/services/authorization", ["exports", "mvp/config/environment", "lodash"], function (_exports, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    metadata: {},
    populate: function populate(metadata) {
      Ember.set(this, 'metadata', metadata);
    },
    is_jmi_user: Ember.computed("metadata.role", function () {
      var role = Ember.get(this, 'metadata.role');
      return role === 'jmi';
    }),
    impersonating: Ember.computed("metadata.impersonate", function () {
      return Ember.get(this, 'metadata.impersonate');
    }),
    isJMIUser: Ember.computed("is_jmi_user", function () {
      return Ember.get(this, 'is_jmi_user');
    }),
    can_admin: Ember.computed("is_jmi_user", function () {
      return Ember.get(this, 'is_jmi_user');
    }),
    can_enter_demo_data: Ember.computed("is_jmi_user", function () {
      return Ember.get(this, 'is_jmi_user');
    }),
    is_a_hospital: Ember.computed("metadata.sentry_medical_centers", function () {
      var metadata = Ember.get(this, 'metadata');
      return Object.keys(metadata).includes("sentry_medical_centers");
    }),
    role: Ember.computed("metadata.role", function () {
      return Ember.get(this, 'metadata.role');
    }),
    sentryMedicalCenters: Ember.computed('metadata.sentry_medical_centers', function () {
      var medicalCenters = Ember.get(this, 'metadata.sentry_medical_centers') || [];
      return medicalCenters.map(function (site) {
        return _lodash.default.padStart(site, 3, "0");
      });
    }),
    isPublicUser: Ember.computed('metadata.public_user', function () {
      return Ember.get(this, 'metadata.public_user') || INSTANCE === 'mvppublic' && Ember.get(this, 'is_a_hospital');
    })
  });

  _exports.default = _default;
});