define("mvp/pods/surveillance/show/publications/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uqZNxsxs",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ui-surveillance/publications/show\",null,[[\"publication\",\"filters\",\"filterSelected\"],[[23,[\"model\"]],[23,[\"filters\"]],[27,\"action\",[[22,0,[]],\"filter-selected\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/publications/show/template.hbs"
    }
  });

  _exports.default = _default;
});