define("mvp/pods/site-management/sites/index/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/sites/index/sites"], function (_exports, _emberApolloClient, _sites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var variables = {
        code: params.code,
        order: "id ASC",
        active: params.activeOnly === true,
        page: params.page,
        country: params.country,
        continent: params.continent,
        name: params.name,
        perPage: params.perPage
      };
      return this.apollo.watchQuery({
        query: _sites.default,
        variables: variables,
        fetchPolicy: "no-cache"
      }, "sites");
    },
    queryParams: {
      page: {
        refreshModel: true
      },
      code: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      activeOnly: {
        refreshModel: true
      },
      country: {
        refreshModel: true
      },
      continent: {
        refreshModel: true
      },
      name: {
        refreshModel: true
      }
    },
    boolConverter: function boolConverter(bool) {
      if (bool === true) return true;

      try {
        return JSON.parse(bool.toLowerCase());
      } catch (_unused) {
        return null;
      }
    }
  });

  _exports.default = _default;
});