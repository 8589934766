define("mvp/pods/components/ui-admin/users/add/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    email: undefined,
    password: undefined,
    organization_id: undefined,
    loading: false,
    organizationsUrl: "/api/organizations/names",
    rolesUrl: "/api/roles/names",
    removeError: function () {
      Ember.set(this, "error", undefined);
    }.observes("email", "organization_id", "roles"),
    showSubmit: Ember.computed("email", "password", "organization_id", "roles", "loading", function () {
      return this.email !== undefined && this.password !== undefined && this.organization_id !== undefined && this.loading !== true;
    }),
    observeOrganizationDropdown: function () {
      var dropdownId = "#" + this.organizationDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("organizationDropdown"),
    observeRolesDropdown: function () {
      var dropdownId = "#" + this.rolesDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("rolesDropdown"),
    init: function init() {
      this._super.apply(this, arguments);

      this.roles = [];
    },
    actions: {
      "update-selected-organization": function updateSelectedOrganization(organization_id) {
        Ember.set(this, "organization_id", organization_id);
      },
      "update-selected-roles": function updateSelectedRoles(roles) {
        var parsedRoles = decodeURIComponent(roles).split(",");
        Ember.set(this, "roles", parsedRoles);
      },
      "addUser": function addUser() {
        // Show that the form is loading
        Ember.set(this, "loading", true);
        var data = {
          user: {
            email: this.email,
            password: this.password,
            organization_id: this.organization_id,
            roles: this.roles
          }
        };
        var url = "/api/users/auth0_create";

        var _self = this;

        this.ajax.post(url, {
          data: data
        }).then(_self._addUserSuccess.bind(_self), _self._addUserFailure.bind(_self));
      }
    },
    _addUserSuccess: function _addUserSuccess() {
      this.userAdded();
    },
    _addUserFailure: function _addUserFailure(error) {
      Ember.set(this, "error", error.errors);
      Ember.set(this, "loading", false);
    }
  });

  _exports.default = _default;
});