define("mvp/pods/components/ui-filter-result/web-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service("current-user"),
    media: Ember.inject.service('media'),
    eventTracker: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.visibleGroupings = {};
      this.expandedItems = {};
      this.visibleRows = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'visibleGroupings', {});
      Ember.set(this, 'expandedItems', {});
      this.showRows();
    },
    didInsertElement: function didInsertElement() {
      Ember.$('body').scrollTop(0);
      Ember.$("#" + this.elementId + " .table-wrapper-scroll-x").stickyTableHeaders({
        fixedOffset: 0
      });
    },
    actions: {
      contract: function contract(row, key) {
        var drug = row.drug;
        var visibleGroupings = this.visibleGroupings;
        var actual_groupings = {};
        row.groupings.forEach(function (item) {
          if (item.value !== null) {
            actual_groupings[item.key] = item.value;
          }
        });
        var expandedItems = this.expandedItems;
        expandedItems[row.drug][key].removeObject(JSON.stringify(row.groupings));
        Ember.set(this, 'expandedItems', expandedItems);
        this.notifyPropertyChange('expandedItems');
        var temp = Ember.$.extend(true, {}, actual_groupings);
        delete temp[key];
        visibleGroupings[drug][key].removeObject(JSON.stringify(temp));
        Ember.set(this, 'visibleGroupings', visibleGroupings);
        this.showRows();
        this.eventTracker.record('split-by-contract', {
          key: key,
          drug: drug
        });
      },
      expand: function expand(row, key) {
        var drug = row.drug;
        var visibleGroupings = this.visibleGroupings;
        var actual_groupings = {};
        row.groupings.forEach(function (item) {
          if (item.value !== null) {
            actual_groupings[item.key] = item.value;
          }
        });
        var expandedItems = this.expandedItems;

        if (expandedItems[row.drug] === undefined) {
          expandedItems[row.drug] = {};
        }

        if (expandedItems[row.drug][key] === undefined) {
          expandedItems[row.drug][key] = [];
        }

        expandedItems[row.drug][key].addObject(JSON.stringify(row.groupings));
        Ember.set(this, 'expandedItems', expandedItems);
        this.notifyPropertyChange('expandedItems');
        var temp = Ember.$.extend(true, {}, actual_groupings);
        delete temp[key];

        if (visibleGroupings[drug] === undefined) {
          visibleGroupings[drug] = {};
        }

        if (visibleGroupings[drug][key] === undefined) {
          visibleGroupings[drug][key] = [];
        }

        visibleGroupings[drug][key].addObject(JSON.stringify(temp));
        Ember.set(this, 'visibleGroupings', visibleGroupings);
        this.showRows();
        this.eventTracker.record('split-by-expand', {
          key: key,
          drug: drug
        });
      }
    },
    showRows: function showRows() {
      var rowsToAdd = [];
      var visibleGroupings = this.visibleGroupings;
      Ember.get(this, 'web-table.rows').forEach(function (row) {
        var shouldAddRow = false;
        var actual_groupings = {};
        row.groupings.forEach(function (item) {
          if (item.value !== null) {
            actual_groupings[item.key] = item.value;
          }
        });

        if (Object.keys(actual_groupings).length === 0) {
          shouldAddRow = true;
        } else if (visibleGroupings[row.drug]) {
          var expandDrugBy = visibleGroupings[row.drug];
          Object.keys(visibleGroupings[row.drug]).forEach(function (key) {
            if (actual_groupings[key]) {
              var temp = Ember.$.extend(true, {}, actual_groupings);
              delete temp[key];

              if (expandDrugBy !== undefined && expandDrugBy[key] !== undefined && expandDrugBy[key].includes(JSON.stringify(temp))) {
                shouldAddRow = true;
              }
            }
          });
        }

        if (shouldAddRow) {
          rowsToAdd.addObject(row);
        }
      });
      Ember.set(this, 'visibleRows', rowsToAdd);
    }
  });

  _exports.default = _default;
});