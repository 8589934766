define("mvp/middleware/index", ["exports", "redux-thunk", "redux-logger", "mvp/config/environment"], function (_exports, _reduxThunk, _reduxLogger, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var middleware = [_reduxThunk.default];

  if (_environment.default.environment !== 'development') {
    middleware.pushObject(_reduxLogger.default);
  }

  var _default = middleware;
  _exports.default = _default;
});