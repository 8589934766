define("mvp/pods/components/d3-charts/pathogens/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals d3 */
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var ajax = this.ajax;
      var project = this.project;
      var url = "/api/projects/".concat(project.id, "/organism-overview");
      ajax.request(url).then(this._handleResponse.bind(this));
    },
    _width: function _width() {
      return Ember.$("#".concat(this.elementId, " svg")).width();
    },
    _handleResponse: function _handleResponse(root) {
      var self = this;

      var width = this._width(),
          height = this._width() - 100,
          radius = Math.min(width, height) / 2,
          // color = d3.scaleOrdinal(d3.schemeGnBu[5]);
      color = d3.scaleOrdinal(['#0081cb', '#4cb5e7', '#cddc28']);

      var svg = d3.select("#".concat(this.elementId, " svg")).attr("width", width).attr("height", height + 30).append("g").attr("class", "container").attr("transform", "translate(" + width / 2 + "," + height * 0.52 + ")");
      var partition = d3.partition();
      var x = d3.scaleLinear().range([0, 2 * Math.PI]);
      var y = d3.scaleSqrt().range([0, radius]);
      var arc = d3.arc().startAngle(function (d) {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x0)));
      }).endAngle(function (d) {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x1)));
      }).innerRadius(function (d) {
        return Math.max(0, y(d.y0));
      }).outerRadius(function (d) {
        return Math.max(0, y(d.y1));
      });
      root = d3.hierarchy(root);
      root.sum(function (d) {
        return d.size;
      });
      svg.selectAll("path").data(partition(root).descendants()).enter().append("g").attr("class", "node");
      var path = svg.selectAll(".node").append("path") // hide inner ring
      // .attr("visibility", function(d) { return d.depth ? null : "hidden"; })
      .attr("d", arc).style("stroke", "#fff").style("stroke-opacity", 0.5).style("fill", function (d) {
        if (!Ember.get(self, 'selected')) {
          Ember.set(self, 'selected', d);
          Ember.set(self, 'root', d);
        }

        if (d.depth) {
          return color((d.children ? d : d.parent).data.name);
        } else {
          return "transparent";
        }
      }).on("click", click).on("mouseover", mouseover);
      var totalSize = path.datum().value;
      svg.append("text").attr('id', 'org-pct').attr('x', 0).attr('y', 0).attr('text-anchor', 'middle').text(function () {
        return self._numberWithCommas(totalSize);
      });
      svg.append("text").attr('id', 'org-name').attr('x', 0).attr('y', 25).attr('text-anchor', 'middle').text(function () {
        return "pathogens";
      });
      svg.append("text").attr('id', 'reset').attr('x', 0).attr('y', 85).attr('text-anchor', 'middle').text("RESET").on("click", function () {
        click(Ember.get(self, "root"));
      });

      function mouseover(d) {
        // commented out cause not being used and causing eslint error
        // var percentage = (100 * d.value / totalSize).toPrecision(3);
        // var percentageString = percentage + "%";
        // if (percentage < 0.1) {
        //   percentageString = "< 0.1%";
        // }
        var sequenceArray = d.ancestors().reverse();
        sequenceArray.shift(); // remove root node from the array

        if (d.depth) {
          // Fade all the segments.
          d3.selectAll("path").style("opacity", 0.15); // Then highlight only those that are an ancestor of the current segment.

          d3.selectAll("path").filter(function (node) {
            return sequenceArray.indexOf(node) >= 0;
          }).style("opacity", 1);
        } else {
          d3.selectAll("path").style("opacity", 1);
        }

        d3.select("#org-name").text(function () {
          return d.depth ? d.data.name : "pathogens";
        });
        d3.select("#org-pct").text(function () {
          return "".concat(self._numberWithCommas(d.value));
        });
      }

      d3.selectAll("svg .container").on("mouseleave", mouseleave); // Restore everything to full opacity when moving off the visualization.

      function mouseleave() {
        var selected = Ember.get(self, 'selected'); // Deactivate all segments during transition.

        d3.selectAll("svg.container").on("mouseover", null); // Transition each segment to full opacity and then reactivate it.

        d3.selectAll("path").transition().duration(300).style("opacity", 1).on("end", function () {
          d3.select(this).on("mouseover", mouseover);
        });
        d3.select("#org-name").text(function () {
          return selected.depth ? selected.data.name : "pathogens";
        });
        d3.select("#org-pct").text(function () {
          return "".concat(self._numberWithCommas(selected.value));
        });
      }

      function click(d) {
        Ember.set(self, 'selected', d);
        svg.transition().duration(750).tween("scale", function () {
          var xd = d3.interpolate(x.domain(), [d.x0, d.x1]),
              yd = d3.interpolate(y.domain(), [0, 1]),
              yr = d3.interpolate(y.range(), [0, radius]);
          return function (t) {
            x.domain(xd(t));
            y.domain(yd(t)).range(yr(t));
          };
        }).selectAll("path").style("opacity", 1).attrTween("d", function (d) {
          return function () {
            return arc(d);
          };
        });
      }
    },
    _numberWithCommas: function _numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  });

  _exports.default = _default;
});