define("mvp/services/ajax", ["exports", "ember-ajax/services/ajax", "mvp/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    jwt: Ember.inject.service(),
    schema: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments); // Use tokens for jmilabs.com requests...


      Ember.set(this, 'trustedHosts', [/\.jmilabs.com/]);
    },
    host: _environment.default.APP.host,
    headers: Ember.computed('schema.header', "jwt.token", function () {
      var token = Ember.get(this, 'jwt.token');
      return {
        'Schema': Ember.get(this, 'schema.header'),
        'Authorization': "Bearer ".concat(token)
      };
    })
  });

  _exports.default = _default;
});