define("mvp/pods/components/c3-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _self = this;

      Ember.$('#' + this.elementId).visibility({
        onTopVisible: function onTopVisible() {
          _self.sendAction("renderChart", Ember.get(_self, "data"), Ember.get(_self, "elementId"));
        }
      });
    }
  });

  _exports.default = _default;
});