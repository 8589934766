define("mvp/pods/components/ui-application/footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    version: Ember.computed("", function () {
      return Ember.$("meta[name='mvp:version']").attr("content");
    })
  });

  _exports.default = _default;
});