define("mvp/pods/components/ui-filter-result/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z5FJOtDM",
    "block": "{\"symbols\":[\"org-count\",\"index\"],\"statements\":[[7,\"h2\"],[11,\"class\",\"ui header frequency-distribution-header\"],[9],[0,\"\\n  \"],[1,[23,[\"header\",\"title\"]],true],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sub header\"],[9],[0,\"\\n\\n\\n    \"],[7,\"div\"],[11,\"class\",\"web-table-organism-descriptions\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[27,\"not\",[[23,[\"media\",\"isMobile\"]]],null],[23,[\"show-organisms\"]]],null]],null,{\"statements\":[[0,\"        Organisms include:\\n\"],[4,\"each\",[[23,[\"header\",\"organism-counts\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"if\",[[22,2,[]],\", \"],null],false],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"header\",\"organism-counts\",\"length\"]],1],null]],null,{\"statements\":[[0,\"              \"],[7,\"a\"],[9],[1,[22,1,[\"organism\"]],true],[0,\" (\"],[1,[22,1,[\"frequency\"]],false],[0,\")\"],[3,\"action\",[[22,0,[]],\"view-organism-results\",[22,1,[\"organism-code\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              \"],[1,[22,1,[\"organism\"]],true],[0,\" (\"],[1,[22,1,[\"frequency\"]],false],[0,\")\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n\\n\"],[4,\"if\",[[23,[\"header\",\"disclaimer\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"ui web-table-disclaimer error message\"],[9],[0,\"\\n        \"],[7,\"b\"],[9],[0,\"DISCLAIMER:\"],[10],[0,\" \"],[1,[23,[\"header\",\"disclaimer\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-filter-result/header/template.hbs"
    }
  });

  _exports.default = _default;
});