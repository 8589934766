define("mvp/pods/components/ui-admin/statistics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "np3D+2lw",
    "block": "{\"symbols\":[\"email\",\"email\",\"event\"],\"statements\":[[7,\"div\"],[11,\"class\",\"stats\"],[9],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"Last 5 views\"],[10],[0,\"\\n  \"],[7,\"table\"],[9],[0,\"\\n    \"],[7,\"thead\"],[9],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"Timestamp\"],[10],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"User\"],[10],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"Action\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"stats\",\"last_view_results\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,3,[\"timestamp\"]],false],[10],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,3,[\"email\"]],false],[10],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,3,[\"action\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"stats\"],[9],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"Most active users in past 5 days\"],[10],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"stats\",\"most_active_users\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"stats\"],[9],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"Today's users\"],[10],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"stats\",\"todays_users\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-admin/statistics/template.hbs"
    }
  });

  _exports.default = _default;
});