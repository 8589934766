define("mvp/pods/components/ui-surveillance/isolates/antibiogram/interp/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yuDY+7TY",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,[\"interp\",\"reaction\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/isolates/antibiogram/interp/template.hbs"
    }
  });

  _exports.default = _default;
});