define("mvp/reducers/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SET_SENTRY_SITE = _exports.SETUP_AVAILABLE_PROJECT_OPTIONS = _exports.API_DATA_SUCCESS = _exports.PROJECT_SELECTED = void 0;
  var PROJECT_SELECTED = 'PROJECT_SELECTED';
  _exports.PROJECT_SELECTED = PROJECT_SELECTED;
  var API_DATA_SUCCESS = 'API_DATA_SUCCESS';
  _exports.API_DATA_SUCCESS = API_DATA_SUCCESS;
  var SETUP_AVAILABLE_PROJECT_OPTIONS = 'SETUP_AVAILABLE_PROJECT_OPTIONS';
  _exports.SETUP_AVAILABLE_PROJECT_OPTIONS = SETUP_AVAILABLE_PROJECT_OPTIONS;
  var SET_SENTRY_SITE = 'SET_SENTRY_SITE';
  _exports.SET_SENTRY_SITE = SET_SENTRY_SITE;
});