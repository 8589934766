define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/map-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      'update-selected-map-type': function updateSelectedMapType(map_type) {
        // console.log(`update selected map type ${map_type}`);
        this['update-selected-map-type'](map_type);
      }
    }
  });

  _exports.default = _default;
});