define("mvp/models/filter", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    name: _emberData.default.attr("string"),
    criteria: _emberData.default.attr("json"),
    surveillance_results: _emberData.default.attr("json"),
    debug: _emberData.default.attr("boolean"),
    only_clinical_results: _emberData.default.attr("boolean"),
    predefined: _emberData.default.attr("boolean"),
    sort_order: _emberData.default.attr("number"),
    include_drugs: _emberData.default.attr("array"),
    updated_at: _emberData.default.attr("date"),
    indentation_level: _emberData.default.attr("number"),
    secret: _emberData.default.attr('string'),
    available_splits: _emberData.default.attr('array'),
    header: _emberData.default.belongsTo('filter-header')
  });

  _exports.default = _default;
});