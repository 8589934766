define("mvp/models/sentry-site-container", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    site_code: _emberData.default.attr("string"),
    sampling_pattern: _emberData.default.attr("number"),
    date_shipped: _emberData.default.attr("date"),
    date_received: _emberData.default.attr("date"),
    asset_uid: _emberData.default.attr("number"),
    item_id: _emberData.default.attr("string"),
    asset_type_code: _emberData.default.attr("string"),
    asset_name: _emberData.default.attr("string"),
    promised_isolate_count: _emberData.default.attr("number"),
    viable_isolate_count: _emberData.default.attr("number"),
    organization: _emberData.default.belongsTo("organization")
  });

  _exports.default = _default;
});