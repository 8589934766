define("mvp/reducers/data", ["exports", "lodash/merge", "mvp/reducers/types"], function (_exports, _merge, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var initialState = {};

  function _default() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;

    switch (action.type) {
      case _types.API_DATA_SUCCESS:
        return (0, _merge.default)(state, action.response);

      default:
        return state;
    }
  }
});