define("mvp/pods/components/ui-surveillance/adhoc/molecular/criteria/modal/typing/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inclusion: 'include',
    method: undefined,
    observeTypingProfile: function () {
      var typing_criteria = {};

      if (this.method !== undefined) {
        typing_criteria['method'] = this.method;
      }

      if (Ember.get(this, 'results.length') > 0) {
        typing_criteria['results'] = this.results;
      }

      this['add-profile'](this.inclusion, {
        typing: typing_criteria
      });
    }.observes('inclusion', 'method', 'results.length'),
    methodUrl: Ember.computed('project.slug', function () {
      var project = this.project;
      return '/api/adhoc/molecular-typing-methods?project=' + encodeURIComponent(Ember.get(project, 'slug'));
    }),
    resultUrl: Ember.computed('project.slug', 'method', function () {
      var project = this.project;
      return '/api/adhoc/molecular-typing-results' + '?project=' + encodeURIComponent(Ember.get(project, 'slug')) + '&method=' + encodeURIComponent(this.method);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.results = [];
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$('#' + this.elementId + ' .inclusion .checkbox').checkbox({
          onChecked: function onChecked() {
            Ember.set(self, 'inclusion', Ember.$(this).data('inclusion'));
          }
        });
      });
    },
    actions: {
      'update-selected-method': function updateSelectedMethod(method) {
        Ember.set(this, 'method', method);

        if (this.resultDropdown !== undefined) {
          this.resultDropdown.send("reset");
        }
      },
      'update-selected-results': function updateSelectedResults(values) {
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        }

        Ember.set(this, 'results', selection);
      }
    }
  });

  _exports.default = _default;
});