define("mvp/pods/components/d3-charts/medical-centers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cet4EYPP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[11,\"class\",\"section title\"],[9],[0,\"Participating Medical Centers\"],[10],[0,\"\\n\"],[7,\"svg\"],[11,\"width\",\"960\"],[11,\"height\",\"450\"],[9],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"banner\"],[9],[7,\"b\"],[9],[0,\"\\n\"],[1,[21,\"medical-center-count\"],false],[10],[0,\" medical centers submitted \"],[7,\"b\"],[9],[1,[21,\"isolate-count\"],false],[10],[0,\" pathogens\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/d3-charts/medical-centers/template.hbs"
    }
  });

  _exports.default = _default;
});