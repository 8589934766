define("mvp/pods/components/d3-charts/year-slider/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals d3 */
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$(window).on('resize', this._render.bind(this));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.debounce(this, this._render, 50);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      Ember.run.debounce(this, this._render, 50);
    },
    _width: function _width() {
      return Ember.$("#".concat(this.elementId, " svg")).width();
    },
    _render: function _render() {
      Ember.$("#".concat(this.elementId, " svg")).html("");
      var svg = d3.select("#".concat(this.elementId, " svg"));
      var years = this.years;
      var self = this;

      if (!this.currentYear) {
        Ember.set(this, 'currentYear', 'All years');
      }

      if (years && years.length > 1) {
        var x = d3.scalePoint().domain(["All years"].concat(years)).rangeRound([0, this._width() - 300]);

        x.invert = function () {
          var domain = x.domain();
          var range = x.range();
          var scale = d3.scaleQuantize().domain(range).range(domain);
          return function (x) {
            return scale(x);
          };
        }();

        Ember.set(this, 'xYearScale', x);
        var slider = svg.append("g").attr("class", "slider").attr("transform", "translate(150,15)");
        slider.append("line").attr("class", "track").attr("x1", x.range()[0]).attr("x2", x.range()[1]).select(function () {
          return this.parentNode.appendChild(this.cloneNode(true));
        }).attr("class", "track-inset").select(function () {
          return this.parentNode.appendChild(this.cloneNode(true));
        }).attr("class", "track-overlay").call(d3.drag().on("start.interrupt", function () {
          slider.interrupt();
        }).on("start drag", function () {
          var year = x.invert(d3.event.x);
          var currentYear = Ember.get(self, 'currentYear');
          handle.attr("cx", x(year));

          if (year !== currentYear) {
            Ember.get(self, 'yearChanged')(year);
            Ember.set(self, 'currentYear', year);
          }
        }));
        slider.insert("g", ".track-overlay").attr("class", "ticks").attr("transform", "translate(0," + 22 + ")").selectAll("text").data(["All years"].concat(years)).enter().append("text").attr("x", function (d) {
          return x(d);
        }).attr("text-anchor", "middle").text(function (d) {
          return d;
        });
        var handle = slider.insert("circle", ".track-overlay").attr("class", "handle").attr("r", 9).attr("cx", x(this.currentYear));
        svg.append("path").attr("class", "animate-button").attr("cursor", "pointer").attr("transform", "translate(80,-15),scale(1.5,1.5)").attr("fill", "#8ba968").attr("d", "M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28").on("click", function () {
          self._animateToggle();
        });
      }
    },
    _animateToggle: function _animateToggle() {
      var button = d3.select(".animate-button");
      var animating = this.animating;

      if (animating) {
        Ember.set(this, 'animating', false);
        button.transition().duration(100).attr("d", "M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28");
      } else {
        Ember.set(this, 'animating', true); // Set button to pause

        button.transition().duration(100).attr("d", "M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26"); // set slider to first year to animate

        this._incrementYear(0);
      }
    },
    _incrementYear: function _incrementYear(index) {
      var xYearScale = this.xYearScale;
      var years = this.years;
      var animating = this.animating;
      var year = years[index];
      var currentYear = this.currentYear;

      if (!this.isDestroyed && animating) {
        if (year !== currentYear) {
          Ember.set(this, 'currentYear', year);
          this.yearChanged(year);
        }

        d3.select(".handle").transition().duration(200).attr("cx", xYearScale(year));

        if (index < years.length - 1) {
          Ember.run.later(this, this._incrementYear, index + 1, 5000);
        } else {
          this._animateToggle();
        }
      }
    }
  });

  _exports.default = _default;
});