define("mvp/pods/components/ui-surveillance/adhoc/molecular/criteria/modal/genes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service('ajax'),
    // Attributes
    'inclusion': 'include',
    'include-likes': false,
    'present-genes': undefined,
    'exact-enzyme-match': false,
    observeAlleleCriteria: function () {
      var criteria = {
        alleles: this.allele_criteria
      };

      if (this.reactions !== undefined && this.reactions.length > 0) {
        criteria['reactions'] = this.reactions;
      }

      if (!this['include-likes']) {
        criteria['exact_match'] = true;
      }

      if (this['exact-enzyme-match']) {
        criteria['exact_enzyme_match'] = true;
      }

      if (this.testingMethods.length !== 3) {
        criteria['testing_methods'] = this.testingMethods;
      }

      Ember.set(this, 'criteria', criteria);
      this.notifyPropertyChange('criteria');
      this['add-profile'](this.inclusion, this.criteria);
    }.observes('allele_criteria', 'reactions.length', 'include-likes', 'testingMethods.length', 'inclusion', 'exact-enzyme-match'),
    removeSelectedGene: function () {
      Ember.set(this, 'selected-gene', undefined);
    }.observes('allele_criteria'),
    fetchMatchingAllelesCaller: function () {
      this.fetchMatchingAlleles();
    }.observes('allele_criteria', 'include-likes'),
    level1Url: Ember.computed('project.slug', function () {
      var project = this.project;
      return '/api/adhoc/molecular-level-1?project=' + encodeURIComponent(Ember.get(project, 'slug'));
    }),
    level2Url: Ember.computed('project.slug', function () {
      var project = this.project;
      return '/api/adhoc/molecular-level-2?project=' + encodeURIComponent(Ember.get(project, 'slug'));
    }),
    level3Url: Ember.computed('project.slug', function () {
      var project = this.project;
      return '/api/adhoc/molecular-level-3?project=' + encodeURIComponent(Ember.get(project, 'slug'));
    }),
    level4Url: Ember.computed('project.slug', function () {
      var project = this.project;
      return '/api/adhoc/molecular-level-4?project=' + encodeURIComponent(Ember.get(project, 'slug'));
    }),
    level5Url: Ember.computed('project.slug', function () {
      var project = this.project;
      return '/api/adhoc/molecular-level-5?project=' + encodeURIComponent(Ember.get(project, 'slug'));
    }),
    level6Url: Ember.computed('project.slug', function () {
      var project = this.project;
      return '/api/adhoc/molecular-level-6?project=' + encodeURIComponent(Ember.get(project, 'slug'));
    }),
    reactionUrl: Ember.computed('project.slug', function () {
      var project = this.project;
      return '/api/adhoc/molecular-reaction?project=' + encodeURIComponent(Ember.get(project, 'slug'));
    }),
    observeLevel1Dropdown: function () {
      if (this.level1Dropdown !== undefined) {
        document.getElementById(this.level1Dropdown.elementId).blur();
      }
    }.observes('level1Dropdown'),
    alleles: Ember.computed('present-genes.length', 'selected-gene', function () {
      var output;
      var selectedGene = this['selected-gene'];

      if (selectedGene) {
        this['present-genes'].forEach(function (row) {
          if (row.level_5 === selectedGene) {
            output = row.level_6.split(",");
          }
        });
      }

      return output;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this['filter-by'] = {
        gene: true,
        allele: true
      };
      this.testingMethods = ['ngs'];
      this.criteria = {
        alleles: {}
      };
      this.allele_criteria = {};
      this.reactions = ['pos'];
    },
    // Lifecycle
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$('#' + this.elementId + ' .allele.checkbox').checkbox({
          onChange: function onChange() {
            self.toggleProperty('filter-by.allele');
          }
        });
        Ember.$('#' + this.elementId + ' .gene.checkbox').checkbox({
          onChange: function onChange() {
            self.toggleProperty('filter-by.gene');
          }
        });
        Ember.$('#' + this.elementId + ' .likes.checkbox').checkbox({
          onChange: function onChange() {
            self.toggleProperty('include-likes');
          }
        });
        Ember.$('#' + this.elementId + ' .exact.checkbox').checkbox({
          onChange: function onChange() {
            self.toggleProperty('exact-enzyme-match');
          }
        });
        Ember.$('#' + this.elementId + ' .checkpoint.checkbox').checkbox({
          onChecked: function onChecked() {
            Ember.get(self, 'testingMethods').addObject('checkpoint');
          },
          onUnchecked: function onUnchecked() {
            Ember.get(self, 'testingMethods').removeObject('checkpoint');
          }
        });
        Ember.$('#' + this.elementId + ' .pcr.checkbox').checkbox({
          onChecked: function onChecked() {
            Ember.get(self, 'testingMethods').addObject('pcr');
          },
          onUnchecked: function onUnchecked() {
            Ember.get(self, 'testingMethods').removeObject('pcr');
          }
        });
        Ember.$('#' + this.elementId + ' .ngs.checkbox').checkbox({
          onChecked: function onChecked() {
            Ember.get(self, 'testingMethods').addObject('ngs');
          },
          onUnchecked: function onUnchecked() {
            Ember.get(self, 'testingMethods').removeObject('ngs');
          }
        });
        Ember.$('#' + this.elementId + ' .inclusion .checkbox').checkbox({
          onChecked: function onChecked() {
            Ember.set(self, 'inclusion', Ember.$(this).data('inclusion'));
          }
        });
        Ember.$('#' + this.elementId + ' .inclusion .include.checkbox').checkbox('check');
        Ember.$('#' + this.elementId + ' .likes.checkbox').checkbox('set unchecked');
        Ember.$('#' + this.elementId + ' .exact.checkbox').checkbox('set unchecked');
        Ember.$('#' + this.elementId + ' .checkpoint.checkbox').checkbox('set unchecked');
        Ember.$('#' + this.elementId + ' .pcr.checkbox').checkbox('set checked');
        Ember.$('#' + this.elementId + ' .ngs.checkbox').checkbox('set unchecked');
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.level1Dropdown !== undefined) {
          this.level1Dropdown.send('set-selected', '');
        }

        if (this.level2Dropdown !== undefined) {
          this.level2Dropdown.send('set-selected', '');
        }

        if (this.level3Dropdown !== undefined) {
          this.level3Dropdown.send('set-selected', '');
        }

        if (this.level4Dropdown !== undefined) {
          this.level4Dropdown.send('set-selected', '');
        }

        if (this.level5Dropdown !== undefined) {
          this.level5Dropdown.send('set-selected', '');
        }

        if (this.level6Dropdown !== undefined) {
          this.level6Dropdown.send('set-selected', '');
        }

        if (this.reactionDropdown !== undefined) {
          this.reactionDropdown.send('set-selected', 'pos');
        }

        Ember.$('#' + this.elementId + ' .likes.checkbox').checkbox('uncheck');
        Ember.$('#' + this.elementId + ' .exact.checkbox').checkbox('uncheck');
        Ember.$('#' + this.elementId + ' .checkpoint.checkbox').checkbox('uncheck');
        Ember.$('#' + this.elementId + ' .pcr.checkbox').checkbox('uncheck');
        Ember.$('#' + this.elementId + ' .ngs.checkbox').checkbox('check');
      });
    },
    // Actions
    actions: {
      'select-gene': function selectGene(gene) {
        Ember.set(this, 'selected-gene', gene);
      },
      'update-selected-level-1': function updateSelectedLevel1(values) {
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        }

        var allele_criteria = this.allele_criteria;

        if (selection.length > 0) {
          allele_criteria['level_1'] = {
            include: selection
          };
        } else {
          delete allele_criteria['level_1'];
        }

        Ember.set(this, 'allele_criteria', allele_criteria);
        this.notifyPropertyChange('allele_criteria');
      },
      'update-selected-level-2': function updateSelectedLevel2(values) {
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        }

        var allele_criteria = this.allele_criteria;

        if (selection.length > 0) {
          allele_criteria['level_2'] = {
            include: selection
          };
        } else {
          delete allele_criteria['level_2'];
        }

        Ember.set(this, 'allele_criteria', allele_criteria);
        this.notifyPropertyChange('allele_criteria');
      },
      'update-selected-level-3': function updateSelectedLevel3(values) {
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        }

        var allele_criteria = this.allele_criteria;

        if (selection.length > 0) {
          allele_criteria['spectrum'] = {
            include: selection
          };
        } else {
          delete allele_criteria['spectrum'];
        }

        Ember.set(this, 'allele_criteria', allele_criteria);
        this.notifyPropertyChange('allele_criteria');
      },
      'update-selected-level-4': function updateSelectedLevel4(values) {
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        }

        var allele_criteria = this.allele_criteria;

        if (selection.length > 0) {
          allele_criteria['level_4'] = {
            include: selection
          };
        } else {
          delete allele_criteria['level_4'];
        }

        Ember.set(this, 'allele_criteria', allele_criteria);
        this.notifyPropertyChange('allele_criteria');
      },
      'update-selected-level-5': function updateSelectedLevel5(values) {
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        }

        var allele_criteria = this.allele_criteria;

        if (selection.length > 0) {
          allele_criteria['level_5'] = {
            include: selection
          };
        } else {
          delete allele_criteria['level_5'];
        }

        Ember.set(this, 'allele_criteria', allele_criteria);
        this.notifyPropertyChange('allele_criteria');
      },
      'update-selected-level-6': function updateSelectedLevel6(values) {
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        }

        var allele_criteria = this.allele_criteria;

        if (selection.length > 0) {
          allele_criteria['level_6'] = {
            include: selection
          };
        } else {
          delete allele_criteria['level_6'];
        }

        Ember.set(this, 'allele_criteria', allele_criteria);
        this.notifyPropertyChange('allele_criteria');
      },
      'update-selected-reaction': function updateSelectedReaction(values) {
        var selection;

        if (values !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        }

        Ember.set(this, 'reactions', selection);
        this.notifyPropertyChange('reactions');
      }
    },
    fetchMatchingAlleles: function fetchMatchingAlleles() {
      var ajax = this.ajax;
      var project = this.project;
      var self = this;
      Ember.set(this, 'present-genes', undefined);

      if (Object.keys(this.allele_criteria).length > 0) {
        Ember.set(this, 'loading-alleles', true);
        ajax.request('/api/adhoc/matching-alleles', {
          method: 'POST',
          data: {
            allele_criteria: Ember.get(self, 'allele_criteria'),
            include_likes: this['include-likes'],
            project: Ember.get(project, 'slug')
          }
        }).then(self._saveAlleles.bind(self));
      }
    },
    _saveAlleles: function _saveAlleles(response) {
      Ember.set(this, 'loading-alleles', false);
      Ember.set(this, 'present-genes', response);
    }
  });

  _exports.default = _default;
});