define("mvp/pods/components/ui-application/footer/copyright/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0JH2xux6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[9],[0,\"© \"],[1,[21,\"year\"],false],[0,\" JMI Laboratories, Inc\"],[10],[0,\" \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"legal\"],[9],[4,\"link-to\",[\"privacy\"],null,{\"statements\":[[0,\"Privacy policy\"]],\"parameters\":[]},null],[0,\"   |   \"],[4,\"link-to\",[\"terms\"],null,{\"statements\":[[0,\"Terms & Conditions\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-application/footer/copyright/template.hbs"
    }
  });

  _exports.default = _default;
});