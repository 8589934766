define("mvp/pods/components/ui-filter-result/nav/component", ["exports", "mvp/config/environment", "downloadjs"], function (_exports, _environment, _downloadjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authorization: Ember.inject.service(),
    router: Ember.inject.service(),
    schema: Ember.inject.service('schema'),
    jwt: Ember.inject.service(),
    classNames: ['ui', 'secondary', 'menu'],
    canExport: Ember.computed('router.currentRouteName', function () {
      var route = Ember.get(this, 'router.currentRouteName');
      return route.includes("surveillance.show.results.tables.mic-distributions");
    }),
    canExportDropdown: Ember.computed('router.currentRouteName', function () {
      var route = Ember.get(this, 'router.currentRouteName');
      return route === "surveillance.show.results.overview";
    }),
    didInsertElement: function didInsertElement() {
      Ember.$("#" + this.elementId + " .dropdown").dropdown();
      Ember.$("#" + this.elementId + " .download-line-list").popup({
        on: 'click',
        inline: true,
        hoverable: false,
        position: 'bottom right'
      });
      Ember.$("#" + this.elementId + " .split-results").popup({
        on: 'click',
        inline: true,
        hoverable: false,
        position: 'bottom right'
      });
    },
    actions: {
      "export-line-list": function exportLineList(type) {
        this['export-line-list'](type);
      },
      'split-by': function splitBy(key) {
        this['split-by'](key);
      },
      'export': function _export() {
        Ember.$('.button.export').addClass('loading');
        var route = Ember.get(this, 'router.currentRouteName');
        var filter = this.filter;
        var exportFunction;

        if (route === "surveillance.show.results.overview") {
          exportFunction = this.webTableExport;
        } else if (route.includes("surveillance.show.results.tables.mic-distributions")) {
          exportFunction = this.micDistributionExport;
        }

        exportFunction(filter, this).then(this._removeLoading.bind(this), // Error
        function () {
          Ember.$('.button.export').removeClass('loading');
          alert('There was an error in exporting results.');
        });
      }
    },
    webTableExport: function webTableExport(filter, self) {
      var extension = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'xlsx';
      var url = "api/filters/" + filter.id + "/web-table/export." + extension + "?secret=" + Ember.get(filter, 'secret');
      var token = Ember.get(self, 'jwt.token');
      return new Ember.RSVP.Promise(function (resolve) {
        // using jQuery's ajax won't work with binary files!
        var ajax = new XMLHttpRequest();
        ajax.open('GET', url);
        ajax.setRequestHeader('Schema', Ember.get(self, 'schema.header'));
        ajax.setRequestHeader('Authorization', "Bearer ".concat(token));
        ajax.responseType = 'blob';
        var mimetypes = {
          'doc': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        };

        ajax.onload = function () {
          var blob = new Blob([ajax.response], {
            type: mimetypes[extension]
          });
          var fileName = ajax.getResponseHeader("Content-Disposition").match(/\sfilename="([^"]+)"(\s|$)/)[1];
          self.saveBlob(blob, fileName);
        };

        ajax.send();
        resolve();
      });
    },
    micDistributionExport: function micDistributionExport(filter, self) {
      var route = Ember.get(self, 'router.currentRouteName');
      var includeCumulativePercentage = route.includes('cumulative-percentage') || route.includes('both');
      var includeFrequency = route.includes('frequency') || route.includes('both');
      var url = _environment.default.APP.host + "/api/filters/" + filter.id + "/mic-distribution/export?secret=" + Ember.get(filter, 'secret') + '&cumulative_percentage=' + includeCumulativePercentage + '&frequency=' + includeFrequency;
      var token = Ember.get(self, 'jwt.token');
      return new Ember.RSVP.Promise(function (resolve) {
        // using jQuery's ajax won't work with binary files!
        var ajax = new XMLHttpRequest();
        ajax.open('GET', url);
        ajax.setRequestHeader('Schema', Ember.get(self, 'schema.header'));
        ajax.setRequestHeader('Authorization', "Bearer ".concat(token));
        ajax.responseType = 'blob';

        ajax.onload = function () {
          var blob = new Blob([ajax.response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          var fileName = ajax.getResponseHeader("Content-Disposition").match(/\sfilename="([^"]+)"(\s|$)/)[1];
          (0, _downloadjs.default)(blob, fileName);
        };

        ajax.send();
        resolve();
      });
    },
    _removeLoading: function _removeLoading() {
      Ember.$('.button.export').removeClass('loading');
    }
  });

  _exports.default = _default;
});