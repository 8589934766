define("mvp/pods/manage/users/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      "transition-to-invite": function transitionToInvite() {
        this.transitionToRoute("manage.users.invite");
      },
      "resend-invite": function resendInvite(user) {
        // user.resend_invite()
        user.resend_invite();
      },
      edit: function edit(user) {
        this.transitionToRoute("manage.users.edit.settings", user.id);
      },
      delete: function _delete(user) {
        Ember.$('.basic.test.modal').modal({
          onApprove: function onApprove() {
            user.destroyRecord();
          }
        }).modal("show");
      },
      resendInvite: function resendInvite(user) {
        user.reinvite(user);
      }
    }
  });

  _exports.default = _default;
});