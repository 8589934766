define("mvp/models/resistance-rate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    country_name: _emberData.default.attr("string"),
    country_code: _emberData.default.attr("string"),
    us_census_region: _emberData.default.attr("string"),
    s_count: _emberData.default.attr("number"),
    i_count: _emberData.default.attr("number"),
    r_count: _emberData.default.attr("number"),
    total_count: _emberData.default.attr("number"),
    created_at: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date")
  });

  _exports.default = _default;
});