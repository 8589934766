define("mvp/pods/components/ui-surveillance/adhoc/molecular/nested/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'secondary', 'segment'],
    'inclusion': 'and',
    // will be populated from parent
    // 'molecular-profile': undefined,
    mappedMolecularProfile: Ember.computed('molecular-profile.length', function () {
      return this['molecular-profile'].map(function (profile) {
        var key = Object.keys(profile)[0];
        return {
          key: key,
          profile: profile[key]
        };
      });
    }),
    observeMolecularProfile: function () {
      var combined_profile = {};
      combined_profile[this.inclusion] = this['molecular-profile'];
      this.updateProfileAt(this.index, combined_profile);
    }.observes('molecular-profile', 'inclusion'),
    inclusionOptions: Ember.computed(function () {
      return [{
        name: 'AND',
        value: 'and'
      }, {
        name: 'OR',
        value: 'or'
      }];
    }),
    observeInclusionDropdown: function () {
      if (this.inclusionDropdown !== undefined) {
        this.inclusionDropdown.send('set-selected', this.inclusion);
      }
    }.observes('inclusionDropdown'),
    actions: {
      'remove-profile': function removeProfile(index) {
        this['molecular-profile'].removeAt(index);
        this.notifyPropertyChange('molecular-profile');
      },
      'remove-nesting': function removeNesting(index) {
        this['remove-nesting'](index);
      },
      updateProfileAt: function updateProfileAt(index, updatedProfile) {
        var mp = this['molecular-profile'];
        mp[index] = Ember.$.extend(true, {}, updatedProfile);
        Ember.set(this, 'molecular-profile', mp);
        this.send("updateMolecularProfile", mp);
      },
      updateMolecularProfile: function updateMolecularProfile(profile) {
        var combined_profile = {};
        combined_profile[this.inclusion] = profile;
        this.updateMolecularProfile([combined_profile]);
      },
      updateNewMolecularProfile: function updateNewMolecularProfile(object) {
        Ember.set(this, 'new-molecular-profile', object);
      },
      inclusionChanged: function inclusionChanged(inclusion) {
        Ember.set(this, 'inclusion', inclusion);
      },
      addCriteria: function addCriteria() {
        Ember.set(this, 'allele_criteria', {});
        Ember.set(this, 'new-molecular-profile', {});
        var self = this;
        Ember.$('#' + this.elementId + ' .choose-molecular-criteria.modal').modal({
          'context': "#" + self.elementId,
          'observeChanges': true,
          'autofocus': false,
          onApprove: function onApprove() {
            var dup_criteria = Ember.$.extend(true, {}, Ember.get(self, 'new-molecular-profile'));
            Ember.get(self, 'molecular-profile').addObject(dup_criteria);
            self.notifyPropertyChange('molecular-profile');
          }
        }).modal('show');
      },
      addNestedCriteria: function addNestedCriteria() {
        Ember.set(this, 'new-molecular-profile', {});
        this['molecular-profile'].addObject({
          'and': []
        });
        this.notifyPropertyChange('molecular-profile');
      }
    }
  });

  _exports.default = _default;
});