define("mvp/pods/terms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ds7sbN0x",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"ui-legal/terms\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/terms/template.hbs"
    }
  });

  _exports.default = _default;
});