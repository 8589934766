define("mvp/pods/components/ui-admin/sentry-containers/send-containers-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    actions: {},
    setupCell: function setupCell() {
      var target_isolates_count = 0;
      var viable_isolates_count = 0;
      var shipped_containers_array = [];
      var received_containers_array = [];
      Ember.get(this.organization, 'sentry_site_containers').forEach(function (container) {
        target_isolates_count = Ember.get(container, 'promised_isolate_count');
        viable_isolates_count = Ember.get(container, 'viable_isolate_count');

        if (Ember.get(container, 'date_received') === null) {
          shipped_containers_array.push(container);
        }

        if (Ember.get(container, 'date_received') !== null) {
          received_containers_array.push(container);
        }
      });

      if (viable_isolates_count < target_isolates_count && shipped_containers_array.length * 50 < target_isolates_count - viable_isolates_count) {
        Ember.set(this, 'send_containers', 'YES');
      } else {
        Ember.set(this, 'send_containers', 'NO');
      }
    }
  });

  _exports.default = _default;
});