define("mvp/pods/admin/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authorization: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!Ember.get(this, 'authorization.can_admin')) {
        this.transitionTo("surveillance.index");
      }
    }
  });

  _exports.default = _default;
});