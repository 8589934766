define("mvp/helpers/is-greater-than-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isGreaterThanColumn = isGreaterThanColumn;
  _exports.default = void 0;

  function isGreaterThanColumn(params
  /*, hash*/
  ) {
    if (params[0] === ">") {
      return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(isGreaterThanColumn);

  _exports.default = _default;
});