define("mvp/helpers/should-show-back-to-original-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shouldShowBackToOriginalFilter = shouldShowBackToOriginalFilter;
  _exports.default = void 0;

  function shouldShowBackToOriginalFilter(params
  /*, hash*/
  ) {
    var original_filter = params[0];
    var current_filter = params[1];
    return original_filter !== undefined && Ember.get(original_filter, "id") !== Ember.get(current_filter, "id");
  }

  var _default = Ember.Helper.helper(shouldShowBackToOriginalFilter);

  _exports.default = _default;
});