define("mvp/models/drug", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    project: _emberData.default.hasMany("project", {
      async: true
    }),
    project_drugs: _emberData.default.hasMany("project-drug", {
      async: true
    })
  });

  _exports.default = _default;
});