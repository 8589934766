define("mvp/pods/components/d3-charts/phenotype-rate/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /* globals d3 */
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'pathogens', []);
      Ember.set(this, 'drugs', []);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.debounce(this, this._calculatePhenotypeRate, 100);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      Ember.run.debounce(this, this._calculatePhenotypeRate, 100);
    },
    actions: {
      'exportTitle': function exportTitle() {
        // console.log('testing');
        var phenotype = this.phenotype;
        var year = this.year;
        var output = "".concat(phenotype.name, " rates");

        if (year !== 'All years') {
          output = output + " - ".concat(year);
        }

        output = output.replace("<em>", "");
        output = output.replace("</em>", "");
        return output;
      },
      'colorize': function colorize(countries) {
        var rates = this.rates;
        var maptype = this.maptype;
        var self = this;

        if (rates && maptype === 'usa') {
          countries // select all the countries
          .transition().duration(800).attr('fill', function (d) {
            var rate = rates[d.id];

            if (rate) {
              var pct = rate.numerator / rate.denominator;
              return self._colorPct(pct);
            } else {
              return "rgba(0,0,0,.2)";
            }
          });
        } else if (rates) {
          countries // select all the countries
          .transition().duration(800).attr("stroke", function (d) {
            if (rates[d.id]) {
              return "rgba(0,0,0,.5)";
            } else {
              return "rgba(0,0,0,0)";
            }
          }).attr('fill', function (d) {
            var rate = rates[d.id];

            if (rate) {
              var pct = rate.numerator / rate.denominator;
              return self._colorPct(pct);
            } else {
              return "rgba(0,0,0,.2)";
            }
          });
        }
      },
      'legend': function legend(g, viewBoxHeight) {
        var _this = this;

        var ext_color_domain = [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0];
        var ls_w = 20,
            ls_h = 20;
        g.append("rect").attr("x", 10).attr("y", viewBoxHeight - 13 * ls_h).attr("width", ls_w * 4.5).attr("height", ls_h * 13).style("fill", "#FFFFFF").style("stroke", "#000000").attr("class", "legendBackground");
        var legend = g.selectAll("g.legend").data(ext_color_domain).enter().append("g").attr("class", "legend");
        legend.append("rect").attr("x", 20).attr("y", function (d, i) {
          return viewBoxHeight - i * ls_h - 2 * ls_h;
        }).attr("width", ls_w).attr("height", ls_h).style("fill", function () {
          return "rgba(0,0,0,.2)";
        });
        legend.append("rect").attr("x", 20).attr("y", function (d, i) {
          return viewBoxHeight - i * ls_h - 2 * ls_h;
        }).attr("width", ls_w).attr("height", ls_h).style("fill", function (d) {
          return _this._colorPct(d);
        });
        legend.append("text").attr("x", 50).attr("y", function (d, i) {
          return viewBoxHeight - i * ls_h - ls_h - 4;
        }).style("font-family", "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif").text(function (d, i) {
          if (i === 0) {
            return "0%";
          } else if (i === 10) {
            return "100%";
          } else {
            return "";
          }
        });
      },
      'guide': function guide(_ref, d, _guide) {
        var _ref2 = _slicedToArray(_ref, 2),
            x = _ref2[0],
            y = _ref2[1];

        var rates = this.rates;
        var rate = rates[d.id];
        var maptype = this.maptype;
        var phenotype = this.phenotype;

        if (rate) {
          var name;

          if (maptype === 'usa') {
            name = rate.region_name_short;
          } else {
            name = rate.country_name;
          }

          _guide.append("text").attr("dy", ".35em").attr("pointer-events", "none").attr("text-anchor", "left").attr("fill", "rgba(255,255,255,.9)").attr("font-size", 26).attr("x", x + 25).attr("y", y - 10).text(name);

          var pct = rate.numerator / rate.denominator;
          var green_color = d3.scaleLinear().domain([0, 1]).range(["#feedde", "#a63603"]); // let color = d3.color(green_color(pct)).lighter(1);

          var phenotype_name = phenotype.name,
              groups = phenotype_name.split(/(<\/{0,1}em>)/gm);

          var title = _guide.append("text").attr("dy", ".35em").attr("pointer-events", "none").attr("text-anchor", "left").attr("fill", green_color(pct)).attr("font-size", 18).attr("x", x + 25).attr("y", y + 24); // .text(`${(pct * 100).toFixed(2)}% ${phenotype.name}`);


          var lineNumber = -1,
              lineHeight = 20,
              dy = 2,
              maxWidth = 300;
          title.append("tspan").attr("dy", ++lineNumber * lineHeight + dy + "px").text("".concat((pct * 100).toFixed(2), "%"));
          var word,
              line = [],
              makeItalic = false;
          groups.forEach(function (group) {
            if (group === "<em>") {
              makeItalic = true;
            } else if (group === "</em>") {
              makeItalic = false;
            } else {
              line = [""];
              var tspan = title.append("tspan").attr("font-style", makeItalic ? "italic" : "normal"),
                  words = group.replace(/&beta;/gm, "β").replace(/&micro;/gm, "µ").split(/(?![^(]*\))\s/g).reverse(); // eslint-disable-next-line no-cond-assign

              while (word = words.pop()) {
                line.push(word);
                tspan.text(line.join(" "));

                if (title.node().getBBox().width > maxWidth) {
                  line.pop();
                  tspan.text(line.join(" "));
                  line = [word];
                  ++lineNumber;
                  tspan = title.append("tspan").attr("x", x + 25).attr("font-style", makeItalic ? "italic" : "normal").attr("dy", lineHeight + "px").text(word);
                }
              }
            }
          });

          _guide.append("text").attr("dy", ".35em").attr("pointer-events", "none").attr("text-anchor", "left").attr("fill", "rgba(255,255,255,.7)").attr("font-size", 18).attr("x", x + 25).attr("y", y + 20 + dy + ++lineNumber * lineHeight + "px").text("".concat(rate.numerator, " / ").concat(rate.denominator, " isolates"));
        }
      }
    },
    _calculatePhenotypeRate: function _calculatePhenotypeRate() {
      var _this2 = this;

      var phenotype = this.phenotype;
      var ajax = this.ajax;
      var project = this.project;
      var url = "api/projects/".concat(project.id, "/heatmap/phenotype_rates");
      var data = {
        numerator_criteria: JSON.stringify(phenotype.numerator_criteria),
        denominator_criteria: JSON.stringify(phenotype.denominator_criteria)
      };
      var maptype = this.maptype;

      if (maptype === 'usa') {
        data["census_division"] = true;
      }

      var year = this.year;

      if (year !== 'All years') {
        data["year"] = year;
      }

      ajax.post(url, {
        data: data
      }).then(this._handleResults.bind(this)).catch(function () {
        _this2._handleResults([]);
      });
    },
    _handleResults: function _handleResults(payload) {
      var rate_lookup = {};
      var maptype = this.maptype;

      if (maptype === 'usa') {
        payload.forEach(function (rate) {
          rate_lookup[parseInt(rate.id)] = rate;
        });
      } else {
        payload.forEach(function (rate) {
          rate_lookup[rate.iso_numeric_code] = rate;
        });
      }

      Ember.set(this, 'rates', rate_lookup);
    },
    _colorPct: function _colorPct(pct) {
      var green_color = d3.scaleLinear().domain([0, 1]).range(["#feedde", "#a63603"]); // .range([
      //   "rgb(42, 148, 204)",
      //   "rgba(118, 166, 64, .4)",
      //   "rgba(118, 166, 64, .6)",
      //   "rgba(118, 166, 64, .8)",
      //   "rgba(118, 166, 64,  1)"
      // ]);

      return green_color(pct); // const red_color = d3.scaleThreshold()
      //   .domain([0.0, 0.1, 0.2, 0.3, 0.4])
      //   .range([
      //     "rgba(202, 56, 23, 1)",
      //     "rgba(202, 56, 23, .8)",
      //     "rgba(202, 56, 23, .6)",
      //     "rgba(202, 56, 23, .4)",
      //     "rgba(202, 56, 23, .2)"
      //   ]);
      // if (pct >= 0.5) {
      //   return green_color(pct);
      // } else {
      //   return red_color(pct);
      // }
    }
  });

  _exports.default = _default;
});