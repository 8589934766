define("mvp/helpers/add-indentation-level-padding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addIndentationLevelPadding = addIndentationLevelPadding;
  _exports.default = void 0;

  function addIndentationLevelPadding(params
  /*, hash*/
  ) {
    return (params[0] - 1) * 20;
  }

  var _default = Ember.Helper.helper(addIndentationLevelPadding);

  _exports.default = _default;
});