define("mvp/pods/surveillance/show/quality-control/runs/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mJSYDL25",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[27,\"ui-surveillance/show-qc-run\",null,[[\"run\",\"loading\",\"surveillanceYear\",\"susceptibility_results\",\"load_susceptibility_results\"],[[23,[\"model\"]],[23,[\"loading\"]],[23,[\"surveillanceYear\"]],[23,[\"susceptibility_results\"]],[27,\"action\",[[22,0,[]],\"load_susceptibility_results\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/quality-control/runs/show/template.hbs"
    }
  });

  _exports.default = _default;
});