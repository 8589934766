define("mvp/pods/components/ui-sentry/nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oKmfgYy6",
    "block": "{\"symbols\":[\"program\"],\"statements\":[[7,\"div\"],[11,\"class\",\"item organization-item\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"organization\"],[9],[0,\"\\n    \"],[1,[23,[\"site\",\"attributes\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"sentry.index\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"home icon\"],[9],[10],[0,\"\\n  Home\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"sentry.isolates\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"bug icon\"],[9],[10],[0,\"\\n  Isolates\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"sentry.downloads\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"download icon\"],[9],[10],[0,\"\\n  Downloads\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"sentry.uploads\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"upload icon\"],[9],[10],[0,\"\\n  Uploads\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"currentUser\",\"organization\",\"programs\"]]],null,{\"statements\":[[4,\"link-to\",[\"sentry.programs\",[22,1,[\"slug\"]]],[[\"class\"],[\"item program\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"book icon\"],[9],[10],[0,\"\\n    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-sentry/nav/template.hbs"
    }
  });

  _exports.default = _default;
});