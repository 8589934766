define("mvp/pods/gatekeeper/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authorization: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (Ember.get(this, 'authorization.isAHospital')) {
        this.replaceWith("surveillance.sentry.index");
      } else {
        this.replaceWith("surveillance.index");
      }
    }
  });

  _exports.default = _default;
});