define("mvp/abilities/user", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    authorization: Ember.inject.service(),
    canAdmin: Ember.computed('currentUser', function () {
      // Only non-MSL, non-Hospital users can change sites
      return Ember.get(this, "authorization.is_jmi_user");
    })
  });

  _exports.default = _default;
});