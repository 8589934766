define("mvp/pods/manage/users/edit/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pE1DWnTN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"boxed-group\"],[9],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"Settings\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"boxed-group-inner\"],[9],[0,\"\\n    \"],[7,\"form\"],[11,\"class\",\"ui form\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"ui right button\"],[9],[0,\"Update\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"fields\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"field six wide\"],[9],[0,\"\\n          \"],[7,\"label\"],[9],[0,\"User Email\"],[10],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"value\",\"class\"],[[23,[\"model\",\"email\"]],\"ui input\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/manage/users/edit/settings/template.hbs"
    }
  });

  _exports.default = _default;
});