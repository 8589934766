define("mvp/pods/surveillance/show/quality-control/runs/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page'],
    page: 1,
    surveillanceController: Ember.inject.controller("surveillance"),
    project: Ember.computed.alias("surveillanceController.project"),
    runsController: Ember.inject.controller("surveillance.show.quality_control.runs"),
    surveillanceYear: Ember.computed.alias("runsController.surveillanceYear"),
    observeProjectChange: function () {
      Ember.set(this, "page", 1);
    }.observes("project"),
    actions: {
      pageChanged: function pageChanged(page) {
        Ember.set(this, "page", page);
      }
    }
  });

  _exports.default = _default;
});