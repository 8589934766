define("mvp/helpers/titleize_spaces", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleizeSpaces = titleizeSpaces;
  _exports.default = void 0;

  function titleizeSpaces(params) {
    var string = "";
    params.forEach(function (element) {
      var el_arr = element.split(" ");
      el_arr.forEach(function (split_el) {
        string += split_el.capitalize() + " ";
      });
    });
    return string;
  }

  var _default = Ember.Helper.helper(titleizeSpaces);

  _exports.default = _default;
});