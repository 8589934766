define("mvp/pods/manage/organizations/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        organization: this.store.find("organization", params.organization_id),
        all_projects: this.store.findAll("project")
      });
    }
  });

  _exports.default = _default;
});