define("mvp/pods/components/ui-demo-data/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2wdqgO+/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"ui negative message transition\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[7,\"i\"],[11,\"class\",\"close icon\"],[9],[10],[3,\"action\",[[22,0,[]],\"closeMessage\"]],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n        Error\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"Demo could not be found.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Batch Demo Entry #:\"],[10],[0,\"\\n    \"],[7,\"form\"],[11,\"class\",\"ui form\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"fields\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"ten wide field\"],[9],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"value\",\"type\",\"placeholder\"],[[27,\"mut\",[[23,[\"demoNumber\"]]],null],\"number\",\"12345\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"six wide field\"],[9],[0,\"\\n          \"],[7,\"button\"],[11,\"class\",\"ui fluid primary button\"],[9],[0,\"Submit\"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"demoSelected\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-demo-data/index/template.hbs"
    }
  });

  _exports.default = _default;
});