define("mvp/pods/components/ui-admin/nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q8pBHl0O",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui vertical fluid pointing menu sticky secondary\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header item\"],[9],[0,\"\\n    Admin\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"small\"],[9],[0,\"\\n      \"],[1,[23,[\"user\",\"claims\",\"email\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"admin.users.permissions\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"dashboard icon\"],[9],[10],[0,\"\\n    User Permissions\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"admin.users\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"users icon\"],[9],[10],[0,\"\\n    Users\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"admin.statistics\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"users icon\"],[9],[10],[0,\"\\n    Recent Users\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"admin.reports\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"file text icon\"],[9],[10],[0,\"\\n    Reports\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-admin/nav/template.hbs"
    }
  });

  _exports.default = _default;
});