define("mvp/pods/programs/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZJ4U4YtY",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"program-report\",null,[[\"program\",\"filter-groups\",\"year\",\"yearChanged\"],[[23,[\"model\"]],[23,[\"filter_groups\"]],[23,[\"year\"]],[27,\"action\",[[22,0,[]],\"yearChanged\"],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"ui program dimmer\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"ui text loader\"],[9],[0,\"Loading...\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/programs/show/template.hbs"
    }
  });

  _exports.default = _default;
});