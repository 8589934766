define("mvp/pods/site-management/compliance-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wCc70NKG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[9],[0,\"Sentry Compliance Summary\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"ui tabular menu\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"site-management.compliance-summary.by-site\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    By Site\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"site-management.compliance-summary.global\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    Global\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/site-management/compliance-summary/template.hbs"
    }
  });

  _exports.default = _default;
});