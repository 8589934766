define("mvp/pods/components/export-dropdown/component", ["exports", "mvp/config/environment", "downloadjs"], function (_exports, _environment, _downloadjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["ui", "item", "dropdown"],
    schema: Ember.inject.service(),
    jwt: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      Ember.$("#".concat(this.elementId)).dropdown();
    },
    actions: {
      'export-by-type': function exportByType(extension) {
        Ember.$('.export-dropdown').addClass('loading');
        var filter = this.filter;
        var exportFunction = this.webTableExport;
        exportFunction(filter, this, extension).then(function () {
          Ember.$('.export-dropdown').removeClass('loading');
        }, // Error
        function () {
          Ember.$('.export-dropdown').removeClass('loading');
          alert('There was an error in exporting results.');
        });
      }
    },
    webTableExport: function webTableExport(filter, self) {
      var extension = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'xlsx';
      var url = _environment.default.APP.host + "/api/filters/" + filter.id + "/web-table/export." + extension + "?secret=" + Ember.get(filter, 'secret');
      return new Ember.RSVP.Promise(function (resolve) {
        // using jQuery's ajax won't work with binary files!
        var token = Ember.get(self, 'jwt.token');
        var ajax = new XMLHttpRequest();
        ajax.open('GET', url);
        ajax.setRequestHeader('Schema', Ember.get(self, 'schema.header'));
        ajax.setRequestHeader('Authorization', "Bearer ".concat(token));
        ajax.responseType = 'blob';
        var mimetypes = {
          'doc': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        };

        ajax.onload = function (response, status, xhr) {
          var blob = new Blob([ajax.response], {
            type: mimetypes[extension]
          });
          var fileName = ajax.getResponseHeader("Content-Disposition").match(/\sfilename="([^"]+)"(\s|$)/)[1]; // var fileName = "webtable.xlsx"

          (0, _downloadjs.default)(blob, fileName);
        };

        ajax.send();
        resolve();
      });
    }
  });

  _exports.default = _default;
});