define("mvp/pods/surveillance/show/quality-control/runs/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service("ajax"),
    model: function model(params, transition) {
      var project = this.modelFor("surveillance.show");
      var ajax = this.ajax;

      var _self = this;

      var surveillanceYear = transition.queryParams.surveillanceYear;
      return new Ember.RSVP.Promise(function (resolve) {
        // First check if the model is loaded
        if (_self.store.hasRecordForId("run", params.run_number)) {
          resolve(_self.store.peekRecord("run", params.run_number));
        } else {
          var url = "/api/projects/" + project.id + "/runs/" + params.run_number;
          var data = {
            surveillance_year: surveillanceYear
          };
          ajax.request(url, {
            data: data
          }).then(_self._saveRuns.bind(_self, params, resolve));
        }
      });
    },
    _saveRuns: function _saveRuns(params, resolve, response) {
      this.store.pushPayload(response);
      resolve(this.store.peekRecord("run", params.run_number));
    }
  });

  _exports.default = _default;
});