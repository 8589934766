define("mvp/pods/components/d3-charts/medical-centers/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals d3, topojson */
  var _default = Ember.Component.extend({
    width: 960,
    height: 450,
    ajax: Ember.inject.service(),
    didInsertElement: function didInsertElement() {// this._renderChart();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var ajax = this.ajax;
      var project = this.project;
      var url = "/api/projects/".concat(project.id, "/medical-center-overview");
      ajax.request(url).then(this._handleResponse.bind(this));
    },
    _handleResponse: function _handleResponse(response) {
      Ember.set(this, "isolate-count", response.count);
      Ember.set(this, "medical-center-count", response.hospital_counts);
      Ember.set(this, "medical-center-locations", response.locations);
      Ember.run.scheduleOnce('afterRender', this, this._renderChart);
    },
    _renderChart: function _renderChart() {
      var width = this.width;
      var height = this.height;
      var locations = this["medical-center-locations"];
      var svg = d3.select("#".concat(this.elementId, " svg")),
          // projection to use on the map
      projection = d3.geoNaturalEarth1().scale(width / 2 / Math.PI).translate([width / 2, height / 2]),
          // svg map path
      path = d3.geoPath().projection(projection); // lat / long lines on the map
      // graticule = d3.geoGraticule();

      d3.json("https://unpkg.com/world-atlas@1.1.4/world/110m.json", function (error, world) {
        if (error) {
          throw error;
        }

        svg.append("use").attr("class", "stroke").attr("xlink:href", "#sphere");
        svg.append("path").attr("class", "land").attr("d", path(topojson.feature(world, world.objects.countries)));
        svg.append("defs").append("path").datum({
          type: "Sphere"
        }).attr("id", "sphere").attr("d", path); // svg.append("path")
        //     .datum(graticule)
        //     .attr("class", "graticule")
        //     .attr("d", path);

        svg.selectAll('.medical-center').data(locations).enter().append('text').attr('class', 'medical-center').attr('x', function (d) {
          return projection(d)[0];
        }).attr('y', function (d) {
          return projection(d)[1];
        }).attr('text-anchor', 'middle').text(function () {
          return "\uF276";
        });
      });
    }
  });

  _exports.default = _default;
});