define("mvp/pods/components/ui-surveillance/adhoc/molecular/criteria/modal/mutations/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inclusion: 'include',
    drugClass: undefined,
    allele: undefined,
    interpretation: undefined,
    mutations: undefined,
    holdCallback: false,
    drugClassUrl: Ember.computed('project.slug', function () {
      var project = Ember.get(this, 'project.slug');
      return "api/adhoc/molecular-mutation-drug-classes" + "?project=".concat(encodeURIComponent(project));
    }),
    alleleUrl: Ember.computed('project.slug', 'drugClass', function () {
      var project = Ember.get(this, 'project.slug');
      var drugClass = Ember.get(this, 'drugClass');

      if (drugClass) {
        return "api/adhoc/molecular-mutation-alleles" + "?project=".concat(encodeURIComponent(project)) + "&drug_class=".concat(encodeURIComponent(drugClass));
      } else {
        return "api/adhoc/molecular-mutation-alleles" + "?project=".concat(encodeURIComponent(project));
      }
    }),
    interpretationUrl: Ember.computed('project.slug', 'allele', function () {
      var project = Ember.get(this, 'project.slug');
      var allele = Ember.get(this, 'allele');
      return "api/adhoc/molecular-mutation-categories" + "?project=".concat(encodeURIComponent(project)) + "&allele=".concat(encodeURIComponent(allele));
    }),
    mutationsUrl: Ember.computed('project.slug', 'allele', function () {
      var project = Ember.get(this, 'project.slug');
      var allele = Ember.get(this, 'allele');
      return "api/adhoc/molecular-mutations" + "?project=".concat(encodeURIComponent(project)) + "&allele=".concat(encodeURIComponent(allele));
    }),
    alleleSelected: Ember.computed('allele', function () {
      var allele = Ember.get(this, 'allele');
      return allele !== undefined && allele !== '';
    }),
    observeProfile: function () {
      var criteria = {};
      var allele = Ember.get(this, 'allele');

      if (Ember.get(this, 'allele')) {
        criteria['alleles'] = {
          level_6: {
            include: [allele]
          }
        };
      } else {
        criteria['alleles'] = {};
      }

      var interpretations = Ember.get(this, 'interpretation');

      if (interpretations !== undefined && interpretations !== '') {
        criteria['interpretations'] = {
          include: interpretations
        };
      } else {
        criteria['interpretations_any'] = true;
      }

      var mutations = Ember.get(this, 'mutations');

      if (mutations !== undefined && mutations !== '') {
        criteria['annotations'] = mutations;
      } // Propogate the current criteria to the modal
      // so the user can click add profile and it will add it!


      Ember.get(this, 'add-profile')(Ember.get(this, 'inclusion'), criteria);
    }.observes('inclusion', 'allele', 'interpretation', 'mutations.length'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._setDefaultValues();
    },
    didInsertElement: function didInsertElement() {
      this._resetUI();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._setDefaultValues();

      this._resetUI();
    },
    actions: {
      'update-selected-allele': function updateSelectedAllele(allele) {
        Ember.set(this, 'allele', allele);

        this._resetDependentDropdowns();
      },
      'update-selected-interpretation': function updateSelectedInterpretation(interpretation) {
        if (!Ember.get(this, 'holdCallback')) {
          Ember.set(this, 'holdCallback', true);
          Ember.set(this, 'mutations', undefined);
          Ember.get(this, 'mutationDropdown').send('set-selected', '');

          if (interpretation === '') {
            Ember.set(this, 'interpretation', undefined);
          } else {
            Ember.set(this, 'interpretation', interpretation.split(","));
          }

          var self = this;
          Ember.run.later(function () {
            Ember.set(self, 'holdCallback', false);
          }, 250);
        }
      },
      'update-selected-mutations': function updateSelectedMutations(values) {
        if (!Ember.get(this, 'holdCallback')) {
          var split_values;

          if (values !== "") {
            split_values = values.split(",").map(function (item) {
              return decodeURIComponent(item);
            });
          } else {
            split_values = [];
          }

          Ember.set(this, 'holdCallback', true);
          Ember.get(this, 'interpretationDropdown').send('set-selected', '');
          Ember.set(this, 'interpretation', undefined);
          Ember.set(this, 'mutations', split_values);
          var self = this;
          Ember.run.later(function () {
            Ember.set(self, 'holdCallback', false);
          }, 250);
        }
      },
      'update-selected-drug-class': function updateSelectedDrugClass(val) {
        Ember.set(this, 'drugClass', val);
        Ember.get(this, 'alleleDropdown').send('reset');
      }
    },
    _setDefaultValues: function _setDefaultValues() {
      Ember.set(this, 'inclusion', 'include');
      Ember.set(this, 'allele', undefined);
      Ember.set(this, 'interpretation', undefined);
      Ember.set(this, 'holdCallback', false);
    },
    _resetUI: function _resetUI() {
      var self = this;
      Ember.$("#".concat(this.elementId, " .inclusion .checkbox")).checkbox({
        onChecked: function onChecked() {
          Ember.set(self, 'inclusion', Ember.$(this).data('inclusion'));
        }
      });
      Ember.$("#".concat(this.elementId, " .include.checkbox")).checkbox('set checked');

      if (Ember.get(this, 'drugClassDropdown')) {
        Ember.get(this, 'drugClassDropdown').send('reset');
      }

      if (Ember.get(this, 'alleleDropdown')) {
        Ember.get(this, 'alleleDropdown').send('reset');
      }

      if (Ember.get(this, 'interpretationDropdown')) {
        Ember.get(this, 'interpretationDropdown').send('reset');
      }

      if (Ember.get(this, 'mutationDropdown')) {
        Ember.get(this, 'mutationDropdown').send('reset');
      }
    },
    _resetDependentDropdowns: function _resetDependentDropdowns() {
      Ember.get(this, 'interpretationDropdown').send('reset');
      Ember.get(this, 'mutationDropdown').send('reset');
    }
  });

  _exports.default = _default;
});