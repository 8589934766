define("mvp/helpers/lv-total-required-received", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lvTotalRequiredReceived = lvTotalRequiredReceived;
  _exports.default = void 0;

  function lvTotalRequiredReceived(params) {
    return _lodash.default.filter(params[1], function (el) {
      return el.site === params[0].code;
    })[0].total_count;
  }

  var _default = Ember.Helper.helper(lvTotalRequiredReceived);

  _exports.default = _default;
});