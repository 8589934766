define("mvp/pods/components/ui-surveillance/publications/viewer/component", ["exports", "mvp/config/environment", "downloadjs"], function (_exports, _environment, _downloadjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service("current-user"),
    selected_publication: undefined,
    loadingFile: undefined,
    // Poller
    poller: undefined,
    pollingInterval: 5000,
    sortedFilters: Ember.computed.sort('selected_publication.filters', 'filterSorting'),
    sortedFiles: Ember.computed.sort("selected_publication.files", "fileSorting"),
    init: function init() {
      this._super.apply(this, arguments); // using standard ascending sort


      this.filterSorting = ['sort_order'];
      this.fileSorting = ['name'];
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.startPolling();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.stopPolling();
    },
    actions: {
      "publication-selected": function publicationSelected(publication) {
        Ember.set(this, "selected_publication", publication);
      },
      "back-to-all-publications": function backToAllPublications() {
        Ember.set(this, "selected_publication", undefined);
      },
      "filter-selected": function filterSelected(filter) {
        this.filterSelected(filter);
      },
      "download": function download(file) {
        Ember.set(this, "loadingFile", file.name);

        var _self = this;

        var url = _environment.default.APP.host + "/api/files/download" + "?file_path=" + encodeURIComponent(file.path);
        Ember.$.ajax({
          url: url,
          success: _downloadjs.default.bind(true, file.type, file.name),
          complete: function complete() {
            Ember.set(_self, "loadingFile", undefined);
          }
        });
      }
    },
    stopPolling: function stopPolling() {
      var poller = this.poller;
      Ember.run.cancel(poller);
    },
    startPolling: function startPolling() {
      var pollingInterval = this.pollingInterval;
      Ember.set(this, "poller", Ember.run.later(this, this.onPoll, pollingInterval));
    },
    onPoll: function onPoll() {
      var project = this.project;

      var _self = this;

      project.reload().then(_self._poll.bind(_self));
    },
    _poll: function _poll() {
      this.startPolling();
    }
  });

  _exports.default = _default;
});