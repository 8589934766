define("mvp/pods/components/ui-surveillance/isolates/antibiogram/interp/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['ui', 'circular', 'label'],
    classNameBindings: ['colorName'],
    attributeBindings: ['data-tooltip'],
    'data-tooltip': Ember.computed('interp.delivery_mechanism', function () {
      var output = [];

      if (Ember.get(this, 'interp.delivery_mechanism')) {
        output.addObject("Delivery Mechanism: " + Ember.get(this, 'interp.delivery_mechanism'));
      }

      if (Ember.get(this, 'interp.infection_type')) {
        output.addObject("Infection Type: " + Ember.get(this, 'interp.infection_type'));
      }

      if (output.length > 0) {
        return output.join(' / ');
      } else {
        return false;
      }
    }),
    colorName: Ember.computed('interp.reaction', function () {
      switch (Ember.get(this, 'interp.reaction')) {
        case 'S':
          return 'green';

        case 'I':
          return 'orange';

        case 'R':
          return 'red';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});