define("mvp/pods/components/ui-admin/dashboard/component", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals Keen */
  var _default = Ember.Component.extend({
    sortedFilterActivity: Ember.computed.sort('filter-activity', 'filterActivitySorting'),
    topSortedFilterActivity: Ember.computed('sortedFilterActivity', function () {
      return this.sortedFilterActivity.slice(0, 5);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.filterActivitySorting = ['result:desc', 'criteria'];
    },
    didInsertElement: function didInsertElement() {
      var _self = this; // var client = new Keen({
      //   projectId: config.KEEN_PROJECT_ID,
      //   readKey: config.KEEN_READ_KEY
      // });
      // Keen.ready(function(){
      //   get(_self, "loadActiveUsers")(client);
      //   get(_self, "loadFilterViews")(client);
      //   get(_self, "loadPageViews")(client, _self);
      //   get(_self, "loadFileDownloads")(client, _self);
      //   get(_self, "loadFiltersActivity")(client, _self);
      // });

    },
    loadActiveUsers: function loadActiveUsers(client) {
      var query = new Keen.Query("count_unique", {
        eventCollection: "page-view",
        interval: "daily",
        targetProperty: "user",
        timeframe: "this_7_days",
        timezone: "UTC"
      });
      client.draw(query, document.getElementById("keen-active-users"), {
        library: "google",
        title: null,
        height: 200,
        chartOptions: {
          chartArea: {
            height: "80%",
            top: "5%",
            bottom: "15%"
          },
          legend: {
            position: "none"
          }
        }
      });
    },
    loadFilterViews: function loadFilterViews(client) {
      var query = new Keen.Query("count", {
        eventCollection: "filter-views",
        interval: "daily",
        timeframe: "this_14_days",
        timezone: "UTC"
      });
      client.draw(query, document.getElementById("keen-filters-viewed"), {
        library: "google",
        title: null,
        height: 200,
        chartOptions: {
          chartArea: {
            height: "80%",
            top: "5%",
            bottom: "15%"
          },
          legend: {
            position: "none"
          }
        }
      });
    },
    loadPageViews: function loadPageViews(client, self) {
      var query = new Keen.Query("count", {
        eventCollection: "page-view",
        timeframe: "this_7_days",
        timezone: "UTC"
      });
      client.run(query, function (err, res) {
        if (err) {// there was an error!
        } else {
          // do something with res.result
          Ember.set(self, "page-views", res.result);
        }
      });
    },
    loadFileDownloads: function loadFileDownloads(client, self) {
      var file_downloads_query = new Keen.Query("count", {
        eventCollection: "file-download",
        timeframe: "this_7_days",
        timezone: "UTC"
      });
      client.run(file_downloads_query, function (err, res) {
        if (!err) {
          Ember.set(self, "file-downloads", res.result);
        }
      });
    },
    loadFiltersActivity: function loadFiltersActivity(client, self) {
      var query = new Keen.Query("count", {
        eventCollection: "filter-views",
        groupBy: ["criteria"],
        timeframe: "this_7_days",
        timezone: "UTC"
      });
      client.run(query, function (err, res) {
        if (err) {// there was an error!
        } else {
          // do something with res.result
          Ember.set(self, "filter-activity", res.result);
        }
      });
    }
  });

  _exports.default = _default;
});