define("mvp/pods/surveillance/show/results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZJG3qstH",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ui-filter-result/header\",null,[[\"header\",\"view-organism-results\"],[[23,[\"model\",\"header\"]],[27,\"action\",[[22,0,[]],\"viewOrganismResults\"],null]]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"media\",\"isMobile\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ui-filter-result/nav\",null,[[\"filter\",\"export-line-list\",\"split-by\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"exportLineList\"],null],[27,\"action\",[[22,0,[]],\"splitBy\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/results/template.hbs"
    }
  });

  _exports.default = _default;
});