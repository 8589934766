define("mvp/pods/demo-data/index/open/route", ["exports", "ember-apollo-client", "mvp/pods/demo-data/index/open/Open"], function (_exports, _emberApolloClient, _Open) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var siteCode = params.siteCode || this.paramsFor("demo-data.index").indexSiteCode;
      var variables = {
        page: params.page,
        perPage: params.perPage,
        siteCode: siteCode
      };
      return this.apollo.watchQuery({
        fetchPolicy: "cache-and-network",
        query: _Open.default,
        variables: variables
      }, 'sentrySampleBatchesActive');
    },
    queryParams: {
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      siteCode: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var siteCode = this.paramsFor("demo-data.index").indexSiteCode;
      controller.setProperties({
        siteObjectives: null,
        selectedSite: null,
        createError: null,
        objectiveOrganism: null,
        siteCode: siteCode
      });
    },
    afterModel: function afterModel() {
      this.transitionTo('demo-data.index.open');
    }
  });

  _exports.default = _default;
});