define("mvp/pods/components/d3-charts/susceptibility-rate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Om/oUQl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"maptype\"]],\"world\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"d3-charts/choropleth-world\",null,[[\"height\",\"width\",\"susceptibility-rates\",\"colorize\",\"guide\",\"exportTitle\",\"legend\"],[\"100%\",\"100%\",[23,[\"rates\"]],[27,\"action\",[[22,0,[]],\"colorize\"],null],[27,\"action\",[[22,0,[]],\"guide\"],null],[27,\"action\",[[22,0,[]],\"exportTitle\"],null],[27,\"action\",[[22,0,[]],\"legend\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"maptype\"]],\"europe\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"d3-charts/choropleth-europe\",null,[[\"height\",\"width\",\"susceptibility-rates\",\"colorize\",\"guide\",\"exportTitle\",\"legend\"],[\"100%\",\"100%\",[23,[\"rates\"]],[27,\"action\",[[22,0,[]],\"colorize\"],null],[27,\"action\",[[22,0,[]],\"guide\"],null],[27,\"action\",[[22,0,[]],\"exportTitle\"],null],[27,\"action\",[[22,0,[]],\"legend\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"maptype\"]],\"usa\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"d3-charts/choropleth-usa-census-division\",null,[[\"height\",\"width\",\"susceptibility-rates\",\"colorize\",\"guide\",\"exportTitle\",\"legend\"],[\"100%\",\"100%\",[23,[\"rates\"]],[27,\"action\",[[22,0,[]],\"colorize\"],null],[27,\"action\",[[22,0,[]],\"guide\"],null],[27,\"action\",[[22,0,[]],\"exportTitle\"],null],[27,\"action\",[[22,0,[]],\"legend\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/d3-charts/susceptibility-rate/template.hbs"
    }
  });

  _exports.default = _default;
});