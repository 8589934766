define("mvp/pods/surveillance/show/quality-control/runs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OBtMId+t",
    "block": "{\"symbols\":[\"year\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ui text menu qc-year-runs\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header item\"],[9],[0,\"Year\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"years\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"class\",[28,[\"item \",[27,\"if\",[[27,\"eq\",[[22,1,[]],[23,[\"surveillanceYear\"]]],null],\"active\",\"\"],null]]]],[9],[0,\"\\n      \"],[1,[22,1,[]],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"set-year\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"ui divider\"],[9],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/quality-control/runs/template.hbs"
    }
  });

  _exports.default = _default;
});