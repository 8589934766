define("mvp/helpers/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleize = titleize;
  _exports.default = void 0;

  function titleize(params) {
    var string = "";
    params.forEach(function (element) {
      var el_arr = element.split("_");
      el_arr.forEach(function (split_el) {
        string += split_el.capitalize() + " ";
      });
    });
    return string;
  }

  var _default = Ember.Helper.helper(titleize);

  _exports.default = _default;
});