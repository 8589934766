define("mvp/pods/demo-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YeVb5388",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pagehead slim\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"ui grid container\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"jmi-icon\"],[11,\"src\",\"assets/images/JMI 4C Stacked REVERSE RGB.png\"],[11,\"height\",\"80\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"sixteen wide column header-container\"],[9],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"ui grid container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sixteen wide column main-content\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/demo-data/template.hbs"
    }
  });

  _exports.default = _default;
});