define("mvp/abilities/adhoc", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    currentUser: Ember.inject.service("current-user"),
    store: Ember.inject.service("store"),
    // Can view sponsored program
    canChangeSiteCode: Ember.computed('currentUser', function () {
      var can = true; // let currentUser = get(this, "currentUser");
      //
      // // Loop through the users roles
      // if (currentUser.content !== null ) {
      //   get(currentUser, "roles").forEach(function(role) {
      //     let role_name = get(role, "name");
      //     // Check to see if the user is an MSL
      //     if (role_name === "msl") {
      //       can = false;
      //     }
      //   });
      // }

      return can;
    }),
    canDownloadPublications: Ember.computed('currentUser', function () {
      var can = false; // let currentUser = get(this, "currentUser");
      //
      // // Loop through the users roles
      // if (currentUser.content !== null ) {
      //   can = true;
      //   get(currentUser, "roles").forEach(function(role) {
      //     let role_name = get(role, "name");
      //     // Check to see if the user is an MSL
      //     if (role_name === "msl") {
      //       can = false;
      //     }
      //   });
      // }

      return can;
    })
  });

  _exports.default = _default;
});