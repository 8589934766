define("mvp/pods/surveillance/show/results/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service('ajax'),
    model: function model() {
      var ajax = this.ajax;
      var filter = this.modelFor("surveillance.show.results");
      var url = "api/filters/".concat(filter.id, "/web-table");
      return ajax.request(url);
    }
  });

  _exports.default = _default;
});