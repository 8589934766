define("mvp/pods/manage/users/invite/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        all_organizations: this.store.findAll("organization")
      });
    }
  });

  _exports.default = _default;
});