define("mvp/pods/surveillance/show/heatmap/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service("ajax"),
    queryParams: ["pathogen", "antibiotic", "year", "authority", "delivery_mechanism", "infection_type"],
    pathogen: "",
    antibiotic: "",
    year: 0,
    authority: "",
    delivery_mechanism: "",
    infection_type: "",
    map_type: "World",
    show_map: false,
    'selected-playing': false,
    init: function init() {
      this._super.apply(this, arguments);

      this.map_types = ["World", "Europe", "US"];
    },
    actions: {
      'update-selected-year': function updateSelectedYear(year, playing) {
        // console.log('updating year');
        Ember.set(this, 'year', year);
        this.UpdateResistanceRates();
        Ember.set(this, "selected-playing", playing);
      },
      'update-selected-pathogen': function updateSelectedPathogen(pathogen) {
        // console.log('updating pathogen');
        Ember.set(this, 'pathogen', pathogen);
        var pathogen_drugs = [];
        var self = this;
        Ember.get(this, 'model.pathogens').forEach(function (p) {
          var p_name = Ember.get(p, 'name');

          if (p_name === pathogen) {
            pathogen_drugs = Ember.get(p, 'drugs').split(",");
          }
        });
        var pathogen_lookup = {};
        var antibiotics = Ember.get(this, 'model.antibiotics');
        var first_drug = "";
        antibiotics.forEach(function (antibiotic) {
          var antibiotic_name = Ember.get(antibiotic, 'alias');

          if (pathogen_drugs.includes(antibiotic_name)) {
            pathogen_lookup[antibiotic_name] = true;

            if (first_drug === "") {
              first_drug = antibiotic_name;
            }
          } else {
            pathogen_lookup[antibiotic_name] = false;
          }
        });

        if (pathogen_lookup[this.antibiotic]) {
          self.send('update-selected-antibiotic', this.antibiotic);
        } else {
          self.send('update-selected-antibiotic', first_drug);
        }

        this.UpdateResistanceRates();
      },
      'update-selected-antibiotic': function updateSelectedAntibiotic(antibiotic) {
        // console.log('updating antibiotic');
        Ember.set(this, 'antibiotic', antibiotic);
        var self = this;
        var model = this.model;
        var ajax = this.ajax;
        var url = "/api/resistance-rate-authorities?" + "pathogen=" + encodeURIComponent(this.pathogen) + "&antibiotic=" + encodeURIComponent(this.antibiotic);
        ajax.request(url).then(self._updateAuthorities.bind(self, model));
        this.UpdateResistanceRates();
      },
      'update-selected-authority': function updateSelectedAuthority(authority) {
        // console.log('updating authority');
        Ember.set(this, 'authority', authority);
        var self = this;
        var model = this.model;
        var ajax = this.ajax;
        var url = "/api/resistance-rate-delivery-mechanisms?" + "pathogen=" + encodeURIComponent(this.pathogen) + "&antibiotic=" + encodeURIComponent(this.antibiotic) + "&authority=" + encodeURIComponent(this.authority);
        ajax.request(url).then(self._updateDeliveryMechanism.bind(self, model));
        this.UpdateResistanceRates();
      },
      'update-selected-delivery-mechanism': function updateSelectedDeliveryMechanism(delivery_mechanism) {
        // console.log('updating delivery_mechanism');
        Ember.set(this, 'delivery_mechanism', delivery_mechanism);
        var self = this;
        var model = this.model;
        var ajax = this.ajax;
        var url = "/api/resistance-rate-infection-types?" + "pathogen=" + encodeURIComponent(this.pathogen) + "&antibiotic=" + encodeURIComponent(this.antibiotic) + "&authority=" + encodeURIComponent(this.authority) + "&delivery_mechanism=" + encodeURIComponent(this.delivery_mechanism);
        ajax.request(url).then(self._updateInfectionType.bind(self, model));
        this.UpdateResistanceRates();
      },
      'update-selected-infection-type': function updateSelectedInfectionType(infection_type) {
        // console.log('updating infection_type ' + infection_type);
        Ember.set(this, 'infection_type', infection_type);
        this.UpdateResistanceRates();
      },
      'update-selected-map-type': function updateSelectedMapType(map_type) {
        // console.log('updating map_type');
        Ember.set(this, 'map_type', map_type); // this.UpdateResistanceRates();
      }
    },
    UpdateResistanceRates: function UpdateResistanceRates() {
      var self = this; // set(this, 'show_map', false);

      if (this.pathogen.length !== 0 && this.antibiotic.length !== 0 && this.authority.length !== 0 && this.delivery_mechanism.length !== 0 && this.infection_type.length !== 0) {
        var params = {
          year: this.year,
          pathogen: this.pathogen,
          antibiotic: this.antibiotic,
          authority: this.authority,
          delivery_mechanism: this.delivery_mechanism,
          infection_type: this.infection_type
        };
        var key = this.year + "_" + this.pathogen + "_" + this.antibiotic + "_" + this.authority + "_" + this.delivery_mechanism + "_" + this.infection_type;
        var model = this.model;
        var resistance_rate_groups = model.resistance_rate_groups;
        var resistance_rate_group_id = resistance_rate_groups[key];

        if (resistance_rate_group_id) {
          this.store.findRecord('resistance-rate-group', resistance_rate_group_id).then(self._updateResistanceRateGroup.bind(self, model));
        } else {
          this.store.queryRecord('resistance-rate-group', params).then(self._updateResistanceRateGroups.bind(self, model, resistance_rate_groups, key));
        }
      }
    },
    _updateResistanceRateGroup: function _updateResistanceRateGroup(model, resistance_rate_group) {
      Ember.set(model, 'resistance_rate_group', resistance_rate_group);
      Ember.set(this, 'show_map', true);
    },
    _updateResistanceRateGroups: function _updateResistanceRateGroups(model, resistance_rate_groups, key, resistance_rate_group) {
      Ember.set(model, 'resistance_rate_group', resistance_rate_group);
      resistance_rate_groups[key] = Ember.get(resistance_rate_group, 'id');
      Ember.set(model, 'resistance_rate_groups', resistance_rate_groups);
      Ember.set(this, 'show_map', true);
    },
    _updateAuthorities: function _updateAuthorities(model, response) {
      Ember.set(model, 'authorities', response);
      this.send('update-selected-authority', Ember.get(this, 'model.authorities.firstObject.name'));
    },
    _updateDeliveryMechanism: function _updateDeliveryMechanism(model, response) {
      Ember.set(model, 'delivery_mechanisms', response);
      this.send('update-selected-delivery-mechanism', Ember.get(this, 'model.delivery_mechanisms.firstObject.name'));
    },
    _updateInfectionType: function _updateInfectionType(model, response) {
      Ember.set(model, 'infection_types', response);
      this.send('update-selected-infection-type', Ember.get(this, 'model.infection_types.firstObject.name'));
    }
  });

  _exports.default = _default;
});