define("mvp/pods/site-management/objectives/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nuoZzOtn",
    "block": "{\"symbols\":[\"objective\"],\"statements\":[[7,\"h1\"],[9],[0,\"Objectives\"],[10],[0,\"\\n\"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"newObjective\"],null]],[11,\"class\",\"ui button primary right\"],[9],[0,\"\\n  New Objective\\n\"],[10],[0,\"\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\"],[7,\"table\"],[11,\"class\",\"ui table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Name\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Description\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Recruitment\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Actions\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[22,0,[\"model\",\"records\"]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n      \"],[7,\"td\"],[9],[1,[22,1,[\"description\"]],false],[10],[0,\"\\n      \"],[7,\"td\"],[9],[1,[22,1,[\"recruitmentName\"]],false],[10],[0,\"\\n      \"],[7,\"td\"],[9],[0,\"\\n\"],[0,\"        \"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"destroyObjective\",[22,1,[]]],null]],[11,\"class\",\"ui button red\"],[9],[0,\"\\n          Delete\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[22,0,[\"model\",\"pageInfo\",\"totalRecords\"]],25],null]],null,{\"statements\":[[0,\"    \"],[7,\"tfoot\"],[9],[0,\"\\n      \"],[7,\"th\"],[11,\"colspan\",\"9\"],[9],[0,\"\\n        \"],[1,[27,\"page-numbers\",null,[[\"content\"],[[23,[\"pagedContent\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/site-management/objectives/index/template.hbs"
    }
  });

  _exports.default = _default;
});