define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/years/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JxiFHmga",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"year_widget\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"ui centered grid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"right aligned four wide column\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"playing\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"ui icon button\"],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"pause icon\"],[9],[10],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],\"pause\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"ui icon button\"],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"undo icon\"],[9],[10],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],\"undo\"]],[10],[0,\"\\n          \"],[7,\"button\"],[11,\"class\",\"ui icon button\"],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"play icon\"],[9],[10],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],\"play\"]],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"left aligned six wide column\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"id\",\"slider\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"left aligned four wide column\"],[9],[0,\" \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/years/template.hbs"
    }
  });

  _exports.default = _default;
});