define("mvp/pods/components/ui-surveillance/adhoc/molecular/new/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authorization: Ember.inject.service(),
    eventBus: Ember.inject.service("event-bus"),
    observeCriteria: function () {
      Ember.run.scheduleOnce('actions', this, function () {
        if (this['molecular-profile'].length > 0) {
          this["update-filter-criteria"]("new_molecular_test_results", JSON.stringify(this.criteria));
        } else {
          this["update-filter-criteria"]("new_molecular_test_results", undefined);
        }
      });
    }.observes('criteria'),
    init: function init() {
      this._super.apply(this, arguments);

      this['molecular-profile'] = [];
      this.criteria = [{
        and: []
      }];
      this.setupInit();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var molecCriteria = Ember.get(this, 'filter.criteria.new_molecular_test_results');
      Ember.set(this, 'inclusion', 'and');

      if (molecCriteria) {
        var criteria = JSON.parse(molecCriteria);
        Ember.set(this, 'criteria', criteria);

        if (criteria[0]["or"]) {
          Ember.set(this, 'molecular-profile', criteria[0]["or"]);
          Ember.set(this, 'inclusion', 'or');
        } else {
          Ember.set(this, 'molecular-profile', criteria[0]["and"]);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.cleanup();
    },
    actions: {
      updateProfileAt: function updateProfileAt(index, updatedProfile) {
        Ember.set(this, 'criteria', [updatedProfile]);
        this.notifyPropertyChange('criteria');
      },
      updateMolecularProfile: function updateMolecularProfile(molecularProfile) {
        Ember.set(this, 'criteria', molecularProfile);
        this.notifyPropertyChange('criteria');
      }
    },
    // Reset the state of this component, usually called when the selected
    // project changes or the user clears the filter.
    reset: function reset() {
      Ember.set(this, 'inclusion', 'and');
      Ember.set(this, 'molecular-profile', []);
      Ember.set(this, 'criteria', [{
        and: []
      }]);
    },
    setupInit: function setupInit() {
      // Subscribe to the event bus
      this.eventBus.on('resetAdhocFilter', this, 'reset');
    },
    // Performs cleanup of the component after the element is destroyed
    cleanup: function cleanup() {
      // Destroy ties to the event bus
      this.eventBus.off('resetAdhocFilter', this, 'reset');
    }
  });

  _exports.default = _default;
});