define("mvp/pods/surveillance/show/no-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lP4wuTfL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"error\"],[11,\"class\",\"ui center aligned compact piled segment\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"ui header\"],[9],[0,\"\\n    No Results\\n    \"],[7,\"div\"],[11,\"class\",\"sub header\"],[9],[0,\"\\n      No surveillance results were found for the specified filter criteria, please choose a different filter.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"surveillance.show.adhoc\"],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"ui button primary\"],[9],[0,\"\\n      Adjust Filter\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/no-results/template.hbs"
    }
  });

  _exports.default = _default;
});