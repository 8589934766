define("mvp/helpers/lv-received-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lvReceivedCount = lvReceivedCount;
  _exports.default = void 0;

  function lvReceivedCount(counts_string) {
    return JSON.parse(counts_string[0])['receivedCount'];
  }

  var _default = Ember.Helper.helper(lvReceivedCount);

  _exports.default = _default;
});