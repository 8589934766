define("mvp/pods/components/ui-filter-result/charts/frequency-distribution/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IByiMzt6",
    "block": "{\"symbols\":[\"chart\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"drug-selection\"],[9],[0,\"\\n  \"],[1,[27,\"async-dropdown\",null,[[\"register-as\",\"isMultiSelect\",\"onChange\",\"options\",\"default-text\"],[[23,[\"drugsDropdown\"]],true,[27,\"action\",[[22,0,[]],\"drugs-changed\"],null],[23,[\"drugAliases\"]],\"Select a set of drugs...\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"ui divider\"],[9],[10],[0,\"\\n  \"],[1,[27,\"ui-surveillance/loading\",null,[[\"without-steps\",\"skipScroll\"],[true,true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"mic-distribution-charts\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"mic-distribution-charts\",\"charts\"]]],null,{\"statements\":[[0,\"\\n      \"],[7,\"h4\"],[11,\"class\",\"ui header frequency-distribution-header\"],[9],[1,[22,1,[\"title\"]],true],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"finlandogram\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"d3-charts/finlandogram\",null,[[\"data\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"d3-charts/frequency-distribution\",null,[[\"data\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"no-results\"],[9],[0,\"Select at least one drug above to get started.\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-filter-result/charts/frequency-distribution/template.hbs"
    }
  });

  _exports.default = _default;
});