define("mvp/helpers/is-paid-for-sentry-year", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPaidForSentryYear = isPaidForSentryYear;
  _exports.default = void 0;

  function isPaidForSentryYear(params
  /*, hash*/
  ) {
    return params[0].paidForSentryYear[_environment.default.APP.SETTINGS.DEMO_ENTRY_YEAR];
  }

  var _default = Ember.Helper.helper(isPaidForSentryYear);

  _exports.default = _default;
});