define("mvp/pods/surveillance/show/publications/index/controller", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Controller.extend({
    surveillanceShowController: Ember.inject.controller('surveillance.show'),
    project: Ember.computed.alias('surveillanceShowController.model'),
    authorization: Ember.inject.service(),
    isParatek: Ember.computed("", function () {
      return INSTANCE === 'mvpparatek';
    })
  });

  _exports.default = _default;
});