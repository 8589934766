define("mvp/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zUe5IT4F",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"content-container\"],[9],[0,\"\\n  \"],[1,[21,\"ui-user/auth0-login\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"ui-application/footer\",null,[[\"reverse-copy\"],[true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});