define("mvp/router", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    redux: Ember.inject.service(),
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    // isTimeTraveling: false,
    init: function init() {
      this._super.apply(this, arguments); // var redux = this.get('redux');
      // redux.store.subscribe(this._timeTravel.bind(this));

    } // _timeTravel: function() {
    //   var redux = this.get('redux');
    //   var current = this.currentPath;
    //   var routerState = redux.getState().router;
    //
    //   const path = routerState.path;
    //   const params = routerState.params;
    //
    //   if(path !== current) {
    //
    //     set(this, 'isTimeTraveling', true);
    //     if(params && Object.keys(params).length > 0) {
    //         this.transitionTo(path, params);
    //     } else {
    //         this.transitionTo(path);
    //     }
    //   }
    // },
    // didTransition: function() {
    //   this._super(...arguments)
    //   var redux = get(this, 'redux');
    //   var routerState = redux.getState().router;
    //
    //   if (!routerState.path || !get(this, 'isTimeTraveling')) {
    //     var path = this.currentPath;
    //     var params = this.get('currentState.routerJsState')['params'][path];
    //     redux.dispatch({
    //         type: '@@router/LOCATION_CHANGE',
    //         path: path,
    //         params: params
    //     });
    //   }
    //
    //   next(() => {
    //     set(this, 'isTimeTraveling', false)
    //   })
    // }

  });
  Router.map(function () {
    this.route('login', {
      path: "/"
    });
    this.route('sentry', {});
    this.route('signup', {});
    this.route('dashboard', {});
    this.route("privacy");
    this.route("terms");
    this.route("demo-data", function () {
      this.route("logging", {
        path: "logging/:id"
      });
      this.route("index", {
        path: "/"
      }, function () {
        this.route('open');
        this.route('pending');
        this.route('received');
      });
      this.route("show", {
        path: "/:id"
      });
      this.route('loading');
    });
    this.route("isolates", function () {
      this.route("show", {
        path: "/:isolate_id"
      });
    });
    this.route('surveillance', {
      path: "/app"
    }, function () {
      // This is the hospital portal
      this.route("sentry", {
        path: "medical-center/:site"
      }, function () {
        this.route("index", {
          path: "/"
        });
        this.route("recruitment");
        this.route("shipments", function () {
          this.route("index", {
            path: "/"
          });
          this.route("show", {
            path: "/:id"
          });
        });
        this.route("demo", function () {
          this.route("index", {
            path: "/"
          });
          this.route("show", {
            path: "/:objective"
          });
          this.route("shipment");
        });
        this.route("isolates", function () {
          this.route("index", {
            path: "/"
          });
          this.route("show", {
            path: "/:accession_number"
          });
        });
        this.route("downloads");
        this.route("uploads");
        this.route("programs", {
          path: "/programs/:program_slug"
        }, function () {
          this.route("index", {
            path: "/"
          });
        });
      });
      this.route("index", {
        path: "/"
      });
      this.route("show", {
        path: "/:project_slug"
      }, function () {
        this.route("dashboard", {
          path: "/"
        });
        this.route("adhoc");
        this.route("category-definitions");
        this.route("heatmap");
        this.route("medical-centers");
        this.route("pathogens");
        this.route('loading-filter', {
          path: "/filter/loading"
        });
        this.route("publications", function () {
          this.route("index", {
            path: "/"
          });
          this.route("show", {
            path: "/:id"
          }, function () {
            this.route("index", {
              path: "/"
            });
          });
        });
        this.route("programs", {
          path: "/programs/:program_slug"
        });
        this.route("quality_control", {
          path: "/qc"
        }, function () {
          this.route("index", {
            path: "/"
          });
          this.route("runs", function () {
            this.route("index", {
              path: "/"
            });
            this.route("show", {
              path: ":run_number"
            });
          });
        });
        this.route("no-results");
        this.route("results", {
          path: "/filter/:filter_id"
        }, function () {
          this.route("index", {
            path: "/"
          });
          this.route("overview");
          this.route("prevalence");
          this.route("tables", function () {
            this.route("mic-distributions", function () {
              this.route("frequency");
              this.route("cumulative-percentage");
              this.route("both");
            });
            this.route("scattergram");
          });
          this.route('charts', function () {
            this.route('mic-distribution');
            this.route('finlandogram');
          });
        });
        this.route('quality-control', function () {
          this.route('runs', function () {
            this.route('show');
          });
        });
        this.route('isolates', function () {
          this.route("index", {
            path: "/"
          });
          this.route("show", {
            path: "/:accession_number"
          });
        });
        this.route('locked', {
          path: "/locked"
        });
      });
      this.route('loading');
    });
    this.route("programs", function () {
      this.route("show", {
        path: "/:program_name"
      });
    });
    this.route("loading");
    this.route("site-management", function () {
      this.route('loading');
      this.route('canisters', function () {
        this.route('new');
        this.route('index', {
          path: "/"
        });
        this.route('edit');
      });
      this.route('objectives', function () {
        this.route('new');
        this.route('index', {
          path: "/"
        });
      });
      this.route('compliance-summary', function () {
        this.route('by-site', {
          path: "/"
        }, function () {
          this.route('confirm-send-report');
        });
        this.route('global');
      });
      this.route('site-contacts', function () {
        this.route('index', {
          path: "/"
        });
      });
      this.route('sites', function () {
        this.route('new');
        this.route('index', {
          path: "/"
        });
        this.route('show', {
          path: "/:id"
        }, function () {
          this.route('contacts', function () {
            this.route('edit', {
              path: '/:contact_id/edit'
            });
            this.route('new');
          });
          this.route('addresses', function () {
            this.route('edit', {
              path: '/:address_id/edit'
            });
            this.route('new');
          });
          this.route('objectives', function () {
            this.route('edit', {
              path: '/:objective_id/edit'
            });
            this.route('new');
          });
          this.route('recruitments', function () {
            this.route('edit', {
              path: '/:recruitment_id/edit'
            });
            this.route('new');
          });
          this.route('shipments', function () {
            this.route('edit', {
              path: '/:shipment_id/edit'
            });
            this.route('new');
          });
        });
        this.route('edit', {
          path: "/:id/edit"
        });
      });
      this.route('shipments', function () {
        this.route('index', {
          path: "/"
        });
        this.route('edit', {
          path: "/:id/edit"
        });
        this.route('new');
      });
    });
    this.route("admin", function () {
      this.route("index", {
        path: "/dashboard"
      });
      this.route('users', function () {
        this.route('permissions');
        this.route("index", {
          path: "/"
        });
        this.route("show", {
          path: "/:user_id"
        });
        this.route('loading');
        this.route("add");
        this.route("edit", {
          path: "/:user_id/edit"
        });
      });
      this.route("statistics");
      this.route('loading');
      this.route('reports');
      this.route('sentry', function () {
        this.route('index', {
          path: '/'
        });
        this.route('containers', function () {
          this.route("index", {
            path: "/"
          });
          this.route('show', {
            path: "/:id"
          });
          this.route('continent');
          this.route('loading');
        });
      });
    });
    this.route("manage", function () {
      this.route("index", {
        path: "/"
      });
      this.route("users", function () {
        this.route("index", {
          path: "/"
        });
        this.route("invite");
        this.route("edit", {
          path: "/:user_id"
        }, function () {
          this.route("settings");
        });
      });
      this.route("projects", function () {
        this.route("index", {
          path: "/"
        });
        this.route("edit", {
          path: "/:project_id"
        }, function () {
          this.route("settings");
        });
      });
      this.route("organizations", function () {
        this.route("index", {
          path: "/"
        });
        this.route("edit", {
          path: "/:organization_id"
        }, function () {
          this.route("settings");
        });
      });
    });
    this.route('surveillance.show.results.no-results');
    this.route('forgot');
    this.route('recover');
    this.route('gatekeeper');
  });
  var _default = Router;
  _exports.default = _default;
});