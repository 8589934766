define("mvp/pods/components/d3-charts/organism-burst/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals d3*/
  var _default = Ember.Component.extend({
    currentInfectionType: null,
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$(window).on('resize', this._handleResize.bind(this));
      Ember.set(this, "margin", {
        top: 200,
        right: 200,
        bottom: 200,
        left: 200
      });
    },
    genera: Ember.computed('data.length', function () {
      var data = this.data;
      var temp = [];
      data.forEach(function (i) {
        if (!temp.includes(i.genus)) {
          temp.addObject(i.genus);
        }
      });
      return temp;
    }),
    organisms: Ember.computed('data.length', function () {
      var data = this.data;
      var temp = [];
      data.forEach(function (i) {
        if (!temp.includes(i.organism)) {
          temp.addObject(i.organism);
        }
      });
      return temp;
    }),
    infection_types: Ember.computed('data.length', function () {
      var data = this.data;
      var temp = [];
      data.forEach(function (i) {
        if (i.organism === null && !temp.includes(i.infection_type)) {
          temp.addObject(i.infection_type);
        }
      });
      return temp;
    }),
    maxCount: Ember.computed('data.length', function () {
      var max = 0;
      var data = this.data;
      data.forEach(function (i) {
        if (i.organism && i.count > max) {
          max = i.count;
        }
      });
      return max;
    }),
    totalCount: Ember.computed('data.length', function () {
      var total = 0;
      var data = this.data;
      data.filter(function (i) {
        return i.organism !== null && i.infection_type === null;
      }).forEach(function (i) {
        total = total + i.count;
      });
      return total;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._requestData();
    },
    _handleResize: function _handleResize() {
      var _this = this;

      Ember.run.debounce(this, function () {
        Ember.$("#".concat(_this.elementId, " svg")).html("");
        Ember.run.scheduleOnce('afterRender', _this, _this._renderChart);
      }, 100);
    },
    _requestData: function _requestData() {
      var ajax = this.ajax;
      var url = '/api/organisms/counts';
      ajax.request(url).then(this._handleResponse.bind(this)).catch();
    },
    _handleResponse: function _handleResponse(payload) {
      Ember.set(this, 'data', payload);
      Ember.run.scheduleOnce('afterRender', this, this._renderChart);
    },
    _height: function _height() {
      return this._width();
    },
    _width: function _width() {
      var _this$margin = this.margin,
          left = _this$margin.left,
          right = _this$margin.right;
      return Ember.$("#".concat(this.elementId)).width() - left - right;
    },
    _setSize: function _setSize() {
      var _this$margin2 = this.margin,
          top = _this$margin2.top,
          right = _this$margin2.right,
          bottom = _this$margin2.bottom,
          left = _this$margin2.left;
      Ember.$("#".concat(this.elementId, " svg")).height(this._width() + top + bottom);
      Ember.$("#".concat(this.elementId, " svg")).width(this._width() + left + right);
    },
    _renderChart: function _renderChart() {
      this._setSize();

      this._renderOrganismOuterBurst();

      this._renderInfectionTypePie();

      this._renderTotalCount();
    },
    _renderOrganismOuterBurst: function _renderOrganismOuterBurst() {
      var _this2 = this;

      var svg = d3.select("#".concat(this.elementId, " svg"));
      var margin = this.margin;

      var height = this._width();

      var width = this._height();

      svg.select('.organism-burst').remove();
      var g = svg.append("g").attr("class", "organism-burst").attr("transform", "translate(".concat(width / 2 + margin.left, ",").concat(height / 2 + margin.top, ")"));
      var innerArcRadius = width * 0.32;
      var outerArcRadius = this._width() / 2; // By default we rollup to total infection type count

      var organism_data = this.data.filter(function (i) {
        return i.organism !== null && i.infection_type === _this2.currentInfectionType;
      }); // Find the total number of organisms so we can show pct

      var totalOrganismsCount = 0;
      var max = 0;
      organism_data.forEach(function (i) {
        totalOrganismsCount = totalOrganismsCount + i.count;

        if (i.count > max) {
          max = i.count;
        }
      });
      organism_data = organism_data.filter(function (i) {
        return i.count / totalOrganismsCount > 0.005;
      }); // Calculate the scales and the angle widths

      var angleWidth = (Math.PI * 2 - 0.3) / organism_data.length;
      var countScale = d3.scaleLinear().rangeRound([innerArcRadius, outerArcRadius]).domain([0.1, max]);
      Ember.set(this, 'originalOrganismScale', countScale);
      var colorScale = d3.scaleOrdinal().domain(this.genera).range(['#5b8cff', '#d0da41', '#324986', '#85c1ec', '#9fb36d', '#1482be']); // Append the count scale

      g.append("g").attr("class", "axis outer").attr("transform", "rotate(-90)").call(d3.axisTop(countScale).tickSizeOuter(0).ticks(3, ",.1r")); // Build the datum from the organism data

      g.selectAll(".organism").data(organism_data).enter().append("g").attr("class", "organism"); // Iterate through each organism grouping

      g.selectAll(".organism").each(function (d, i) {
        var g = d3.select(this);
        var angle = {
          start: angleWidth * i + 0.10,
          end: angleWidth * (i + 1) + 0.10
        };
        var arc = d3.arc().innerRadius(innerArcRadius).outerRadius(countScale(d.count)).startAngle(angle.start).endAngle(angle.end);
        g.append("path").attr("class", "organism-arc").attr("d", arc).attr("fill", colorScale(d.genus));
        var arcOutline = d3.arc().innerRadius(innerArcRadius).outerRadius(outerArcRadius).startAngle(angle.start).endAngle(angle.end);
        g.append("path").attr("class", "my-arc").attr("d", arcOutline).attr("fill", "transparent").attr("stroke", "rgba(255,255,255,.5)").attr("stroke-width", 0.1);
        var centroid_text = (angle.end - angle.start) / 2 + angle.start;
        centroid_text = centroid_text * (180 / Math.PI) - 90;
        var flip = false;

        if (centroid_text > 90) {
          flip = true;
        }

        g.append("text").attr("class", "info-text").attr("x", outerArcRadius + 10).attr("transform", function () {
          if (flip) {
            return "rotate(".concat(centroid_text - 180, "),translate(-").concat(outerArcRadius * 2 + 20, ", 0)");
          } else {
            return "rotate(" + centroid_text + ")";
          }
        }).attr("text-anchor", function () {
          if (flip) {
            return 'end';
          } else {
            return 'start';
          }
        }).attr("dy", ".6em").attr("font-size", function () {
          if (d.organism.length > 32) {
            return "10px";
          } else if (d.organism.length > 26) {
            return "12px";
          } else if (d.organism.length > 20) {
            return "14px";
          } else {
            return "16px";
          }
        }).text(d.organism); // let pct = ((d.count / totalOrganismsCount) * 100).toFixed(2);

        var numberWithCommas = function numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        };

        g.append("text").attr("class", "organism pct-text").attr("x", outerArcRadius + 10).attr("transform", function () {
          if (flip) {
            return "rotate(".concat(centroid_text - 180, "),translate(-").concat(outerArcRadius * 2 + 20, ", 0)");
          } else {
            return "rotate(" + centroid_text + ")";
          }
        }).attr("text-anchor", function () {
          if (flip) {
            return 'end';
          } else {
            return 'start';
          }
        }).attr("dy", "-.5em").attr("text-size", "16px").text("".concat(numberWithCommas(d.count)));
      });
    },
    _renderInfectionTypePie: function _renderInfectionTypePie() {
      var svg = d3.select("#".concat(this.elementId, " svg"));
      var defs = svg.append("defs").attr("class", "textPaths");
      var margin = this.margin;
      var self = this;

      var height = this._width();

      var width = this._height();

      var g = svg.append("g").attr("class", "infection-type-pie").attr("transform", "translate(".concat(width / 2 + margin.left, ",").concat(height / 2 + margin.top, ")"));
      var innerArcRadius = width * 0.15;
      var outerArcRadius = width * 0.25;
      var infection_type_data = this.data.filter(function (i) {
        return i.genus === null && i.infection_type !== null;
      }); // Find the total number of organisms so we can show pct

      var totalCount = 0;
      infection_type_data.forEach(function (i) {
        totalCount = totalCount + i.count;
      }); // Find the total number of organisms so we can show pct

      var maxPct = 0;
      infection_type_data.forEach(function (i) {
        if (i.count / totalCount > maxPct) {
          maxPct = i.count / totalCount;
        }
      });
      var angleWidth = (Math.PI * 2 - 0.8) / infection_type_data.length;
      var countScale = d3.scaleLinear().rangeRound([innerArcRadius, outerArcRadius]).domain([0, maxPct]);
      var colorScale = d3.scaleOrdinal().domain(this.infection_types).range(['#5b8cff', '#d0da41', '#324986', '#85c1ec', '#9fb36d', '#1482be']); // Build the datum from the organism data

      g.selectAll(".infection-type").data(infection_type_data).enter().append("g").attr("class", "infection-type"); // Iterate through each organism grouping

      g.selectAll(".infection-type").each(function (d, i) {
        var g = d3.select(this);
        var angle = {
          start: angleWidth * i + 0.3,
          end: angleWidth * (i + 1) + 0.3
        };
        var pct = d.count / totalCount;
        var arc = d3.arc().innerRadius(innerArcRadius).outerRadius(countScale(pct)).startAngle(angle.start).endAngle(angle.end);
        g.append("path").attr("class", "inner-infection-arc").attr("d", arc).attr("fill", colorScale(d.infection_type));
        var arcOutline = d3.arc().innerRadius(innerArcRadius).outerRadius(outerArcRadius).startAngle(angle.start).endAngle(angle.end);
        defs.append("path").attr("id", "infectionTypeOutlineArc".concat(i)).attr("d", arcOutline);
        g.append("path").attr("class", "my-arc") // .attr("id", `infectionTypeOutlineArc${i}`)
        .attr("d", arcOutline).attr("fill", "transparent").attr("stroke", "rgba(255,255,255,.5)").attr("cursor", "pointer").attr("stroke-width", 0.1).on("mouseenter", function () {
          d3.selectAll(".allShowing").each(function () {
            d3.select(this).attr("opacity", "0.2");
          });
          d3.selectAll(".inner-infection-arc").each(function (e) {
            if (e.infection_type === d.infection_type) {
              d3.select(this).attr("opacity", 1);
            } else {
              d3.select(this).attr("opacity", 0.2);
            }
          });

          self._showOrganismsForInfectionType(d.infection_type);
        });

        var numberWithCommas = function numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        };

        var location = window.location;

        if (d.infection_type === "Community-acquired respiratory tract infection") {
          g.append("text").attr("class", "infectionTypeText").attr("x", 5) //Move the text from the start angle of the arc
          .attr("dy", -30) //Move the text down
          .attr("font-size", 12).attr("fill", "rgba(255,255,255,.8)").append("textPath").attr("class", "textpath".concat(i)).attr("xlink:xlink:href", "".concat(location, "#infectionTypeOutlineArc").concat(i)).text(numberWithCommas(d.count));
          g.append("text").attr("class", "infectionTypeText").attr("x", 5) //Move the text from the start angle of the arc
          .attr("dy", -20) //Move the text down
          .attr("font-size", 10).attr("fill", "#888388").append("textPath").attr("xlink:xlink:href", "".concat(location, "#infectionTypeOutlineArc").concat(i)).text("Community-acquired");
          g.append("text").attr("class", "infectionTypeText").attr("x", 5) //Move the text from the start angle of the arc
          .attr("dy", -10) //Move the text down
          .attr("font-size", 10).attr("fill", "#888388").append("textPath").attr("xlink:xlink:href", "".concat(location, "#infectionTypeOutlineArc").concat(i)).text("respiratory tract infection");
        } else if (d.infection_type === "Pneumonia in hospitalized patients") {
          g.append("text").attr("class", "infectionTypeText").attr("x", 5) //Move the text from the start angle of the arc
          .attr("dy", -30) //Move the text down
          .attr("font-size", 12).attr("fill", "rgba(255,255,255,.8)").append("textPath").attr("xlink:xlink:href", "".concat(location, "#infectionTypeOutlineArc").concat(i)).text(numberWithCommas(d.count));
          g.append("text").attr("class", "infectionTypeText").attr("x", 5) //Move the text from the start angle of the arc
          .attr("dy", -20) //Move the text down
          .attr("font-size", 10).attr("fill", "#888388").append("textPath").attr("xlink:xlink:href", "".concat(location, "#infectionTypeOutlineArc").concat(i)).text("Pneumonia in");
          g.append("text").attr("class", "infectionTypeText").attr("x", 5) //Move the text from the start angle of the arc
          .attr("dy", -10) //Move the text down
          .attr("font-size", 10).attr("fill", "#888388").append("textPath").attr("xlink:xlink:href", "".concat(location, "#infectionTypeOutlineArc").concat(i)).text("hospitalized patients");
        } else {
          g.append("text").attr("class", "infectionTypeText").attr("x", 5) //Move the text from the start angle of the arc
          .attr("dy", -20) //Move the text down
          .attr("font-size", 12).attr("fill", "rgba(255,255,255,.8)").append("textPath").attr("xlink:xlink:href", "".concat(location, "#infectionTypeOutlineArc").concat(i)).text(numberWithCommas(d.count));
          g.append("text").attr("class", "infectionTypeText").attr("x", 5) //Move the text from the start angle of the arc
          .attr("dy", -10) //Move the text down
          .attr("font-size", 10).attr("fill", "#888388").append("textPath").attr("xlink:xlink:href", "".concat(location, "#infectionTypeOutlineArc").concat(i)).text(function () {
            return d.infection_type;
          });
        }
      }); // Append the count scale

      g.append("g").attr("class", "axis").attr("transform", "rotate(-90)").call(d3.axisTop(countScale).tickSizeOuter(0).ticks(2, ",.1p"));
    },
    _showOrganismsForInfectionType: function _showOrganismsForInfectionType(infection_type) {
      Ember.set(this, 'currentInfectionType', infection_type);

      this._renderOrganismOuterBurst();
    },
    _renderTotalCount: function _renderTotalCount() {
      var svg = d3.select("#".concat(this.elementId, " svg"));
      var margin = this.margin;
      var self = this;

      var height = this._width();

      var width = this._height();

      var g = svg.append("g").attr("class", "totalCount").attr("transform", "translate(".concat(width / 2 + margin.left, ",").concat(height / 2 + margin.top, ")"));

      var numberWithCommas = function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };

      var totalCount = this.data.filter(function (i) {
        return i.genus === null;
      }).reduce(function (accu, i) {
        return accu + i.count;
      }, 0);
      g.append("circle").attr("class", "allShowing").attr("cx", 0).attr("cy", 0).attr("r", this._width() * .12).attr("opacity", .65).style("fill", "#9db5de").style("cursor", "pointer").on("mouseenter", function () {
        d3.select(this).attr("opacity", "0.65");
        d3.selectAll(".inner-infection-arc").each(function () {
          d3.select(this).attr("opacity", 0.2);
        });

        self._showOrganismsForInfectionType(null);
      });
      g.append("text").attr("y", -10).style("cursor", "pointer").style("fill", "rgba(255,255,255,.9)").style("text-anchor", "middle").style("font-size", "24px").text(numberWithCommas(totalCount));
      g.append("text").attr("y", 10).style("cursor", "pointer").style("fill", "rgba(255,255,255,.9)").style("text-anchor", "middle").style("font-size", "10px").text("isolates collected");
      g.append("text").attr("y", 21).style("cursor", "pointer").style("fill", "rgba(255,255,255,.9)").style("text-anchor", "middle").style("font-size", "10px").text("via prevalence in");
      g.append("text").attr("y", 32).style("cursor", "pointer").style("fill", "rgba(255,255,255,.9)").style("text-anchor", "middle").style("font-size", "10px").text("the SENTRY program");
      g.append("text").attr("y", 43).style("cursor", "pointer").style("fill", "rgba(255,255,255,.9)").style("text-anchor", "middle").style("font-size", "10px").text("from 2013 to 2017");
    }
  });

  _exports.default = _default;
});