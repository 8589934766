define("mvp/pods/surveillance/sentry/index/controller", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service("current-user"),
    year: Ember.computed('model.years', function () {
      // const years = get(this, 'model.years');
      // if (years.length > 0 ) {
      //   return get(this, 'model.years')[0];
      // } else {
      //   return 2018;
      // }
      return _environment.default.APP.SETTINGS.RECRUITMENT_YEAR;
    }),
    total_target_count: 0,
    demoVersion: Ember.computed(function () {
      return INSTANCE === 'mvpdemo';
    }),
    objectives: Ember.computed("model", "year", function () {
      var year = this.year;
      var objectives_for_years = Ember.get(this, "model.objective_data_for_year");
      var result = null;

      if (objectives_for_years !== null) {
        result = objectives_for_years[year];
      }

      return result;
    }),
    setTotalTargetCount: Ember.computed("currentUser.organization.site_objectives.count", function () {
      var total = 0;
      this.site_objectives.then(this._sumAllSiteObjectiveTargetCounts.bind(this, total));
      return total;
    }),
    actions: {
      'recruit': function recruit() {
        this.transitionToRoute("surveillance.sentry.recruitment");
      },
      'change-year': function changeYear(year) {
        Ember.set(this, 'year', year);
      }
    },
    _sumAllSiteObjectiveTargetCounts: function _sumAllSiteObjectiveTargetCounts(total, site_objectives) {
      site_objectives.forEach(function (site_objective) {
        total += Ember.get(site_objective, "target_count");
      });
    }
  });

  _exports.default = _default;
});