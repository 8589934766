define("mvp/pods/site-management/objectives/edit/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/objectives/index/objectives", "mvp/pods/site-management/objectives/show"], function (_exports, _emberApolloClient, _objectives, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var variables = {
        id: params.id
      };
      var objective = this.apollo.watchQuery({
        query: _show.default,
        variables: variables
      }, "objective");
      var objectives = this.apollo.watchQuery({
        query: _objectives.default
      }, "objectives");
      return Ember.RSVP.hash({
        params: params,
        objective: objective,
        objectives: objectives,
        changeset: {}
      });
    }
  });

  _exports.default = _default;
});