define("mvp/models/project-publication", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    files: _emberData.default.attr("array"),
    updated_at: _emberData.default.attr("date"),
    description: _emberData.default.attr("string"),
    download_url: _emberData.default.attr("string"),
    view_url: _emberData.default.attr("string"),
    project: _emberData.default.belongsTo("project", {
      async: true
    }),
    filters: _emberData.default.hasMany("filter", {
      async: true,
      inverse: 'project_publicaiton'
    })
  });

  _exports.default = _default;
});