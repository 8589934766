define("mvp/pods/components/ui-surveillance/loading/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // steps: [
    //   "Counting dilution/edge frequencies",
    //   "Identifying unique organisms",
    //   "Establishing drug reaction counts",
    //   "Adding breakpoint footnotes",
    //   "Normalizing frequency distributions",
    //   "Verifying drug reaction interpretations",
    //   "Generating frequency distributions",
    //   "Assessing MIC profile",
    //   "Filtering isolates based on selected criteria",
    //   "Grouping isolates based on phenotypic properties",
    //   "Analyzing frequency distributions",
    // ],
    stepTickSpeed: 4000,
    stepIndex: undefined,
    stepToShow: Ember.computed('stepIndex', function () {
      if (this.stepIndex !== undefined) {
        return this.steps.objectAt(this.stepIndex);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.steps = ["In 2020, JMI published 31 articles in peer-reviewed journals", "JMI presented 44 posters at scientific meetings in 2020", "JMI provides support for antimicrobial surveillance, drug development, clinical trials, molecular studies, and investigations of the microbiome", "Over 40,000 global clinical isolates are tested annually as part of the SENTRY program", "JMI analyzed over +4,000 isolate genomes in 2020 using next-generation sequencing", "JMI curates a proprietary library of more than 4,300 resistance genes/alleles", "Isolate species are confirmed using MALDI-TOF MS", "SENTRY MVP contains data on >1 million isolates collected from 1997 to 2021", "More than 200 hospitals in more than 45 countries have submitted isolates to the SENTRY Program"];
    },
    didInsertElement: function didInsertElement() {
      if (!this.skipScroll) {
        Ember.$('body').scrollTop(0);
      }

      var _self = this;

      Ember.run.later(function () {
        _self.newRandomStep();
      }, this.stepTickSpeed);
      var len = Ember.get(this, 'steps.length');
      var randomStep = Math.floor(Math.random() * len);
      Ember.set(this, "stepIndex", randomStep);
    },
    newRandomStep: function newRandomStep() {
      var len = Ember.get(this, 'steps.length');
      var oldStep = this.stepIndex;
      var newStep = Math.floor(Math.random() * len);

      while (newStep === oldStep) {
        newStep = Math.floor(Math.random() * len);
      }

      Ember.$("#" + this.elementId + " h2 .sub.header").fadeOut(1000);

      var _self = this;

      Ember.run.later(function () {
        if (!_self.isDestroyed) {
          Ember.set(_self, "stepIndex", newStep);
          Ember.$("#" + _self.elementId + " h2 .sub.header").fadeIn(500);
        }
      }, 1000);
      Ember.run.later(function () {
        if (!_self.isDestroyed) {
          _self.newRandomStep();
        }
      }, Ember.get(_self, 'stepTickSpeed'));
    }
  });

  _exports.default = _default;
});