define("mvp/reducers/index", ["exports", "redux", "mvp/reducers/data", "mvp/reducers/router", "mvp/reducers/session", "mvp/reducers/site-management"], function (_exports, _redux, _data, _router, _session, _siteManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _redux.combineReducers)({
    data: _data.default,
    router: _router.default,
    session: _session.default,
    siteManagement: _siteManagement.default
  });

  _exports.default = _default;
});