define("mvp/models/run", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    in_count: _emberData.default.attr("number"),
    low_count: _emberData.default.attr("number"),
    high_count: _emberData.default.attr("number"),
    ni_count: _emberData.default.attr("number"),
    nil_count: _emberData.default.attr("number"),
    completed_date: _emberData.default.attr("date"),
    panels: _emberData.default.attr("array"),
    project: _emberData.default.belongsTo("project")
  });

  _exports.default = _default;
});