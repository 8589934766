define("mvp/templates/components/page-numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oWpSjSRL",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ui right floated pagination menu\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"canStepBackward\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"icon item\"],[11,\"href\",\"#\"],[9],[7,\"i\"],[11,\"class\",\"left chevron icon\"],[9],[10],[3,\"action\",[[22,0,[]],\"incrementPage\",-1]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[23,[\"pageItems\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"dots\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[9],[0,\"...\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[22,1,[\"current\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"item active\"],[9],[1,[22,1,[\"page\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"item\"],[11,\"href\",\"#\"],[9],[1,[22,1,[\"page\"]],false],[3,\"action\",[[22,0,[]],\"pageClicked\",[22,1,[\"page\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[4,\"if\",[[23,[\"canStepForward\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"icon item\"],[11,\"href\",\"#\"],[9],[7,\"i\"],[11,\"class\",\"right chevron icon\"],[9],[10],[3,\"action\",[[22,0,[]],\"incrementPage\",1]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[2,\" {{#if canStepForward}}\\n  <a href=\\\"#\\\" {{action \\\"incrementPage\\\" 1}}>&raquo;</a>\\n{{/if}} \"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/templates/components/page-numbers.hbs"
    }
  });

  _exports.default = _default;
});