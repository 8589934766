define("mvp/pods/components/ui-surveillance/adhoc/organisms/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    //////////////
    // Services //
    //////////////
    // Provide access to the current signed in user
    currentUser: Ember.inject.service("current-user"),
    // Provide a pubsub mechanism to listen to and invoke events
    eventBus: Ember.inject.service("event-bus"),
    media: Ember.inject.service('media'),
    authorization: Ember.inject.service(),
    ////////////////
    // Properties //
    ////////////////
    // Contains the current filter as built in the ui-surveillance/adhoc component
    filter: undefined,
    classNameBindings: ['media.classNames'],
    // Stores a referenence to the genus group dropdown component, the component
    // will register to this values through the `register-as` attribute.
    genusGroupDropdown: undefined,
    genusGroupSelectionRaw: undefined,
    // Store the state of the currently selected genus criteria.
    organismDropdown: undefined,
    collectionNo: undefined,
    initilizingCollectionNumber: false,
    /////////////////
    // Genus Group //
    /////////////////
    initializeGenusGroupDropdown: function () {
      var genus;
      var group;

      if (Ember.get(this, "filter.criteria.genus.include") !== undefined) {
        genus = Ember.get(this, "filter.criteria.genus.include");
      } else if (Ember.get(this, "filter.criteria.genus.exclude") !== undefined) {
        genus = Ember.get(this, "filter.criteria.genus.exclude");
      }

      if (Ember.get(this, "filter.criteria.group.include") !== undefined) {
        group = Ember.get(this, "filter.criteria.group.include");
      } else if (Ember.get(this, "filter.criteria.group.exclude") !== undefined) {
        group = Ember.get(this, "filter.criteria.group.exclude");
      }

      var selected = [];

      if (genus !== undefined) {
        genus.forEach(function (option) {
          selected.addObject(encodeURIComponent(JSON.stringify({
            "genus": option
          })));
        });
      }

      if (group !== undefined) {
        group.forEach(function (option) {
          selected.addObject(encodeURIComponent(JSON.stringify({
            "group": option
          })));
        });
      }

      if (selected.length > 0) {
        this.genusGroupDropdown.send("set-selected", selected);
      }
    }.observes("genusGroupDropdown"),
    observeGenusGroup: function () {
      var values = Ember.get(this, "genusGroup.values");
      var inclusion = Ember.get(this, "genusGroup.inclusion");
      var genusValues = [];
      var groupValues = [];
      values.forEach(function (obj) {
        if (obj.genus !== undefined) {
          genusValues.pushObject(obj.genus);
        } else if (obj.group !== undefined) {
          groupValues.pushObject(obj.group);
        }
      });
      Ember.set(this, "genus.inclusion", inclusion);
      Ember.set(this, "genus.values", genusValues);
      this.notifyPropertyChange("genus");
      Ember.set(this, "group.inclusion", inclusion);
      Ember.set(this, "group.values", groupValues);
      this.notifyPropertyChange("group");
    }.observes("genusGroup"),
    // Calculates the url to use when populating the genus-group dropdown
    genusGroupUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project");
      return "api/adhoc/genus-groups" + "?project_name=" + encodeURIComponent(project_name);
    }),
    // Determines if the genus-group dropdown is enabled
    genusGroupEnabled: Ember.computed("filter.criteria", function () {
      // Genus group should be enabled as long as a project is selected
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    observeCriteriaForGenusGroupReset: function () {
      var filter = this.filter;
      var genusGroupValues = Ember.get(this, "genusGroup.values");
      var genusGroupRawValues = Ember.get(this, "genusGroup.raw_values");
      var excludeGenus = filter === undefined || Ember.get(filter, "criteria.genus") === undefined;
      var excludeGroup = filter === undefined || Ember.get(filter, "criteria.group") === undefined;

      var _self = this;

      Ember.get(this, "genus.values").forEach(function (item) {
        if (excludeGenus) {
          genusGroupValues.forEach(function (i, idx) {
            if (i.genus === item) {
              Ember.$("#" + Ember.get(_self, "genusGroupDropdown").elementId + " .ui.dropdown").dropdown("remove selected", genusGroupRawValues.objectAt(idx));
            }
          });
        }
      });
      Ember.get(this, "group.values").forEach(function (item) {
        if (excludeGroup) {
          genusGroupValues.forEach(function (i, idx) {
            if (i.group === item) {
              Ember.$("#" + Ember.get(_self, "genusGroupDropdown").elementId + " .ui.dropdown").dropdown("remove selected", genusGroupRawValues.objectAt(idx));
            }
          });
        }
      });
    }.observes("filter.criteria"),
    ///////////
    // Genus //
    ///////////
    // Observe for changes to the currenlty selected genus
    observeGenus: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var genus;

      if (Ember.get(this, "genus.values.length") > 0) {
        genus = {};
        genus[Ember.get(this, "genus.inclusion").toLowerCase()] = Ember.get(this, "genus.values");
      } // Send the action to parent component


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("genus", genus);
      });
    }.observes("genus"),
    ///////////
    // Group //
    ///////////
    // Observe for changes to the currenlty selected group
    observeGroup: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var group;

      if (Ember.get(this, "group.values.length") > 0) {
        group = {};
        group[Ember.get(this, "group.inclusion").toLowerCase()] = Ember.get(this, "group.values");
      } // Send the action to parent component


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("group", group);
      });
    }.observes("group"),
    //////////////
    // Organism //
    //////////////
    initializeOrganismDropdown: function () {
      var organisms;

      if (Ember.get(this, "filter.criteria.organism.include") !== undefined) {
        organisms = Ember.get(this, "filter.criteria.organism.include");
      } else if (Ember.get(this, "filter.criteria.organism.exclude") !== undefined) {
        organisms = Ember.get(this, "filter.criteria.organism.exclude");
      }

      var selected = [];

      if (organisms !== undefined) {
        organisms.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.organismDropdown.send("set-selected", selected);
      }
    }.observes("organismDropdown"),
    // Observe changes to organism
    observeOrganisms: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var organism;

      if (Ember.get(this, "organism.values.length") > 0) {
        organism = {};
        organism[Ember.get(this, "organism.inclusion").toLowerCase()] = Ember.get(this, "organism.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("organism", organism);
      });
    }.observes("organism"),
    observeCriteriaForOrganismReset: function () {
      if (Ember.get(this, "filter.criteria.organism") === undefined && Ember.get(this, "organism.values.length") > 0) {
        this.resetOrganism();
      }
    }.observes("filter.criteria"),
    organismEnabled: Ember.computed("filter.criteria", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      // aways show if development or mvpinternal
      if (INSTANCE == 'development' || INSTANCE == 'mvpinternal') {
        return Ember.get(this, "filter.criteria.project") !== undefined;
      } else {
        return Ember.get(this, "filter.criteria.project") !== undefined && (Ember.get(this, "filter.criteria.genus") !== undefined || Ember.get(this, "filter.criteria.group") !== undefined);
      }
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    organismsUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project");
      var genus = this.genus;
      var group = this.group; // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/organisms" + "?project_name=" + encodeURIComponent(project_name) + "&genus=" + encodeURIComponent(JSON.stringify(genus)) + "&group=" + encodeURIComponent(JSON.stringify(group));
    }),
    ///////////////////////
    // Collection Number //
    ///////////////////////
    observeCollectionNo: function () {
      var collection_number = this.collectionNo; // console.log("Collection Number set:" + collection_number);

      if (!this.initilizingCollectionNumber) {
        var clean_collection_number = []; // Check if the string has a comma

        if (collection_number.indexOf(",\n") !== -1) {
          clean_collection_number = collection_number.split(",\n");
        } else if (collection_number.indexOf(",") !== -1) {
          // Remove space and split on comma
          clean_collection_number = collection_number.replace(" ", "").split(",");
        } else {
          // Split on newline character
          clean_collection_number = collection_number.split("\n");
        }

        if (collection_number.length > 0 && clean_collection_number.length > 0) {
          Ember.run.scheduleOnce('actions', this, function () {
            this["update-filter-criteria"]("accession_number", {
              include: clean_collection_number
            });
          });
        } else {
          Ember.run.scheduleOnce('actions', this, function () {
            this["update-filter-criteria"]("accession_number", undefined);
          });
        }
      }
    }.observes("collectionNo"),
    collectionNumberEnabled: Ember.computed("filter.criteria", function () {
      // Collection number field should be enabled when a project is chosen.
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setupInit();
      this.setupUI();
      this.genusGroup = {
        inclusion: "Include",
        values: [],
        raw_values: []
      };
      this.genus = {
        inclusion: "Include",
        values: []
      };
      this.group = {
        inclusion: "Include",
        values: []
      };
      this.organism = {
        inclusion: "Include",
        values: []
      };
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.cleanup();
    },
    /////////////
    // Actions //
    /////////////
    actions: {
      "update-inclusion-genus-group": function updateInclusionGenusGroup(inclusion) {
        Ember.set(this, "genusGroup.inclusion", inclusion);
        this.notifyPropertyChange("genusGroup");
      },
      "update-selected-genus-group": function updateSelectedGenusGroup(selection) {
        var values = [];
        var raw_values = [];

        if (selection !== "") {
          selection.split(",").forEach(function (value) {
            raw_values.pushObject(value);
            values.pushObject(JSON.parse(decodeURIComponent(value)));
          });
        }

        Ember.set(this, "genusGroup.values", values);
        Ember.set(this, "genusGroup.raw_values", raw_values);
        this.notifyPropertyChange("genusGroup");
      },
      "update-inclusion-organism": function updateInclusionOrganism(inclusion) {
        Ember.set(this, "organism.inclusion", inclusion);
        this.notifyPropertyChange("organism");
      },
      // Handle logic when the slected organism has changed. The values will be a
      // CSV string representign the selected values.
      "update-selected-organism": function updateSelectedOrganism(values) {
        // Parse the CSV string to pull out the selected organisms.
        var organismValues;

        if (values !== "") {
          organismValues = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          organismValues = [];
        } // Update the slected organism values


        Ember.set(this, "organism.values", organismValues); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("organism");
      },
      // Reset the state of this component, usually called when the selected
      // project changes or the user clears the filter.
      "reset": function reset() {
        // Reset the filter criteria data.
        Ember.set(this, "genus", {
          inclusion: "Include",
          values: []
        });
        Ember.set(this, "group", {
          inclusion: "Include",
          values: []
        });
        Ember.set(this, "organism", {
          inclusion: "include",
          values: []
        }); // Clear the dropdowns

        if (this.genusGroupDropdown !== undefined) {
          this.genusGroupDropdown.send("reset");
        }

        if (this.organismDropdown !== undefined) {
          this.organismDropdown.send("reset");
        }
      }
    },
    ///////////////
    // Functions //
    ///////////////
    setupInit: function setupInit() {
      // Subscribe to the event bus
      this.eventBus.on('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.on('resetAdhocFilter', this, 'resetAdhocFilter'); // console.log(get(this, "filter.criteria"));

      if (Ember.get(this, "filter.criteria.accession_number.include") !== undefined) {
        var filter_accession_numbers = Ember.get(this, "filter.criteria.accession_number.include").join(",");
        var self = this;
        Ember.run.later(function () {
          Ember.set(self, "initilizingCollectionNumber", true);
          Ember.set(self, "collectionNo", filter_accession_numbers);
          Ember.set(self, "initilizingCollectionNumber", false);
        }, 50);
      }
    },
    // Set up the ui after this component has been rendered
    setupUI: function setupUI() {
      var _self = this; // Schedule to update the ui after render


      Ember.run.schedule('afterRender', this, function () {
        // Setup genus group inclusion checkbox
        Ember.$("#" + _self.elementId + " .checkbox.genus-group").checkbox({
          onChecked: function onChecked() {
            _self.send("update-inclusion-genus-group", "Exclude");
          },
          onUnchecked: function onUnchecked() {
            _self.send("update-inclusion-genus-group", "Include");
          }
        }); // Setup organisms inclusion checkbox

        Ember.$("#" + _self.elementId + " .checkbox.organism").checkbox({
          onChecked: function onChecked() {
            _self.send("update-inclusion-organism", "Exclude");
          },
          onUnchecked: function onUnchecked() {
            _self.send("update-inclusion-organism", "Include");
          }
        }); // More ui set up should be added here...
      });
    },
    reset: function reset() {
      this.resetGenusGroup();
      this.resetOrganism();
      this.resetCollectionNumber();
    },
    // Performs cleanup of the component after the element is destroyed
    cleanup: function cleanup() {
      // Destroy ties to the event bus
      this.eventBus.off('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.off('resetAdhocFilter', this, 'resetAdhocFilter');
    },
    // Notify observers within this component that the filter criteria has
    // changed. This will be invoked through the global event bus.
    filterCriteriaChanged: function filterCriteriaChanged(key) {
      this.notifyPropertyChange("filter.criteria"); // If the project, genus, or group has changed we need to reset
      // the organisms.

      if (key === "project" || key === "genus" || key === "group") {
        this.resetOrganism();
      }
    },
    // Perform necessary actions when adhoc filter is reset
    resetAdhocFilter: function resetAdhocFilter() {
      this.reset();
    },
    // Reset the currently selected organism
    resetGenusGroup: function resetGenusGroup() {
      // Reset the checkbox
      Ember.$(".genus-group.ui.checkbox").checkbox("set unchecked"); // Reset dropdown UI

      var genusGroupDropdown = this.genusGroupDropdown;
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (genusGroupDropdown !== undefined) {
          genusGroupDropdown.send("reset");
        }
      }); // Reset the actual values

      Ember.set(this, "genusGroup", {
        inclusion: "Include",
        values: [],
        raw_values: []
      });
    },
    // Reset the currently selected organism
    resetOrganism: function resetOrganism() {
      // Reset the checkbox
      Ember.$(".organism.ui.checkbox").checkbox("set unchecked"); // Reset the dropdown

      var organismDropdown = this.organismDropdown;
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (organismDropdown !== undefined) {
          organismDropdown.send("reset");
        }
      }); // Reset the actual values

      Ember.set(this, "organisms", {
        inclusion: "include",
        values: []
      });
    },
    resetCollectionNumber: function resetCollectionNumber() {
      Ember.set(this, "collectionNo", "");
    }
  });

  _exports.default = _default;
});