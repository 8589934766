define("mvp/pods/components/ui-site-management/form/select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedOption: null,
    actions: {
      setSelection: function setSelection(selected) {
        this.set('selectedOption', selected);
        this.set("changeset." + this.name, selected);
      }
    }
  });

  _exports.default = _default;
});