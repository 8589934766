define("mvp/pods/components/ui-admin/users/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wvrjvne3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"admin.users.index\"],[[\"class\"],[\"back-to\"]],{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"reply icon\"],[9],[10],[0,\"\\n  Back to all users\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"h1\"],[9],[0,\"Edit User\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"ui error message\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"Error\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[21,\"error\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"form\"],[12,\"class\",[28,[\"ui form \",[27,\"if\",[[23,[\"loading\"]],\"loading\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    Email:\\n    \"],[1,[23,[\"user\",\"email\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    User Metadata:\\n    \"],[1,[27,\"textarea\",null,[[\"value\",\"class\"],[[23,[\"user\",\"user_metadata_str\"]],\"user_metadata\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    Application Metadata:\\n    \"],[1,[27,\"textarea\",null,[[\"value\",\"class\"],[[23,[\"user\",\"app_metadata_str\"]],\"app_metadata\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"fluid ui primary button\"],[11,\"type\",\"submit\"],[9],[0,\"Submit\"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"updateUser\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-admin/users/edit/template.hbs"
    }
  });

  _exports.default = _default;
});