define("mvp/pods/components/ui-demo-data/index/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service("store"),
    demoNumber: "",
    disabled: false,
    actions: {
      closeMessage: function closeMessage() {
        Ember.set(this, "error", false);
      },
      demoSelected: function demoSelected() {
        if (parseInt(this.demoNumber) >= 20000) {
          this.demoSelected(this.demoNumber);
          return null;
        }

        if (!this.disabled) {
          Ember.set(this, "disabled", true);
          Ember.$("#" + this.elementId + " button").addClass("loading");
          var store = this.store;

          var _self = this;

          store.findRecord("demo", this.demoNumber).then(_self._demoSelectedSuccess.bind(_self)).catch(_self._demoSelectedException.bind(_self));
        }
      }
    },
    _demoSelectedSuccess: function _demoSelectedSuccess() {
      this.demoSelected(this.demoNumber);
    },
    _demoSelectedException: function _demoSelectedException() {
      Ember.set(this, "error", true);
      Ember.set(this, "demoNumber", undefined);
      Ember.$("#" + this.elementId + " button").removeClass("loading");
    }
  });

  _exports.default = _default;
});