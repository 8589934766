define("mvp/pods/surveillance/show/results/tables/scattergram/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['leftDrugs', 'bottomDrugs'],
    showResultsController: Ember.inject.controller('surveillance.show.results'),
    filter: Ember.computed.alias('showResultsController.model'),
    surveillanceShowController: Ember.inject.controller("surveillance.show"),
    project: Ember.computed.alias("surveillanceShowController.model"),
    isolateShowController: Ember.inject.controller("surveillance.show.isolates.show"),
    resultsController: Ember.inject.controller("surveillance.show.results"),
    observeFilterId: function () {
      Ember.set(this, 'leftDrugs', []);
      Ember.set(this, 'bottomDrugs', []);
    }.observes('filter.id'),
    init: function init() {
      this._super.apply(this, arguments);

      this.leftDrugs = [];
      this.bottomDrugs = [];
    },
    actions: {
      "view-isolate": function viewIsolate(isolate, isolates) {
        Ember.set(this.isolateShowController, "isolates", isolates);
        Ember.set(this.isolateShowController, "isolate", isolate);
        Ember.set(this.isolateShowController, "backRoute", "surveillance.show.results.tables.scattergram");
        Ember.set(this.isolateShowController, "backFilter", Ember.get(this, "resultsController.model"));
        this.transitionToRoute("surveillance.loading");
        this.replaceRoute("surveillance.show.isolates.show", Ember.get(this, "project.slug"), isolate);
      },
      updateLeftDrugs: function updateLeftDrugs(drugs) {
        Ember.set(this, 'leftDrugs', drugs);
      },
      updateBottomDrugs: function updateBottomDrugs(drugs) {
        Ember.set(this, 'bottomDrugs', drugs);
      }
    }
  });

  _exports.default = _default;
});