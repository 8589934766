define("mvp/pods/components/ui-program/report-stacked/chart/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;
  /* globals d3 */

  var _default = Ember.Component.extend({
    svgExport: Ember.inject.service('svg-export'),
    eventTracker: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var data = this._generateBarChartData(this);

      var drugs = this._getDrugsList(this);

      var stack = d3.stack();
      var max = 100;
      var viewBoxWidth = 300;
      var viewBoxHeight = 300;

      this._setUpResponsiveSVG(this, viewBoxWidth, viewBoxHeight);

      var svg = d3.select("#".concat(this.elementId, " svg")),
          margin = {
        top: 20,
        right: 20,
        bottom: 70,
        left: 60
      },
          width = viewBoxWidth - margin.left - margin.right,
          height = viewBoxHeight - margin.top - margin.bottom; // x axis scale

      var xScale = d3.scaleBand().domain(drugs).range([0, width]).padding(0.3); // y axis scale

      var yScale = d3.scaleLinear().domain([0, max]).range([height, 0]); // color scale for stacked bar values

      var zScale = d3.scaleOrdinal().domain(["S", "I", "R", "NS", "NI"]).range(["#3fa846", "#d1c62b", "#ad4432", "#322bb5", "#378bbf"]);

      this._drawBackground(svg, margin, width, height);

      var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")").classed("g_main", true);
      var tooltip = d3.select("body").append("div").style("position", "absolute").style("opacity", 0);

      this._drawXAxis(g, height, xScale, drugs);

      this._drawYAxis(g, height, margin, yScale);

      this._drawBarChart(this, g, stack, data, xScale, yScale, zScale, tooltip);

      this._drawLegend(this, g, height, margin, zScale, tooltip);

      this._drawDownloadButton(this, g, margin, width, viewBoxWidth, viewBoxHeight);
    },
    _generateBarChartData: function _generateBarChartData(self) {
      var raw_data = Ember.get(self, 'data');
      var raw_data_lookup = raw_data.reduce(function (acc, cv) {
        var key = cv[0];
        acc[key] = cv.slice(1);
        return acc;
      }, {});
      var raw_drugs = raw_data_lookup["x"];
      var raw_s = raw_data_lookup["S"];
      var raw_i = raw_data_lookup["I"];
      var raw_r = raw_data_lookup["R"];
      var raw_ns = raw_data_lookup["NS"];
      var raw_ni = raw_data_lookup["NI"];
      return raw_drugs.map(function (obj, index) {
        return {
          drug: obj,
          S: raw_s[index],
          I: raw_i[index],
          R: raw_r[index],
          NS: raw_ns[index],
          NI: raw_ni[index]
        };
      });
    },
    _getDrugsList: function _getDrugsList(self) {
      var raw_data = Ember.get(self, 'data');
      var raw_data_lookup = raw_data.reduce(function (acc, cv) {
        var key = cv[0];
        acc[key] = cv.slice(1);
        return acc;
      }, {});
      return raw_data_lookup["x"];
    },
    _setUpResponsiveSVG: function _setUpResponsiveSVG(self, viewBoxWidth, viewBoxHeight) {
      // set up svg and container divs for responsive design
      d3.select("#".concat(self.elementId, " div#chartId")).append("div").classed("svg-container", true).append("svg").attr("preserveAspectRatio", "xMinYMin meet").attr("viewBox", "0 0 ".concat(viewBoxWidth, " ").concat(viewBoxHeight)).classed("svg-content-responsive", true);
    },
    _drawBackground: function _drawBackground(svg, margin, width, height) {
      // Chart transluscent background
      svg.append("rect").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom - 10).attr("rx", 15).attr("ry", 15).classed("svg-background", true).attr("fill", "rgba(0, 0, 0, 0.7)");
    },
    _drawXAxis: function _drawXAxis(g, height, xScale) {
      // x axis
      var xaxis = g.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + height + ")").call(d3.axisBottom(xScale));
      var svg_export = this.svgExport;
      var textSize = 9; // concatinate axis labels

      xaxis.selectAll(".tick text").each(function (d) {
        var textNode = d3.select(this).text(null).style("font-size", "".concat(textSize, "px")).style("font-family", "Arial");
        svg_export.wrap_text({
          textNode: textNode,
          text: d,
          maxWidth: xScale.bandwidth(),
          lineHeight: 8,
          styleFn: function styleFn(node) {
            return node.attr("x", 0);
          }
        });

        while (textNode.node().getBBox().width > xScale.step() + 5) {
          textNode.style("font-size", "".concat(--textSize, "px")).style("font-family", "Arial");
        }
      });
      xaxis.selectAll(".tick text").each(function () {
        d3.select(this).style("font-size", "".concat(textSize, "px")).style("font-family", "Arial");
      });
      xaxis.selectAll(".tick text").style("fill", "#d8d8d8");
      xaxis.selectAll(".tick line").style("stroke", "#d8d8d8");
      xaxis.selectAll("path").style("stroke", "#d8d8d8");
    },
    _drawYAxis: function _drawYAxis(g, height, margin, yScale) {
      // y axis
      var yaxis = g.append("g").attr("class", "axis axis--y").call(d3.axisLeft(yScale).ticks(10));
      yaxis.selectAll(".tick text").style("fill", "#d8d8d8");
      yaxis.selectAll(".tick line").style("stroke", "#d8d8d8");
      yaxis.selectAll("path").style("stroke", "#d8d8d8"); // text label for the y axis

      g.append("text").attr("transform", "rotate(-90)").attr("y", 0 - margin.left + 10).attr("x", 0 - height / 2).attr("dy", "1em").attr("font-size", "8px").style("text-anchor", "middle").style("fill", "#d8d8d8").style("font-family", "Arial").text("Cum. % of S, I, and R");
    },
    _drawBarChart: function _drawBarChart(self, g, stack, data, xScale, yScale, zScale, tooltip) {
      // stacked series bar chart
      g.selectAll(".series").data(stack.keys(["S", "I", "R", "NS", "NI"])(data)).enter().append("g").attr("class", "series").attr("fill", function (d) {
        return zScale(d.key);
      }).selectAll("rect").data(function (d) {
        return d;
      }).enter().append("rect").attr("class", "bar").attr("x", function (d) {
        return xScale(d.data.drug);
      }).attr("y", function (d) {
        return yScale(d[1]);
      }).attr("height", function () {
        return 0;
      }).attr("width", xScale.bandwidth()).on("mouseover", function (d) {
        self._barMouseOver(self, g, d, tooltip, zScale);
      }).on("mousemove", function () {
        self._barMouseMove(tooltip);
      }).on("mouseleave", function (d) {
        self._barMouseLeave(g, d, tooltip);
      }); // animate in appearance of bars

      g.selectAll(".bar").transition().attr("height", function (d) {
        return yScale(d[0]) - yScale(d[1]);
      }).attr("y", function (d) {
        return yScale(d[1]);
      }).delay(function (d, i) {
        return i * 40;
      }).duration(1000).ease(d3.easeBounceOut);
    },
    _barMouseOver: function _barMouseOver(self, g, d, tooltip, zScale) {
      var selectedData = {
        drugName: "",
        S: 0,
        I: 0,
        R: 0,
        NS: 0,
        NI: 0
      };
      g.selectAll(".bar").filter(function (barData) {
        if (barData.data.drug === d.data.drug) {
          selectedData["drugName"] = d.data.drug;
          selectedData["S"] = d.data.S;
          selectedData["I"] = d.data.I;
          selectedData["R"] = d.data.R;
          selectedData["NS"] = d.data.NS;
          selectedData["NI"] = d.data.NI;
        }

        return barData.data.drug === d.data.drug;
      }).style("opacity", "0.5");
      tooltip.style("opacity", 0.95).style("visibility", "visible");
      tooltip.html(self._tooltipHTML(self, selectedData, zScale)).style('left', d3.event.pageX + 30 + "px").style('top', d3.event.pageY - 180 + "px");
    },
    _tooltipHTML: function _tooltipHTML(self, selectedData, zScale) {
      var tooltipHTML = "\n    <div class=\"d3ChartTooltip\">\n      <table class=\"d3ChartTooltip-table\">\n        <thead>\n          <th colspan=\"2\" class=\"d3ChartTooltip-th\">\n            <strong>".concat(selectedData["drugName"], "</strong>\n          </th>\n        </thead>");
      Object.keys(selectedData).forEach(function (key) {
        if (key !== "drugName") {
          tooltipHTML += self._tooltipBreakpointTr(key, selectedData[key], zScale);
        }
      });
      tooltipHTML += "</table></div>";
      return tooltipHTML;
    },
    _tooltipBreakpointTr: function _tooltipBreakpointTr(name, value, zScale) {
      return "\n        <tr>\n          <td class=\"d3ChartTooltip-td\">\n            <div class=\"d3ChartTooltip-colorSwatch\"\n            style=\"background-color: ".concat(zScale(name), ";\"></div>\n            <strong>").concat(name, "</strong>\n          </td>\n          <td class=\"d3ChartTooltip-td\">\n            ").concat(value, "\n          </td>\n        </tr>");
    },
    _barMouseMove: function _barMouseMove(tooltip) {
      tooltip.style('left', d3.event.pageX + 30 + "px").style('top', d3.event.pageY - 180 + "px");
    },
    _barMouseLeave: function _barMouseLeave(g, d, tooltip) {
      tooltip.style("opacity", 0).style("visibility", "hidden");
      g.selectAll(".bar").filter(function (barData) {
        return barData.value === d.value;
      }).style("opacity", "1");
    },
    _drawLegend: function _drawLegend(self, g, height, margin, zScale, tooltip) {
      var dataL = 0;
      var offset = 50;
      var legend = g.selectAll('.legends').data(["S", "I", "R", "NS", "NI"]).enter().append('g').attr("class", "legends").attr("transform", function (d, i) {
        if (i === 0) {
          dataL = d.length + offset - 30;
          return "translate(-30," + (height + margin.top + 15) + ")";
        } else {
          var newdataL = dataL;
          dataL += d.length + offset;
          return "translate(" + newdataL + "," + (height + margin.top + 15) + ")";
        }
      }).on("mouseover", function (d) {
        self._legendMouseOver(self, g, d, tooltip);
      }).on("mousemove", function (d) {
        self._legendMouseMove(d, tooltip);
      }).on("mouseleave", function (d) {
        self._legendMouseLeave(g, d, tooltip);
      });
      legend.append('rect').attr("x", 0).attr("y", -5).attr("width", 40).attr("height", 20).attr("fill", "rgba(0, 0, 0, 0.0)");
      legend.append('rect').attr("x", 0).attr("y", 0).attr("width", 10).attr("height", 10).style("fill", function (d) {
        return zScale(d);
      });
      legend.append('text').attr("x", 15).attr("y", 10).text(function (d) {
        return d;
      }).style("font-family", "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif").attr("class", "textselected").style("fill", "#d8d8d8").style("text-anchor", "start").style("font-size", 11).style("font-family", "Arial");
    },
    _legendMouseOver: function _legendMouseOver(self, g, d, tooltip) {
      var selectedData = {};
      g.selectAll(".series").filter(function (seriesData) {
        seriesData.forEach(function (sd) {
          selectedData[sd.data.drug] = sd.data[d];
        });
        return seriesData.key !== d;
      }).style("opacity", "0.15");
      tooltip.style("opacity", 0.95).style("visibility", "visible");
      tooltip.html(self._legendTooltipHTML(self, d, selectedData)); // display tooltip on other side of cursor in NS or NI case

      if (d === "NS" || d === "NI") {
        var tooltipSize = tooltip.node().getBoundingClientRect();
        var tooltipWidth = tooltipSize.width;
        tooltip.style('left', d3.event.pageX - tooltipWidth - 60 + "px").style('top', d3.event.pageY - 100 + "px");
      } else {
        tooltip.style('left', d3.event.pageX + 40 + "px").style('top', d3.event.pageY - 100 + "px");
      }
    },
    _legendTooltipHTML: function _legendTooltipHTML(self, d, selectedData) {
      var tooltipHTML = "\n    <div class=\"d3ChartTooltip\">\n      <table class=\"d3ChartTooltip-table\">\n        <thead>\n          <th colspan=\"2\" class=\"d3ChartTooltip-th\">\n            <strong>".concat(d, "</strong>\n          </th>\n        </thead>");
      Object.keys(selectedData).forEach(function (key) {
        tooltipHTML += self._legendTooltipDrugTr(key, selectedData[key]);
      });
      tooltipHTML += "</table></div>";
      return tooltipHTML;
    },
    _legendTooltipDrugTr: function _legendTooltipDrugTr(name, value) {
      return "\n        <tr>\n          <td class=\"d3ChartTooltip-td\">\n            <strong>".concat(name, "</strong>\n          </td>\n          <td class=\"d3ChartTooltip-td\">\n            ").concat(value, "\n          </td>\n        </tr>");
    },
    _legendMouseMove: function _legendMouseMove(d, tooltip) {
      // display tooltip on other side of cursor in NS or NI case
      if (d === "NS" || d === "NI") {
        var tooltipSize = tooltip.node().getBoundingClientRect();
        var tooltipWidth = tooltipSize.width;
        tooltip.style('left', d3.event.pageX - tooltipWidth - 60 + "px").style('top', d3.event.pageY - 100 + "px");
      } else {
        tooltip.style('left', d3.event.pageX + 40 + "px").style('top', d3.event.pageY - 100 + "px");
      }
    },
    _legendMouseLeave: function _legendMouseLeave(g, d, tooltip) {
      tooltip.style("opacity", 0).style("visibility", "hidden");
      g.selectAll(".series").filter(function (seriesData) {
        return seriesData.key !== d;
      }).style("opacity", "1");
    },
    _drawDownloadButton: function _drawDownloadButton(self, g, margin, width, viewBoxWidth, viewBoxHeight) {
      // download button
      var button = g.append('rect').attr("x", width - margin.right / 2.0).attr("y", 0 - margin.top / 2.0).attr("width", 20).attr("height", 20).attr("rx", 5).attr("ry", 5).classed("svg-downloadButton", true).style("fill", "rgba(255, 255, 255, 0.5)"); // draw download button icon

      var icon = g.append("svg:image").attr("xlink:href", self._downloadButtonIcon()).attr("x", width - margin.right / 2.0 + 1).attr("y", 0 - margin.top / 2.0 + 1).classed("svg-downloadButton", true).attr("width", 18).attr("height", 18); // download button event handling

      icon.on("mouseover", function () {
        self._downloadButtonMouseOver(button);
      }).on("mouseleave", function () {
        self._downloadButtonMouseLeave(button);
      }).on("click", function () {
        self._downloadButtonMouseClick(self, margin, viewBoxWidth, viewBoxHeight);
      });
    },
    _downloadButtonIcon: function _downloadButtonIcon() {
      return "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxwYXRoIGQ9IiAgTTMyLDM1YzAsMCw4LjMxMiwwLDkuMDk4LDBDNDUuNDYzLDM1LDQ5LDMxLjQ2Myw0OSwyNy4wOTlzLTMuNTM3LTcuOTAyLTcuOTAyLTcuOTAyYy0wLjAyLDAtMC4wMzgsMC4wMDMtMC4wNTgsMC4wMDMgIGMwLjA2MS0wLjQ5NCwwLjEwMy0wLjk5NCwwLjEwMy0xLjUwNGMwLTYuNzEtNS40MzktMTIuMTUtMTIuMTUtMTIuMTVjLTUuMjI5LDAtOS42NzIsMy4zMDktMTEuMzg2LDcuOTQxICBjLTEuMDg3LTEuMDg5LTIuNTkxLTEuNzY0LTQuMjUxLTEuNzY0Yy0zLjMxOSwwLTYuMDA5LDIuNjktNi4wMDksNi4wMDhjMCwwLjA4NSwwLjAxLDAuMTY3LDAuMDEzLDAuMjUxICBDMy42OTUsMTguOTk1LDEsMjIuMzQ0LDEsMjYuMzMxQzEsMzEuMTE5LDQuODgxLDM1LDkuNjcsMzVjMC44MjcsMCw4LjMzLDAsOC4zMywwIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz48cG9seWxpbmUgZmlsbD0ibm9uZSIgcG9pbnRzPSIzMCw0MSAyNSw0NiAyMCw0MSAgICIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIi8+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIiB4MT0iMjUiIHgyPSIyNSIgeTE9IjI2IiB5Mj0iNDUuNjY4Ii8+PC9zdmc+";
    },
    _downloadButtonMouseOver: function _downloadButtonMouseOver(button) {
      button.style("fill", "rgba(255, 255, 255, 1.0)");
    },
    _downloadButtonMouseLeave: function _downloadButtonMouseLeave(button) {
      button.style("fill", "rgba(255, 255, 255, 0.5)");
    },
    _downloadButtonMouseClick: function _downloadButtonMouseClick(self, margin, viewBoxWidth, viewBoxHeight) {
      // inner function to clone an svg selection
      function clone(selector) {
        var node = d3.select(selector).node();
        return d3.select(node.parentNode.insertBefore(node.cloneNode(true), node.nextSibling));
      } // chart info attributes


      var year = Ember.get(self, 'year') || 'All years';
      var groups = Ember.get(self, 'groups');
      var index = Ember.get(self, 'index');
      var main_component = Ember.get(self, 'main-component'); // get full pathogen name

      var header_html = Ember.get(main_component, "name");
      var header_div = document.createElement("div");
      header_div.innerHTML = header_html;
      var fullPathogenName = header_div.innerText; // get full region name and isolate count

      var footer_html = groups[index]["name"];
      var footer_div = document.createElement("div");
      footer_div.innerHTML = footer_html;
      var fullRegionName = footer_div.innerText;
      var fullIsolateCount = groups[index]["count"];
      var filename = "".concat(year, "_").concat(fullRegionName, "_").concat(fullPathogenName);
      filename = filename.replace(/:/gi, ' ');
      filename = filename.replace(/ +/gi, '_'); // make and style clone of svg

      var copy = clone("#".concat(self.elementId, " svg"));

      self._styleSvgCloneForExport(self, copy, margin, viewBoxWidth, viewBoxHeight, year, fullPathogenName, fullRegionName, fullIsolateCount); // export png of svg copy


      Ember.get(self, 'svgExport').export(copy, filename); // remove svg copy from DOM

      copy.remove();
      Ember.get(self, 'eventTracker').record('download-image-report-stacked', {
        filename: filename,
        drug: self.parentView.selectedDrug
      });
    },
    _styleSvgCloneForExport: function _styleSvgCloneForExport(self, copy, margin, viewBoxWidth, viewBoxHeight, year, fullPathogenName, fullRegionName, fullIsolateCount) {
      // fix padding for export image
      var exportPaddingWidth = 100;
      var newViewBoxWidth = viewBoxWidth + exportPaddingWidth;
      var newViewBoxHeight = viewBoxHeight + 300;
      var g_main_x = margin.left + exportPaddingWidth / 2.0;
      var g_main_y = margin.top; // set up header string

      var headerString = "".concat(fullPathogenName); // set up footer strings

      var footerString = "".concat(year, " ").concat(fullRegionName);
      var subFooterString = "".concat(fullIsolateCount, " isolates tested"); // fix viewbox of svg

      copy.attr("viewBox", "0 0 ".concat(newViewBoxWidth, " ").concat(newViewBoxHeight)); // remove download button

      copy.selectAll(".svg-downloadButton").remove();

      if (INSTANCE === 'mvppublic') {
        copy.append("svg:image").attr('x', g_main_x - 50).attr('y', g_main_y).attr('width', viewBoxWidth).attr('height', viewBoxHeight).attr('opacity', 0.075).attr("xlink:href", Ember.get(self, 'svgExport').jmi_logo());
      }

      copy.append("svg:image").attr('x', 50).attr('y', viewBoxHeight + 30).attr('width', 60).attr('height', 60).attr("xlink:href", Ember.get(self, 'svgExport').jmi_logo()); // fix background styling

      copy.select(".svg-background").attr("width", newViewBoxWidth).attr("height", newViewBoxHeight).attr("rx", 0).attr("ry", 0).attr("fill", "rgba(0, 0, 0, 1.0)"); // add header text

      copy.append('text').attr("x", 130).attr("y", viewBoxHeight + 45).text(headerString).style("fill", "#d8d8d8").style("text-anchor", "left").style("font-family", "Arial").style("font-size", 15); // add footer text

      copy.append('text').attr("x", 130).attr("y", viewBoxHeight + 65).text(footerString).style("fill", "#d8d8d8").style("text-anchor", "left").style("font-family", "Arial").style("font-size", 12); // add subfooter text

      copy.append('text').attr("x", 130).attr("y", viewBoxHeight + 80).text(subFooterString).style("fill", "#d8d8d8").style("text-anchor", "left").style("font-family", "Arial").style("font-size", 12);
      var textNode = copy.append('text').attr("x", 30).attr("y", viewBoxHeight + 120).text(null).style("font-family", "Arial").style("fill", "#d8d8d8").style("text-anchor", "left").style("font-size", "6px");
      Ember.get(self, 'svgExport').wrap_text({
        textNode: textNode,
        text: Ember.get(self, 'svgExport').disclaimer(),
        maxWidth: newViewBoxWidth - 60,
        lineHeight: 8,
        styleFn: function styleFn(node) {
          return node;
        }
      }); // fix location of main group for graph

      copy.selectAll(".g_main").attr("transform", "translate(" + g_main_x + "," + g_main_y + ")");
    }
  });

  _exports.default = _default;
});