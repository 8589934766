define("mvp/models/signup", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // const { attr } = DS;
  var _default = _emberData.default.Model.extend({
    invitation_token: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    accepttoken: (0, _emberApiActions.memberAction)({
      path: 'acccept_invitation'
    })
  });

  _exports.default = _default;
});