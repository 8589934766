define("mvp/pods/surveillance/show/programs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service("store"),
    ajax: Ember.inject.service("ajax"),
    currentUser: Ember.inject.service("current-user"),
    year: undefined,
    program_group: undefined,
    region: "1:  New England",
    site: undefined,
    actions: {
      changeProgramGroup: function changeProgramGroup(program_group_id) {
        var store = this.store;

        var _self = this;

        store.findRecord("program-group", program_group_id).then(_self._updateProgramGroup.bind(_self));
      },
      changeYear: function changeYear(year) {
        year = parseInt(year);

        if (isNaN(year)) {
          Ember.set(this, "year", undefined);
        } else {
          Ember.set(this, "year", year);
        }
      },
      changeRegion: function changeRegion(region) {
        Ember.set(this, "region", region);
      },
      changeSite: function changeSite(site) {
        Ember.set(this, "site", site);
      }
    },
    _updateProgramGroup: function _updateProgramGroup(group) {
      Ember.set(this, "program_group", group);
    }
  });

  _exports.default = _default;
});