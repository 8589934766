define("mvp/pods/components/ui-surveillance/adhoc/resistance/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //////////////
    // Services //
    //////////////
    // Provide access to the current signed in user
    currentUser: Ember.inject.service("current-user"),
    // Provide a pubsub mechanism to listen to and invoke events
    eventBus: Ember.inject.service("event-bus"),
    media: Ember.inject.service("media"),
    ////////////////
    // Properties //
    ////////////////
    // Contains the current filter as built in the ui-surveillance/adhoc component
    filter: undefined,
    // Stores a referenence to the organism dropdown component, the component
    // will register to this values through the `register-as` attribute.
    drugDropdown: undefined,
    authority: undefined,
    authorityDropdown: undefined,
    dilution: undefined,
    dilutionDropdown: undefined,
    comparison: undefined,
    comparisonDropdown: undefined,
    existsChosen: false,
    //////////
    // drugs //
    //////////
    // Observe changes to organism
    observedrugs: function () {
      // Reset authority
      Ember.set(this, "authority", undefined);

      if (this.authorityDropdown !== undefined) {
        this.authorityDropdown.send("reset");
      }
    }.observes("drugs.length"),
    drugsEnabled: Ember.computed('filter.criteria', function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    drugUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/drug-names" + "?project_name=" + encodeURIComponent(project_name);
    }),
    ///////////////
    // Authority //
    ///////////////
    // Observe changes to organism
    observeAuthority: function () {
      // Reset reaction
      Ember.set(this, "reactions", []);
      Ember.$(".reactions .ui.checkbox.susceptible").checkbox("set unchecked");
      Ember.$(".reactions .ui.checkbox.resistant").checkbox("set unchecked");
      Ember.$(".reactions .ui.checkbox.intermediate").checkbox("set unchecked");
      Ember.$(".reactions .ui.checkbox.non-susceptible").checkbox("set unchecked"); // Reset comparison

      Ember.set(this, "comparison", undefined); // Reset dilution

      Ember.set(this, "dilution", undefined);
    }.observes("authority"),
    authorityEnabled: Ember.computed("drugs.length", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return Ember.get(this, "drugs.length") > 0 && !(Ember.get(this, "drugs.length") === 1 && this.drugs.objectAt(0) === "");
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    authorityUrl: Ember.computed("filter.criteria", "drugs.length", function () {
      var project_name = Ember.get(this, "filter.criteria.project");
      var drugs = this.drugs;
      var genusCriteria = Ember.get(this, "filter.criteria.genus");
      var groupCriteria = Ember.get(this, "filter.criteria.group");
      var organismCriteria = Ember.get(this, "filter.criteria.organism");
      var additional_criteria = "";

      if (genusCriteria !== undefined) {
        additional_criteria += "&genus_criteria=" + encodeURIComponent(JSON.stringify(genusCriteria));
      }

      if (groupCriteria !== undefined) {
        additional_criteria += "&group_criteria=" + encodeURIComponent(JSON.stringify(groupCriteria));
      }

      if (organismCriteria !== undefined) {
        additional_criteria += "&organism_criteria=" + encodeURIComponent(JSON.stringify(organismCriteria));
      } // !Important - We must use encodeURIComponent for IE.


      return "/api/adhoc/authorities" + "?project_name=" + encodeURIComponent(project_name) + "&drug=" + encodeURIComponent(JSON.stringify(drugs)) + additional_criteria;
    }),
    ///////////////
    // Reactions //
    ///////////////
    observeReactions: function () {}.observes("reactions.length"),
    reactionsEnabled: Ember.computed("drugs.length", "authority", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return this.drugs !== undefined && this.authority !== undefined && this.authority[0] !== "" && !this.authority.includes("custom");
    }),
    observeReactionsEnabled: function () {
      var _self = this;

      if (this.reactionsEnabled) {
        Ember.run.later(function () {
          _self.setupCheckboxListener("susceptible", "S");

          _self.setupCheckboxListener("intermediate", "I");

          _self.setupCheckboxListener("resistant", "R");

          _self.setupCheckboxListener("non-susceptible", "NS");
        }, 50);
      }
    }.observes("reactionsEnabled"),
    ////////////////
    // Custom MIC //
    ////////////////
    customMicEnabled: Ember.computed("drugs.length", "authority", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return this.drugs !== undefined && this.authority !== undefined && this.authority.includes("custom");
    }),
    ////////////////
    // Comparison //
    ////////////////
    // Observe changes to organism
    observeComparison: function () {
      if (this.comparison !== undefined && this.comparison.includes("exists")) {
        Ember.set(this, "existsChosen", true);
        Ember.set(this, "dilution", undefined);
      } else {
        Ember.set(this, "existsChosen", false);
      }
    }.observes("comparison"),
    // A property representing the string of where the dropdown should look
    // for its values.
    comparisonUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/comparison" + "?project_name=" + encodeURIComponent(project_name);
    }),
    //////////////
    // Dilution //
    //////////////
    // Observe changes to organism
    observeDilution: function () {}.observes("dilution"),
    // A property representing the string of where the dropdown should look
    // for its values.
    dilutionUrl: Ember.computed("filter.criteria", "drugs.length", function () {
      var project_name = Ember.get(this, "filter.criteria.project");
      var drugs = this.drugs; // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/dilutions" + "?project_name=" + encodeURIComponent(project_name) + "&drug=" + encodeURIComponent(JSON.stringify(drugs));
    }),
    /////////////////
    // Add Profile //
    /////////////////
    canAddProfileEnabled: Ember.computed("drugs.length", "authority", "comparison", "dilution", "reactions.length", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return this.drugs !== undefined && this.authority !== undefined && this.authority.includes("custom") && (this.comparison !== undefined && this.dilution !== undefined || this.comparison !== undefined && this.comparison.includes("exists")) || this.drugs !== undefined && this.authority !== undefined && !this.authority.includes("custom") && Ember.get(this, "reactions.length") > 0;
    }),
    ////////////////////
    // Reacts like to //
    ////////////////////
    reactsLikeToReadable: Ember.computed("reactsLikeTo.length", function () {
      var _self = this;

      return this.reactsLikeTo.map(function (rlt) {
        var readable_rlt = {};
        readable_rlt["drug"] = rlt["drug"];
        readable_rlt["authority"] = rlt["authority"];
        readable_rlt["publication"] = rlt["publication"];
        readable_rlt["delivery_mechanism"] = rlt["delivery_mechanism"];
        readable_rlt["infection_type"] = rlt["infection_type"];
        readable_rlt["interpretations"] = _self.toSentence(rlt["interpretations"]);
        return readable_rlt;
      });
    }),
    resistanceProfiles: Ember.computed("reactsLikeTo.length", "customMicProfile.length", function () {
      var output = [];
      this.reactsLikeTo.forEach(function (rlt, index) {
        // Find out the interpretations affix
        var interp_type;
        var interpretations = rlt["interpretations"];

        if (interpretations.length === 4) {
          interp_type = 'has breakpoints';
        } else if (interpretations.length === 3 && interpretations.includes("I") && interpretations.includes("R") && interpretations.includes("NS")) {
          // Can shorten to non-susceptible
          interp_type = 'non-susceptible';
        } else {
          // map keys to 'clean' values
          var interps = interpretations.map(function (interp) {
            switch (interp) {
              case "S":
                return "susceptible";

              case "I":
                return "intermediate";

              case "R":
                return "resistant";

              case "NS":
                return "non-susceptible";
            }
          }); // transform keys to sentence

          interp_type = interps.reduce(function (prev, curr, i) {
            return prev + curr + (i === interps.length - 2 ? ' or ' : ', ');
          }, '').slice(0, -2);
        } // map the drugs to an array with or


        var drugs = rlt["drug"];
        drugs = drugs.reduce(function (prev, curr, i) {
          return prev + curr.toLowerCase() + (i === drugs.length - 2 ? ' or ' : ', ');
        }, '').slice(0, -2);
        var sentence = interp_type + " when tested against " + drugs + " according to " + rlt["authority"] + " " + rlt["publication"];

        if (rlt["delivery_mechanism"] !== "") {
          sentence += " when delivered via " + rlt["delivery_mechanism"];
        }

        if (rlt["infection_type"] !== "") {
          sentence += " for " + rlt["infection_type"];
        }

        output.addObject({
          "action": "remove-reacts-like-to",
          "index": index,
          "text": sentence
        });
      });

      var _self = this;

      this.customMicProfile.forEach(function (cmp, index) {
        var comp;

        switch (cmp["comparison"]) {
          case "<":
            comp = "less than";
            break;

          case "≤":
            comp = "less than or equal to";
            break;

          case "=":
            comp = "equal to";
            break;

          case "≠":
            comp = "not equal to";
            break;

          case "≥":
            comp = "greater than or equal to";
            break;

          case ">":
            comp = "greater than";
            break;
        }

        var drugs = _self.toSentence(cmp["drug"]).replace("and", "or");

        if (cmp["dilution"] !== undefined) {
          var human_readable = "";
          human_readable += "MIC is " + comp + " " + parseFloat(cmp["dilution"]).toString() + " &micro;g/mL";
          human_readable += " when tested against " + drugs;
          output.addObject({
            "action": "remove-custom-mic-profile",
            "index": index,
            "text": human_readable
          });
        } else {
          output.addObject({
            "action": "remove-custom-mic-profile",
            "index": index,
            "text": "tested against " + drugs
          });
        }
      }); // Reset drugs

      Ember.run.scheduleOnce('afterRender', function () {
        if (!_self.isDestroyed && Ember.get(_self, "drugs") !== undefined) {
          Ember.get(_self, "drugs").clear();

          if (Ember.get(_self, "drugDropdown") !== undefined) {
            Ember.get(_self, "drugDropdown").send("reset");
          }
        }
      });
      return output;
    }),
    observeReactsLikeTo: function () {
      var rlt;

      if (Ember.get(this, "reactsLikeTo.length") > 0) {
        rlt = this.reactsLikeTo;
      }

      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("reacts_like_to", rlt);
      });
    }.observes("reactsLikeTo.length"),
    observeCriteriaForReactsLikeToReset: function () {
      if (Ember.get(this, "filter.criteria.reacts_like_to") === undefined && Ember.get(this, "reactsLikeTo.length") > 0) {
        this.resetReactsLikeTo();
      }
    }.observes("filter.criteria"),
    reactsLikeToEnabled: Ember.computed("filter.criteria", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    ////////////////////////
    // Custom MIC Profile //
    ////////////////////////
    observeCustomMicProfile: function () {
      var cmp;

      if (Ember.get(this, "customMicProfile.length") > 0) {
        cmp = this.customMicProfile;
      }

      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("custom_mic_profile", cmp);
      });
    }.observes("customMicProfile.length"),
    observeCriteriaForCustomMicProfileReset: function () {
      if (Ember.get(this, "filter.criteria.custom_mic_profile") === undefined && Ember.get(this, "customMicProfile.length") > 0) {
        this.resetCustomMicProfile();
      }
    }.observes("filter.criteria"),
    init: function init() {
      this._super.apply(this, arguments);

      this.setupInit();
      this.drugs = [];
      this.reactions = [];
      this.reactsLikeTo = [];
      this.customMicProfile = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupUI();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.cleanup();
    },
    /////////////
    // Actions //
    /////////////
    actions: {
      // Handle logic when the slected organism has changed. The values will be a
      // CSV string represention the selected values.
      "update-selected-drugs": function updateSelectedDrugs(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (selection !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "drugs", selection);
      },
      "update-selected-authority": function updateSelectedAuthority(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (selection !== "") {
          selection = decodeURIComponent(values).split(",");
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "authority", selection);
      },
      "update-selected-comparison": function updateSelectedComparison(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (selection !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "comparison", selection);
      },
      "update-selected-dilution": function updateSelectedDilution(values) {
        // Parse the CSV string to pull out the selected organisms.
        var selection;

        if (selection !== "") {
          selection = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          selection = [];
        } // Update the slected organism values


        Ember.set(this, "dilution", selection);
      },
      "add-resistance-profile": function addResistanceProfile() {
        // Determine what resistance profile was selected
        if (this.drugs !== undefined && this.authority !== undefined && !this.authority.includes("custom") && Ember.get(this, "reactions.length") > 0) {
          // Build and add the reacts-like-to
          var authority = this.authority;
          var rlt = {};
          rlt["drug"] = Ember.copy(this.drugs);
          rlt["authority"] = authority[0];
          rlt["publication"] = authority[1];
          rlt["delivery_mechanism"] = authority[2];
          rlt["infection_type"] = authority[3];
          rlt["interpretations"] = this.reactions.slice(0);
          this.reactsLikeTo.addObject(rlt);
        } else {
          // Build and add the custom-mic-profile
          var cmp = {};
          cmp["drug"] = Ember.copy(this.drugs);
          cmp["comparison"] = this.comparison[0];

          if (this.dilution !== undefined) {
            cmp["dilution"] = this.dilution[0];
          }

          this.customMicProfile.addObject(cmp);
        }
      },
      "remove-reacts-like-to": function removeReactsLikeTo(index) {
        this.reactsLikeTo.removeAt(index);
      },
      "remove-custom-mic-profile": function removeCustomMicProfile(index) {
        this.customMicProfile.removeAt(index);
      }
    },
    ///////////////
    // Functions //
    ///////////////
    setupInit: function setupInit() {
      // Subscribe to the event bus
      this.eventBus.on('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.on('resetAdhocFilter', this, 'resetAdhocFilter');
    },
    // Set up the ui after this component has been rendered
    setupUI: function setupUI() {
      if (Ember.get(this, "filter.criteria.reacts_like_to.length") > 0) {
        Ember.set(this, "reactsLikeTo", Ember.get(this, "filter.criteria.reacts_like_to"));
      }

      if (Ember.get(this, "filter.criteria.custom_mic_profile.length") > 0) {
        Ember.set(this, "customMicProfile", Ember.get(this, "filter.criteria.custom_mic_profile"));
      }
    },
    // Reset the state of this component, usually called when the selected
    // project changes or the user clears the filter.
    reset: function reset() {
      Ember.set(this, "drugs", undefined);
      Ember.set(this, "reactsLikeTo", []);
      Ember.set(this, "customMicProfile", []);

      if (this.drugsDropdown !== undefined) {
        this.drugsDropdown.send("reset");
      }
    },
    // Performs cleanup of the component after the element is destroyed
    cleanup: function cleanup() {
      // Destroy ties to the event bus
      this.eventBus.off('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.off('resetAdhocFilter', this, 'resetAdhocFilter');
    },
    // Notify observers within this component that the filter criteria has
    // changed. This will be invoked through the global event bus.
    filterCriteriaChanged: function filterCriteriaChanged() {
      this.notifyPropertyChange("filter.criteria");
    },
    resetCustomMicProfile: function resetCustomMicProfile() {
      // Reset the actual values
      Ember.set(this, "customMicProfile", []);
    },
    // Perform necessary actions when adhoc filter is reset
    resetAdhocFilter: function resetAdhocFilter() {
      this.reset();
    },
    resetReactsLikeTo: function resetReactsLikeTo() {
      // Reset the actual values
      Ember.set(this, "reactsLikeTo", []);
    },
    setupCheckboxListener: function setupCheckboxListener(description, interpretation) {
      var _self = this;

      Ember.$(".reactions .ui.checkbox." + description).checkbox({
        onChecked: function onChecked() {
          if (interpretation === "NS") {
            Ember.$(".reactions .ui.checkbox.intermediate").checkbox("set checked");
            Ember.$(".reactions .ui.checkbox.resistant").checkbox("set checked");
            Ember.get(_self, "reactions").addObject("NS");
            Ember.get(_self, "reactions").addObject("I");
            Ember.get(_self, "reactions").addObject("R");
          } else {
            Ember.get(_self, "reactions").addObject(interpretation);
          }
        },
        onUnchecked: function onUnchecked() {
          Ember.get(_self, "reactions").removeObject(interpretation);
        }
      });
    },
    // Convert an array to a sentence
    toSentence: function toSentence(array) {
      var arr = array.slice(0);
      var lastWord = "";

      if (arr.length > 1) {
        lastWord = " and " + arr.pop();
      }

      return arr.join(", ") + lastWord;
    }
  });

  _exports.default = _default;
});