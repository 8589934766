define("mvp/pods/manage/users/invite/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CuNzWU8k",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"invite-user\",null,[[\"organizations\",\"invite\",\"inviteErrorMessage\"],[[23,[\"sortedOrganizations\"]],[27,\"action\",[[22,0,[]],\"invite\"],null],[23,[\"inviteErrorMessage\"]]]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/manage/users/invite/template.hbs"
    }
  });

  _exports.default = _default;
});