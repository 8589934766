define("mvp/pods/surveillance/show/quality-control/runs/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service("ajax"),
    store: Ember.inject.service("store"),
    runsController: Ember.inject.controller("surveillance.show.quality_control.runs"),
    surveillanceYear: Ember.computed.alias("runsController.surveillanceYear"),
    observeRun: function () {
      Ember.set(this, "susceptibility_results", undefined);
    }.observes("model"),
    actions: {
      load_susceptibility_results: function load_susceptibility_results(include_interpretations) {
        var ajax = this.ajax;
        var store = this.store;
        var run = this.model;
        var project_id = Ember.get(this, "model.project.id");

        var _self = this;

        var surveillanceYear = this.surveillanceYear;
        var url = "/api/projects/" + project_id + "/runs/" + run.id + "/susceptibility-results?surveillance_year=" + surveillanceYear;

        if (!this.show_all_results) {
          url += "&include_interpretations=" + encodeURIComponent(include_interpretations);
        } // set(this, "loading", true);
        // set(this, "susceptibility_results", []);
        // Clear the store of all susceptiblity-results


        store.unloadAll("susceptibility-result");
        ajax.request(url).then(_self._saveSusceptibilityResults.bind(_self, store));
      }
    },
    _saveSusceptibilityResults: function _saveSusceptibilityResults(store, response) {
      // Load the records to the store
      store.pushPayload(response);
      Ember.set(this, "susceptibility_results", store.peekAll("susceptibility-result"));
      Ember.set(this, "loading", undefined);
    }
  });

  _exports.default = _default;
});