define("mvp/pods/components/ui-filter-result/cross-tabs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    store: Ember.inject.service("store"),
    classNames: ["ui", "grid", "middle", "aligned"],
    drugsChosen: false,
    bottomDrugDropdown: undefined,
    loading: false,
    viewIsolateData: undefined,
    isolatesViewer: undefined,
    isolates: undefined,
    drugUrl: Ember.computed("filter.id", function () {
      return "/api/filters/" + Ember.get(this, "filter.id") + "/drug-names";
    }),
    observeLeftDrugDropdown: function () {
      var _self = this;

      if (Ember.get(this, "group1Drugs.length") > 0) {
        Ember.run.schedule("afterRender", function () {
          Ember.get(_self, "leftDrugDropdown").send("set-selected", Ember.get(_self, "group1Drugs"));
        });
      }
    }.observes("leftDrugDropdown"),
    observeBottomDrugDropdown: function () {
      var _self = this;

      if (Ember.get(this, "group2Drugs.length") > 0) {
        Ember.run.schedule("afterRender", function () {
          Ember.get(_self, "bottomDrugDropdown").send("set-selected", Ember.get(_self, "group2Drugs"));
        });
      }
    }.observes("bottomDrugDropdown"),
    observeChosenDrugs: function () {
      var group1Drugs = this.group1Drugs;
      var group2Drugs = this.group2Drugs;

      if (group1Drugs.length > 0 && group2Drugs.length > 0) {
        Ember.set(this, "drugsChosen", true);
        Ember.run.scheduleOnce('actions', this, function () {
          this.getCrossTabData(this.synthEdges);
        });
      } else {
        Ember.set(this, "drugsChosen", false);
      }
    }.observes("group1Drugs.length", "group2Drugs.length", "synthEdges"),
    didInsertElement: function didInsertElement() {
      var _self = this;

      Ember.run.schedule("afterRender", function () {
        _self.observeChosenDrugs();
      });
    },
    actions: {
      leftDrugSelected: function leftDrugSelected(drug_names) {
        Ember.run.scheduleOnce('actions', this, function () {
          this.leftDrugSelected(drug_names);
        });
      },
      viewIsolate: function viewIsolate(isolate) {
        Ember.run.scheduleOnce('actions', this, function () {
          this.viewIsolate(isolate, this.isolates);
        });
      },
      bottomDrugSelected: function bottomDrugSelected(drug_names) {
        Ember.run.scheduleOnce('actions', this, function () {
          this.bottomDrugSelected(drug_names);
        });
      },
      viewIsolates: function viewIsolates(data) {
        Ember.set(this, "viewIsolateData", data);
        this.isolatesViewer.send("showModal");
      },
      getIsolates: function getIsolates(page, perPage) {
        var _self = this;

        var data = this.viewIsolateData;
        var store = this.store;
        var left_ordinal_id = data.left_ordinal_id;
        var bottom_ordinal_id = data.bottom_ordinal_id;
        var group1Drugs = this.group1Drugs;
        var group2Drugs = this.group2Drugs;
        var crossTabData = {
          filterId: Ember.get(this, "filter.id"),
          group1Drugs: group1Drugs,
          group2Drugs: group2Drugs,
          left_ordinal_id: left_ordinal_id,
          bottom_ordinal_id: bottom_ordinal_id
        };
        store.query("isolate", {
          "cross-tab-data": crossTabData,
          "page": page
        }).then(_self._loadedIsolates.bind(_self, page, perPage));
      }
    },
    _loadedIsolates: function _loadedIsolates(page, perPage, results) {
      Ember.set(this.isolatesViewer, "totalPagesBinding", {
        page: page,
        perPage: perPage,
        totalPages: results.meta.total_pages
      });
      Ember.set(this, "isolates", results);
    }
  });

  _exports.default = _default;
});