define("mvp/pods/components/ui-filter-result/scattergram/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authorization: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service('store'),
    page: 1,
    drugAliases: Ember.computed("drugs.length", function () {
      var output = [];
      this.drugs.forEach(function (drug) {
        output.addObject({
          name: Ember.get(drug, 'alias'),
          value: Ember.get(drug, 'alias')
        });
      });
      return output;
    }),
    observeBottomDrugsDropdown: function () {
      if (this.bottomDrugsDropdown !== undefined && Ember.get(this, 'bottomDrugs.length') > 0) {
        this.bottomDrugsDropdown.send('set-selected', this.bottomDrugs);
      }
    }.observes('bottomDrugsDropdown'),
    observeLeftDrugsDropdown: function () {
      if (this.leftDrugsDropdown !== undefined && Ember.get(this, 'leftDrugs.length') > 0) {
        this.leftDrugsDropdown.send('set-selected', this.leftDrugs);
      }
    }.observes('leftDrugsDropdown'),
    didReceiveAttrs: function didReceiveAttrs() {
      var leftDrugs = this.leftDrugs;
      var bottomDrugs = this.bottomDrugs;
      var filter = this.filter;
      var store = this.store;
      var self = this;
      Ember.set(this, 'scattergram', undefined);

      if (leftDrugs.length > 0 && bottomDrugs.length > 0) {
        var ajax = this.ajax;
        var url = 'api/filters/' + filter.id + '/scattergram';
        Ember.set(this, 'loading', true);
        ajax.request(url, {
          data: {
            'left-drugs': leftDrugs,
            'bottom-drugs': bottomDrugs
          }
        }).then(self._saveScattergram.bind(self, store));
      }
    },
    actions: {
      'left-drug-changed': function leftDrugChanged(drugs) {
        if (drugs === "") {
          this.updateLeftDrugs([]);
        } else {
          this.updateLeftDrugs(decodeURIComponent(drugs).split(','));
        }
      },
      'bottom-drug-changed': function bottomDrugChanged(drugs) {
        if (drugs === "") {
          this.updateBottomDrugs([]);
        } else {
          this.updateBottomDrugs(decodeURIComponent(drugs).split(','));
        }
      },
      'isolates-page-clicked': function isolatesPageClicked(page) {
        Ember.set(this, 'page', page);
        this.updateIsolates();
      },
      'view-isolates': function viewIsolates(cell) {
        Ember.set(this, 'cell', cell);
        Ember.set(this, 'page', 1);
        this.updateIsolates();
      },
      'view-isolate': function viewIsolate(isolate) {
        var _this = this;

        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.$("#" + _this.elementId + " .ui.modal").modal('hide');
        });
        this['view-isolate'](isolate, this.isolates);
      }
    },
    updateIsolates: function updateIsolates() {
      var _this2 = this;

      var ajax = this.ajax;
      var filter = this.filter;
      var leftDrugs = this.leftDrugs;
      var bottomDrugs = this.bottomDrugs;
      var store = this.store;
      var self = this;
      var page = this.page;
      var cell = this.cell;
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$("#" + _this2.elementId + " .ui.modal").modal({
          detachable: false,
          observeChanges: true
        }).modal("show");
      });
      var url = "api/filters/" + filter.id + "/scattergram-isolates-at";
      Ember.set(self, 'isolates', undefined);
      ajax.request(url, {
        data: {
          'left-drugs': leftDrugs,
          'bottom-drugs': bottomDrugs,
          'bottom-unnormalized-ids': cell['bottom-unnormalized-ids'],
          'left-unnormalized-ids': cell['left-unnormalized-ids'],
          'page': page
        }
      }).then(self._saveIsolates.bind(self, page, store));
    },
    _saveIsolates: function _saveIsolates(page, store, payload) {
      store.unloadAll('isolate');
      store.pushPayload(payload);
      var isolates = [];
      payload.data.forEach(function (item) {
        isolates.addObject(store.peekRecord('isolate', item.id));
      });
      Ember.set(this, "totalPagesBinding", {
        page: page,
        perPage: 10,
        totalPages: payload.meta.total_pages
      });
      Ember.set(this, 'isolates', isolates);
    },
    _saveScattergram: function _saveScattergram(store, payload) {
      store.unloadAll('scattergram');
      store.pushPayload(payload);
      Ember.set(this, 'scattergram', store.peekRecord('scattergram', payload.data.id));
      Ember.set(this, 'loading', false);
    }
  });

  _exports.default = _default;
});