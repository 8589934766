define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/all-maps/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      'update-selected-year': function updateSelectedYear(year, playing) {
        // console.log(`update selected year ${year}`);
        this['update-selected-year'](year, playing);
      }
    }
  });

  _exports.default = _default;
});