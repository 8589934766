define("mvp/pods/site-management/sites/show/addresses/edit/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/sites/show/addresses/show"], function (_exports, _emberApolloClient, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var variables = {
        id: params.address_id
      };
      var site_address = this.apollo.watchQuery({
        query: _show.default,
        variables: variables
      }, "siteAddress");
      var sc = this.modelFor("site-management.sites.show.contacts");
      return Ember.RSVP.hash({
        params: params,
        siteContacts: sc,
        addressTypes: this.address_types(),
        siteAddress: site_address,
        changeset: {}
      });
    },
    address_types: function address_types() {
      return ["office", "personal"];
    }
  });

  _exports.default = _default;
});