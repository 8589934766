define("mvp/pods/site-management/sites/show/objectives/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cX1DAASd",
    "block": "{\"symbols\":[\"so\"],\"statements\":[[4,\"if\",[[22,0,[\"model\",\"records\"]]],null,{\"statements\":[[0,\"    \"],[7,\"table\"],[11,\"class\",\"ui table\"],[9],[0,\"\\n      \"],[7,\"thead\"],[9],[0,\"\\n        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Year\"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Objective\"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Target\"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Deadline\"],[10],[0,\"\\n\"],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[22,0,[\"model\",\"records\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\"],[9],[0,\"\\n            \"],[7,\"td\"],[9],[1,[22,1,[\"year\"]],false],[10],[0,\"\\n            \"],[7,\"td\"],[9],[1,[22,1,[\"objective\",\"name\"]],false],[10],[0,\"\\n            \"],[7,\"td\"],[9],[1,[22,1,[\"targetCount\"]],false],[10],[0,\"\\n            \"],[7,\"td\"],[9],[1,[27,\"moment-format\",[[22,1,[\"deadline\"]],\"DDMMMYYYY\"],null],false],[10],[0,\"\\n\"],[0,\"          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[22,0,[\"model\",\"pageInfo\",\"totalRecords\"]],10],null]],null,{\"statements\":[[0,\"        \"],[7,\"tfoot\"],[9],[0,\"\\n          \"],[7,\"th\"],[11,\"colspan\",\"9\"],[9],[0,\"\\n            \"],[1,[27,\"page-numbers\",null,[[\"content\"],[[23,[\"pagedContent\"]]]]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\"],[9],[0,\"\\n    Site is currently has no objectives.\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[7,\"div\"],[11,\"class\",\"ui modal\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/site-management/sites/show/objectives/template.hbs"
    }
  });

  _exports.default = _default;
});