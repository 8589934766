define("mvp/pods/components/async-dropdown-with-categories/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service("ajax"),
    media: Ember.inject.service("media"),
    classNameBindings: ['media.classNames'],
    isMultiSelect: false,
    isLoading: false,
    url: undefined,
    categories: undefined,
    doReset: false,
    holdCallback: false,
    allowTab: true,
    selectOnKeydown: true,
    fullTextSearch: true,
    dropdownSelector: Ember.computed("elementId", function () {
      return "#" + this.elementId + " .ui.dropdown";
    }),
    dropdown: Ember.computed('dropdownSelector', function () {
      return Ember.$(this.dropdownSelector);
    }),
    isIPhone: Ember.computed('media.isMobile', 'iOS', function () {
      return Ember.get(this, 'media.isMobile') && this.iOS;
    }),
    watchForReset: function () {
      this.send("reset");
    }.observes("doReset"),
    cleanDirection: Ember.computed("direction", function () {
      var direction = this.direction;

      if (direction !== undefined) {
        return direction;
      } else {
        return "auto";
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      Ember.set(this, 'iOS', iOS);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var self = _this;
        var isIPhone = _this.isIPhone;
        Ember.$("#" + _this.elementId + " i.dropdown.icon").click(function () {
          if (!isIPhone) {
            if (Ember.get(self, "categories") === undefined) {
              Ember.get(self, 'dropdown').click();
            }
          }
        });
        Ember.$("#" + _this.elementId + " input.search").keydown(function () {
          if (Ember.get(self, "categories") === undefined) {
            event.preventDefault();
          }
        });

        if (isIPhone) {
          Ember.$("#" + _this.elementId + " input.search").attr('readonly', 'true');
        }

        _this.setupDropdown(_this);

        Ember.set(_this, "register-as", _this);
      });
    },
    actions: {
      mobileSelected: function mobileSelected(event) {
        var selected = Ember.$(event.target).val();
        var ds = this.dropdownSelector;
        var temp;

        if (Array.isArray(selected)) {
          temp = selected.sort();
        } else {
          temp = [selected];
        }

        Ember.set(this, 'selectedOptions', temp);
        this.send("onChange", temp.join(','));
        Ember.$(ds).dropdown('set exactly', temp);
      },
      "reset": function reset() {
        var ds = this.dropdownSelector;
        Ember.$(ds).dropdown("clear");
        Ember.$(ds).dropdown("set text", this["default-text"]);
        Ember.$(ds + " .text").addClass("default");

        if (!this.isDestroyed) {
          Ember.set(this, "categories", undefined);
        }
      },
      "onChange": function onChange(selection) {
        this.onChange(selection);
      },
      "set-selected": function setSelected(selected) {
        var _self = this;

        Ember.set(this, "holdCallback", true);
        Ember.run.scheduleOnce('actions', this, function () {
          if (this.categories !== undefined) {
            Ember.run.later(function () {
              Ember.$(Ember.get(_self, "dropdownSelector")).dropdown("set exactly", selected);

              if (!_self.isDestroyed) {
                Ember.set(_self, "holdCallback", false);
              }
            }, 100);
          } else {
            _self.requestOptionsFromBackend().then(_self._renderOptionsAfterSelect.bind(_self, selected));
          }
        });
      }
    },
    setupDropdown: function setupDropdown(self) {
      var ds = this.dropdownSelector;
      var isIPhone = this.isIPhone;
      var isMultiSelect = this.isMultiSelect;
      this.dropdown.dropdown({
        useLabels: !isIPhone,
        allowCategorySelection: false,
        forceSelection: false,
        allowTab: Ember.get(self, "allowTab"),
        fullTextSearch: Ember.get(self, "fullTextSearch"),
        selectOnKeydown: Ember.get(self, "selectOnKeydown"),
        action: 'activate',
        onChange: function onChange(selection) {
          if (!isIPhone) {
            self.send("onChange", selection);

            if (!isMultiSelect) {
              Ember.$(ds).dropdown("hide");
            }
          }
        },
        direction: this.cleanDirection,
        onShow: function onShow() {
          if (Ember.get(self, "categories") === undefined) {
            self.loadOptions().then(self._showDropdown.bind(self));
            return false;
          } else {
            if (Ember.get(self, "categories") !== undefined && isIPhone) {
              Ember.run.later(self, function () {
                Ember.$("#" + self.elementId + " select")[0].focus();
              }, 100);
              return false;
            }
          }
        }
      });
    },
    reset: function reset() {
      Ember.$(this.dropdownSelector).dropdown("clear");
    },
    loadOptions: function loadOptions() {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.set(self, 'isLoading', true);

        if (Ember.get(self, "categories") !== undefined) {
          Ember.set(self, 'isLoading', false);
          resolve();
        } else {
          self.requestOptionsFromBackend().then(self._renderOptionsAfterLoad.bind(self, resolve));
        }
      });
    },
    requestOptionsFromBackend: function requestOptionsFromBackend() {
      var ajax = this.ajax;
      var url = this.url;
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        ajax.request(url).then(self._requestOptionsSuccess.bind(self, resolve, reject), self._rejectReason.bind(self, reject));
      });
    },
    _renderOptionsAfterSelect: function _renderOptionsAfterSelect(selected, categories) {
      var self = this;

      if (!this.isDestroyed) {
        Ember.set(this, "categories", categories);

        if (categories.length > 0) {
          Ember.$(this.dropdownSelector + " .message").remove();
        }

        Ember.$(this.dropdownSelector).removeClass("loading");

        if (!this.holdCallback) {
          Ember.$(this.dropdownSelector).dropdown("show");
        }

        Ember.run.later(function () {
          Ember.$(Ember.get(self, "dropdownSelector")).dropdown("set exactly", selected);

          if (!self.isDestroyed) {
            Ember.set(self, "holdCallback", false);
          }
        }, 100);
      }
    },
    _showDropdown: function _showDropdown() {
      this.dropdown.dropdown('show');
    },
    _renderOptionsAfterLoad: function _renderOptionsAfterLoad(resolve, categories) {
      var _this2 = this;

      var isIPhone = this.isIPhone;

      if (!this.isDestroyed) {
        Ember.set(this, 'categories', categories);
        Ember.set(this, 'isLoading', false);
        resolve();
        Ember.run.scheduleOnce('afterRender', this, function () {
          if (isIPhone) {
            Ember.run.later(_this2, function () {
              Ember.$("#" + _this2.elementId + " select")[0].focus();
            }, 100);
          } else {
            _this2.dropdown.addClass('active');
          } // Remove the 'No results found' message


          if (categories.length > 0) {
            Ember.$("#" + _this2.elementId + " .message").remove();
          }
        });
      }
    },
    _requestOptionsSuccess: function _requestOptionsSuccess(resolve, reject, response) {
      if (response.success === true) {
        resolve(response.results);
      } else {
        reject("Response not valid");
      }
    },
    _rejectReason: function _rejectReason(reject, reason) {
      reject(reason);
    }
  });

  _exports.default = _default;
});