define("mvp/abilities/program", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    currentUser: Ember.inject.service("current-user"),
    store: Ember.inject.service("store"),
    // Can view sponsored program
    canView: Ember.computed('currentUser.organization.is_a_hospital', function () {
      var can = false;
      var currentUser = Ember.get(this, "currentUser"); // First check if the user belongs to a hospital

      var organization = Ember.get(currentUser, "organization");

      if (organization !== undefined) {
        // Only hospital users can view programs
        if (Ember.get(organization, "is_a_hospital")) {
          can = true;
        }
      } // If the user does not belong to a hospital,


      if (!can) {
        // Loop through the users roles
        if (currentUser.content !== null) {
          Ember.get(currentUser, "roles").forEach(function (role) {
            var role_name = Ember.get(role, "name"); // Check to see if the user is an MSL

            if (role_name === "msl") {
              can = true;
            }
          });
        }
      }

      return can;
    })
  });

  _exports.default = _default;
});