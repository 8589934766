define("mvp/abilities/organization", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    currentUser: Ember.inject.service("current-user"),
    store: Ember.inject.service("store"),
    // Can the user manage a project
    canManage: Ember.computed('currentUser.content', function () {
      var can = false; // let currentUser = get(this, "currentUser");
      // if (currentUser.content !== null ) {
      //   get(currentUser, "roles").forEach(function(role) {
      //     let role_name = get(role, "name");
      //
      //     // If we have an admin role, we can manage this organization
      //     if (role_name === "admin") {
      //       can = true;
      //     }
      //   });
      // }

      return can;
    })
  });

  _exports.default = _default;
});