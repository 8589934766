define("mvp/pods/components/ui-surveillance/adhoc/molecular/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //////////////
    // Services //
    //////////////
    currentUser: Ember.inject.service("current-user"),
    eventBus: Ember.inject.service("event-bus"),
    media: Ember.inject.service("media"),
    testGroup: undefined,
    testGroupDropdown: undefined,
    testName: undefined,
    testNameDropdown: undefined,
    testResult: undefined,
    testResultDropdown: undefined,
    only_molecular: undefined,
    ///////////////////////
    // Molecular Profile //
    ///////////////////////
    observeMolecularProfile: function () {
      var mtr;

      if (Ember.get(this, "molecularProfile.length") > 0) {
        mtr = this.molecularProfile;
      }

      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("molecular_test_results", mtr);
      });
    }.observes("molecularProfile.length"),
    /////////////////
    // Test Groups //
    /////////////////
    observeTestGroup: function () {
      this.testNameDropdown.send("reset");
      Ember.set(this, "testName", undefined);
    }.observes("testGroup"),
    testGroupUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/molecular-test-groups" + "?project_name=" + encodeURIComponent(project_name);
    }),
    testGroupEnabled: Ember.computed("filter.criteria", function () {
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    /////////////////
    // Test Name //
    /////////////////
    observeTestName: function () {
      this.testResultDropdown.send("reset");
    }.observes("testName"),
    testNameUrl: Ember.computed("filter.criteria", "testGroup", function () {
      var project_name = Ember.get(this, "filter.criteria.project");
      var test_groups = this.testGroup; // !Important - We must use encodeURIComponent for IE.

      var url = "/api/adhoc/molecular-test-names" + "?project_name=" + encodeURIComponent(project_name);

      if (test_groups !== undefined) {
        url += "&testgroup=" + encodeURIComponent(this.testGroup);
      }

      return url;
    }),
    testNameEnabled: Ember.computed("testGroup", function () {
      return this.testGroup !== undefined;
    }),
    /////////////////
    // Test Result //
    /////////////////
    testResultUrl: Ember.computed("filter.criteria", "testGroup", "testName", function () {
      var project_name = Ember.get(this, "filter.criteria.project");
      var test_group = this.testGroup;
      var test_name = this.testName; // !Important - We must use encodeURIComponent for IE.

      var url = "/api/adhoc/molecular-test-results" + "?project_name=" + encodeURIComponent(project_name);

      if (test_group !== undefined) {
        url += "&testgroup=" + encodeURIComponent(test_group);
      }

      if (test_name !== undefined) {
        url += "&testname=" + encodeURIComponent(test_name);
      }

      return url;
    }),
    testResultEnabled: Ember.computed("testGroup", function () {
      return this.testGroup !== undefined;
    }),
    ////////////////////
    // only_molecular //
    ////////////////////
    observeOnlyMolecular: function () {
      var only_molecular = this.only_molecular;
      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("has_molecular_results", only_molecular);
      });
    }.observes("only_molecular"),
    init: function init() {
      this._super.apply(this, arguments);

      this.setupInit();
      this.molecularProfile = [];
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.observeOnlyMolecular();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.cleanup();
    },
    actions: {
      "update-selected-test-group": function updateSelectedTestGroup(item) {
        Ember.set(this, "testGroup", decodeURIComponent(item));
      },
      "update-selected-test-name": function updateSelectedTestName(item) {
        Ember.set(this, "testName", decodeURIComponent(item));
      },
      "update-selected-test-result": function updateSelectedTestResult(item) {
        Ember.set(this, "testResult", decodeURIComponent(item));
      },
      "add-molecular-profile": function addMolecularProfile() {
        var molecularProfile = this.molecularProfile;
        var test_result = this.testResult;

        if (test_result === undefined) {
          test_result = "ALL";
        }

        molecularProfile.pushObject({
          include: {
            testgroup: this.testGroup,
            testname: this.testName,
            testresult: test_result
          }
        });
        this.reset();
      },
      "remove-item": function removeItem(index) {
        this.molecularProfile.removeAt(index);
      }
    },
    // Perform necessary actions when adhoc filter is reset
    resetAdhocFilter: function resetAdhocFilter() {
      Ember.set(this, "molecularProfile", []);
      this.reset();
    },
    // Reset the state of this component, usually called when the selected
    // project changes or the user clears the filter.
    reset: function reset() {
      Ember.set(this, "testGroup", undefined);
      Ember.set(this, "testName", undefined);
      Ember.set(this, "testResult", undefined);

      if (this.testGroupDropdown) {
        this.testGroupDropdown.send("reset");
      }

      if (this.testNameDropdown) {
        this.testNameDropdown.send("reset");
      }

      if (this.testResultDropdown) {
        this.testResultDropdown.send("reset");
      }
    },
    // Performs cleanup of the component after the element is destroyed
    cleanup: function cleanup() {
      // Destroy ties to the event bus
      this.eventBus.off('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.off('resetAdhocFilter', this, 'resetAdhocFilter');
    },
    setupInit: function setupInit() {
      // Subscribe to the event bus
      this.eventBus.on('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.on('resetAdhocFilter', this, 'resetAdhocFilter');
    },
    // Notify observers within this component that the filter criteria has
    // changed. This will be invoked through the global event bus.
    filterCriteriaChanged: function filterCriteriaChanged(key) {
      var criteria = Ember.get(this, "filter.criteria");

      if (key === "has_molecular_results" && criteria.has_molecular_results === undefined) {
        Ember.set(this, "only_molecular", undefined);
        Ember.$("#" + this.elementId + " .ui.checkbox.only-molecular").checkbox("uncheck");
      }

      this.notifyPropertyChange("filter.criteria");
    },
    rendered: function rendered() {
      var _self = this;

      Ember.$("#" + this.elementId + " .ui.checkbox.only-molecular").checkbox({
        onChecked: function onChecked() {
          Ember.set(_self, "only_molecular", true);
        },
        onUnchecked: function onUnchecked() {
          Ember.set(_self, "only_molecular", undefined);
        }
      });
    }
  });

  _exports.default = _default;
});