define("mvp/helpers/dashify-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dashifyString = dashifyString;
  _exports.default = void 0;

  function dashifyString(params) {
    return params[0].replace(/\s+/g, '-').toLowerCase();
  }

  var _default = Ember.Helper.helper(dashifyString);

  _exports.default = _default;
});