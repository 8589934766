define("mvp/pods/components/ui-demo-data/site/sample-details/primary-diagnosis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "value": "ABD",
    "name": "ABD - Abdominal pain"
  }, {
    "value": "BLAD",
    "name": "BLAD - Bladder"
  }, {
    "value": "BUR",
    "name": "BUR - Burn"
  }, {
    "value": "CAN",
    "name": "CAN - Cancer/Leukemia/Lymphoma"
  }, {
    "value": "CAR",
    "name": "CAR - Cardiovascular"
  }, {
    "value": "CELL",
    "name": "CELL - Cellulitis/Skin infection"
  }, {
    "value": "CF",
    "name": "CF - Cystic fibrosis"
  }, {
    "value": "COVID-19",
    "name": "COVID-19"
  }, {
    "value": "CYS",
    "name": "CYS - Cystitis"
  }, {
    "value": "DIAB",
    "name": "DIAB - Diabetes"
  }, {
    "value": "FEV",
    "name": "FEV - Fever"
  }, {
    "value": "GI",
    "name": "GI - Gastrointestinal"
  }, {
    "value": "HIV",
    "name": "HIV - HIV/AIDS"
  }, {
    "value": "INF",
    "name": "INF - Infection"
  }, {
    "value": "NEU",
    "name": "NEU - Neurologic"
  }, {
    "value": "PULM",
    "name": "PULM - Pulmonary"
  }, {
    "value": "REN",
    "name": "REN - Renal"
  }, {
    "value": "SEP",
    "name": "SEP - Sepsis"
  }, {
    "value": "STRO",
    "name": "STRO - Stroke"
  }, {
    "value": "TRA",
    "name": "TRA - Transplantation"
  }, {
    "value": "TRM",
    "name": "TRM - Trauma"
  }, {
    "value": "OTH",
    "name": "OTH - Other"
  }, {
    "value": "",
    "name": "NULL"
  }];
  _exports.default = _default;
});