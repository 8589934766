define("mvp/pods/site-management/sites/show/contacts/edit/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/sites/show/contacts/show"], function (_exports, _emberApolloClient, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var variables = {
        id: params.contact_id
      };
      var site_contact = this.apollo.watchQuery({
        query: _show.default,
        variables: variables
      }, "siteContact");
      return Ember.RSVP.hash({
        roles: this.roles(),
        statuses: this.statuses(),
        params: params,
        siteContact: site_contact,
        changeset: {}
      });
    },
    roles: function roles() {
      return ["guest", "registered", "co_investigator", "micro_contact", "pharm_contact", "site_director", "jmi"];
    },
    statuses: function statuses() {
      return ["active", "pending", "revoked"];
    }
  });

  _exports.default = _default;
});