define("mvp/pods/components/ui-surveillance/adhoc/header/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vkVWqXFS",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"i\"],[11,\"class\",\"delete icon\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n  \"],[1,[21,\"name\"],false],[0,\"\\n\"],[4,\"if\",[[27,\"not-eq\",[[23,[\"name\"]],\"Project\"],null]],null,{\"statements\":[],\"parameters\":[]},null],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"menu\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"item\"],[9],[1,[21,\"value\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/adhoc/header/label/template.hbs"
    }
  });

  _exports.default = _default;
});