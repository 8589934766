define("mvp/pods/surveillance/show/results/charts/cross-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JMeK+iT4",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"cross-tabs\",null,[[\"filter\",\"viewIsolate\",\"group1Drugs\",\"group2Drugs\",\"leftDrugSelected\",\"bottomDrugSelected\",\"data\",\"getCrossTabData\",\"synthEdges\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"view-isolate\"],null],[23,[\"group1Drugs\"]],[23,[\"group2Drugs\"]],[27,\"action\",[[22,0,[]],\"leftDrugSelected\"],null],[27,\"action\",[[22,0,[]],\"bottomDrugSelected\"],null],[23,[\"data\"]],[27,\"action\",[[22,0,[]],\"getCrossTabData\"],null],[23,[\"synthEdges\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/results/charts/cross-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});