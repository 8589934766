define("mvp/pods/surveillance/sentry/recruitment/route", ["exports", "ember-redux", "mvp/pods/surveillance/sentry/recruitment/query", "mvp/config/environment"], function (_exports, _emberRedux, _query, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RecruitRoute = Ember.Route.extend({
    apollo: Ember.inject.service(),
    model: function model() {
      return this.apollo.query({
        query: _query.default,
        variables: {
          siteCode: this.site.attributes.site_code.padStart(3, '0'),
          year: _environment.default.APP.SETTINGS.RECRUITMENT_YEAR
        }
      }, "site");
    }
  });

  var mapStateToComputed = function mapStateToComputed(state) {
    return {
      site: state.siteManagement.site
    };
  };

  var _default = (0, _emberRedux.connect)(mapStateToComputed)(RecruitRoute);

  _exports.default = _default;
});