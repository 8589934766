define("mvp/pods/site-management/sites/show/shipments/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),
    queryParams: ['page'],
    page: 1,
    pagedContent: (0, _pagedArray.default)('content', {
      page: Ember.computed.alias("parent.page"),
      totalPages: Ember.computed.alias("parent.model.pageInfo.totalPages")
    }),
    openModal: function openModal() {
      Ember.$(".ui.modal").modal({
        detachable: false,
        observeChanges: false
      }).modal("show");
    },
    actions: {
      viewDelivery: function viewDelivery(ss) {
        window.open('https://www.fedex.com/apps/fedextrack/?action=track&action=track&tracknumbers=' + ss.trackingNum, '_blank');
      },
      toggleShipmentDetails: function toggleShipmentDetails(shipment) {
        var shipment_id = shipment.id;
        var button = Ember.$(event.target);
        var row = Ember.$("#shipment-details-id-" + shipment_id);
        button.toggleClass("active");
        row.toggleClass("active");

        if (button.hasClass("active")) {
          button.html("<i class='minus icon'></i> Hide");
          row.show();
        } else {
          button.html("<i class='plus icon'></i> View");
          row.hide();
        }
      },
      toggleAllShipmentDetails: function toggleAllShipmentDetails() {
        var row = Ember.$(".shipment-details");
        row.addClass("active");

        if (Ember.$(".shipment-details:visible").length == 0) {
          Ember.$(".shipment-item-details-toggle").addClass("active").html("<i class='minus icon'></i> Hide");
          Ember.$(".shipment-details").show();
        } else {
          row.removeClass("active");
          Ember.$(".shipment-item-details-toggle").removeClass("active").html("<i class='plus icon'></i> View");
          Ember.$(".shipment-details").hide();
        }
      },
      newShipment: function newShipment() {
        this.openModal();
        this.transitionToRoute('site-management.sites.show.shipments.new');
      },
      editShipment: function editShipment(ss) {
        this.openModal();
        this.transitionToRoute('site-management.sites.show.shipments.edit', ss.id);
      }
    }
  });

  _exports.default = _default;
});