define("mvp/pods/surveillance/show/isolates/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9NgoIyBE",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ui-surveillance/isolates/index\",null,[[\"page\",\"pageChanged\",\"showLink\"],[[23,[\"page\"]],[27,\"action\",[[22,0,[]],\"pageChanged\"],null],\"surveillance.show.isolates.show\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/isolates/index/template.hbs"
    }
  });

  _exports.default = _default;
});