define("mvp/pods/components/ui-program/component-viewer/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    store: Ember.inject.service("store"),
    classNames: ["sixteen", "wide", "column"],
    loading: true,
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    }),
    updateData: function () {
      Ember.set(this, "loading", true);
      var ajax = this.ajax;
      var component = this.component;
      var data = {};

      if (this.year !== undefined) {
        data.year = this.year;
      }

      if (this.region !== undefined) {
        data.region = this.region;
      }

      if (this.site !== undefined) {
        data.site = this.site;
      }

      var _self = this;

      ajax.request("/api/program-components/" + component.id + "/counts", {
        data: data
      }).then(_self._saveGroups.bind(_self));
    }.observes("year", "region", "site"),
    init: function init() {
      this._super.apply(this, arguments);

      this.groups = [];
    },
    didInsertElement: function didInsertElement() {
      var _self = this;

      Ember.run("afterRender", function () {
        _self.updateData();
      });
    },
    _saveGroups: function _saveGroups(response) {
      Ember.set(this, "groups", response);
      Ember.set(this, "loading", false);
    }
  });

  _exports.default = _default;
});