define("mvp/pods/site-management/canisters/new/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/canisters/new/all_sites"], function (_exports, _emberApolloClient, _all_sites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var variables = {
        order: "id ASC",
        page: params.page,
        perPage: params.perPage
      };
      var sites = this.apollo.watchQuery({
        query: _all_sites.default,
        variables: variables
      }, "allSites");
      return Ember.RSVP.hash({
        sites: sites,
        changeset: {}
      });
    }
  });

  _exports.default = _default;
});