define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/all-years/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jD0Fd1yz",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"selected-year\"]],0],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"years_shown_label\"],[9],[0,\"\\n    Showing results for isolates collected between \"],[1,[21,\"firstYear\"],false],[0,\"-\"],[1,[21,\"lastYear\"],false],[0,\".\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"a\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"expand icon\"],[9],[10],[0,\"\\n    Split by year\\n  \"],[3,\"action\",[[22,0,[]],\"update-selected-year\",[23,[\"firstYear\"]],false]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"compress icon\"],[9],[10],[0,\"\\n    Rollup all years\\n  \"],[3,\"action\",[[22,0,[]],\"update-selected-year\",0,false]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/all-years/template.hbs"
    }
  });

  _exports.default = _default;
});