define("mvp/instance-initializers/global", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    // Setup so we can access it ember outside standard workflow
    window.Mvp = application;
  }

  var _default = {
    name: 'global',
    initialize: initialize
  };
  _exports.default = _default;
});