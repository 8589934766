define("mvp/pods/manage/projects/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      edit: function edit(project) {
        this.transitionToRoute("manage.projects.edit.settings", project);
      }
    }
  });

  _exports.default = _default;
});