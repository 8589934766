define("mvp/pods/components/ui-filter-result/header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service("current-user"),
    media: Ember.inject.service('media'),
    actions: {
      'view-organism-results': function viewOrganismResults(code) {
        this['view-organism-results'](code);
      }
    }
  });

  _exports.default = _default;
});