define("mvp/pods/components/d3-charts/choropleth-world/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yIf5R8xr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"ui basic icon inverted button\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"download icon\"],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"download\"]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"chartId\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/d3-charts/choropleth-world/template.hbs"
    }
  });

  _exports.default = _default;
});