define("mvp/pods/components/ui-admin/reports/component", ["exports", "mvp/config/environment", "downloadjs"], function (_exports, _environment, _downloadjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    actions: {
      "isolate-count-report": function isolateCountReport() {
        if (!this.downloadingIsolateCountReport) {
          Ember.set(this, "downloadingIsolateCountReport", true);
          var self = this;
          Ember.$.ajax({
            url: "".concat(_environment.default.APP.host, "/api/reports/isolate-count-report"),
            success: _downloadjs.default.bind(true, "text/csv", "isolate_count_report.csv"),
            complete: function complete() {
              Ember.set(self, "downloadingIsolateCountReport", false);
            },
            beforeSend: function beforeSend(request) {
              request.setRequestHeader('Schema', Ember.get(self, 'schema.header'));
              Ember.get(self, "session").authorize( // Name of the authenticator to use with ajax
              'authorizer:jwt', function (headerName, headerValue) {
                request.setRequestHeader(headerName, headerValue);
              });
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});