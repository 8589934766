define("mvp/pods/components/ui-surveillance/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jBPoFAbe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pagehead slim\"],[9],[0,\"\\n\\n  \"],[1,[27,\"ui-application/logo\",null,[[\"height\",\"logoSize\",\"cssClasses\"],[\"90\",\"small\",\"jmi-icon\"]]],false],[0,\"\\n\\n  \"],[2,\" <a href=\\\"https://www.jmilabs.com/\\\" target=\\\"_blank\\\">\\n    <img\\n      class=\\\"jmi-icon\\\"\\n      src=\\\"assets/images/JMI 4C Stacked REVERSE RGB.png\\\"\\n      height=\\\"90\\\"\\n    />\\n  </a> \"],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"header-container\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"projectOptions\",\"length\"]],1],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"pagehead-header\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"project\"],[9],[0,\"\\n          \"],[1,[21,\"ui-surveillance/project-selection\"],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"hideSurveillanceNav\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"ui-surveillance/nav\",null,[[\"selected_filter\"],[[23,[\"selected_filter\"]]]]],false],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"back-to-original\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"shouldShowBackTo\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"ui basic button backToOriginal\"],[9],[0,\"\\n            \"],[7,\"i\"],[11,\"class\",\"reply icon\"],[9],[10],[0,\"\\n            Back to original filter\\n          \"],[3,\"action\",[[22,0,[]],\"viewFilter\",[23,[\"original_filter\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/header/template.hbs"
    }
  });

  _exports.default = _default;
});