define("mvp/adapters/filter", ["exports", "mvp/adapters/application", "mvp/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    _ajax: Ember.inject.service("ajax"),
    session: Ember.inject.service('session'),
    schema: Ember.inject.service(),
    pollUntilJobCompleted: function pollUntilJobCompleted(uuid) {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        self.pollJob(uuid).then(self._resolveData.bind(self, resolve), self._rejectData.bind(self, reject));
      });
    },
    pollJob: function pollJob(uuid) {
      var url = [_environment.default.APP.host, "api/jobs", uuid].join('/');
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.run.later(function () {
          Ember.$.ajax({
            beforeSend: function beforeSend(request) {
              request.setRequestHeader('Schema', Ember.get(self, 'schema.header'));
              Ember.get(self, "session").authorize( // Name of the authenticator to use with ajax
              'authorizer:jwt', function (headerName, headerValue) {
                request.setRequestHeader(headerName, headerValue);
              });
            },
            dataType: "json",
            url: url,
            success: function success(data, response, jqXHR) {
              if (jqXHR.status === 202) {
                self.pollJob(uuid).then(self._resolveData.bind(self, resolve), self._rejectData.bind(self, reject));
              } else {
                resolve({
                  data: data,
                  response: response,
                  jqXHR: jqXHR
                });
              }
            },
            error: function error(jqXHR) {
              reject({
                jqXHR: jqXHR
              });
            }
          });
        }, 700);
      });
    },
    findRecord: function findRecord(store, type, id) {
      var url = [_environment.default.APP.host, "api/filters", id].join('/');
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          beforeSend: function beforeSend(request) {
            request.setRequestHeader('Schema', Ember.get(self, 'schema.header'));
            Ember.get(self, "session").authorize( // Name of the authenticator to use with ajax
            'authorizer:jwt', function (headerName, headerValue) {
              request.setRequestHeader(headerName, headerValue);
            });
          },
          dataType: "json",
          url: url,
          success: function success(data, response, jqXHR) {
            if (jqXHR.status === 202) {
              self.pollUntilJobCompleted(data.job_id).then(self._jobCompleted.bind(self, url, resolve), self._jobErrored.bind(self, url, resolve, reject));
            } else {
              resolve(data);
            }
          },
          error: function error(jqXHR) {
            reject(jqXHR);
          }
        });
      });
    },
    _resolveData: function _resolveData(resolve, data) {
      resolve(data);
    },
    _rejectData: function _rejectData(reject, data) {
      reject(data);
    },
    _jobCompleted: function _jobCompleted(url, resolve) {
      var self = this;

      this._ajax.request(url).then(self._resolveData.bind(self, resolve));
    },
    _jobErrored: function _jobErrored(url, resolve, reject, data) {
      var responseText = data.jqXHR.responseText;
      var self = this;

      if (responseText === "The task failed because of an error: Empty frequency distributions") {
        this._ajax.request(url).then(self._resolveData.bind(self, resolve));
      } else {
        reject(data);
      }
    }
  });

  _exports.default = _default;
});