define("mvp/pods/components/inline-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uq6GPe+/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n\"],[4,\"is-paratek\",null,null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"p-loading\"],[9],[0,\"Loading\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"typing_loader\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"object\"],[11,\"class\",\"puffer\"],[11,\"width\",\"150\"],[11,\"data\",\"assets/images/puff.svg\"],[11,\"type\",\"image/svg+xml\"],[9],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"inverted\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\"],[11,\"class\",\"logo\"],[11,\"src\",\"assets/images/jmi_logo_stacked.png\"],[11,\"style\",\"height:150px;\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"img\"],[11,\"class\",\"logo\"],[11,\"src\",\"assets/images/JMI 4C Stacked REVERSE RGB.png\"],[11,\"style\",\"height:150px;\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/inline-loader/template.hbs"
    }
  });

  _exports.default = _default;
});