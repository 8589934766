define("mvp/models/susceptibility-result", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dilution: _emberData.default.attr("number"),
    edge: _emberData.default.attr("number"),
    testing_method: _emberData.default.attr("string"),
    unit: _emberData.default.attr("string"),
    sort_ordinal: _emberData.default.attr("number"),
    drug_alias: _emberData.default.attr("string"),
    run_number: _emberData.default.attr("number"),
    position_in_run: _emberData.default.attr("number"),
    panel_name: _emberData.default.attr("string"),
    panel_lot: _emberData.default.attr("string"),
    reader_name: _emberData.default.attr("string"),
    read_date: _emberData.default.attr("date"),
    is_a_clinical_result: _emberData.default.attr("boolean"),
    created_at: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date"),
    isolate: _emberData.default.belongsTo("isolate", {
      async: true
    }),
    drug: _emberData.default.belongsTo("drug", {
      async: true
    }),
    project_drug: _emberData.default.belongsTo("drug", {
      async: true
    }),
    project: _emberData.default.belongsTo("project", {
      async: true
    }),
    interpretations: _emberData.default.hasMany("interpretation", {
      async: false
    }),
    clsiInterps: Ember.computed('interpretations', function () {
      var clsiInterps = [];
      this.interpretations.forEach(function (interp) {
        if (Ember.get(interp, 'authority') === 'CLSI' && Ember.get(interp, 'delivery_mechanism') !== 'MDR') {
          clsiInterps.addObject(interp);
        }
      });
      return clsiInterps;
    }),
    eucastInterps: Ember.computed('interpretations', function () {
      var eucastInterps = [];
      this.interpretations.forEach(function (interp) {
        if (Ember.get(interp, 'authority') === 'EUCAST' && Ember.get(interp, 'delivery_mechanism') !== 'MDR') {
          eucastInterps.addObject(interp);
        }
      });
      return eucastInterps;
    })
  });

  _exports.default = _default;
});