define("mvp/pods/surveillance/show/results/charts/finlandogram/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service('ajax'),
    model: function model() {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var filter = self.modelFor('surveillance.show.results'); // Get all the drugs present in the filter results

        var ajax = Ember.get(self, 'ajax');
        var url = 'api/filters/' + filter.id + "/drugs";
        ajax.request(url).then(self._collectDrugs.bind(self, resolve), self._rejectPromise.bind(self, reject));
      });
    },
    _collectDrugs: function _collectDrugs(resolve, payload) {
      var _this = this;

      this.store.unloadAll('project-drug');
      this.store.pushPayload(payload);
      var drugs = [];
      payload.data.forEach(function (drug) {
        drugs.pushObject(_this.store.peekRecord('project-drug', drug.id));
      });
      resolve(drugs);
    },
    _rejectPromise: function _rejectPromise(reject) {
      reject();
    }
  });

  _exports.default = _default;
});