define("mvp/pods/demo-data/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    schema: Ember.inject.service(),
    model: function model() {
      return this.store.queryRecord('project', {
        slug: 'sentry'
      });
    },
    afterModel: function afterModel(model) {
      var schema = this.schema;
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        schema.setSchema(Ember.get(model, 'version')).then(self._resolvePromise.bind(self, resolve));
      });
    },
    _resolvePromise: function _resolvePromise(resolve) {
      resolve();
    }
  });

  _exports.default = _default;
});