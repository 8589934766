define("mvp/pods/components/ui-surveillance/adhoc/header/label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["item"],
    classNameBindings: ["isProject:canHover:noHover"],
    isProject: Ember.computed('name', function () {
      return this.name !== "Project";
    }),
    click: function click() {
      if (this.isProject) {
        this.removeAction(this.key);
      }
    }
  });

  _exports.default = _default;
});