define("mvp/pods/surveillance/show/results/tables/mic-distributions/both/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      var organism_counts = this.modelFor("surveillance.show.results").get('header.organism-counts');
      this.controller.set('organismCounts', organism_counts);

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});