define("mvp/helpers/objective-compliance-status-total-cell-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectiveComplianceStatusTotalCell = objectiveComplianceStatusTotalCell;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function objectiveComplianceStatusTotalCell(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        statuses = _ref2[0];

    var total_promised = 0;
    var total_viable = 0;
    var total_pending = 0;
    var total_non_viable = 0;
    statuses.forEach(function (status) {
      var isolates_promised_count = Ember.get(status, 'isolates_promised_count');
      var isolates_viable_count = Ember.get(status, 'isolates_viable_count');
      var isolates_pending_count = Ember.get(status, 'isolates_pending_count');
      var isolates_non_viable_count = Ember.get(status, 'isolates_non_viable_count');

      if (isolates_promised_count) {
        total_promised += isolates_promised_count;
        total_viable += isolates_viable_count;
        total_pending += isolates_pending_count;
        total_non_viable += isolates_non_viable_count;
      }
    });
    var return_string = "<b>Total" + "<br>" + total_viable + "/" + total_promised + "<br>" + total_pending + "<br>" + total_non_viable + "</b>";
    return return_string;
  }

  var _default = Ember.Helper.helper(objectiveComplianceStatusTotalCell);

  _exports.default = _default;
});