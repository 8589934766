define("mvp/pods/site-management/sites/index/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'perPage', 'activeOnly', 'code', 'country', 'continent', 'name'],
    page: 1,
    perPage: 25,
    activeOnly: true,
    pagedContent: (0, _pagedArray.default)('content', {
      page: Ember.computed.alias("parent.page"),
      perPage: Ember.computed.alias("parent.perPage"),
      totalPages: Ember.computed.alias("parent.model.pageInfo.totalPages")
    }),
    actions: {
      editSite: function editSite(site) {
        this.transitionToRoute('site-management.sites.edit', site.id);
      },
      newSite: function newSite() {
        this.transitionToRoute('site-management.sites.new');
      },
      showInactive: function showInactive() {
        Ember.$(".showInactive").toggleClass("green");
        this.set('activeOnly', !this.activeOnly);
      },
      filterByString: function filterByString(string) {
        var val = event.target.value;

        if (val) {
          this.set(string, event.target.value);
        } else {
          this.set(string, null);
        }

        this.set("page", 1);
      },
      clearFilters: function clearFilters() {
        var _this = this;

        event.preventDefault();
        Object.entries({
          'activeOnly': true,
          'code': null,
          'country': null,
          'name': null,
          'continent': null
        }).forEach(function (v) {
          _this.set(v[0], v[1]);
        });
      }
    }
  });

  _exports.default = _default;
});