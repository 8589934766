define("mvp/pods/surveillance/show/publications/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var project = this.modelFor("surveillance.show");
      var url = "/api/projects/".concat(project.id, "/publications");
      var ajax = this.ajax;
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        ajax.request(url).then(self._handResponse.bind(_this, resolve), reject);
      });
    },
    _handResponse: function _handResponse(resolve, payload) {
      var store = this.store;
      resolve(store.push(payload));
    }
  });

  _exports.default = _default;
});