define("mvp/pods/surveillance/sentry/shipments/index/controller", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    demoEntryYear: Ember.computed("", function () {
      return _environment.default.APP.SETTINGS.DEMO_ENTRY_YEAR;
    }),
    actions: {
      downloadPackingSlip: function downloadPackingSlip(sample_shipment_id) {
        window.open("".concat(_environment.default.APP.sm_host, "/downloads/packing_slip?sample_shipment_id=").concat(sample_shipment_id));
      }
    }
  });

  _exports.default = _default;
});