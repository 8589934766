define("mvp/pods/components/ui-admin/sentry-compliance/bacterial-total-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    setupCell: function setupCell() {
      var sum_promised = 0;
      var sum_viable = 0;
      var sum_pending = 0;
      var sum_non_viable = 0;
      this.statuses.forEach(function (status) {
        if (Ember.get(status, 'isolates_promised_count') && Ember.get(status, 'objective_code') !== 'F') {
          sum_promised += Ember.get(status, 'isolates_promised_count');
          sum_viable += Ember.get(status, 'isolates_viable_count');
          sum_pending += Ember.get(status, 'isolates_pending_count');
          sum_non_viable += Ember.get(status, 'isolates_non_viable_count');
        }
      });
      Ember.set(this, 'total_promised', sum_promised);
      Ember.set(this, 'total_viable', sum_viable);
      Ember.set(this, 'total_pending', sum_pending);
      Ember.set(this, 'total_non_viable', sum_non_viable);
    }
  });

  _exports.default = _default;
});