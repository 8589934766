define("mvp/models/isolate-demo-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    collection_no: _emberData.default.attr("number"),
    study_year: _emberData.default.attr("number"),
    site_code: _emberData.default.attr("string"),
    objective: _emberData.default.attr("string"),
    jmi_org_code: _emberData.default.attr("string"),
    isolate_status_code: _emberData.default.attr("string"),
    demo_id: _emberData.default.attr("number"),
    line_number: _emberData.default.attr("number"),
    skip_line: _emberData.default.attr("boolean"),
    site_org_code: _emberData.default.attr("string"),
    demo_form_status_code: _emberData.default.attr("string")
  });

  _exports.default = _default;
});