define("mvp/pods/site-management/sites/show/recruitments/new/route", ["exports", "ember-apollo-client", "lodash", "mvp/pods/site-management/sites/show/recruitments/new/recruitments", "mvp/pods/site-management/sites/show/recruitments/new/site_recruitment_groups"], function (_exports, _emberApolloClient, _lodash, _recruitments, _site_recruitment_groups) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var site = this.modelFor("site-management.sites.show");
      var recruitments = this.apollo.watchQuery({
        query: _recruitments.default,
        fetchPolicy: 'cache-and-network'
      }, "recruitments");
      var srgs = this.apollo.watchQuery({
        query: _site_recruitment_groups.default
      }, "siteRecruitmentGroups");
      return Ember.RSVP.hash({
        params: params,
        siteId: site.id,
        recruitments: recruitments,
        siteRecruitmentGroups: srgs,
        changeset: {
          recruitment: {},
          objectives: {}
        }
      });
    },
    afterModel: function afterModel(model) {
      var names = _lodash.default.map(model.recruitments, "name");

      Ember.set(model, 'recruitmentNames', names);
    }
  });

  _exports.default = _default;
});