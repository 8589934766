define("mvp/pods/components/ui-legal/privacy/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      window.scrollTo(0, 0);
    }
  });

  _exports.default = _default;
});