define("mvp/pods/admin/users/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      userSaved: function userSaved() {
        this.transitionToRoute("admin.users.index");
      }
    }
  });

  _exports.default = _default;
});