define("mvp/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v3jn9JC2",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"model\",\"instance\"]],\"mvpparatek\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"link\"],[11,\"integrity\",\"\"],[11,\"rel\",\"stylesheet\"],[12,\"href\",[28,[[21,\"rootURL\"],\"assets/paratek.css\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"link\"],[11,\"integrity\",\"\"],[11,\"rel\",\"stylesheet\"],[12,\"href\",[28,[[21,\"rootURL\"],\"assets/jmi.css\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/templates/head.hbs"
    }
  });

  _exports.default = _default;
});