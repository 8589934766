define("mvp/pods/demo-data/index/route", ["exports", "ember-apollo-client", "mvp/pods/demo-data/index/query"], function (_exports, _emberApolloClient, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model() {
      return this.apollo.watchQuery({
        fetchPolicy: "cache-and-network",
        query: _query.default
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        siteObjectives: null,
        selectedSite: null,
        createError: null,
        objectiveOrganism: null
      });
    },
    afterModel: function afterModel() {
      this.transitionTo('demo-data.index.pending');
    }
  });

  _exports.default = _default;
});