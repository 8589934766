define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/delivery-mechanisms/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      'update-selected-delivery-mechanism': function updateSelectedDeliveryMechanism(delivery_mechanism) {
        // console.log(`update selected delivery mechanism ${delivery_mechanism}`);
        this['update-selected-delivery-mechanism'](delivery_mechanism);
      }
    }
  });

  _exports.default = _default;
});