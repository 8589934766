define("mvp/pods/components/ui-surveillance/filter-criteria-labels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TUKLyw1i",
    "block": "{\"symbols\":[\"fd\"],\"statements\":[[4,\"each\",[[23,[\"filterDescription\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"ui label\"],[9],[0,\"\\n    \"],[1,[22,1,[\"name\"]],false],[7,\"div\"],[11,\"class\",\"detail\"],[9],[1,[22,1,[\"value\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/filter-criteria-labels/template.hbs"
    }
  });

  _exports.default = _default;
});