define("mvp/pods/components/ui-filter-result/nav/split-results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      'split-by': function splitBy(key) {
        this['split-by'](key);
      }
    }
  });

  _exports.default = _default;
});