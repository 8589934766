define("mvp/pods/components/ui-admin/sentry-compliance/status-total-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "10RuUdQE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"b\"],[9],[0,\"\\n  \"],[1,[21,\"total_viable\"],false],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[1,[21,\"total_promised\"],false],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[1,[21,\"total_pending\"],false],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[1,[21,\"total_non_viable\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-admin/sentry-compliance/status-total-cell/template.hbs"
    }
  });

  _exports.default = _default;
});