define("mvp/pods/components/ui-admin/users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "soemzUoi",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[0,\"\\n\\n\"],[7,\"table\"],[11,\"class\",\"ui table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"collapsing\"],[9],[0,\"Email\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Actions\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"users\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n          \"],[1,[22,1,[\"email\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"ui mini labeled basic buttons\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"ui button\"],[9],[0,\"\\n              \"],[7,\"i\"],[11,\"class\",\"edit icon\"],[9],[10],[0,\"\\n              Edit details\\n            \"],[3,\"action\",[[22,0,[]],\"edit\",[22,1,[]]]],[10],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"ui button\"],[9],[0,\"\\n              \"],[7,\"i\"],[11,\"class\",\"address card icon\"],[9],[10],[0,\"\\n              Impersonate\\n            \"],[3,\"action\",[[22,0,[]],\"impersonate\",[22,1,[\"id\"]]]],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"tfoot\"],[9],[0,\"\\n    \"],[7,\"th\"],[11,\"colspan\",\"5\"],[9],[0,\"\\n      \"],[1,[27,\"page-numbers\",null,[[\"content\",\"action\"],[[23,[\"pageBinding\"]],[27,\"action\",[[22,0,[]],\"pageClicked\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-admin/users/template.hbs"
    }
  });

  _exports.default = _default;
});