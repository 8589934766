define("mvp/pods/components/ui-surveillance/heatmap-old/ammap-world-low-susceptibility-map/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupMap();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupMap();
    },
    actions: {},
    setupMap: function setupMap() {
      if (this["ammap-data"]) {
        var areas = [];
        this["ammap-data"].forEach(function (countryData) {
          areas.push({
            "id": Ember.get(countryData, "country_code"),
            "value": Ember.get(countryData, "resistant_percentage"),
            "customData": "<strong>".concat(Ember.get(countryData, "country_name"), "</strong>\n          <br>\n          ").concat(Ember.get(countryData, "resistant_percentage"), "% Resistance\n          <br>\n          ").concat(Ember.get(countryData, "isolates_tested"), " Isolates Tested\n          <br>\n          <em>").concat(Ember.get(countryData, "year"), "</em>\n          ")
          });
        });
        Ember.run.scheduleOnce('afterRender', this, function () {
          window.AmCharts.makeChart("chartdiv", {
            "type": "map",
            "theme": "light",
            "projection": "eckert5",
            "balloon": {
              "textAlign": "left"
            },
            "colorSteps": 10,
            "dataProvider": {
              "map": "worldLow",
              "areas": areas
            },
            "areasSettings": {
              "autoZoom": true,
              "balloonText": "[[customData]]"
            },
            "valueLegend": {
              "right": 10,
              "minValue": "low res",
              "maxValue": "high res"
            },
            "export": {
              "enabled": true
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});