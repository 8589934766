define("mvp/pods/components/ui-admin/users/show/component", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals Keen */
  var _default = Ember.Component.extend({
    sortedFilterActivity: Ember.computed.sort('filter-activity', 'filterActivitySorting'),
    topSortedFilterActivity: Ember.computed("sortedFilterActivity", function () {
      return this.sortedFilterActivity.slice(0, 10);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.filterActivitySorting = ['result:desc', 'criteria'];
    },
    didInsertElement: function didInsertElement() {// let client = new Keen({
      //   projectId: config.KEEN_PROJECT_ID,
      //   readKey: config.KEEN_READ_KEY
      // });
      // let _self = this;
      // Keen.ready(function(){
      //   get(_self, "loadActivity")(client, _self);
      //   get(_self, "loadFiltersActivity")(client, _self);
      //   get(_self, "loadProjectActivity")(client, _self);
      // });
    },
    actions: {
      remove: function remove() {
        var _self = this;

        Ember.$('.basic.test.modal').modal({
          detachable: false,
          onApprove: function onApprove() {
            Ember.get(_self, "user").destroyRecord();

            _self.sendAction("back");
          }
        }).modal("show");
      }
    },
    loadActivity: function loadActivity(client, self) {
      var query = new Keen.Query("count", {
        eventCollection: "page-view",
        filters: [{
          "operator": "eq",
          "property_name": "user",
          "property_value": Ember.get(self, "user.email")
        }],
        interval: "daily",
        timeframe: "this_7_days",
        timezone: "UTC"
      });
      client.draw(query, document.getElementById("page_activity_chart"), {
        library: "google",
        title: null,
        height: 200,
        chartOptions: {
          chartArea: {
            height: "80%",
            top: "5%",
            bottom: "15%"
          },
          legend: {
            position: "none"
          }
        }
      });
    },
    loadFiltersActivity: function loadFiltersActivity(client, self) {
      var query = new Keen.Query("count", {
        eventCollection: "filter-views",
        filters: [{
          "operator": "eq",
          "property_name": "user",
          "property_value": Ember.get(self, "user.email")
        }],
        groupBy: ["criteria"],
        timeframe: "this_14_days",
        timezone: "UTC"
      });
      client.run(query, function (err, res) {
        if (err) {// there was an error!
        } else {
          // do something with res.result
          Ember.set(self, "filter-activity", res.result);
        }
      });
    },
    loadProjectActivity: function loadProjectActivity(client, self) {
      var query = new Keen.Query("count", {
        eventCollection: "filter-views",
        filters: [{
          "operator": "eq",
          "property_name": "user",
          "property_value": Ember.get(self, "user.email")
        }],
        groupBy: ["project"],
        timeframe: "this_14_days",
        timezone: "UTC"
      });
      client.draw(query, document.getElementById("project_activity_chart"), {
        library: "google",
        title: null,
        height: 200,
        chartOptions: {
          chartArea: {
            height: "80%",
            top: "5%",
            bottom: "15%"
          },
          legend: {
            position: "bottom"
          }
        }
      });
    }
  });

  _exports.default = _default;
});