define("mvp/helpers/contact-initials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contactInitials = contactInitials;
  _exports.default = void 0;

  function contactInitials(params) {
    var first_name = params[0] ? params[0].charAt(0) : "";
    var last_name = params[1] ? params[1].charAt(0) : "";
    return first_name.capitalize() + last_name.capitalize();
  }

  var _default = Ember.Helper.helper(contactInitials);

  _exports.default = _default;
});