define("mvp/pods/components/ui-surveillance/index/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    media: Ember.inject.service("media"),
    classNameBindings: ['media.classNames'],
    secondsThisYear: 0,
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    }),
    basePairs: Ember.computed('secondsThisYear', function () {
      return this.secondsThisYear * 629;
    }),
    mics: Ember.computed('secondsThisYear', function () {
      return Math.round(this.secondsThisYear * 3 / 60);
    }),
    sentryYear: Ember.computed('', function () {
      var currDate = new Date();
      var startSentry = new Date(currDate.getFullYear(), "5", "1");
      var thisYear = currDate.getFullYear();

      if (startSentry > currDate) {
        thisYear -= 1;
      }

      return thisYear;
    }),
    didInsertElement: function didInsertElement() {
      this.updateSeconds();
    },
    actions: {
      projectChanged: function projectChanged(slug) {
        this.projectChanged(slug);
      }
    },
    updateSeconds: function updateSeconds() {
      if (!this.isDestroyed) {
        var dateObj = new Date();
        var dateCurrent = Math.round(dateObj.getTime() / 1000);
        var dateInit = new Date(this.sentryYear, "5", "1");
        dateInit = Math.round(dateInit.getTime() / 1000);
        Ember.set(this, 'secondsThisYear', dateCurrent - dateInit);
        Ember.run.later(this, this.updateSeconds, 1000);
      }
    }
  });

  _exports.default = _default;
});