define("mvp/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    locked: _emberData.default.attr("boolean"),
    mutex_locked: _emberData.default.attr("boolean"),
    slug: _emberData.default.attr("string"),
    version: _emberData.default.attr('string'),
    has_heatmap: _emberData.default.attr('boolean'),
    drugs: _emberData.default.hasMany("drug", {
      async: true
    }),
    project_drugs: _emberData.default.hasMany("project-drug", {
      async: true
    }),
    publications: _emberData.default.hasMany("project-publication", {
      async: true
    }),
    programs: _emberData.default.hasMany("program", {
      async: true
    }),
    download_url: _emberData.default.attr("string")
  });

  _exports.default = _default;
});