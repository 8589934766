define("mvp/pods/components/ui-admin/users/edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    email: undefined,
    user_metadata: undefined,
    app_metadata: undefined,
    loading: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var user = this.user;
      Ember.set(this, 'email', Ember.get(user, "email"));
      Ember.set(this, 'user_metadata', Ember.get(user, "user_metadata"));
      Ember.set(this, 'app_metadata', Ember.get(user, "app_metadata"));
    },
    actions: {
      "updateUser": function updateUser() {
        Ember.set(this, "error", undefined); // Show that the form is loading

        Ember.set(this, "loading", true);
        var data = {
          user: {
            user_metadata: Ember.$('textarea.user_metadata').val(),
            app_metadata: Ember.$('textarea.app_metadata').val()
          }
        };
        var url = "/api/users/" + this.user.id + "/auth0_update";

        var _self = this;

        this.ajax.put(url, {
          data: data
        }).then(_self._updateUserSuccess.bind(_self), _self._updateUserFailure.bind(_self));
      }
    },
    _updateUserSuccess: function _updateUserSuccess() {
      this.userSaved();
    },
    _updateUserFailure: function _updateUserFailure(error) {
      Ember.set(this, "error", error.errors);
      Ember.set(this, "loading", false);
    }
  });

  _exports.default = _default;
});