define("mvp/pods/components/ui-program/report-summary/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    store: Ember.inject.service("store"),
    breakpointYear: _environment.default.APP.SETTINGS.BREAKPOINT_YEAR,
    classNames: ["ui", "grid"],
    matchingFootnote: Ember.computed('', function () {
      return "Criteria as published by CLSI [".concat(this.breakpointYear, "], EUCAST [").concat(this.breakpointYear, "], and US FDA [").concat(this.breakpointYear, "]");
    }),
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    }),
    getData: function () {
      if (this.component !== undefined) {
        var ajax = this.ajax;
        var component = this.component;
        var data = {};

        if (this.year !== undefined) {
          data.year = this.year;
        }

        if (this.region !== undefined) {
          data.region = this.region;
        }

        if (this.site !== undefined) {
          data.site = this.site;
        }

        var url = "/api/program-components/" + component.id + "/filters";

        var _self = this;

        ajax.request(url, {
          data: data
        }).then(_self._getFiltersIfNotDestroyed.bind(_self));
      }
    }.observes("year", "region", "site", "component"),
    didReceiveAttrs: function didReceiveAttrs() {
      var _self = this;

      Ember.run("afterRender", function () {
        _self.getData();
      });
    },
    getFilters: function getFilters(filter_ids) {
      var ajax = this.ajax;
      var store = this.store;

      var _self = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var promises = [];
        filter_ids.forEach(function (id) {
          var url = "api/filters/" + id + "/web-table";
          promises.pushObject(ajax.request(url));
        });
        Ember.RSVP.all(promises).then(_self._saveWebTables.bind(_self, resolve, store));
      });
    },
    _getFiltersIfNotDestroyed: function _getFiltersIfNotDestroyed(res) {
      if (!this.isDestroyed) {
        this.getFilters(res);
      }
    },
    _saveWebTables: function _saveWebTables(resolve, store, requests) {
      var webTables = [];

      if (!this.isDestroyed) {
        requests.forEach(function (payload) {
          store.pushPayload(payload);
          var id = payload.data.id;
          webTables.pushObject(store.peekRecord('web-table', id));
        });
      }

      Ember.set(this, 'webTables', webTables);
      resolve();
    }
  });

  _exports.default = _default;
});