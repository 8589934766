define("mvp/pods/components/ui-surveillance/adhoc/molecular/criteria/modal/expressions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inclusion: 'include',
    alleles: undefined,
    interp: undefined,
    group: undefined,
    holdCallback: false,
    expressionGroupUrl: Ember.computed('project.slug', function () {
      var slug = Ember.get(this, 'project.slug');
      return "api/adhoc/molecular-expression-groups" + "?project=".concat(encodeURIComponent(slug));
    }),
    expressionInterpUrl: Ember.computed('project.slug', 'group', function () {
      var slug = Ember.get(this, 'project.slug');
      var group = Ember.get(this, 'group');
      return "api/adhoc/molecular-expression-interps" + "?project=".concat(encodeURIComponent(slug)) + "&test_group=".concat(encodeURIComponent(group));
    }),
    expressionAlleleUrl: Ember.computed('project.slug', 'group', function () {
      var slug = Ember.get(this, 'project.slug');
      var group = Ember.get(this, 'group');
      return "api/adhoc/molecular-expression-alleles" + "?project=".concat(encodeURIComponent(slug)) + "&test_group=".concat(encodeURIComponent(group));
    }),
    groupSelected: Ember.computed('group', function () {
      var group = Ember.get(this, 'group');
      return group !== undefined && group !== '';
    }),
    observeProfile: function () {
      var criteria = {};
      var group = Ember.get(this, 'group');
      var alleles = Ember.get(this, 'alleles');

      if (group !== undefined && group !== '') {
        criteria['expressions'] = {
          test_group: {
            include: [group]
          }
        };

        if (alleles !== undefined && alleles !== '') {
          criteria['expressions']['test_name'] = {
            include: alleles
          };
        }
      } else {
        criteria['expressions'] = {};
      }

      var interp = Ember.get(this, 'interp');

      if (interp !== undefined && interp !== '') {
        criteria['interpretations'] = {
          include: [interp]
        };
      }

      Ember.get(this, 'add-profile')(Ember.get(this, 'inclusion'), criteria);
    }.observes('inclusion', 'group', 'interp', 'alleles.length'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._setDefaultValues();
    },
    didInsertElement: function didInsertElement() {
      this._setupUI();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._setDefaultValues();

      this._setupUI();
    },
    actions: {
      'update-selected-expression-group': function updateSelectedExpressionGroup(group) {
        Ember.set(this, 'group', decodeURIComponent(group));

        this._resetDependentDropdowns();
      },
      'update-selected-expression-interp': function updateSelectedExpressionInterp(interp) {
        if (!Ember.get(this, 'holdCallback')) {
          Ember.set(this, 'holdCallback', true);
          Ember.set(this, 'interp', decodeURIComponent(interp));
          Ember.set(this, 'alleles', undefined);
          Ember.get(this, 'expressionAlleleDropdown').send('set-selected', '');
          var self = this;
          Ember.run.later(function () {
            Ember.set(self, 'holdCallback', false);
          }, 250);
        }
      },
      'update-selected-expression-alleles': function updateSelectedExpressionAlleles(values) {
        if (!Ember.get(this, 'holdCallback')) {
          Ember.set(this, 'holdCallback', true);
          var split_values;

          if (values !== "") {
            split_values = values.split(",").map(function (item) {
              return decodeURIComponent(item);
            });
          } else {
            split_values = [];
          }

          Ember.set(this, 'alleles', split_values);
          Ember.set(this, 'interp', undefined);
          Ember.get(this, 'expressionInterpDropdown').send('set-selected', '');
          var self = this;
          Ember.run.later(function () {
            Ember.set(self, 'holdCallback', false);
          }, 250);
        }
      }
    },
    _setDefaultValues: function _setDefaultValues() {
      Ember.set(this, 'inclusion', 'include');
      Ember.set(this, 'alleles', undefined);
      Ember.set(this, 'group', undefined);
      Ember.set(this, 'interp', undefined);
    },
    _setupUI: function _setupUI() {
      var _this = this;

      var self = this; // Handle when the inclusion checkbox is checked

      Ember.$("#".concat(this.elementId, " .inclusion .checkbox")).checkbox({
        onChecked: function onChecked() {
          Ember.set(self, 'inclusion', Ember.$(this).data('inclusion'));
        }
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$("#".concat(_this.elementId, " .include.checkbox")).checkbox('check');

        if (Ember.get(_this, 'expressionGroupDropdown') !== undefined) {
          Ember.get(_this, 'expressionGroupDropdown').send('set-selected', '');
        }

        if (Ember.get(_this, 'expressionAlleleDropdown') !== undefined) {
          Ember.get(_this, 'expressionAlleleDropdown').send('set-selected', '');
        }

        if (Ember.get(_this, 'expressionInterpDropdown') !== undefined) {
          Ember.get(_this, 'expressionInterpDropdown').send('set-selected', '');
        }
      });
    },
    _resetDependentDropdowns: function _resetDependentDropdowns() {
      Ember.get(this, 'expressionAlleleDropdown').send('reset');
      Ember.get(this, 'expressionInterpDropdown').send('reset');
    }
  });

  _exports.default = _default;
});