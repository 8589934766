define("mvp/pods/components/ui-surveillance/adhoc/header/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    authorization: Ember.inject.service(),
    eventBus: Ember.inject.service("event-bus"),
    media: Ember.inject.service(),
    classNames: ['four', 'wide', 'column'],
    classNameBindings: ['media.classNames'],
    projectDropdown: undefined,
    availableProjects: undefined,
    shouldShowProjectDropdown: undefined,
    filter: undefined,
    // Holds the count of the number of isolates within the scope of the currently
    // selected filter criteria. Users can only submit this filter if the count is
    // above zero.
    count: 0,
    isCounting: true,
    beginCounting: false,
    canClearFilter: Ember.computed("filter.criteria", function () {
      if (Ember.get(this, "filter.criteria") !== undefined) {
        return Object.keys(Ember.get(this, "filter.criteria")).length > 1;
      } else {
        return false;
      }
    }),
    // Determines if a valid filter is selected for the current criteria
    filterSelected: Ember.computed("filter.criteria", function () {
      return Ember.get(this, "filter.criteria") !== {};
    }),
    // Describe the current filter in a human friendly way.
    filterDescription: Ember.computed("filter.criteria", function () {
      var description_parts = [];
      var criteria = Ember.get(this, "filter.criteria");
      var self = this; // For each key, value pair within the criteria produce the
      // 'description_part' corresponding to that key value

      Ember.$.each(criteria, function (key, value) {
        switch (key) {
          case "project":
            description_parts.pushObject({
              removeAction: "removeAction",
              key: "project",
              name: "Project",
              value: value
            });
            break;

          case "genus":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "genus",
                name: "Genus",
                value: self.toSentence(value.include.sort())
              });
            } else {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "genus",
                name: "Genus exclude",
                value: self.toSentence(value.exclude.sort())
              });
            }

            break;

          case "group":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "group",
                name: "Group",
                value: self.toSentence(value.include.sort())
              });
            } else {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "group",
                name: "Group exclude",
                value: self.toSentence(value.exclude.sort())
              });
            }

            break;

          case "organism":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "organism",
                name: "Organisms",
                value: self.toSentence(value.include.sort())
              });
            } else {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "organism",
                name: "Organisms exclude",
                value: self.toSentence(value.exclude.sort())
              });
            }

            break;

          case "accession_number":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "accession_number",
                name: "Accession Number",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "organism_code":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "organism_code",
                name: "Organism Code",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "year":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "year",
                name: "Year",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "continent":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "continent",
                name: "Geographic Regions",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "country":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "country",
                name: "Countries",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "us_census_region":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "us_census_region",
                name: "US Census Regions",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "european_region":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "european_region",
                name: "European Regions",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "site":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "site",
                name: "Site Codes",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "core_sites":
            if (value.include.length === 2) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "core_sites",
                name: "Core and non-Core Sites",
                value: ""
              });
            } else if (value.include[0] === true) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "core_sites",
                name: "Core Sites",
                value: ""
              });
            } else if (value.include[0] === false) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "core_sites",
                name: "non-Core Sites",
                value: ""
              });
            }

            break;

          case "client_category_value":
            if (value.include !== undefined) {
              var category_parts = {};
              value.include.forEach(function (item) {
                Ember.$.each(JSON.parse(item), function (category, value) {
                  if (category_parts[category] === undefined) {
                    category_parts[category] = [];
                  }

                  category_parts[category].pushObject(value);
                });
              });
              Object.keys(category_parts).forEach(function (item) {
                description_parts.pushObject({
                  removeAction: "removeClientCategoryValue",
                  key: item,
                  name: item,
                  value: self.toSentence(category_parts[item].sort())
                });
              });
            }

            break;

          case "client_infection_type":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "client_infection_type",
                name: "Infection Types",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "service":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "service",
                name: "Medical Service",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "client_infection_source":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "client_infection_source",
                name: "Infection Sources",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "nosocomial":
            if (value.include !== undefined) {
              if (value.include.length === 1) {
                if (value.include[0] === "Nosocomial") {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "nosocomial",
                    name: "Nosocomial",
                    value: ""
                  });
                } else {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "nosocomial",
                    name: "Community-acquired",
                    value: ""
                  });
                }
              } else {
                description_parts.pushObject({
                  removeAction: "removeAction",
                  key: "nosocomial",
                  name: "Nosocomial and Community-acquired",
                  value: ""
                });
              }
            }

            break;

          case "gender":
            if (value.include !== undefined) {
              var readable_value = value.include.map(function (val) {
                if (val === 'm') {
                  return "Male";
                } else {
                  return "Female";
                }
              });
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "gender",
                name: "Gender",
                value: self.toSentence(readable_value.sort())
              });
            }

            break;

          case "diagnosis":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "diagnosis",
                name: "Diagnoses",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "icu":
            if (value.include !== undefined) {
              if (value.include.length === 1) {
                if (value.include[0] === "true") {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "icu",
                    name: "ICU",
                    value: ""
                  });
                } else {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "icu",
                    name: "non-ICU",
                    value: ""
                  });
                }
              } else {
                description_parts.pushObject({
                  removeAction: "removeAction",
                  key: "icu",
                  name: "ICU and non-ICU",
                  value: ""
                });
              }
            }

            break;

          case "vap":
            if (value.include !== undefined) {
              if (value.include.length === 1) {
                if (value.include[0] === "true") {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "vap",
                    name: "VAP",
                    value: ""
                  });
                } else {
                  description_parts.pushObject({
                    removeAction: "removeAction",
                    key: "vap",
                    name: "non-VAP",
                    value: ""
                  });
                }
              } else {
                description_parts.pushObject({
                  removeAction: "removeAction",
                  key: "vap",
                  name: "VAP and non-VAP",
                  value: ""
                });
              }
            }

            break;

          case "objective":
            if (value.include !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "objective",
                name: "Objectives",
                value: self.toSentence(value.include.sort())
              });
            }

            break;

          case "has_molecular_results":
            if (value !== undefined) {
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "has_molecular_results",
                name: "Only isolates with molecular test results",
                value: ""
              });
            }

            break;

          case "age_range":
            if (value.include !== undefined) {
              var minAge = value.include.min;
              var maxAge = value.include.max;
              var human_readable;

              if (minAge !== "" && maxAge !== "") {
                human_readable = "between " + minAge + " and " + maxAge + " years old";
              } else if (minAge !== "") {
                human_readable = "greater than or equal to " + minAge + " years old";
              } else {
                human_readable = "less than or equal to " + maxAge + " years old";
              }

              description_parts.pushObject({
                removeAction: "removeAction",
                key: "age_range",
                name: "Age",
                value: human_readable
              });
            }

            break;

          case "new_molecular_test_results":
            description_parts.pushObject({
              removeAction: "removeAction",
              key: "new_molecular_test_results",
              name: "Molecular Profile",
              value: "custom profile selected"
            });
            break;

          case "prevalence":
            description_parts.pushObject({
              removeAction: "removeAction",
              key: "prevalence",
              name: value === true || value === 'true' ? 'Prevalence Mode' : '',
              value: ""
            });
            break;

          case "reacts_like_to":
            if (value !== undefined) {
              // let parts = [];
              //
              // value.forEach(function(rlt) {
              //   let interps = rlt["interpretations"].map(function(interp) {
              //     switch(interp) {
              //       case "S":
              //         return "Susceptible";
              //       case "I":
              //         return "Intermediate";
              //       case "R":
              //         return "Resistant";
              //       case "NS":
              //         return "Non-Susceptible";
              //     }
              //   });
              //   let human_readable = self.toSentence(interps).replace("and", "or");
              //   let drugs = self.toSentence(rlt["drug"]).replace("and", "or");
              //   human_readable += " to " + drugs;
              //   if (rlt["delivery_mechanism"] !== "") {
              //     human_readable += " when delivered via " + rlt["delivery_mechanism"];
              //   }
              //   if (rlt["infection_type"] !== "") {
              //     human_readable += " for " + rlt["infection_type"];
              //   }
              //   human_readable += " when interpreted by ";
              //   human_readable += rlt["authority"] + " " + rlt["publication"];
              //   parts.addObject(human_readable);
              // });
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "reacts_like_to",
                name: "Resistance Profiles",
                value: value.length + " selected"
              });
            }

            break;

          case "custom_mic_profile":
            if (value !== undefined) {
              // let parts = [];
              // let exists_drugs = [];
              //
              // value.forEach(function(cmp) {
              //   let comp;
              //   switch(cmp["comparison"] ) {
              //     case "<":
              //       comp = "less than";
              //       break;
              //     case "≤":
              //       comp = "less than or equal to";
              //       break;
              //     case "=":
              //       comp = "equal to";
              //       break;
              //     case "≠":
              //       comp = "not equal to";
              //       break;
              //     case "≥":
              //       comp = "greater than or equal to";
              //       break;
              //     case ">":
              //       comp = "greater than";
              //       break;
              //   }
              //   if (cmp["dilution"] !== undefined) {
              //     let human_readable = "";
              //     let drugs = self.toSentence(cmp["drug"]).replace("and", "or");
              //     human_readable +=  "MIC is " + comp + " " + cmp["dilution"] + " µg/mL";
              //     human_readable += " when tested against " + drugs;
              //     parts.addObject(human_readable);
              //   } else {
              //     exists_drugs.pushObject(cmp["drug"]);
              //   }
              // });
              //
              //
              // if (exists_drugs.length > 0 ) {
              //   let human_readable = "";
              //   human_readable += "Isolates tested against " + self.toSentence(exists_drugs);
              //   parts.addObject(human_readable);
              // }
              description_parts.pushObject({
                removeAction: "removeAction",
                key: "custom_mic_profile",
                name: "Custom MIC Profiles",
                value: value.length + " selected"
              });
            }

            break;
        }
      }); // Order the parts, join with comma, and output

      return description_parts;
    }),
    // Observe changes on filter criteria and perform necessary updates
    observeFilterCriteria: function () {
      if (this.beginCounting) {
        Ember.run.debounce(this, this.updateFilterCount, 100); // this.updateFilterCount();
      }
    }.observes("filter.criteria"),
    // }.observes("filter.criteria", "filter.include_drugs.length"),
    canPressButton: Ember.computed("count", "authorization.is_jmi_user", "filter.criteria", function () {
      if (Ember.get(this, "authorization.is_jmi_user")) {
        return this.count > 0;
      } else {
        var criteria = Ember.get(this, "filter.criteria");
        return criteria !== undefined && (criteria.genus !== undefined || criteria.group !== undefined) && this.count > 0;
      }
    }),
    // Adds in commas to the count to make it a little more human readable.
    countPretty: Ember.computed("count", function () {
      var count = this.count; // Regex replacement to string

      return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.listen();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setup();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setTimerToDebounceCounting();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.cleanup();
    },
    actions: {
      "removeAction": function removeAction(key) {
        if (key === "project") {
          this.send("clear-filter");
        } else {
          this["update-filter-criteria"](key, undefined);
        }
      },
      "removeClientCategoryValue": function removeClientCategoryValue(key) {
        var ccv = Ember.get(this, 'filter.criteria.client_category_value')['include'];

        if (ccv !== undefined) {
          Ember.$.extend(true, [], ccv).forEach(function (item) {
            var category = Object.keys(JSON.parse(item))[0];

            if (category === key) {
              ccv.removeObject(item);
            }
          });

          if (ccv.length > 0) {
            this["update-filter-criteria"]('client_category_value', {
              include: ccv
            });
          } else {
            this["update-filter-criteria"]('client_category_value', undefined);
          }
        }
      },
      "clear-filter": function clearFilter() {
        if (Ember.get(this, "availableProjects.length") === 1) {
          var project_name = this.availableProjects.objectAt(0);
          Ember.set(this, "filter.criteria", {
            "project": project_name
          });
        } else {
          var project = Ember.get(this, "filter.criteria.project");
          Ember.set(this, "filter.criteria", {
            project: project
          });
          Ember.$("#" + Ember.get(this, "projectDropdown.elementId")).dropdown("clear");
        }

        this.eventBus.trigger("resetAdhocFilter");
      },
      "filter-selected": function filterSelected() {
        this["filter-selected"](this.filter);
      }
    },
    filterCriteriaChanged: function filterCriteriaChanged() {
      this.notifyPropertyChange("filter.criteria");
    },
    // Convert an array to a sentence
    toSentence: function toSentence(array) {
      var arr = array.slice(0);
      var lastWord = "";

      if (arr.length > 1) {
        lastWord = " and " + arr.pop();
      }

      return arr.join(", ") + lastWord;
    },
    updateFilterCount: function updateFilterCount() {
      var ajax = this.ajax;
      var self = this;
      var criteria = Ember.get(this, "filter.criteria");
      var include_drugs = Ember.get(this, "filter.include_drugs");
      var url = "/api/adhoc/count";

      if (criteria !== undefined && criteria.project !== undefined) {
        Ember.set(this, "isCounting", true);
        ajax.put(url, {
          data: {
            criteria: criteria,
            include_drugs: include_drugs
          }
        }).then(self._countUpdater.bind(self));
      } else {
        if (!this.isDestroyed) {
          Ember.set(this, "count", 0);
        }
      }
    },
    listen: function listen() {
      this.eventBus.on('filterCriteriaChanged', this, 'filterCriteriaChanged');
    },
    cleanup: function cleanup() {
      this.eventBus.off('filterCriteriaChanged', this, 'filterCriteriaChanged');
    },
    setup: function setup() {
      var availableProjectsCount = Ember.get(this, "availableProjects.length"); // Determine if we should show the project selection dropdown.

      Ember.set(this, "shouldShowProjectDropdown", availableProjectsCount > 1);
    },
    setTimerToDebounceCounting: function setTimerToDebounceCounting() {
      // Debounce 250ms before we start counting
      // to give criteria time to properly set up
      Ember.run.later(this, function () {
        if (!this.isDestroyed) {
          Ember.set(this, 'beginCounting', true);
          this.observeFilterCriteria();
        }
      }, 250);
    },
    _countUpdater: function _countUpdater(count) {
      if (!this.isDestroyed) {
        Ember.set(this, "isCounting", false);
        Ember.set(this, "count", count);
      }
    }
  });

  _exports.default = _default;
});