define("mvp/pods/site-management/objectives/new/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/objectives/new/recruitments"], function (_exports, _emberApolloClient, _recruitments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var recruitments = this.apollo.watchQuery({
        query: _recruitments.default
      }, "recruitments");
      return Ember.RSVP.hash({
        recruitments: recruitments,
        params: params,
        changeset: {}
      });
    }
  });

  _exports.default = _default;
});