define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/all-controls/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      'update-selected-map-type': function updateSelectedMapType(map_type) {
        // console.log(`update selected map type ${map_type}`);
        this['update-selected-map-type'](map_type);
      },
      'update-selected-pathogen': function updateSelectedPathogen(pathogen) {
        // console.log(`update selected pathogen ${pathogen}`);
        Ember.set(this, 'selected-authority', "");
        Ember.set(this, 'selected-delivery-mechanism', "");
        Ember.set(this, 'selected-infection-type', "");
        this['update-selected-pathogen'](pathogen);
      },
      'update-selected-antibiotic': function updateSelectedAntibiotic(antibiotic) {
        // console.log(`update selected antibiotic ${antibiotic}`);
        Ember.set(this, 'selected-authority', "");
        Ember.set(this, 'selected-delivery-mechanism', "");
        Ember.set(this, 'selected-infection-type', "");
        this['update-selected-antibiotic'](antibiotic);
      },
      'update-selected-authority': function updateSelectedAuthority(authority) {
        // console.log(`update selected authority ${authority}`);
        Ember.set(this, 'selected-delivery-mechanism', "");
        Ember.set(this, 'selected-infection-type', "");
        this['update-selected-authority'](authority);
      },
      'update-selected-delivery-mechanism': function updateSelectedDeliveryMechanism(delivery_mechanism) {
        // console.log(`update selected delivery mechanism ${delivery_mechanism}`);
        Ember.set(this, 'selected-infection-type', "");
        this['update-selected-delivery-mechanism'](delivery_mechanism);
      },
      'update-selected-infection-type': function updateSelectedInfectionType(infection_type) {
        // console.log(`update selected infection type ${infection_type}`);
        this['update-selected-infection-type'](infection_type);
      },
      'update-selected-year': function updateSelectedYear(year, playing) {
        //console.log(`update selected year ${year}`);
        this['update-selected-year'](year, playing);
      }
    }
  });

  _exports.default = _default;
});