define("mvp/pods/surveillance/sentry/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ugXjeNvo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[11,\"src\",\"assets/images/logo_white.png\"],[11,\"height\",\"90px\"],[9],[10],[0,\"\\n\\n\"],[0,\"\\n\"],[1,[27,\"ui-sentry/hospital-recruitment/banner\",null,[[\"hospital\",\"recruit\"],[[23,[\"currentUser\",\"organization\"]],[27,\"action\",[[22,0,[]],\"recruit\"],null]]]],false],[0,\"\\n\\n\"],[1,[27,\"ui-sentry/breakdown-by-objective\",null,[[\"selected-year\",\"years\",\"objectives\",\"change-year\"],[[23,[\"year\"]],[23,[\"model\",\"years\"]],[23,[\"objectives\"]],[27,\"action\",[[22,0,[]],\"change-year\"],null]]]],false],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/sentry/index/template.hbs"
    }
  });

  _exports.default = _default;
});