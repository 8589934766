define("mvp/pods/components/is-paratek/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    INSTANCE: _environment.default.EmberENV.INSTANCE
  });

  _exports.default = _default;
});