define("mvp/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "mvp/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),
    currentUser: Ember.inject.service('current-user'),
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    jwt: Ember.inject.service('jwt'),
    headData: Ember.inject.service(),
    beforeModel: function beforeModel() {
      Ember.set(this, 'headData.instance', _environment.default.EmberENV.INSTANCE);
      sessionStorage.clear();
      return this._populateJWT();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._populateJWT().then(this._goToGatekeeper.bind(this));
    },
    _goToGatekeeper: function _goToGatekeeper() {
      this.transitionTo('gatekeeper');
    },
    _populateJWT: function _populateJWT() {
      return this.jwt.populate();
    }
  });

  _exports.default = _default;
});