define("mvp/pods/surveillance/sentry/shipments/index/route", ["exports", "ember-apollo-client", "mvp/pods/surveillance/sentry/shipments/index/query", "ember-redux", "mvp/config/environment"], function (_exports, _emberApolloClient, _query, _emberRedux, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DemoRoute = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model() {
      return this.apollo.watchQuery({
        fetchPolicy: "cache-and-network",
        query: _query.default,
        variables: {
          siteCode: this.site.attributes.site_code.padStart(3, "0"),
          year: _environment.default.APP.SETTINGS.DEMO_ENTRY_YEAR
        }
      }, "site");
    }
  });

  var mapStateToComputed = function mapStateToComputed(state) {
    return {
      site: state.siteManagement.site
    };
  };

  var _default = (0, _emberRedux.connect)(mapStateToComputed)(DemoRoute);

  _exports.default = _default;
});