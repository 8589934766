define("mvp/pods/site-management/sites/show/objectives/edit/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/sites/show/objectives/show"], function (_exports, _emberApolloClient, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var variables = {
        id: params.objective_id
      };
      var site_objective = this.apollo.watchQuery({
        query: _show.default,
        variables: variables
      }, "siteObjective");
      return Ember.RSVP.hash({
        params: params,
        siteObjective: site_objective,
        changeset: {}
      });
    }
  });

  _exports.default = _default;
});