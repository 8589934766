define("mvp/pods/surveillance/show/results/tables/scattergram/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/0X95MLj",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ui-filter-result/scattergram\",null,[[\"leftDrugs\",\"bottomDrugs\",\"drugs\",\"filter\",\"view-isolate\",\"updateLeftDrugs\",\"updateBottomDrugs\"],[[23,[\"leftDrugs\"]],[23,[\"bottomDrugs\"]],[23,[\"model\"]],[23,[\"filter\"]],[27,\"action\",[[22,0,[]],\"view-isolate\"],null],[27,\"action\",[[22,0,[]],\"updateLeftDrugs\"],null],[27,\"action\",[[22,0,[]],\"updateBottomDrugs\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/results/tables/scattergram/template.hbs"
    }
  });

  _exports.default = _default;
});