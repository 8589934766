define("mvp/pods/components/ui-application/logo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/I1kA1dw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"is-paratek\",null,null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"href\",\"\"],[11,\"target\",\"_blank\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"style\",\"width:210px;\"],[11,\"class\",\"jmi-icon\"],[11,\"alt\",\"paratek logo\"],[11,\"src\",\"assets/images/paratek-white-transparent.png\"],[9],[10],[0,\"\\n   \"],[7,\"p\"],[11,\"class\",\"keystone\"],[9],[0,\"KEYSTONE\"],[7,\"sup\"],[11,\"class\",\"keystone\"],[9],[0,\"™\"],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"href\",\"https://www.jmilabs.com/\"],[11,\"target\",\"_blank\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"jmi-icon\"],[11,\"src\",\"assets/images/JMI 4C Stacked REVERSE RGB.png\"],[11,\"style\",\"height:90px;\"],[11,\"alt\",\"JMI logo\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-application/logo/template.hbs"
    }
  });

  _exports.default = _default;
});