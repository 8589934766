define("mvp/pods/admin/sentry/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["year", "continent", "sites"],
    year: "2017",
    continent: "North America",
    sites: "core",
    show_nv_links: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.objective_list = ['A', 'B', 'C', 'D', 'E', 'Q', 'X', 'F'];
    },
    observeYearSelect: function () {
      var _this = this;

      Ember.run.debounce(this, function () {
        var year = _this['year-select'];

        if (year.length > 0) {
          _this.resetModalParams();

          Ember.set(_this, 'year', year);
        }
      }, 250);
    }.observes('year-select'),
    observeContinentSelect: function () {
      var _this2 = this;

      Ember.run.debounce(this, function () {
        var continent = _this2['continent-select'];

        if (continent.length > 0) {
          _this2.resetModalParams();

          Ember.set(_this2, 'continent', continent);
          Ember.set(_this2, 'sites', "core");
        }
      }, 250);
    }.observes('continent-select'),
    observeSitesSelect: function () {
      var _this3 = this;

      Ember.run.debounce(this, function () {
        var sites = _this3['sites-select'];

        if (sites.length > 0) {
          _this3.resetModalParams();

          Ember.set(_this3, 'sites', sites);
        }
      }, 250);
    }.observes('sites-select'),
    actions: {
      'show-non-viable-modal': function showNonViableModal(site, objective) {
        Ember.set(this, "modal-site", site);
        Ember.set(this, "modal-objective", objective);
        var year = this.year;
        var site_code = Ember.get(site, 'site_code');
        var objective_code = objective;
        var self = this;
        self.store.query('isolate-demo-detail', {
          year: year,
          site_code: site_code,
          objective: objective_code
        }).then(self._showModal.bind(self));
      },
      'show-demo-page': function showDemoPage(isolate_demo_detail) {
        Ember.$(".ui.modal").modal({
          detachable: false,
          observeChanges: true
        }).modal("hide");
        this.transitionToRoute('demo-data.show', Ember.get(isolate_demo_detail, 'demo_id'));
      }
    },
    resetModalParams: function resetModalParams() {
      Ember.set(this, "modal-site", undefined);
      Ember.set(this, "modal-objective", undefined);
      Ember.set(this, "modal-isolate-demo-details", undefined);
    },
    _showModal: function _showModal(results) {
      Ember.set(this, "modal-isolate-demo-details", results);
      Ember.$(".ui.modal").modal({
        detachable: false,
        observeChanges: true
      }).modal("show");
    }
  });

  _exports.default = _default;
});