define("mvp/pods/components/ui-admin/sentry-containers/shipped-containers-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    actions: {},
    setupCell: function setupCell() {
      var shipped_containers_array = [];
      Ember.get(this.organization, 'sentry_site_containers').forEach(function (container) {
        if (Ember.get(container, 'date_received') === null) {
          shipped_containers_array.push(container);
        }
      });
      shipped_containers_array = shipped_containers_array.sort(function (a, b) {
        var nameA = Ember.get(a, 'item_id').toUpperCase();
        var nameB = Ember.get(b, 'item_id').toUpperCase();

        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      Ember.set(this, 'shipped_containers', shipped_containers_array);
    }
  });

  _exports.default = _default;
});