define("mvp/helpers/is-not-zero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNotZero = isNotZero;
  _exports.default = void 0;

  function isNotZero(params
  /*, hash*/
  ) {
    return Number(params) !== 0;
  }

  var _default = Ember.Helper.helper(isNotZero);

  _exports.default = _default;
});