define("mvp/helpers/decode-uri", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decodeUri = decodeUri;
  _exports.default = void 0;

  function decodeUri(params
  /*, hash*/
  ) {
    return encodeURIComponent(params[0]);
  }

  var _default = Ember.Helper.helper(decodeUri);

  _exports.default = _default;
});