define("mvp/pods/surveillance/show/results/prevalence/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sh2J1fqL",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[7,\"table\"],[11,\"class\",\"ui celled striped small selectable table unstackable\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Level 1\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Genus\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Organism\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Frequency\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"rows\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[9],[1,[22,1,[\"level_1\"]],false],[10],[0,\"\\n        \"],[7,\"td\"],[9],[1,[22,1,[\"genus\"]],false],[10],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"organism\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,1,[\"organism\"]],false],[0,\" (\"],[1,[22,1,[\"organism_code\"]],false],[0,\")\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n        \"],[7,\"td\"],[9],[1,[22,1,[\"frequency\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/results/prevalence/template.hbs"
    }
  });

  _exports.default = _default;
});