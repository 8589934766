define("mvp/services/apollo", ["exports", "ember-apollo-client/services/apollo", "apollo-link-context", "apollo-link-error"], function (_exports, _apollo, _apolloLinkContext, _apolloLinkError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _apollo.default.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    clientOptions: Ember.computed(function () {
      return {
        link: this.link,
        cache: this.cache
      };
    }),
    link: Ember.computed(function () {
      var _this = this;

      var httpLink = this._super.apply(this, arguments);

      var authLink = (0, _apolloLinkContext.setContext)(function (request, context) {
        var _this$get = _this.get('session.data.authenticated'),
            token = _this$get.token;

        var headers = {};

        if (token) {
          headers["Authorization"] = "Bearer ".concat(token);
        }

        return {
          headers: headers
        };
      });
      var resetToken = (0, _apolloLinkError.onError)(function (_ref) {
        var graphQLErrors = _ref.graphQLErrors,
            networkError = _ref.networkError;

        if (networkError && networkError.statusCode === 401) {
          // remove cached token on 401 from the server
          _this.session.invalidate();

          _this.router.replaceWith('login');
        } else if (graphQLErrors) {
          var messages = "";
          graphQLErrors.forEach(function (el) {
            messages += el.message + "\n";
          });
          alert(messages);
        }
      });
      var flowLink = authLink.concat(resetToken);
      return flowLink.concat(httpLink);
    })
  });

  _exports.default = _default;
});