define("mvp/services/poller", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _ajax: Ember.inject.service("ajax"),
    session: Ember.inject.service('session'),
    requestWithPoller: function requestWithPoller(url) {
      var _self = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          beforeSend: function beforeSend(request) {
            Ember.get(_self, "session").authorize( // Name of the authenticator to use with ajax
            'authorizer:jwt', function (headerName, headerValue) {
              request.setRequestHeader(headerName, headerValue);
            });
          },
          dataType: "json",
          url: url,
          success: function success(data, response, jqXHR) {
            if (jqXHR.status === 202) {
              _self.pollUntilJobCompleted(data.job_id).then(_self._requestAndResolve.bind(_self, resolve, url), _self._rejectViaRun.bind(_self, reject));
            } else {
              Ember.run(null, resolve, data);
            }
          },
          error: function error(jqXHR) {
            Ember.run(null, reject, jqXHR);
          }
        });
      });
    },
    pollJob: function pollJob(uuid) {
      var url = [_environment.default.APP.host, "api/jobs", uuid].join('/');

      var _self = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.run.later(function () {
          Ember.$.ajax({
            beforeSend: function beforeSend(request) {
              Ember.get(_self, "session").authorize( // Name of the authenticator to use with ajax
              'authorizer:jwt', function (headerName, headerValue) {
                request.setRequestHeader(headerName, headerValue);
              });
            },
            dataType: "json",
            url: url,
            success: function success(data, response, jqXHR) {
              if (jqXHR.status === 202) {
                _self.pollJob(uuid).then(_self._resolvePromise.bind(_self, resolve), _self._rejectPromise.bind(_self, reject));
              } else {
                resolve({
                  data: data,
                  response: response,
                  jqXHR: jqXHR
                });
              }
            },
            error: function error(jqXHR) {
              reject({
                jqXHR: jqXHR
              });
              alert(jqXHR.statusText);
            }
          });
        }, 700);
      });
    },
    pollUntilJobCompleted: function pollUntilJobCompleted(uuid) {
      var _self = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _self.pollJob(uuid).then(_self._resolvePromise.bind(_self, resolve), _self._rejectPromise.bind(_self, reject));
      });
    },
    _resolvePromise: function _resolvePromise(resolve, data) {
      resolve(data);
    },
    _rejectPromise: function _rejectPromise(reject, data) {
      reject(data);
    },
    _resolveViaRun: function _resolveViaRun(resolve, data) {
      Ember.run(null, resolve, data);
    },
    _rejectViaRun: function _rejectViaRun(reject, jqXHR) {
      Ember.run(null, reject, jqXHR);
    },
    _requestAndResolve: function _requestAndResolve(resolve, url) {
      this._ajax.request(url).then(this._resolveViaRun.bind(this, resolve));
    }
  });

  _exports.default = _default;
});