define("mvp/pods/site-management/sites/show/addresses/new/route", ["exports", "ember-apollo-client", "lodash"], function (_exports, _emberApolloClient, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var site_vars = this.modelFor("site-management.sites.show");
      var site_contacts = this.modelFor("site-management.sites.show.contacts");
      return Ember.RSVP.hash({
        params: params,
        siteId: site_vars.id,
        addressTypes: this.address_types(),
        siteContacts: site_contacts,
        changeset: {
          address: {}
        }
      });
    },
    address_types: function address_types() {
      return ["office", "personal"];
    }
  });

  _exports.default = _default;
});