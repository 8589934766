define("mvp/pods/surveillance/sentry/uploads/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H0W/J1tP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui centered grid\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"ten wide column \"],[9],[0,\"\\n\\n    \"],[1,[21,\"ui-sentry/uploads\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/sentry/uploads/template.hbs"
    }
  });

  _exports.default = _default;
});