define("mvp/pods/manage/organizations/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import pagedArray from 'ember-cli-pagination/computed/paged-array';
  var _default = Ember.Controller.extend({
    // setup our query params
    queryParams: ["page"],
    totalPagesBinding: "content.totalPages",
    // set default values, can cause problems if left out
    // if value matches default, it won't display in the URL
    page: 1,
    actions: {
      pageClicked: function pageClicked(page) {
        Ember.set(this, 'page', page);
      },
      "edit": function edit(organization) {
        this.transitionToRoute("manage.organizations.edit.settings", organization.id);
      }
    }
  });

  _exports.default = _default;
});