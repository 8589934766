define("mvp/pods/surveillance/sentry/downloads/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pEn4aUAp",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"yearOptions\",\"length\"]],1],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"right year-dropdown\"],[11,\"style\",\"width:250px;margin-top:20px;\"],[9],[0,\"\\n    \"],[1,[27,\"async-dropdown\",null,[[\"register-as\",\"onChange\",\"options\",\"default-text\"],[[23,[\"yearsDropdown\"]],[27,\"action\",[[22,0,[]],\"yearChanged\"],null],[23,[\"yearOptions\"]],\"Select a year...\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[22,0,[\"yearBump\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ui-sentry/downloads\",null,[[\"year\",\"site-objectives\"],[[23,[\"year\"]],[23,[\"objectives\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/sentry/downloads/template.hbs"
    }
  });

  _exports.default = _default;
});