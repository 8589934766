define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/map-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xY4iVx5C",
    "block": "{\"symbols\":[\"map_type\"],\"statements\":[[7,\"img\"],[11,\"class\",\"sentry icon\"],[11,\"src\",\"assets/images/sentry_logo.png\"],[11,\"width\",\"80%\"],[9],[10],[0,\"\\n\\n \"],[7,\"div\"],[11,\"class\",\"ui small basic buttons\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"map-types\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,1,[]],[23,[\"selected-map-type\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"ui button active\"],[9],[0,\"\\n        \"],[1,[22,1,[]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"update-selected-map-type\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"ui button\"],[9],[0,\"\\n        \"],[1,[22,1,[]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"update-selected-map-type\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/map-selector/template.hbs"
    }
  });

  _exports.default = _default;
});