define("mvp/models/site-objective", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    years: _emberData.default.attr('array'),
    objective_data_for_year: _emberData.default.attr('json') // site_code: DS.attr("string"),
    // study_year: DS.attr("number"),
    // objective_code: DS.attr("string"),
    // target_count: DS.attr("number"),
    // viable_count: DS.attr("number"),
    // non_viable_count: DS.attr("number"),
    // pending_count: DS.attr("number"),
    // deadline: DS.attr("date"),
    // last_shipment_received_date: DS.attr("date")

  });

  _exports.default = _default;
});