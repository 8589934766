define("mvp/pods/surveillance/show/heatmap/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Services
    ajax: Ember.inject.service("ajax"),
    currentUser: Ember.inject.service('current-user'),
    queryParams: {
      pathogen: {
        refreshModel: false
      },
      antibiotic: {
        refreshModel: false
      },
      authority: {
        refreshModel: false
      },
      year: {
        refreshModel: false
      },
      delivery_mechanism: {
        refreshModel: false
      },
      infection_type: {
        refreshModel: false
      }
    },
    model: function model() {
      var self = this;
      return Ember.RSVP.hash({
        resistance_rate_groups: {},
        resistance_rate_group: {},
        years: [],
        pathogens: self.store.query('resistance-rate-dbct', {}),
        antibiotics: self.store.query('project-drug', {}),
        authorities: [],
        delivery_mechanisms: [],
        infection_types: []
      });
    },
    afterModel: function afterModel(model) {
      var ajax = this.ajax;
      var years_url = "/api/resistance-rate-years";
      ajax.request(years_url).then(this._updateResistanceRateYears.bind(this, model));
      return;
    },
    _updateResistanceRateYears: function _updateResistanceRateYears(model, response) {
      Ember.set(model, 'years', response);
    }
  });

  _exports.default = _default;
});