define("mvp/pods/components/ui-surveillance/adhoc/geographic/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //////////////
    // Services //
    //////////////
    // Provide access to the current signed in user
    authorization: Ember.inject.service(),
    // Provide a pubsub mechanism to listen to and invoke events
    eventBus: Ember.inject.service("event-bus"),
    media: Ember.inject.service("media"),
    ////////////////
    // Properties //
    ////////////////
    // Contains the current filter as built in the ui-surveillance/adhoc component
    filter: undefined,
    // Stores a referenence to the organism dropdown component, the component
    // will register to this values through the `register-as` attribute.
    yearDropdown: undefined,
    regionDropdown: undefined,
    countryDropdown: undefined,
    censusRegionDropdown: undefined,
    europeanRegionDropdown: undefined,
    siteDropdown: undefined,
    showSiteDropdown: Ember.computed('authorization.{isJMIUser}', function () {
      return Ember.get(this, 'authorization.isJMIUser');
    }),
    ///////////////
    // Functions //
    ///////////////
    //////////
    // Year //
    //////////
    initializeYearDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.year.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.year.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.yearDropdown.send("set-selected", selected);
      }
    }.observes("yearDropdown"),
    // Observe changes to organism
    observeYears: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var year;

      if (Ember.get(this, "year.values.length") > 0) {
        year = {};
        year[Ember.get(this, "year.inclusion").toLowerCase()] = Ember.get(this, "year.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("year", year);
      });
    }.observes("year"),
    observeCriteriaForYearReset: function () {
      if (Ember.get(this, "filter.criteria.year") === undefined && Ember.get(this, "year.values.length") > 0) {
        this.resetYear();
      }
    }.observes("filter.criteria"),
    yearEnabled: Ember.computed("filter.criteria", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    yearUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/years" + "?project_name=" + encodeURIComponent(project_name);
    }),
    ////////////
    // Region //
    ////////////
    initializeRegionDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.continent.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.continent.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.regionDropdown.send("set-selected", selected);
      }
    }.observes("regionDropdown"),
    // Observe changes to organism
    observeRegion: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var region;

      if (Ember.get(this, "region.values.length") > 0) {
        region = {};
        region[Ember.get(this, "region.inclusion").toLowerCase()] = Ember.get(this, "region.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("continent", region);
      });
    }.observes("region"),
    observeCriteriaForRegionReset: function () {
      if (Ember.get(this, "filter.criteria.continent") === undefined && Ember.get(this, "region.values.length") > 0) {
        this.resetRegion();
      }
    }.observes("filter.criteria"),
    regionEnabled: Ember.computed("filter.criteria", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    regionUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/continents" + "?project_name=" + encodeURIComponent(project_name);
    }),
    /////////////
    // Country //
    /////////////
    initializeCountryDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.country.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.country.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.countryDropdown.send("set-selected", selected);
      }
    }.observes("countryDropdown"),
    // Observe changes to organism
    observeCountry: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var country;

      if (Ember.get(this, "country.values.length") > 0) {
        country = {};
        country[Ember.get(this, "country.inclusion").toLowerCase()] = Ember.get(this, "country.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("country", country);
      });
    }.observes("country"),
    observeCriteriaForCountryReset: function () {
      if (Ember.get(this, "filter.criteria.country") === undefined && Ember.get(this, "country.values.length") > 0) {
        this.resetCountry();
      }
    }.observes("filter.criteria"),
    countryEnabled: Ember.computed("filter.criteria", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    countryUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project");
      var regions = this.region; // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/countries" + "?project_name=" + encodeURIComponent(project_name) + "&continents=" + encodeURIComponent(JSON.stringify(regions));
    }),
    ///////////////////
    // Census Region //
    ///////////////////
    initializeCensusRegionDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.us_census_region.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.us_census_region.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.censusRegionDropdown.send("set-selected", selected);
      }
    }.observes("censusRegionDropdown"),
    observeCriteriaForCensusRegionReset: function () {
      if (Ember.get(this, "filter.criteria.us_census_region") === undefined && Ember.get(this, "censusRegion.values.length") > 0) {
        this.resetCensusRegion();
      }
    }.observes("filter.criteria"),
    // Examines the current filter critiera to see if we should show the
    // US Census Region dropdown.
    censusRegionEnabled: Ember.computed("filter.criteria", function () {
      // US Census Region dropdown should be enbaled if we have the USA country
      // selected in included countries.
      var criteria = Ember.get(this, "filter.criteria");
      return Ember.get(this, "filter.criteria.country") !== undefined && criteria.country !== undefined && criteria.country.include !== undefined && criteria.country.include.includes("USA");
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    censusRegionUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/us-census-region" + "?project_name=" + encodeURIComponent(project_name);
    }),
    /////////////////////
    // European Region //
    /////////////////////
    initializeEuropeanRegionDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.european_region.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.european_region.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        Ember.get(this, "europeanRegionDropdown").send("set-selected", selected);
      }
    }.observes("europeanRegionDropdown"),
    observeCriteriaForEuropeanRegionReset: function () {
      if (Ember.get(this, "filter.criteria.european_region") === undefined && Ember.get(this, "europeanRegion.values.length") > 0) {
        this.resetEuropeanRegion();
      }
    }.observes("filter.criteria"),
    // Examines the current filter critiera to see if we should show the
    // European Region dropdown.
    europeanRegionEnabled: Ember.computed("filter.criteria", function () {
      // European Region dropdown should be enbaled if we have the Europe continent
      // selected in included regions.
      var criteria = Ember.get(this, "filter.criteria");
      return Ember.get(this, "filter.criteria.continent") !== undefined && criteria.continent !== undefined && criteria.continent.include !== undefined && criteria.continent.include.includes("Europe");
    }),
    // A property representing the string of where the dropdown should look
    // for its values.
    europeanRegionUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project"); // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/european-region" + "?project_name=" + encodeURIComponent(project_name);
    }),
    //////////
    // Site //
    //////////
    initializeSiteDropdown: function () {
      var values;

      if (Ember.get(this, "filter.criteria.site.include") !== undefined) {
        values = Ember.get(this, "filter.criteria.site.include");
      }

      var selected = [];

      if (values !== undefined) {
        values.forEach(function (option) {
          selected.addObject(option);
        });
      }

      if (selected.length > 0) {
        this.siteDropdown.send("set-selected", selected);
      }
    }.observes("siteDropdown"),
    observeSite: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var site;

      if (Ember.get(this, "site.values.length") > 0) {
        site = {};
        site[Ember.get(this, "site.inclusion").toLowerCase()] = Ember.get(this, "site.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("site", site);
      });
    }.observes("site"),
    observeCriteriaForSiteReset: function () {
      if (Ember.get(this, "filter.criteria.site") === undefined && Ember.get(this, "site.values.length") > 0) {
        this.resetSite();
      }
    }.observes("filter.criteria"),
    siteEnabled: Ember.computed("filter.criteria", function () {
      // Organism dropdown should be enbaled if we have selected a project, and
      // a genus or group has also been selected.
      return Ember.get(this, "filter.criteria.project") !== undefined;
    }),
    // Observe changes to organism
    observeCensusRegion: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var censusRegion;

      if (Ember.get(this, "censusRegion.values.length") > 0) {
        censusRegion = {};
        censusRegion[Ember.get(this, "censusRegion.inclusion").toLowerCase()] = Ember.get(this, "censusRegion.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        this["update-filter-criteria"]("us_census_region", censusRegion);
      });
    }.observes("censusRegion"),
    // Observe changes to organism
    observeEuropeanRegion: function () {
      // Convert to the standard json style to submit change
      // {include: [x,x,...]} or {exclude: [x,x,...]}.
      var europeanRegion;

      if (Ember.get(this, "europeanRegion.values.length") > 0) {
        europeanRegion = {};
        europeanRegion[Ember.get(this, "europeanRegion.inclusion").toLowerCase()] = Ember.get(this, "europeanRegion.values");
      } // Send the selected organism to the parent component action


      Ember.run.scheduleOnce('actions', this, function () {
        Ember.get(this, "update-filter-criteria")("european_region", europeanRegion);
      });
    }.observes("europeanRegion"),
    // A property representing the string of where the dropdown should look
    // for its values.
    siteUrl: Ember.computed("filter.criteria", function () {
      var project_name = Ember.get(this, "filter.criteria.project");
      var regions = this.region;
      var countries = this.country;
      var us_census_region = this.censusRegion;
      var european_region = this.europeanRegion; // !Important - We must use encodeURIComponent for IE.

      return "/api/adhoc/site-codes" + "?project_name=" + encodeURIComponent(project_name) + "&continents=" + encodeURIComponent(JSON.stringify(regions)) + "&countries=" + encodeURIComponent(JSON.stringify(countries)) + "&european_regions=" + encodeURIComponent(JSON.stringify(european_region)) + "&us_census_regions=" + encodeURIComponent(JSON.stringify(us_census_region));
    }),
    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);

      this.setupInit();
      this.setupUI();
      this.year = {
        inclusion: "include",
        values: []
      };
      this.region = {
        inclusion: "include",
        values: []
      };
      this.country = {
        inclusion: "include",
        values: []
      };
      this.censusRegion = {
        inclusion: "include",
        values: []
      };
      this.europeanRegion = {
        inclusion: "include",
        values: []
      };
      this.site = {
        inclusion: "include",
        values: []
      };
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.cleanup();
    },
    /////////////
    // Actions //
    /////////////
    actions: {
      // Handle logic when the slected organism has changed. The values will be a
      // CSV string represention the selected values.
      "update-selected-year": function updateSelectedYear(values) {
        // Parse the CSV string to pull out the selected organisms.
        var yearValues;

        if (values !== "") {
          yearValues = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          yearValues = [];
        } // Update the slected organism values


        Ember.set(this, "year.values", yearValues); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("year");
      },
      "update-selected-region": function updateSelectedRegion(values) {
        // Parse the CSV string to pull out the selected organisms.
        var regionValues;

        if (values !== "") {
          regionValues = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          regionValues = [];
        } // Update the slected organism values


        Ember.set(this, "region.values", regionValues); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("region");
      },
      "update-selected-country": function updateSelectedCountry(values) {
        // Parse the CSV string to pull out the selected organisms.
        var countryValues;

        if (values !== "") {
          countryValues = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          countryValues = [];
        } // Update the slected organism values


        Ember.set(this, "country.values", countryValues); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("country");
      },
      "update-selected-census-region": function updateSelectedCensusRegion(values) {
        // Parse the CSV string to pull out the selected organisms.
        var censusRegionValues;

        if (values !== "") {
          censusRegionValues = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          censusRegionValues = [];
        } // Update the slected organism values


        Ember.set(this, "censusRegion.values", censusRegionValues); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("censusRegion");
      },
      "update-selected-european-region": function updateSelectedEuropeanRegion(values) {
        // Parse the CSV string to pull out the selected organisms.
        var europeanRegionValues;

        if (values !== "") {
          europeanRegionValues = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          europeanRegionValues = [];
        } // Update the slected organism values


        Ember.set(this, "europeanRegion.values", europeanRegionValues); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("europeanRegion");
      },
      "update-selected-site": function updateSelectedSite(values) {
        // Parse the CSV string to pull out the selected organisms.
        var siteValues;

        if (values !== "") {
          siteValues = values.split(",").map(function (item) {
            return decodeURIComponent(item);
          });
        } else {
          siteValues = [];
        } // Update the slected organism values


        Ember.set(this, "site.values", siteValues); // Notify that observers (within this component) that the organism has
        // been updated.

        this.notifyPropertyChange("site");
      }
    },
    // Reset the state of this component, usually called when the selected
    // project changes or the user clears the filter.
    reset: function reset() {
      // Reset the filter criteria data.
      Ember.set(this, "year", {
        inclusion: "Include",
        values: []
      });
      Ember.set(this, "region", {
        inclusion: "Include",
        values: []
      });
      Ember.set(this, "country", {
        inclusion: "Include",
        values: []
      });
      Ember.set(this, "site", {
        inclusion: "include",
        values: []
      }); // Clear the dropdowns

      if (this.yearDropdown !== undefined) {
        this.yearDropdown.send("reset");
      }

      if (this.regionDropdown !== undefined) {
        this.regionDropdown.send("reset");
      }

      if (this.countryDropdown !== undefined) {
        this.countryDropdown.send("reset");
      }

      if (this.siteDropdown !== undefined) {
        this.siteDropdown.send("reset");
      }
    },
    setupInit: function setupInit() {
      // Subscribe to the event bus
      this.eventBus.on('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.on('resetAdhocFilter', this, 'resetAdhocFilter');
    },
    // Set up the ui after this component has been rendered
    setupUI: function setupUI() {// // Schedule to update the ui after render
      // Ember.run.schedule('afterRender', this, function () {
      //
      //   // More ui set up should be added here...
      // });
    },
    // Notify observers within this component that the filter criteria has
    // changed. This will be invoked through the global event bus.
    filterCriteriaChanged: function filterCriteriaChanged(key) {
      this.notifyPropertyChange("filter.criteria"); // Need to reset country dropdown if geographic_regions changed

      if (key === "continent") {
        Ember.set(this, "country", {
          inclusion: "Include",
          values: []
        });

        if (this.countryDropdown !== undefined) {
          this.countryDropdown.send("reset");
        }
      }

      if (key === "continent" || key === "country" || key === "european_region" || key === "us_census_region") {
        Ember.set(this, "site", {
          inclusion: "Include",
          values: []
        });

        if (this.siteDropdown) {
          this.siteDropdown.send("reset");
        }
      } // Need to clear US Census Region if the selected country does not include
      // USA.


      var criteria = Ember.get(this, "filter.criteria");

      if (criteria.us_census_region !== undefined && (criteria.country === undefined || criteria.country.include === undefined || !criteria.country.include.includes("USA"))) {
        // Reset US Census Region elements
        Ember.set(this, "censusRegion", {
          inclusion: "include",
          values: []
        });
        this.censusRegionDropdown.send("reset");
      } // Need to clear European Region if the selected continent does not include
      // Europe.


      if (criteria.european_region !== undefined && (criteria.continent === undefined || criteria.continent.include === undefined || !criteria.continent.include.includes("Europe"))) {
        // Reset European Region elements
        Ember.set(this, "europeanRegion", {
          inclusion: "include",
          values: []
        });
        Ember.get(this, "europeanRegionDropdown").send("reset");
      }
    },
    // Perform necessary actions when adhoc filter is reset
    resetAdhocFilter: function resetAdhocFilter() {
      this.reset();
    },
    resetYear: function resetYear() {
      // Reset the dropdown
      var dropdown = this.yearDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "year", {
        inclusion: "include",
        values: []
      });
    },
    resetRegion: function resetRegion() {
      // Reset the dropdown
      var dropdown = this.regionDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "region", {
        inclusion: "include",
        values: []
      });
    },
    resetCountry: function resetCountry() {
      // Reset the dropdown
      var dropdown = this.countryDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "country", {
        inclusion: "include",
        values: []
      });
    },
    resetCensusRegion: function resetCensusRegion() {
      // Reset the dropdown
      var dropdown = this.censusRegionDropdown;
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "censusRegion", {
        inclusion: "include",
        values: []
      });
    },
    resetEuropeanRegion: function resetEuropeanRegion() {
      // Reset the dropdown
      var dropdown = Ember.get(this, "europeanRegionDropdown");
      dropdown.send("reset"); // Reset the actual values

      Ember.set(this, "europeanRegion", {
        inclusion: "include",
        values: []
      });
    },
    resetSite: function resetSite() {
      // Reset the dropdown
      if (this.siteDropdown) {
        this.siteDropdown.send("reset");
      } // Reset the actual values


      Ember.set(this, "site", {
        inclusion: "include",
        values: []
      });
    },
    // Watch for changes to the filter criteria, and clear US Census Region if the
    observeCensusRegionEnabled: function observeCensusRegionEnabled() {
      var criteria = Ember.get(this, "filter.criteria");

      if (criteria.us_census_region !== undefined && (criteria.country === undefined || criteria.country.include === undefined || !criteria.country.include.includes("USA"))) {
        Ember.set(this, "censusRegion", {
          inclusion: "include",
          values: []
        });
        this.censusRegionDropdown.send("reset");
      }
    },
    observeEuropeanRegionEnabled: function observeEuropeanRegionEnabled() {
      var criteria = Ember.get(this, "filter.criteria");

      if (criteria.european_region !== undefined && (criteria.continent === undefined || criteria.continent.include === undefined || !criteria.continent.include.includes("Europe"))) {
        Ember.set(this, "europeanRegion", {
          inclusion: "include",
          values: []
        });
        Ember.get(this, "europeanRegionDropdown").send("reset");
      }
    },
    // Performs cleanup of the component after the element is destroyed
    cleanup: function cleanup() {
      // Destroy ties to the event bus
      this.eventBus.off('filterCriteriaChanged', this, 'filterCriteriaChanged');
      this.eventBus.off('resetAdhocFilter', this, 'resetAdhocFilter');
    }
  });

  _exports.default = _default;
});