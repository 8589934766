define("mvp/pods/admin/users/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YMC8OTly",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ui-admin/users/edit\",null,[[\"user\",\"userSaved\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"userSaved\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/admin/users/edit/template.hbs"
    }
  });

  _exports.default = _default;
});