define("mvp/helpers/lv-total-received", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lvTotalReceived = lvTotalReceived;
  _exports.default = void 0;

  function lvTotalReceived(site) {
    var site_attrs = site[0];
    var total = 0;
    site_attrs.siteObjectives.forEach(function (el) {
      total += JSON.parse(el.lvCounts).receivedCount;
    });
    return total;
  }

  var _default = Ember.Helper.helper(lvTotalReceived);

  _exports.default = _default;
});