define("mvp/pods/components/ui-surveillance/adhoc/molecular/criteria/modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$('#' + this.elementId + ' .profile.menu .item').tab();
      });
    },
    actions: {
      'add-profile': function addProfile(inclusion, criteria) {
        var criteria_with_inclusion = {};
        criteria_with_inclusion[inclusion] = criteria;
        this.updateNewMolecularProfile(criteria_with_inclusion);
      }
    }
  });

  _exports.default = _default;
});