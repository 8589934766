define("mvp/pods/surveillance/sentry/demo/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQdCXj6E",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"flex flex-row\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"sentry icon\"],[11,\"style\",\"height:90px;\"],[11,\"src\",\"assets/images/logo_white.png\"],[11,\"alt\",\"Sentry Icon\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"flex flex-row bg-gray-300 my-6\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"w-1/3 py-2 text-center bg-blue-600 text-white\"],[9],[0,\" \\n    \"],[7,\"span\"],[11,\"class\",\"rounded-full bg-gray-300 text-black inline-block w-6 h-6 font-bold mr-6\"],[9],[0,\"1\"],[10],[0,\" Select Objective \\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"w-1/3 py-2 text-center\"],[9],[0,\" 2. Enter Demo Data \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"w-1/3 py-2 text-center\"],[9],[0,\" 3. Shipment Confirmation \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"ui-sentry/demo-entry/choose-objective\",null,[[\"siteRecruitments\"],[[22,0,[\"model\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/sentry/demo/index/template.hbs"
    }
  });

  _exports.default = _default;
});