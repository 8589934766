define("mvp/pods/components/d3-charts/frequency-distribution/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;
  /* globals d3 */

  var _default = Ember.Component.extend({
    svgExport: Ember.inject.service('svg-export'),
    eventTracker: Ember.inject.service(),
    dilutions: Ember.computed('data.data.columns.length', function () {
      return this.data.data.columns[0].slice(1);
    }),
    drugs: Ember.computed('data.data.columns.length', function () {
      var drugs = this.data.data.columns.slice(1).map(function (row) {
        return row[0];
      });
      return drugs;
    }),
    drug_results: Ember.computed('data.data.columns.length', function () {
      var data = this.data.data.columns.slice(1);
      var dilution = this.dilutions;
      var results = [];
      data.forEach(function (row) {
        var temp = {};
        temp.drug = row[0];
        temp.results = row.slice(1).map(function (i, idx) {
          return {
            dilution: dilution[idx],
            pct: i
          };
        }).filter(function (i) {
          return i.pct;
        });
        results.addObject(temp);
      });
      return results;
    }),
    margin: Ember.computed('drugs.length', function () {
      var drugs = this.drugs;
      var max_name_length = 0;
      drugs.forEach(function (drug) {
        if (drug.length > max_name_length) {
          max_name_length = drug.length;
        }
      });
      return {
        top: 30,
        right: 45 + max_name_length * 8,
        bottom: 70,
        left: 70
      };
    }),
    max: Ember.computed('drug_results.length', function () {
      var drug_results = this.drug_results;
      var max = 0;
      drug_results.forEach(function (drug_result) {
        drug_result.results.forEach(function (result) {
          if (max < result.pct) {
            max = result.pct;
          }
        });
      });
      return max;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$(window).on('resize', this._handleResize.bind(this));
      Ember.get(this, 'height');
      Ember.run.scheduleOnce('afterRender', this, this._setupHeight);
      Ember.run.scheduleOnce('afterRender', this, this._renderChart);
    },
    didReceiveAttrs: function didReceiveAttrs() {// scheduleOnce('afterRender', this, this._renderChart);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      Ember.run.scheduleOnce('afterRender', this, this._renderChart);
    },
    _handleResize: function _handleResize() {
      var _this = this;

      Ember.run.debounce(this, function () {
        Ember.$("#".concat(_this.elementId, " svg")).html("");
        Ember.run.scheduleOnce('afterRender', _this, _this._renderChart);
      }, 100);
    },
    _setupHeight: function _setupHeight() {
      Ember.$("#".concat(this.elementId, " svg")).height(Ember.get(this, 'height'));
    },
    _renderChart: function _renderChart() {
      var _this2 = this;

      var margin = Ember.get(this, 'margin');

      var viewBoxWidth = this._viewBoxWidth();

      var viewBoxHeight = this._viewBoxHeight();

      this._setUpResponsiveSVG(this, viewBoxWidth, viewBoxHeight);

      var svg = d3.select("#".concat(this.elementId, " svg")); // Acts as the background for the chart

      svg.append("rect").attr("width", this._width() + margin.left + margin.right).attr("height", this._height() + margin.top + margin.bottom).attr("rx", 15).attr("ry", 15).classed("svg-background", true).attr("fill", "rgba(0,0,0,.7)"); // Setup the main grouping for the chart data

      var g = svg.append("g").classed("g_main", true).attr("transform", "translate(".concat(margin.left, ",").concat(margin.top, ")")); // Build and render the axis for the chart

      var axis = this._renderAxis(g); // Bulid and render the chart legend


      this._renderLegend(g, axis);

      this.drug_results.forEach(function (drug_result) {
        _this2._renderBarChart(g, axis, drug_result);
      }); // Render the guideline

      var guideLine = this._renderGuideLine(g); // Render the overlay to catch mouseover events


      this._renderOverlay(g, axis, guideLine); // Render download button


      this._renderDownloadButton(this, g);
    },
    _setUpResponsiveSVG: function _setUpResponsiveSVG(self, viewBoxWidth, viewBoxHeight) {
      // set up svg and container divs for responsive design
      d3.select("#".concat(self.elementId, " div#chartId")).append("div").classed("svg-container", true).append("svg").attr("preserveAspectRatio", "xMinYMin meet").attr("viewBox", "0 0 ".concat(viewBoxWidth, " ").concat(viewBoxHeight)).classed("svg-content-responsive", true);
    },
    _viewBoxWidth: function _viewBoxWidth() {
      return Ember.$("#".concat(this.elementId, " svg")).width() - 100;
    },
    _viewBoxHeight: function _viewBoxHeight() {
      return Ember.$("#".concat(this.elementId, " svg")).height() - 20;
    },
    _width: function _width() {
      var margin = Ember.get(this, 'margin');
      return this._viewBoxWidth() - margin.left - margin.right;
    },
    _height: function _height() {
      var margin = Ember.get(this, 'margin');
      return this._viewBoxHeight() - margin.top - margin.bottom;
    },
    _renderAxis: function _renderAxis(g) {
      var drugs = this.drugs;
      var x0 = d3.scaleBand().rangeRound([0, this._width()]).paddingInner(0.1).domain(this.dilutions);
      var x1 = d3.scaleBand().padding(0.05).domain(drugs).rangeRound([0, x0.bandwidth()]);
      var axis_g = g.append("g").attr("class", "axis").attr("transform", "translate(0," + this._height() + ")").call(d3.axisBottom(x0).tickSizeOuter(0));

      if (Ember.get(this, 'dilutions').length > 8) {
        axis_g.selectAll("text").attr("transform", 'rotate(40)').attr("dx", "1em").style("text-anchor", "start");
      }

      x0.invert = function () {
        var domain = x0.domain();
        var range = x0.range();
        var scale = d3.scaleQuantize().domain(range).range(domain);
        return function (x) {
          return scale(x);
        };
      }();

      g.append("text").attr("x", 0).attr("y", 0).attr("dy", "0.32em").attr("class", "y-label").attr("font-size", 11).attr("font-family", "Arial").attr("fill", "rgba(255,255,255,.9)").attr("text-anchor", "middle").attr("letter-spacing", "2px").attr("transform", "translate(" + this._width() / 2 + "," + (this._height() + 42) + ")").text(this.data.axis.x.label.text);
      var max = this.max;
      var y = d3.scaleLinear().rangeRound([this._height(), 0]).domain([0, max]);
      g.append("g").attr("class", "axis").call(d3.axisLeft(y));
      g.append("text").attr("x", 2).attr("y", 0).attr("dy", "0.32em").attr("text-anchor", "start").attr("class", "y-label").attr("font-size", 11).attr("font-family", "Arial").attr("fill", "rgba(255,255,255,.9)").attr("text-anchor", "middle").attr("letter-spacing", "2px").attr("transform", "rotate(270,0,0),translate(-" + this._height() / 2 + ", -40)").text("% of isolates");
      var z = d3.scaleOrdinal().domain(drugs).range(['#5b8cff', '#d0da41', '#324986', '#85c1ec', '#9fb36d', '#1482be']);
      return {
        x0: x0,
        x1: x1,
        y: y,
        z: z
      };
    },
    _renderLegend: function _renderLegend(g, _ref) {
      var z = _ref.z;
      var drugs = this.drugs;
      var legend = g.append("g").attr("class", "legend");
      var legend_row = legend.selectAll(".lengend-row").data(drugs).enter().append("g").attr("class", "legend-row");
      var self = this;
      legend_row.append("rect").attr("x", this._width() + 20).attr("y", function (_, i) {
        return self._height() / 2 + i * 25 + 30;
      }).attr("transform", "translate(0,-" + drugs.length / 2 * 24 + ")").attr("height", 20).attr("width", 20).attr("rx", 3).attr("ry", 3).attr("fill", function (d) {
        return z(d);
      });
      legend_row.append("text").attr("x", this._width() + 60).attr("y", function (_, i) {
        return self._height() / 2 + i * 25 + 15 + 30;
      }).attr("transform", "translate(0,-" + drugs.length / 2 * 24 + ")").attr("fill", "rgba(255,255,255,.9)").text(function (d) {
        return d;
      });
    },
    _renderBarChart: function _renderBarChart(g, _ref2, drug_result) {
      var x0 = _ref2.x0,
          x1 = _ref2.x1,
          y = _ref2.y,
          z = _ref2.z;
      var self = this;
      var bar = g.append("g").selectAll("g").data(drug_result.results).enter().append("rect").attr("class", "bar").attr("x", function (d) {
        return x0(d.dilution);
      }).attr("y", function (d) {
        return y(d.pct);
      }).attr("width", x1.bandwidth()).attr("height", function (d) {
        return self._height() - y(d.pct);
      }).attr("fill", z(drug_result.drug)).attr("transform", function () {
        var x_translate = x1(drug_result.drug);

        if (x_translate !== undefined) {
          return "translate(" + x1(drug_result.drug) + ",0)";
        } else {
          return "translate(0,0)";
        }
      });
      bar.transition().delay(50).duration(250).ease(d3.easeSinInOut).attr("y", function (d) {
        return y(d.pct);
      }).attr("height", function (d) {
        return self._height() - y(d.pct);
      });
    },
    _renderGuideLine: function _renderGuideLine(g) {
      return g.append("line").attr("class", "guideLine").attr("x1", 0).attr("y1", 0).attr("x2", 0).attr("y2", this._height()).attr("stroke-opacity", 0);
    },
    _renderOverlay: function _renderOverlay(g, _ref3, guideLine) {
      var x0 = _ref3.x0,
          z = _ref3.z;
      var drug_results = this.drug_results;
      g.append("rect").attr("class", "overlay").attr("width", this._width()).attr("height", this._height()).on("mouseout", mouseout).on("mousemove", mousemove);

      function mouseout() {
        Ember.run.debounce(this, function () {
          guideLine.transition().duration(200).attr("stroke-opacity", 0);
          g.selectAll(".guide").remove();
          g.selectAll(".legend").attr("opacity", 1);
          g.selectAll(".bar").each(function () {
            d3.select(this).transition().duration(50).attr("fill-opacity", 1);
          });
        }, 100);
      }

      function mousemove() {
        var mouseLocation = d3.mouse(this);
        var overDilution = x0.invert(mouseLocation[0]);
        var nx = x0(overDilution) + x0.bandwidth() / 2;
        var ny = mouseLocation[1];
        Ember.run.debounce(this, function () {
          guideLine.transition().duration(10).attr("x1", nx).attr("x2", nx).attr("stroke-opacity", 0.5);
          var text_items = [];
          drug_results.forEach(function (drug_result) {
            drug_result.results.forEach(function (result) {
              if (result.dilution === overDilution) {
                text_items.addObject({
                  drug: drug_result.drug,
                  pct: result.pct
                });
              }
            });
          });
          g.selectAll(".bar").each(function () {
            d3.select(this).transition().duration(50).attr("fill-opacity", function (d) {
              if (d.dilution === overDilution) {
                return 1;
              } else {
                return 0.1;
              }
            });
          });
          g.selectAll(".legend").attr("opacity", 0);
          g.selectAll(".guide").remove();
          var guide = g.append("g").attr("class", "guide");
          var guideBckgd = guide.append("rect").attr("class", "guide-text-bkgd").attr("fill", "rgba(255,255,255,.8)").attr("pointer-events", "none");
          var guideText = guide.selectAll("g").data(text_items).enter().append("g").attr("class", "guide-text").attr("pointer-events", "none");
          guideText.append("rect").attr("x", nx + 40).attr("y", function (_, i) {
            return ny + 23 * i - 5;
          }).attr("width", 14).attr("height", 14).attr("rx", 2).attr("ry", 2).attr("fill", function (d) {
            return z(d.drug);
          });
          guideText.append("text").attr("dy", ".35em").attr("text-anchor", "left").attr("fill", "rgba(0,0,0,.9)").attr("font-size", 14).attr("font-family", "Arial").attr("x", nx + 60).attr("y", function (_, i) {
            return ny + 23 * i;
          }).text(function (d) {
            return "".concat(d.drug, " - ").concat(d.pct, "%");
          });

          if (text_items.length > 0) {
            var bbox = guide.node().getBBox();
            guideBckgd.attr("x", bbox.x - 5).attr("y", bbox.y - 5).attr("rx", 5).attr("ry", 5).attr("width", bbox.width + 10).attr("height", bbox.height + 10);
          } else {
            guideBckgd.attr("x", 0).attr("y", 0).attr("width", 0).attr("height", 0);
          }
        }, 50);
      }
    },
    _renderDownloadButton: function _renderDownloadButton(self, g) {
      var margin = Ember.get(self, 'margin');
      var width = self._width() + margin.right - margin.left; // download button

      var button = g.append('rect').attr("x", width).attr("y", 0 - margin.top / 2.0).attr("width", 35).attr("height", 35).attr("rx", 5).attr("ry", 5).classed("svg-downloadButton", true).style("fill", "rgba(255, 255, 255, 0.5)"); // draw download button icon

      var icon = g.append("svg:image").attr("xlink:href", self._downloadButtonIcon()).attr("x", width + 2).attr("y", 0 - margin.top / 2.0 + 1).classed("svg-downloadButton", true).attr("width", 30).attr("height", 30); // download button event handling

      icon.on("mouseover", function () {
        self._downloadButtonMouseOver(button);
      }).on("mouseleave", function () {
        self._downloadButtonMouseLeave(button);
      }).on("click", function () {
        self._downloadButtonMouseClick(self);
      });
    },
    _downloadButtonIcon: function _downloadButtonIcon() {
      return "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxwYXRoIGQ9IiAgTTMyLDM1YzAsMCw4LjMxMiwwLDkuMDk4LDBDNDUuNDYzLDM1LDQ5LDMxLjQ2Myw0OSwyNy4wOTlzLTMuNTM3LTcuOTAyLTcuOTAyLTcuOTAyYy0wLjAyLDAtMC4wMzgsMC4wMDMtMC4wNTgsMC4wMDMgIGMwLjA2MS0wLjQ5NCwwLjEwMy0wLjk5NCwwLjEwMy0xLjUwNGMwLTYuNzEtNS40MzktMTIuMTUtMTIuMTUtMTIuMTVjLTUuMjI5LDAtOS42NzIsMy4zMDktMTEuMzg2LDcuOTQxICBjLTEuMDg3LTEuMDg5LTIuNTkxLTEuNzY0LTQuMjUxLTEuNzY0Yy0zLjMxOSwwLTYuMDA5LDIuNjktNi4wMDksNi4wMDhjMCwwLjA4NSwwLjAxLDAuMTY3LDAuMDEzLDAuMjUxICBDMy42OTUsMTguOTk1LDEsMjIuMzQ0LDEsMjYuMzMxQzEsMzEuMTE5LDQuODgxLDM1LDkuNjcsMzVjMC44MjcsMCw4LjMzLDAsOC4zMywwIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiLz48cG9seWxpbmUgZmlsbD0ibm9uZSIgcG9pbnRzPSIzMCw0MSAyNSw0NiAyMCw0MSAgICIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIi8+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIiB4MT0iMjUiIHgyPSIyNSIgeTE9IjI2IiB5Mj0iNDUuNjY4Ii8+PC9zdmc+";
    },
    _downloadButtonMouseOver: function _downloadButtonMouseOver(button) {
      button.style("fill", "rgba(255, 255, 255, 1.0)");
    },
    _downloadButtonMouseLeave: function _downloadButtonMouseLeave(button) {
      button.style("fill", "rgba(255, 255, 255, 0.5)");
    },
    _downloadButtonMouseClick: function _downloadButtonMouseClick(self) {
      // inner function to clone an svg selection
      function clone(selector) {
        var node = d3.select(selector).node();
        return d3.select(node.parentNode.insertBefore(node.cloneNode(true), node.nextSibling));
      } // chart info attributes


      var year = self.parentView.attrs.filter.value.criteria.year ? self.parentView.attrs.filter.value.criteria.year.include.join() : 'All years';
      var groups = self.parentView.attrs.filter.value.criteria.country ? self.parentView.attrs.filter.value.criteria.country.include.join() : 'All Regions';
      var main_component = self.parentView.attrs.filter.value.criteria.project;
      var sel_drugs = self.parentView.drugs.join(); // get full pathogen name

      var header_html = "Project: " + main_component;
      var header_div = document.createElement("div");
      header_div.innerHTML = header_html;
      var fullPathogenName = header_div.innerText; // get full region name and isolate count

      var footer_html = groups;
      var footer_div = document.createElement("div");
      footer_div.innerHTML = footer_html;
      var fullRegionName = footer_div.innerText;
      var selDrugs = sel_drugs;
      var filename = "".concat(year, "_").concat(fullRegionName, "_").concat(fullPathogenName);
      filename = filename.replace(/:/gi, ' ');
      filename = filename.replace(/ +/gi, '_'); // make and style clone of svg

      var copy = clone("#".concat(self.elementId, " svg"));

      self._styleSvgCloneForExport(self, copy, year, fullPathogenName, fullRegionName, selDrugs); // export png of svg copy


      Ember.get(self, 'svgExport').export(copy, filename); // remove svg copy from DOM

      copy.remove();
      Ember.get(self, 'eventTracker').record('download-image-freq-dist-drug', {
        filename: filename,
        drug: self.parentView.drugs.join()
      });
    },
    _styleSvgCloneForExport: function _styleSvgCloneForExport(self, copy, year, fullPathogenName, fullRegionName, selDrugs) {
      var margin = Ember.get(self, 'margin');

      var viewBoxWidth = self._viewBoxWidth();

      var viewBoxHeight = self._viewBoxHeight(); // fix padding for export image


      var exportPaddingHeight = 150;
      var newViewBoxWidth = viewBoxWidth;
      var newViewBoxHeight = viewBoxHeight + exportPaddingHeight;
      var newSpace = (newViewBoxHeight - viewBoxHeight + 115) * 2;
      var g_main_x = margin.left;
      var g_main_y = margin.top + 25;

      if (INSTANCE === 'mvppublic') {
        copy.append("svg:image").attr('x', g_main_x - 50).attr('y', g_main_y - 50).attr('width', viewBoxWidth).attr('height', viewBoxHeight).attr('opacity', 0.075).attr("xlink:href", Ember.get(self, 'svgExport').jmi_logo());
      }

      copy.append("svg:image").attr('x', g_main_x).attr('y', newSpace).attr('width', 60).attr('height', 60).attr("xlink:href", Ember.get(self, 'svgExport').jmi_logo()); // set up header string

      var headerString = "".concat(fullPathogenName); // set up footer strings

      var footerString = "".concat(year, ": ").concat(fullRegionName);
      var subFooterString = "".concat(selDrugs); // fix viewbox of svg

      copy.attr("viewBox", "0 0 ".concat(newViewBoxWidth, " ").concat(newViewBoxHeight)); // remove unwanted elements

      copy.selectAll(".overlay").remove();
      copy.selectAll(".svg-downloadButton").remove();
      copy.selectAll(".svg-downloadButton").remove(); // fix axis element coloring

      copy.selectAll(".axis .tick text").style("fill", "#d8d8d8").style("font-size", "8px").style("font-family", "Arial");
      copy.selectAll(".axis .tick line").style("stroke", "#d8d8d8");
      copy.selectAll(".axis path").style("stroke", "#d8d8d8"); // fix background styling

      copy.select(".svg-background").attr("width", newViewBoxWidth).attr("height", newViewBoxHeight).attr("rx", 0).attr("ry", 0).attr("fill", "rgba(0, 0, 0, 1.0)"); // add legend

      copy.selectAll(".legend").attr('transform', "translate(0,0)"); // add header text

      copy.append('text').attr("x", g_main_x + 80).attr("y", newSpace + 15).text(headerString).style("font-family", "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif").style("fill", "#d8d8d8").style("text-anchor", "left").style("font-size", "15px").style("font-family", "Arial"); // add footer text

      copy.append('text').attr("x", g_main_x + 80).attr("y", newSpace + 35).text(footerString).style("font-family", "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif").style("fill", "#d8d8d8").style("text-anchor", "left").style("font-size", "12px").style("font-family", "Arial"); // add subfooter text

      copy.append('text').attr("x", g_main_x + 80).attr("y", newSpace + 55).text(subFooterString).style("font-family", "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif").style("fill", "#d8d8d8").style("text-anchor", "left").style("font-size", "12px").style("font-family", "Arial");
      var textNode = copy.append('text').attr("x", g_main_x).attr("y", newSpace + 80).text(null).style("font-family", "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif").style("fill", "#d8d8d8").style("text-anchor", "left").style("font-size", "6px").style("font-family", "Arial");
      Ember.get(self, 'svgExport').wrap_text({
        textNode: textNode,
        text: Ember.get(self, 'svgExport').disclaimer(),
        maxWidth: viewBoxWidth,
        lineHeight: 8,
        styleFn: function styleFn(node) {
          return node;
        }
      }); // fix location of main group for graph

      copy.selectAll(".g_main").attr("transform", "translate(" + g_main_x + "," + g_main_y + ")");
    }
  });

  _exports.default = _default;
});