define("mvp/pods/components/ui-surveillance/heatmap/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;
  var defaultMapType = INSTANCE === 'mvpparatek' ? 'usa' : 'world';

  var _default = Ember.Component.extend({
    selectedAuthority: 'CLSI',
    maptype: defaultMapType,
    selectedRateType: 'Antibiotic susceptibility',
    year: "All years",
    selectedDeliveryMechanism: "None",
    selectedInfectionType: "None",
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    }),
    firstYear: Ember.computed('years', function () {
      var years = this.years;
      return years[0];
    }),
    lastYear: Ember.computed('years', function () {
      var years = this.years;
      return years[years.length - 1];
    }),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._setup();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._setup();
    },
    actions: {
      'change-year': function changeYear(year) {
        Ember.set(this, 'year', year);
      },
      'change-maptype': function changeMaptype(maptype) {
        Ember.set(this, 'maptype', maptype);
      },
      'select-rate-type': function selectRateType() {
        Ember.$("#".concat(this.elementId, " .main")).hide("slide", {
          direction: "left"
        }, 250);
        Ember.$("#".concat(this.elementId, " .rate-type-selection")).show("slide", {
          direction: "right"
        }, 250);
      },
      'select-pathogen': function selectPathogen() {
        Ember.$("#".concat(this.elementId, " .main")).hide("slide", {
          direction: "left"
        }, 250);
        Ember.$("#".concat(this.elementId, " .pathogen-selection")).show("slide", {
          direction: "right"
        }, 250);
      },
      'select-drug': function selectDrug() {
        Ember.$("#".concat(this.elementId, " .main")).hide("slide", {
          direction: "left"
        }, 250);
        Ember.$("#".concat(this.elementId, " .drug-selection")).show("slide", {
          direction: "right"
        }, 250);
      },
      'select-phenotype': function selectPhenotype() {
        Ember.$("#".concat(this.elementId, " .main")).hide("slide", {
          direction: "left"
        }, 250);
        Ember.$("#".concat(this.elementId, " .phenotype-selection")).show("slide", {
          direction: "right"
        }, 250);
      },
      'back': function back() {
        this._backToMain();
      },
      'change-pathogen': function changePathogen(pathogen) {
        Ember.set(this, 'selectedPathogen', pathogen);

        this._setupDrugs();

        Ember.run.later(this, this._backToMain, 200);
      },
      'change-drug': function changeDrug(drug) {
        Ember.set(this, 'selectedDrug', drug);
        Ember.set(this, 'selectedAuthority', 'CLSI');
        Ember.set(this, 'selectedDeliveryMechanism', 'None');
        Ember.set(this, 'selectedInfectionType', 'None');

        this._setupAuthorities();

        Ember.run.later(this, this._backToMain, 200);
      },
      'change-rate-type': function changeRateType(type) {
        Ember.set(this, 'selectedRateType', type);
        Ember.run.later(this, this._backToMain, 200);
      },
      'change-phenotype': function changePhenotype(phenotype) {
        Ember.set(this, 'selectedPhenotype', phenotype);
        Ember.run.later(this, this._backToMain, 200);
      },
      'update-selected-authority': function updateSelectedAuthority(authority) {
        Ember.set(this, 'selectedAuthority', authority);

        this._setupDeliveryMechanisms();

        Ember.run.later(this, this._backToMain, 200);
      },
      'update-selected-delivery-mechanism': function updateSelectedDeliveryMechanism(deliveryMechanism) {
        Ember.set(this, 'selectedDeliveryMechanism', deliveryMechanism);

        this._setupInfectionTypes();

        Ember.run.later(this, this._backToMain, 200);
      },
      'update-selected-infection-type': function updateSelectedInfectionType(infectionType) {
        Ember.set(this, 'selectedInfectionType', infectionType);
        Ember.run.later(this, this._backToMain, 200);
      }
    },
    _setup: function _setup() {
      Ember.set(this, 'selectedPathogen', this.pathogens.objectAt(0));
      Ember.set(this, 'selectedPhenotype', this.phenotypes.objectAt(0));

      this._setupDrugs();
    },
    _setupDrugs: function _setupDrugs() {
      var ajax = this.ajax;
      var project = this.project;
      var selectedPathogen = this.selectedPathogen;
      var url = "api/projects/".concat(project.id, "/heatmap/drugs") + "?pathogen=".concat(encodeURIComponent(selectedPathogen));
      ajax.request(url).then(this._handleDrugs.bind(this)).catch();
    },
    _setupAuthorities: function _setupAuthorities() {
      var ajax = this.ajax;
      var project = this.project;
      var selectedPathogen = this.selectedPathogen;
      var selectedDrug = this.selectedDrug;
      var url = "api/projects/".concat(project.id, "/heatmap/authorities") + "?pathogen=".concat(encodeURIComponent(selectedPathogen)) + "&drug_name=".concat(encodeURIComponent(selectedDrug));
      ajax.request(url).then(this._handleAuthorities.bind(this)).catch();
    },
    _setupDeliveryMechanisms: function _setupDeliveryMechanisms() {
      var ajax = this.ajax;
      var project = this.project;
      var selectedPathogen = this.selectedPathogen;
      var selectedDrug = this.selectedDrug;
      var selectedAuthority = this.selectedAuthority;
      var url = "api/projects/".concat(project.id, "/heatmap/delivery_mechanisms") + "?pathogen=".concat(encodeURIComponent(selectedPathogen)) + "&drug_name=".concat(encodeURIComponent(selectedDrug)) + "&authority=".concat(encodeURIComponent(selectedAuthority));
      ajax.request(url).then(this._handleDeliveryMechanisms.bind(this)).catch();
    },
    _setupInfectionTypes: function _setupInfectionTypes() {
      var ajax = this.ajax;
      var project = this.project;
      var selectedPathogen = this.selectedPathogen;
      var selectedDrug = this.selectedDrug;
      var selectedAuthority = this.selectedAuthority;
      var selectedDeliveryMechanism = this.selectedDeliveryMechanism;
      var url = "api/projects/".concat(project.id, "/heatmap/infection_types") + "?pathogen=".concat(encodeURIComponent(selectedPathogen)) + "&drug_name=".concat(encodeURIComponent(selectedDrug)) + "&authority=".concat(encodeURIComponent(selectedAuthority)) + "&delivery_mechanism=".concat(encodeURIComponent(selectedDeliveryMechanism));
      ajax.request(url).then(this._handleInfectionTypes.bind(this)).catch();
    },
    _handleDrugs: function _handleDrugs(drugs) {
      Ember.set(this, 'drugs', drugs);
      Ember.set(this, 'selectedDrug', drugs.objectAt(0));

      this._setupAuthorities();
    },
    _handleAuthorities: function _handleAuthorities(authorities) {
      Ember.set(this, 'authorities', authorities);
      Ember.set(this, 'selectedAuthority', authorities.objectAt(0));

      this._setupDeliveryMechanisms();
    },
    _handleDeliveryMechanisms: function _handleDeliveryMechanisms(deliveryMechanisms) {
      Ember.set(this, 'deliveryMechanisms', deliveryMechanisms);
      Ember.set(this, 'selectedDeliveryMechanism', deliveryMechanisms.objectAt(0));

      this._setupInfectionTypes();
    },
    _handleInfectionTypes: function _handleInfectionTypes(infectionTypes) {
      Ember.set(this, 'infectionTypes', infectionTypes);
      Ember.set(this, 'selectedInfectionType', infectionTypes.objectAt(0));
    },
    _backToMain: function _backToMain() {
      Ember.$("#".concat(this.elementId, " .phenotype-selection")).hide("slide", {
        direction: "right"
      }, 250);
      Ember.$("#".concat(this.elementId, " .rate-type-selection")).hide("slide", {
        direction: "right"
      }, 250);
      Ember.$("#".concat(this.elementId, " .pathogen-selection")).hide("slide", {
        direction: "right"
      }, 250);
      Ember.$("#".concat(this.elementId, " .drug-selection")).hide("slide", {
        direction: "right"
      }, 250);
      Ember.$("#".concat(this.elementId, " .main")).show("slide", {
        direction: "left"
      }, 250);
    }
  });

  _exports.default = _default;
});