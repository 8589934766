define("mvp/pods/components/ui-demo-data/site/sample-details/specimen-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "B": [{
      "value": "BC",
      "name": "Blood Culture"
    }, {
      "value": "BAL",
      "name": "Bronchoalveolar lavage/wash"
    }, {
      "value": "EYE",
      "name": "Eye/Conjunctiva"
    }, {
      "value": "IP",
      "name": "Invasive Pulmonary (not BAL)"
    }, {
      "value": "ME",
      "name": "Middle Ear Fluid"
    }, {
      "value": "PF",
      "name": "Pleural Fluid"
    }, {
      "value": "SI",
      "name": "Sinus"
    }, {
      "value": "SP",
      "name": "Sputum (Lower respiratory tract)"
    }, {
      "value": "TA",
      "name": "Endotracheal Tube/Tracheal Aspirate"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "URT",
      "name": "Upper Respiratory Tract"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "CX": [{
      "value": "BAL",
      "name": "Bronchoalveolar lavage/wash"
    }, {
      "value": "IP",
      "name": "Invasive Pulmonary (not BAL)"
    }, {
      "value": "PF",
      "name": "Pleural Fluid"
    }, {
      "value": "SP",
      "name": "Sputum (Lower respiratory tract)"
    }, {
      "value": "TA",
      "name": "Endotracheal Tube/Tracheal Aspirate"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "D": [{
      "value": "AB",
      "name": "Abscess (pus)"
    }, {
      "value": "BON",
      "name": "Bone/Joint Culture"
    }, {
      "value": "DFI",
      "name": "Diabetic Foot Infection"
    }, {
      "value": "TI",
      "name": "Tissues"
    }, {
      "value": "WD",
      "name": "Skin/Skin Structure/Wound/Drainage/Ulcer"
    }, {
      "value": "ASP",
      "name": "Wound Aspirate"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "E": [{
      "value": "TI",
      "name": "Tissue"
    }, {
      "value": "UC",
      "name": "Ureteral Catheter"
    }, {
      "value": "FOL",
      "name": "Urethral Catheter (Foley)"
    }, {
      "value": "UR",
      "name": "Urine/Urinary Tract"
    }, {
      "value": "WD",
      "name": "Wound/Drainage/Ulcer"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "EX": [{
      "value": "UC",
      "name": "Ureteral Catheter"
    }, {
      "value": "FOL",
      "name": "Urethral Catheter (Foley)"
    }, {
      "value": "UR",
      "name": "Urine/Urinary Tract"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "F": [{
      "value": "AB",
      "name": "Abscess (pus)"
    }, {
      "value": "ASP",
      "name": "Aspirate"
    }, {
      "value": "BC",
      "name": "Blood Culture"
    }, {
      "value": "BON",
      "name": "Bone/Joint Culture"
    }, {
      "value": "BAL",
      "name": "Bronchoalveolar lavage/wash"
    }, {
      "value": "CSF",
      "name": "Cerebrospinal Fluid"
    }, {
      "value": "ENT",
      "name": "Ear/Nose/Throat"
    }, {
      "value": "EYE",
      "name": "Eye/Conjunctiva"
    }, {
      "value": "GEN",
      "name": "Genital Tract"
    }, {
      "value": "GI",
      "name": "GI Tract/Bowel/Bile"
    }, {
      "value": "IV",
      "name": "Intraveneous/IV Line/Catheter"
    }, {
      "value": "IP",
      "name": "Invasive Pulmonary (not BAL)"
    }, {
      "value": "LRT",
      "name": "Lower Respiratory Tract"
    }, {
      "value": "PRT",
      "name": "Peritoneal Fluid"
    }, {
      "value": "PF",
      "name": "Pleural Fluid"
    }, {
      "value": "REC",
      "name": "Rectum/Rectal Swab"
    }, {
      "value": "SI",
      "name": "Sinus"
    }, {
      "value": "SP",
      "name": "Sputum"
    }, {
      "value": "ST",
      "name": "Stool"
    }, {
      "value": "TI",
      "name": "Tissue"
    }, {
      "value": "TA",
      "name": "Endotrachael Tube/Tracheal Aspirate"
    }, {
      "value": "URT",
      "name": "Upper Respiratory Tract"
    }, {
      "value": "UC",
      "name": "Ureteral Catheter"
    }, {
      "value": "UR",
      "name": "Urine/Urinary Tract"
    }, {
      "value": "WD",
      "name": "Skin/Skin Structure/Wound/Drainage/Ulcer"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "G": [{
      "value": "ST",
      "name": "Stool"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "N": [{
      "value": "AB",
      "name": "Abscess (pus)"
    }, {
      "value": "ASP",
      "name": "Aspirate"
    }, {
      "value": "BC",
      "name": "Blood Culture"
    }, {
      "value": "BON",
      "name": "Bone/Joint Culture"
    }, {
      "value": "DIA",
      "name": "Dialysis Line"
    }, {
      "value": "END",
      "name": "Endocarditis"
    }, {
      "value": "ENT",
      "name": "Ear/Nose/Throat"
    }, {
      "value": "GI",
      "name": "GI Tract/Bowel/Bile"
    }, {
      "value": "IV",
      "name": "Intraveneous/IV Line/Catheter"
    }, {
      "value": "LRT",
      "name": "Lower Respiratory Tract"
    }, {
      "value": "GEN",
      "name": "Genital Tract"
    }, {
      "value": "UR",
      "name": "Urine/Urinary Tract"
    }, {
      "value": "WD",
      "name": "Skin/Skin Structure/Wound/Drainage/Ulcer"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "Q": [{
      "value": "AB",
      "name": "Abscess (pus)"
    }, {
      "value": "ASP",
      "name": "Aspirate"
    }, {
      "value": "GI",
      "name": "GI tract/Bowel/Bile"
    }, {
      "value": "PRT",
      "name": "Peritoneal Fluid"
    }, {
      "value": "REC",
      "name": "Rectum/Rectal Swab"
    }, {
      "value": "TI",
      "name": "Tissue"
    }, {
      "value": "WD",
      "name": "Skin/Skin Structure/Wound/Drainage/Ulcer"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "V": [{
      "value": "BC",
      "name": "Blood Culture"
    }, {
      "value": "BON",
      "name": "Bone/Joint Culture"
    }, {
      "value": "BAL",
      "name": "Bronchoalveolar lavage/wash"
    }, {
      "value": "CSF",
      "name": "Cerebrospinal Fluid"
    }, {
      "value": "DBI",
      "name": "Diabetic Foot Infection"
    }, {
      "value": "DIA",
      "name": "Dialysis Line"
    }, {
      "value": "ENT",
      "name": "Ear/Nose/Throat"
    }, {
      "value": "EYE",
      "name": "Eye/Conjunctiva"
    }, {
      "value": "GEN",
      "name": "Genital Tract"
    }, {
      "value": "GI",
      "name": "GI Tract/Bowel/Bile"
    }, {
      "value": "IV",
      "name": "Intraveneous/IV Line/Catheter"
    }, {
      "value": "IP",
      "name": "Invasive Pulmonary (not BAL)"
    }, {
      "value": "LRT",
      "name": "Lower Respiratory Tract"
    }, {
      "value": "ME",
      "name": "Middle Ear Fluid"
    }, {
      "value": "PRT",
      "name": "Peritoneal Fluid"
    }, {
      "value": "PF",
      "name": "Pleural Fluid"
    }, {
      "value": "REC",
      "name": "Rectum/Rectal Swab"
    }, {
      "value": "SI",
      "name": "Sinus"
    }, {
      "value": "SP",
      "name": "Sputum"
    }, {
      "value": "TI",
      "name": "Tissue"
    }, {
      "value": "TA",
      "name": "Endotrachael Tube/Tracheal Aspirate"
    }, {
      "value": "URT",
      "name": "Upper Respiratory Tract"
    }, {
      "value": "UC",
      "name": "Ureteral Catheter"
    }, {
      "value": "UR",
      "name": "Urine/Urinary Tract"
    }, {
      "value": "WD",
      "name": "Skin/Skin Structure/Wound/Drainage/Ulcer"
    }, {
      "value": "ASP",
      "name": "Aspirate"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "CR": [{
      "value": "BC",
      "name": "Blood Culture"
    }, {
      "value": "BON",
      "name": "Bone/Joint Culture"
    }, {
      "value": "BAL",
      "name": "Bronchoalveolar lavage/wash"
    }, {
      "value": "CSF",
      "name": "Cerebrospinal Fluid"
    }, {
      "value": "DBI",
      "name": "Diabetic Foot Infection"
    }, {
      "value": "DIA",
      "name": "Dialysis Line"
    }, {
      "value": "ENT",
      "name": "Ear/Nose/Throat"
    }, {
      "value": "EYE",
      "name": "Eye/Conjunctiva"
    }, {
      "value": "GEN",
      "name": "Genital Tract"
    }, {
      "value": "GI",
      "name": "GI Tract/Bowel/Bile"
    }, {
      "value": "IV",
      "name": "Intraveneous/IV Line/Catheter"
    }, {
      "value": "IP",
      "name": "Invasive Pulmonary (not BAL)"
    }, {
      "value": "LRT",
      "name": "Lower Respiratory Tract"
    }, {
      "value": "ME",
      "name": "Middle Ear Fluid"
    }, {
      "value": "PRT",
      "name": "Peritoneal Fluid"
    }, {
      "value": "PF",
      "name": "Pleural Fluid"
    }, {
      "value": "REC",
      "name": "Rectum/Rectal Swab"
    }, {
      "value": "SI",
      "name": "Sinus"
    }, {
      "value": "SP",
      "name": "Sputum"
    }, {
      "value": "TI",
      "name": "Tissue"
    }, {
      "value": "TA",
      "name": "Endotrachael Tube/Tracheal Aspirate"
    }, {
      "value": "URT",
      "name": "Upper Respiratory Tract"
    }, {
      "value": "UC",
      "name": "Ureteral Catheter"
    }, {
      "value": "UR",
      "name": "Urine/Urinary Tract"
    }, {
      "value": "WD",
      "name": "Skin/Skin Structure/Wound/Drainage/Ulcer"
    }, {
      "value": "ASP",
      "name": "Aspirate"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "X": [{
      "value": "ASP",
      "name": "Aspirate"
    }, {
      "value": "BC",
      "name": "Blood Culture"
    }, {
      "value": "BON",
      "name": "Bone/Joint Culture"
    }, {
      "value": "BAL",
      "name": "Bronchoalveolar lavage/wash"
    }, {
      "value": "CSF",
      "name": "Cerebrospinal Fluid"
    }, {
      "value": "DFI",
      "name": "Diabetic Foot Infection"
    }, {
      "value": "DIA",
      "name": "Dialysis Line"
    }, {
      "value": "ENT",
      "name": "Ear/Nose/Throat"
    }, {
      "value": "EYE",
      "name": "Eye/Conjunctiva"
    }, {
      "value": "GEN",
      "name": "Genital Tract"
    }, {
      "value": "GI",
      "name": "GI Tract/Bowel/Bile"
    }, {
      "value": "IV",
      "name": "Intraveneous/IV Line/Catheter"
    }, {
      "value": "IP",
      "name": "Invasive Pulmonary (not BAL)"
    }, {
      "value": "LRT",
      "name": "Lower Respiratory Tract"
    }, {
      "value": "ME",
      "name": "Middle Ear Fluid"
    }, {
      "value": "PRT",
      "name": "Peritoneal Fluid"
    }, {
      "value": "PF",
      "name": "Pleural Fluid"
    }, {
      "value": "REC",
      "name": "Rectum/Rectal Swab"
    }, {
      "value": "SI",
      "name": "Sinus"
    }, {
      "value": "SP",
      "name": "Sputum"
    }, {
      "value": "ST",
      "name": "Stool"
    }, {
      "value": "TI",
      "name": "Tissue"
    }, {
      "value": "TA",
      "name": "Endotrachael Tube/Tracheal Aspirate"
    }, {
      "value": "URT",
      "name": "Upper Respiratory Tract"
    }, {
      "value": "UC",
      "name": "Ureteral Catheter"
    }, {
      "value": "UR",
      "name": "Urine/Urinary Tract"
    }, {
      "value": "WD",
      "name": "Skin/Skin Structure/Wound/Drainage/Ulcer"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "XX": [{
      "value": "ASP",
      "name": "Aspirate"
    }, {
      "value": "AD",
      "name": "Atopic Dermatitis"
    }, {
      "value": "BC",
      "name": "Blood Culture"
    }, {
      "value": "BON",
      "name": "Bone/Joint Culture"
    }, {
      "value": "BAL",
      "name": "Bronchoalveolar lavage/wash"
    }, {
      "value": "CSF",
      "name": "Cerebrospinal Fluid"
    }, {
      "value": "DFI",
      "name": "Diabetic Foot Infection"
    }, {
      "value": "DIA",
      "name": "Dialysis Line"
    }, {
      "value": "ENT",
      "name": "Ear/Nose/Throat"
    }, {
      "value": "EYE",
      "name": "Eye/Conjunctiva"
    }, {
      "value": "GEN",
      "name": "Genital Tract"
    }, {
      "value": "GI",
      "name": "GI Tract/Bowel/Bile"
    }, {
      "value": "IV",
      "name": "Intraveneous/IV Line/Catheter"
    }, {
      "value": "IP",
      "name": "Invasive Pulmonary (not BAL)"
    }, {
      "value": "LRT",
      "name": "Lower Respiratory Tract"
    }, {
      "value": "ME",
      "name": "Middle Ear Fluid"
    }, {
      "value": "PRT",
      "name": "Peritoneal Fluid"
    }, {
      "value": "PF",
      "name": "Pleural Fluid"
    }, {
      "value": "REC",
      "name": "Rectum/Rectal Swab"
    }, {
      "value": "SI",
      "name": "Sinus"
    }, {
      "value": "SP",
      "name": "Sputum"
    }, {
      "value": "ST",
      "name": "Stool"
    }, {
      "value": "TI",
      "name": "Tissue"
    }, {
      "value": "TA",
      "name": "Endotrachael Tube/Tracheal Aspirate"
    }, {
      "value": "URT",
      "name": "Upper Respiratory Tract"
    }, {
      "value": "UC",
      "name": "Ureteral Catheter"
    }, {
      "value": "UR",
      "name": "Urine/Urinary Tract"
    }, {
      "value": "WD",
      "name": "Skin/Skin Structure/Wound/Drainage/Ulcer"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }],
    "other": [{
      "value": "BAL",
      "name": "Bronchoalveolar lavage/wash"
    }, {
      "value": "IP",
      "name": "Invasive Pulmonary (not BAL)"
    }, {
      "value": "PF",
      "name": "Pleural Fluid"
    }, {
      "value": "SP",
      "name": "Sputum (Lower respiratory tract)"
    }, {
      "value": "TA",
      "name": "Endotracheal Tube/Tracheal Aspirate"
    }, {
      "value": "OTH",
      "name": "Other or Unknown"
    }, {
      "value": "",
      "name": "NULL"
    }]
  };
  _exports.default = _default;
});