define("mvp/pods/components/ui-surveillance/publications/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pt2yFTN2",
    "block": "{\"symbols\":[\"filter\"],\"statements\":[[7,\"h2\"],[11,\"class\",\"ui header\"],[9],[0,\"\\n    \"],[1,[23,[\"publication\",\"name\"]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sub header\"],[9],[0,\"\\n    Last updated \"],[1,[27,\"moment-from-now\",[[23,[\"publication\",\"updated_at\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"ui segment\"],[9],[0,\"\\n\\n  \"],[7,\"h4\"],[11,\"class\",\"ui header\"],[9],[0,\"Organisms & Organism Groups\"],[10],[0,\"\\n  \"],[7,\"table\"],[9],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"filters\"]]],null,{\"statements\":[[0,\"\\n\\n        \"],[7,\"tr\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[27,\"gt\",[[22,1,[\"indentation_level\"]],0],null]],null,{\"statements\":[[0,\"              \"],[7,\"td\"],[12,\"style\",[28,[\"padding-left:\",[27,\"add-indentation-level-padding\",[[22,1,[\"indentation_level\"]]],null],\"px;\"]]],[9],[0,\"\\n                \"],[7,\"i\"],[11,\"class\",\"caret right icon\"],[9],[10],[0,\"\\n                \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[1,[22,1,[\"name\"]],true],[3,\"action\",[[22,0,[]],\"filter-selected\",[22,1,[]]]],[10],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"td\"],[9],[0,\"\\n              \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[1,[22,1,[\"name\"]],true],[3,\"action\",[[22,0,[]],\"filter-selected\",[22,1,[]]]],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n          \"],[7,\"td\"],[11,\"class\",\"age\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"right\"],[9],[0,\"\\n              updated \"],[1,[27,\"moment-from-now\",[[22,1,[\"updated_at\"]]],null],false],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/publications/show/template.hbs"
    }
  });

  _exports.default = _default;
});