define("mvp/pods/components/ui-surveillance/quality-control/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service("store"),
    filter: undefined,
    drugsUrl: "/api/qc/drugs",
    panelsUrl: "/api/qc/panels",
    lotsUrl: "/api/qc/lots",
    readDatesUrl: "/api/qc/read_dates",
    runsUrl: "/api/qc/runs",
    readersUrl: "/api/qc/readers",
    criteria: "",
    observeIncludeSummary: function () {
      this.notifyPropertyChange("filter");
    }.observes("include_summary.length"),
    updateCriteria: function () {
      var criteria = {
        objective: {
          include: ["QC"]
        },
        project: "SENTRY QC"
      };
      var panels = this.panels;

      if (panels.length > 0) {
        criteria["panel_name"] = {
          include: panels
        };
      }

      var lots = this.lots;

      if (lots.length > 0) {
        criteria["panel_lot"] = {
          include: lots
        };
      }

      var read_dates = this.read_dates;

      if (read_dates.length > 0) {
        criteria["read_date"] = {
          include: read_dates
        };
      }

      var runs = this.runs;

      if (runs.length > 0) {
        criteria["run_number"] = {
          include: runs
        };
      }

      var readers = this.readers;

      if (readers.length > 0) {
        criteria["reader_name"] = {
          include: readers
        };
      }

      Ember.set(this, "criteria", encodeURIComponent(JSON.stringify(criteria)));
    }.observes("drugs.length", "panels.length", "lots.length", "read_dates.length", "runs.length", "readers.length"),
    buildQcFilter: function () {
      Ember.set(this, "error_filter", false);
      Ember.set(this, "filter", undefined);
      var drugs = this.drugs;

      if (drugs.length > 0) {
        Ember.set(this, "loading_filter", true);
        var group_by = encodeURIComponent(JSON.stringify(this.splits));
        var filter = this.store.createRecord("filter", {
          criteria: this.criteria,
          debug: false,
          only_clinical_results: false,
          group_by: group_by,
          include_drugs: drugs
        });

        var _self = this;

        filter.save().then(_self._reloadFilter.bind(_self, filter));
      }
    }.observes("drugs.length", "splits.length", "criteria"),
    init: function init() {
      this._super.apply(this, arguments);

      this.splits = ["organism_code"];
      this.include_summary = [];
      this.drugs = [];
      this.panels = [];
      this.lots = [];
      this.read_dates = [];
      this.runs = [];
      this.readers = [];
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.setupCheckBoxes();
    },
    actions: {
      "view-isolate": function viewIsolate() {},
      "update-selected-drug": function updateSelectedDrug(values) {
        this.updateSelectedHelper("drugs", values);
      },
      "update-selected-panel": function updateSelectedPanel(values) {
        this.updateSelectedHelper("panels", values);
      },
      "update-selected-lots": function updateSelectedLots(values) {
        this.updateSelectedHelper("lots", values);
      },
      "update-selected-read-dates": function updateSelectedReadDates(values) {
        this.updateSelectedHelper("read_dates", values);
      },
      "update-selected-runs": function updateSelectedRuns(values) {
        this.updateSelectedHelper("runs", values);
      },
      "update-selected-readers": function updateSelectedReaders(values) {
        this.updateSelectedHelper("readers", values);
      }
    },
    updateSelectedHelper: function updateSelectedHelper(holder, values) {
      var vals = values.split(",").map(function (item) {
        return decodeURIComponent(item);
      });

      if (vals[0] !== "") {
        Ember.set(this, holder, vals);
      } else {
        Ember.set(this, holder, []);
      }
    },
    setupCheckBoxes: function setupCheckBoxes() {
      this.setupCheckBoxSplitHelper("panels", "panel_name");
      this.setupCheckBoxSplitHelper("lots", "panel_lot");
      this.setupCheckBoxSplitHelper("read-dates", "read_date");
      this.setupCheckBoxSplitHelper("runs", "run_number");
      this.setupCheckBoxSplitHelper("readers", "reader_name");
      this.setupCheckBoxSummaryHelper("panels", "panel_name");
      this.setupCheckBoxSummaryHelper("lots", "panel_lot");
      this.setupCheckBoxSummaryHelper("read-dates", "read_date");
      this.setupCheckBoxSummaryHelper("runs", "run_number");
      this.setupCheckBoxSummaryHelper("readers", "reader_name");
    },
    setupCheckBoxSplitHelper: function setupCheckBoxSplitHelper(matcher, split_by) {
      var _self = this; // Setup genus group inclusion checkbox


      Ember.$("#" + this.elementId + " .checkbox." + matcher).checkbox({
        onChecked: function onChecked() {
          var splits = Ember.get(_self, "splits");
          splits.addObject(split_by);
          Ember.set(_self, "splits", splits);
          Ember.$("#" + _self.elementId + " .checkbox." + matcher + "-summary").checkbox("check");
        },
        onUnchecked: function onUnchecked() {
          var splits = Ember.get(_self, "splits");
          splits.removeObject(split_by);
          Ember.set(_self, "splits", splits);
          Ember.$("#" + _self.elementId + " .checkbox." + matcher + "-summary").checkbox("uncheck");
        }
      });
    },
    setupCheckBoxSummaryHelper: function setupCheckBoxSummaryHelper(matcher, summary) {
      var _self = this; // Setup genus group inclusion checkbox


      Ember.$("#" + this.elementId + " .checkbox." + matcher + "-summary").checkbox({
        onChecked: function onChecked() {
          var include_summary = Ember.get(_self, "include_summary");
          include_summary.addObject(summary);
          Ember.set(_self, "include_summary", include_summary);
        },
        onUnchecked: function onUnchecked() {
          var include_summary = Ember.get(_self, "include_summary");
          include_summary.removeObject(summary);
          Ember.set(_self, "include_summary", include_summary);
        }
      });
    },
    _saveFilter: function _saveFilter(completed_filter) {
      Ember.set(this, "loading_filter", false);
      Ember.set(this, "filter", completed_filter);
    },
    _reloadFilter: function _reloadFilter(filter, completed_filter) {
      filter.reload().then(this._saveFilter.bind(this, completed_filter), function () {
        Ember.set(this, "loading_filter", false);
        Ember.set(this, "error_filter", true);
      });
    }
  });

  _exports.default = _default;
});