define("mvp/pods/components/ui-site-management/icon-hover/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onHoverIn: function onHoverIn() {
      Ember.$(".ui.icon").popup({
        popup: '.popup',
        position: 'top left'
      });
    },
    onHoverOut: function onHoverOut() {
      Ember.$(".ui.icon").popup('hide');
    },
    didInsertElement: function didInsertElement() {
      this.$().hover(this.onHoverIn.bind(this), this.onHoverOut.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('mouseenter mouseleave');
    }
  });

  _exports.default = _default;
});