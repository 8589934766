define("mvp/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise"], function (_exports, _devise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devise.default.extend({
    serverTokenEndpoint: '/api/users/sign_in'
  });

  _exports.default = _default;
});