define("mvp/pods/demo-data/index/received/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexSiteCodeController: Ember.inject.controller('demo-data.index'),
    queryParams: ['page', 'perPage', 'siteCode'],
    page: Ember.computed.alias('indexSiteCodeController.indexPage') || 1,
    perPage: 10,
    siteCode: Ember.computed.alias('indexSiteCodeController.indexSiteCode'),
    pagedContent: (0, _pagedArray.default)('content', {
      page: Ember.computed.alias("parent.page"),
      perPage: Ember.computed.alias("parent.perPage"),
      totalPages: Ember.computed.alias("parent.model.pageInfo.totalPages")
    })
  });

  _exports.default = _default;
});