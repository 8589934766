define("mvp/pods/components/ui-admin/sentry-containers/isolates-target-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    actions: {},
    setupCell: function setupCell() {
      var target_isolates_count = 0;
      Ember.get(this.organization, 'sentry_site_containers').forEach(function (container) {
        target_isolates_count = Ember.get(container, 'promised_isolate_count');
      });
      Ember.set(this, 'target_isolates_count', target_isolates_count);
    }
  });

  _exports.default = _default;
});