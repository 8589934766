define("mvp/pods/components/ui-application/header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service("media"),
    classNameBindings: ['media.classNames'],
    actions: {
      toggleSidebar: function toggleSidebar() {
        Ember.$('.ui.sidebar').sidebar('toggle');
      }
    }
  });

  _exports.default = _default;
});