define("mvp/pods/manage/projects/edit/settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    ajax: Ember.inject.service("ajax"),
    actions: {
      update: function update() {
        Ember.$(".update.form").addClass("loading");
        this.model.save().then(this._removeLoadingClassFromForm);
      },
      "update-project-drugs": function updateProjectDrugs() {
        Ember.$("form.project-drugs").addClass("loading");
        Ember.RSVP.allSettled(Ember.get(this, "model.project_drugs").invoke("save")).then(this._removeLoadingClassFromDrugs);
      },
      updateProjectDrugs: function updateProjectDrugs(collection, newOrder) {
        collection.setObjects(newOrder);
        collection.forEach(function (item, index) {
          Ember.set(item, "sort_ordinal", index);
        });
      }
    },
    _removeLoadingClassFromDrugs: function _removeLoadingClassFromDrugs() {
      Ember.$("form.project-drugs").removeClass("loading");
    },
    _removeLoadingClassFromForm: function _removeLoadingClassFromForm() {
      Ember.$(".update.form").removeClass("loading");
    }
  });

  _exports.default = _default;
});