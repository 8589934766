define("mvp/pods/components/ui-admin/sentry-compliance/status-action-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    zeroPadSiteCode: Ember.computed('organization', function () {
      var z = '0';
      var width = 3;
      var site_code_string = Ember.get(this, 'organization.site_code') + '';
      return site_code_string.length >= width ? site_code_string : new Array(width - site_code_string.length + 1).join(z) + site_code_string;
    })
  });

  _exports.default = _default;
});