define("mvp/pods/components/ui-site-management/form/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "21YYOV4h",
    "block": "{\"symbols\":[\"select_option\"],\"statements\":[[7,\"select\"],[12,\"required\",[21,\"required\"]],[11,\"class\",\"ui selection dropdown\"],[12,\"name\",[22,0,[\"name\"]]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"setSelection\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"unless\",[[22,0,[\"changesetValue\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[11,\"value\",\"\"],[11,\"selected\",\"true\"],[9],[0,\"-- Select One --\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[22,0,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,1,[]]],[12,\"selected\",[27,\"if\",[[27,\"eq\",[[22,1,[]],[22,0,[\"changesetValue\"]]],null],\"true\"],null]],[9],[1,[22,1,[]],false],[10],[0,\"      \\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-site-management/form/select/template.hbs"
    }
  });

  _exports.default = _default;
});