define("mvp/pods/components/ui-demo-data/show/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals moment */
  var _default = Ember.Component.extend({
    tagName: "tr",
    line: undefined,
    // "static-null-options": [{value: "", name: ""}],
    sourceOfBsiUrl: Ember.computed("objective", function () {
      return "/api/demos/source-of-bsi" + "?objective=" + encodeURIComponent(this.objective);
    }),
    sexUrl: Ember.computed("objective", function () {
      return "/api/demos/sex" + "?objective=" + encodeURIComponent(this.objective);
    }),
    serviceUrl: Ember.computed("objective", function () {
      return "/api/demos/service" + "?objective=" + encodeURIComponent(this.objective);
    }),
    primaryDiagnosisUrl: Ember.computed("objective", function () {
      return "/api/demos/primary-diagnosis" + "?objective=" + encodeURIComponent(this.objective);
    }),
    icuUrl: Ember.computed("objective", function () {
      return "/api/demos/icu" + "?objective=" + encodeURIComponent(this.objective);
    }),
    nosocomialUrl: Ember.computed("objective", function () {
      return "/api/demos/nosocomial" + "?objective=" + encodeURIComponent(this.objective);
    }),
    cfPatientUrl: Ember.computed("objective", function () {
      return "/api/demos/cf-patient" + "?objective=" + encodeURIComponent(this.objective);
    }),
    ncfbPatientUrl: Ember.computed("objective", function () {
      return "/api/demos/ncfb-patient" + "?objective=" + encodeURIComponent(this.objective);
    }),
    specimenUrl: Ember.computed("objective", function () {
      return "/api/demos/specimen" + "?objective=" + encodeURIComponent(this.objective);
    }),
    invalidDates: Ember.computed('line.{admission_date,culture_date}', function () {
      var admit_date = Ember.get(this, 'line.admission_date');
      var culture_date = Ember.get(this, 'line.culture_date');
      var output = false;

      if (admit_date !== '' && culture_date !== '') {
        if (moment(admit_date, 'YYYY-MM-DD').isAfter(culture_date)) {
          output = true;
        }
      }

      return output;
    }),
    observeSourceOfBsiDropdown: function () {
      if (this.sourceOfBsiDropdown !== undefined && Ember.get(this, "line.source_of_bsi") !== "") {
        this.sourceOfBsiDropdown.send("set-selected", Ember.get(this, "line.source_of_bsi"));
      }

      var dropdownId = "#" + this.sourceOfBsiDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("sourceOfBsiDropdown"),
    observeSexDropdown: function () {
      if (this.sexDropdown !== undefined && Ember.get(this, "line.sex") !== "") {
        this.sexDropdown.send("set-selected", Ember.get(this, "line.sex"));
      }

      var dropdownId = "#" + this.sexDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("sexDropdown"),
    observeServiceDropdown: function () {
      if (this.serviceDropdown !== undefined && Ember.get(this, "line.service") !== "") {
        this.serviceDropdown.send("set-selected", Ember.get(this, "line.service"));
      }

      var dropdownId = "#" + this.serviceDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("serviceDropdown"),
    observePrimaryDiagnosisDropdown: function () {
      if (this.primaryDiagnosisDropdown !== undefined && Ember.get(this, "line.primary_diagnosis") !== "") {
        this.primaryDiagnosisDropdown.send("set-selected", Ember.get(this, "line.primary_diagnosis"));
      }

      var dropdownId = "#" + this.primaryDiagnosisDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("primaryDiagnosisDropdown"),
    observeSpecimenDropdown: function () {
      if (this.specimenDropdown !== undefined && Ember.get(this, "line.specimen_type") !== "") {
        this.specimenDropdown.send("set-selected", Ember.get(this, "line.specimen_type"));
      }

      var dropdownId = "#" + this.specimenDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("specimenDropdown"),
    observeVentilatorDropdown: function () {
      if (this.ventilatorDropdown !== undefined && Ember.get(this, "line.vap") !== "") {
        this.ventilatorDropdown.send("set-selected", Ember.get(this, "line.vap"));
      }

      var dropdownId = "#" + this.ventilatorDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("ventilatorDropdown"),
    observeIcuDropdown: function () {
      if (this.icuDropdown !== undefined && Ember.get(this, "line.icu") !== "") {
        this.icuDropdown.send("set-selected", Ember.get(this, "line.icu"));
      }

      var dropdownId = "#" + this.icuDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("icuDropdown"),
    observeNosocomialDropdown: function () {
      if (this.nosocomialDropdown !== undefined && Ember.get(this, "line.nosocomial") !== "") {
        this.nosocomialDropdown.send("set-selected", Ember.get(this, "line.nosocomial"));
      }

      var dropdownId = "#" + this.nosocomialDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("nosocomialDropdown"),
    observeCfpatientDropdown: function () {
      if (this.cfpatientDropdown !== undefined && Ember.get(this, "line.cfpatient") !== "") {
        this.cfpatientDropdown.send("set-selected", Ember.get(this, "line.cfpatient"));
      }

      var dropdownId = "#" + this.cfpatientDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("cfpatientDropdown"),
    observeNcfbpatientDropdown: function () {
      if (this.ncfbpatientDropdown !== undefined && Ember.get(this, "line.ncfbpatient") !== "") {
        this.ncfbpatientDropdown.send("set-selected", Ember.get(this, "line.ncfbpatient"));
      }

      var dropdownId = "#" + this.ncfbpatientDropdown.elementId;
      Ember.$(dropdownId + " input").focus(function () {
        Ember.$(dropdownId).trigger("click");
      });
    }.observes("ncfbpatientDropdown"),
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.demo.form' + ' #' + this.elementId).form({
        on: 'change',
        inline: true,
        fields: {
          age: {
            identifier: 'age',
            optional: true,
            rules: [{
              type: 'integer[0..125]',
              prompt: 'Please enter an age between 0 and 125'
            }]
          }
        },
        selector: {
          field: 'input.age'
        }
      });
    },
    actions: {
      "update-source-of-bsi": function updateSourceOfBsi(val) {
        Ember.set(this, "line.source_of_bsi", val);
        this.notifyPropertyChange("line");
      },
      "update-sex": function updateSex(val) {
        Ember.set(this, "line.sex", val);
      },
      "update-service": function updateService(val) {
        Ember.set(this, "line.service", val);
      },
      "update-primary-diagnosis": function updatePrimaryDiagnosis(val) {
        Ember.set(this, "line.primary_diagnosis", val);
      },
      "update-icu": function updateIcu(val) {
        Ember.set(this, "line.icu", val);
      },
      "update-nosocomial": function updateNosocomial(val) {
        Ember.set(this, "line.nosocomial", val);
      },
      "update-cfpatient": function updateCfpatient(val) {
        Ember.set(this, "line.cfpatient", val);
      },
      "update-ncfbpatient": function updateNcfbpatient(val) {
        Ember.set(this, "line.ncfbpatient", val);
      },
      "update-ventilator": function updateVentilator(val) {
        Ember.set(this, "line.vap", val);
      },
      "update-specimen": function updateSpecimen(val) {
        Ember.set(this, "line.specimen_type", val);
      },
      ageCheck: function ageCheck(e) {
        var age = parseInt(e.target.value);

        if (age > 125 || age < 0) {
          e.target.value = "";
          return alert("Selection must be a number between 0 and 125");
        } else {
          this.set("line.age", age);
        }
      }
    }
  });

  _exports.default = _default;
});