define("mvp/pods/components/ui-demo-data/show/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    saving: false,
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$("#".concat(this.elementId, " .dropdown input")).focus(function () {
        Ember.$("#".concat(self.elementId, " .table-wrapper-scroll-x")).addClass("extra-padding");
      });
    },
    actions: {
      save: function save() {
        var self = this;
        Ember.set(this, "saving", true);
        this.demo.save().then(self._sendDoneSavingAction.bind(self), self._saveErrors.bind(self));
      }
    },
    _sendDoneSavingAction: function _sendDoneSavingAction() {
      Ember.set(this, "saving", false);
      this.doneSaving();
    },
    _saveErrors: function _saveErrors(err) {
      var _this = this;

      Ember.set(this, "saving", false);
      Ember.set(this, 'error', err.message);
      var self = this;
      Ember.run.later(function () {
        Ember.set(self, 'error', undefined);
        Ember.$("#".concat(_this.elementId, " .error.message .close")).on('click', self._fadeoutErrorMessages);
      }, 100);
    },
    _fadeoutErrorMessages: function _fadeoutErrorMessages() {
      Ember.$(this).closest('.message').transition('fade');
    }
  });

  _exports.default = _default;
});