define("mvp/helpers/compliance-row-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.complianceRowClass = complianceRowClass;
  _exports.default = void 0;

  function complianceRowClass(params
  /*, hash*/
  ) {
    if (params[0] === true && params[1] / params[2] > .95) {
      return "sentry-year-fulfilled sentry-year-paid";
    } else if (params[0] === true) {
      return "sentry-year-paid";
    } else if (params[1] / params[2] > .95) {
      return "sentry-year-fulfilled";
    } else {
      return "";
    }
  }

  var _default = Ember.Helper.helper(complianceRowClass);

  _exports.default = _default;
});