define("mvp/pods/site-management/compliance-summary/by-site/confirm-send-report/route", ["exports", "ember-apollo-client"], function (_exports, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model() {
      return this.modelFor('site-management.compliance-summary.by-site');
    }
  });

  _exports.default = _default;
});