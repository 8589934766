define("mvp/pods/components/ui-demo-data/site/sample-details/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "value": "AMB",
    "name": "AMB - Ambulatory/Outpatient"
  }, {
    "value": "BUR",
    "name": "BUR - Burn"
  }, {
    "value": "CAR",
    "name": "CAR - Cardiothoracic/Pulmonary"
  }, {
    "value": "DER",
    "name": "DER - Dermatology"
  }, {
    "value": "DIA",
    "name": "DIA - Dialysis"
  }, {
    "value": "ENT",
    "name": "ENT - Ear, Nose, Throat (Otolarynology)"
  }, {
    "value": "ER",
    "name": "ER - Emergency"
  }, {
    "value": "FAM",
    "name": "FAM - Family Practice"
  }, {
    "value": "GEN",
    "name": "GEN - Gastroenterology/GI"
  }, {
    "value": "GER",
    "name": "GER - Geriatics"
  }, {
    "value": "HEM",
    "name": "HEM - Hematology/Oncology"
  }, {
    "value": "ICU",
    "name": "ICU - Intensive Care Unit"
  }, {
    "value": "ID",
    "name": "ID - Infectious Disease"
  }, {
    "value": "LTC",
    "name": "LTC - Long-Term Care"
  }, {
    "value": "MED",
    "name": "MED - Internal Medicine"
  }, {
    "value": "NEU",
    "name": "NEU - Neurology"
  }, {
    "value": "NS",
    "name": "NS - Neurosurgery"
  }, {
    "value": "OBGYN",
    "name": "OBGYN - Obstetrics/Gynecology"
  }, {
    "value": "OPH",
    "name": "OPH - Ophthalmology"
  }, {
    "value": "ORT",
    "name": "ORT - Orthopedics"
  }, {
    "value": "PED",
    "name": "PED - Pediatrics/Neonate"
  }, {
    "value": "PSY",
    "name": "PSY - Psychiatry"
  }, {
    "value": "REN",
    "name": "REN - Renal"
  }, {
    "value": "RH",
    "name": "RH - Rehabilitation"
  }, {
    "value": "SUR",
    "name": "SUR - Surgery"
  }, {
    "value": "TRA",
    "name": "TRA - Transplant"
  }, {
    "value": "TRM",
    "name": "TRM - Trauma"
  }, {
    "value": "URO",
    "name": "URO - Urology"
  }, {
    "value": "",
    "name": "NULL"
  }];
  _exports.default = _default;
});