define("mvp/pods/components/ui-surveillance/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WF5O14ah",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"loader container\"],[9],[0,\"\\n  \"],[1,[21,\"inline-loader\"],false],[0,\"\\n\"],[4,\"unless\",[[23,[\"without-steps\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"ui divider\"],[9],[10],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"ui header\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"sub header\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"step\"]]],null,{\"statements\":[[0,\"          \"],[1,[21,\"step\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[21,\"stepToShow\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/loading/template.hbs"
    }
  });

  _exports.default = _default;
});