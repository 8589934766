define("mvp/pods/components/ui-surveillance/publications/rss-feed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+IOtACgN",
    "block": "{\"symbols\":[\"publication\",\"index\"],\"statements\":[[7,\"a\"],[11,\"href\",\"https://www.jmilabs.com/jmi-publications/\"],[11,\"target\",\"_blank\"],[11,\"class\",\"ui large more primary button\"],[9],[0,\"\\n  View All Publications\\n\"],[10],[0,\"\\n\\n\"],[7,\"h1\"],[11,\"class\",\"ui header\"],[9],[0,\"Most Recent JMI Publications\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"feed\",\"items\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[22,2,[]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"ui divider\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"ui header\"],[9],[1,[22,1,[\"title\"]],true],[10],[0,\"\\n  \"],[7,\"p\"],[9],[1,[22,1,[\"content:encoded\"]],true],[10],[0,\"\\n\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/publications/rss-feed/template.hbs"
    }
  });

  _exports.default = _default;
});