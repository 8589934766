define("mvp/pods/components/c3-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YV1M2ybU",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"chart-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"chart hidden\"],[12,\"id\",[28,[\"chart_\",[21,\"elementId\"]]]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"ui segment\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"ui active inverted dimmer\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"ui large text loader\"],[9],[0,\"Loading...\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/c3-chart/template.hbs"
    }
  });

  _exports.default = _default;
});