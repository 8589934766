define("mvp/pods/components/d3-charts/infection-types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wYMK2t2I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"height\",\"100%\"],[11,\"width\",\"100%\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/d3-charts/infection-types/template.hbs"
    }
  });

  _exports.default = _default;
});