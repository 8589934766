define("mvp/pods/components/ui-surveillance/adhoc/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    // Services definition
    authorization: Ember.inject.service(),
    store: Ember.inject.service(),
    eventBus: Ember.inject.service("event-bus"),
    media: Ember.inject.service(),
    classNames: ["ui", "stackable", "grid", 'divided'],
    classNameBindings: ['media.classNames'],
    // Filter will be an unsaved filter with the default values in the
    // setup() function above. We will store the selected criteria within
    // filter.criteria.
    filter: undefined,
    // Project will represent the currently selected dataset, a.k.a. protocol.
    // Only one project can be selected at a time.
    project: undefined,
    mvpPublic: Ember.computed(function () {
      return INSTANCE === 'mvp';
    }),
    showClientMolecular: Ember.computed('project.name', function () {
      var allowedProjects = ['SENTRY Public', 'Meropenem-vaborbactam', 'Gepotidacin', 'Cefiderocol'];
      var project = Ember.get(this, 'project.name');
      return allowedProjects.includes(project);
    }),
    // Setup the component after the attributes have been received. In charge of
    // creating a new record in the local store, and initializing default values.
    didReceiveAttrs: function didReceiveAttrs() {
      // Initialize a new filter
      var filter;

      if (this.filter === undefined || Ember.get(this, 'filter.id') !== null) {
        filter = this.store.createRecord("filter");
      } else {
        filter = this.filter;
      } // Initialize default filter values


      Ember.set(filter, "only_clinical_results", true);
      Ember.set(filter, "debug", false);

      if (this.filter !== undefined) {
        var criteria = _objectSpread({}, Ember.get(this, "filter.criteria"));

        delete criteria["project_id"];
        criteria["project"] = Ember.get(this, "project.name");
        Ember.set(filter, "criteria", criteria); // Set the drugs to include only if original filter has
        // specified drugs to include.

        var include_drugs = Ember.get(this, "filter.include_drugs");

        if (include_drugs !== undefined && include_drugs.length > 0) {
          Ember.set(filter, "include_drugs", include_drugs);
        }
      } else if (this.filter === undefined || Ember.get(this, "filter.criteria.project") !== this.project) {
        Ember.set(filter, "criteria", {
          project: Ember.get(this, "project.name")
        });
      }

      if (Ember.get(filter, "include_drugs") !== undefined) {
        Ember.set(this, "adjust-drugs", true);
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.set(this, "filter", filter);
      });
    },
    didInsertElement: function didInsertElement() {
      var elementId = this.elementId;

      var _self = this;

      Ember.$('body').scrollTop(0);
      Ember.$("#" + elementId + " i.help.circle.icon").popup({
        addTouchEvents: false
      });

      if (!Ember.get(this, 'media.isMobile')) {
        Ember.$("#" + this.elementId + " .ui.sticky").sticky({
          context: "#" + this.elementId + " > .twelve.wide.column",
          observeChanges: true,
          offset: 20
        });
      }

      if (Ember.get(this, "currentUser.is_jmi_user")) {
        Ember.$(".ui.checkbox.debug").checkbox({
          onChecked: function onChecked() {
            Ember.set(_self, "filter.debug", true);
          },
          onUnchecked: function onUnchecked() {
            Ember.set(_self, "filter.debug", false);
          }
        }).checkbox("set unchecked");
        Ember.$(".ui.checkbox.only-clinical-results").checkbox({
          onChecked: function onChecked() {
            Ember.set(_self, "filter.only_clinical_results", true);
          },
          onUnchecked: function onUnchecked() {
            Ember.set(_self, "filter.only_clinical_results", false);
          }
        }).checkbox("set checked");
      }
    },
    // Actions for this component
    actions: {
      "setIncludeDrugs": function setIncludeDrugs(drugs) {
        Ember.set(this.filter, "include_drugs", drugs);
      },
      "toggle-adjust-drugs": function toggleAdjustDrugs() {
        Ember.set(this, "adjust-drugs", true);
      },
      // Called when the filter criteria should be updated
      "update-filter-criteria": function updateFilterCriteria(key, obj) {
        Ember.run.scheduleOnce('actions', this, function () {
          var criteria = Ember.get(this, "filter.criteria"); // Break if criteria has already been serialized

          if (Ember.typeOf(criteria) === "string") {
            return;
          }

          if (obj === undefined) {
            var _criteria = Ember.get(this, "filter.criteria");

            if (_criteria !== undefined && _criteria[key] !== undefined) {
              delete _criteria[key]; // Notify observers that the filter was updated

              this.notifyPropertyChange("filter.criteria"); // Update the event bus to notify child components

              this.eventBus.trigger("filterCriteriaChanged", key);
            }
          } else {
            Ember.set(this, "filter.criteria." + key, obj); // Notify observers that the filter was updated

            this.notifyPropertyChange("filter.criteria"); // Update the event bus to notify child components

            this.eventBus.trigger("filterCriteriaChanged", key);
          }
        });
      },
      "filter-selected": function filterSelected(filter) {
        this["filter-selected"](filter);
      }
    } // Reset the project attribute to default values
    // resetProject: function() {
    //   if (get(this, "availableProjects.length") === 1) {
    //     let project = get(this, "availableProjects").objectAt(0);
    //     set(this, "project", project);
    //   } else  {
    //     set(this, "project", undefined);
    //   }
    // },
    // Stores the projects that this user is allowed to view, this is loaded within
    // the route and passed as an attribute
    // availableProjects: undefined,

  });

  _exports.default = _default;
});