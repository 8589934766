define("mvp/pods/surveillance/show/quality-control/runs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['surveillanceYear'],
    runsIndexController: Ember.inject.controller('surveillance.show.quality_control.runs.index'),
    router: Ember.inject.service(),
    surveillanceYear: 2022,
    init: function init() {
      this._super.apply(this, arguments);

      var years = [2017, 2018, 2019, 2020, 2021, 2022];
      Ember.set(this, 'years', years);
    },
    actions: {
      'set-year': function setYear(year) {
        var router = this.router;
        var route = Ember.get(router, 'currentRouteName');

        if (route === 'surveillance.show.quality_control.runs.show') {
          router.transitionTo('surveillance.show.quality_control.runs.index');
        }

        Ember.set(this, 'runsIndexController.page', 1);
        Ember.set(this, 'surveillanceYear', year);
      }
    }
  });

  _exports.default = _default;
});