define("mvp/models/program-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    components: _emberData.default.hasMany("program-components", {
      async: true
    }),
    program: _emberData.default.belongsTo("program", {
      async: true
    })
  });

  _exports.default = _default;
});