define("mvp/transforms/array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return Ember.typeOf(serialized) === "array" ? serialized : [];
    },
    serialize: function serialize(value) {
      return value;
    }
  });

  _exports.default = _default;
});