define("mvp/pods/site-management/sites/show/objectives/controller", ["exports", "ember-cli-pagination/computed/paged-array"], function (_exports, _pagedArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service(),
    queryParams: ['page'],
    page: 1,
    pagedContent: (0, _pagedArray.default)('content', {
      page: Ember.computed.alias("parent.page"),
      totalPages: Ember.computed.alias("parent.model.pageInfo.totalPages")
    }),
    openModal: function openModal() {
      Ember.$(".ui.modal").modal({
        detachable: false,
        observeChanges: false
      }).modal("show");
    }
  });

  _exports.default = _default;
});