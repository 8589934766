define("mvp/pods/admin/users/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GT6ZOXke",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ui-admin/users/show\",null,[[\"user\",\"back\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"back\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/admin/users/show/template.hbs"
    }
  });

  _exports.default = _default;
});