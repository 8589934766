define("mvp/pods/components/is-paratek/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "feJP47h8",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"INSTANCE\"]],\"mvpparatek\"],null]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"   \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/is-paratek/template.hbs"
    }
  });

  _exports.default = _default;
});