define("mvp/pods/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    media: Ember.inject.service("media"),
    classNames: ['test'],
    currentPathDidChange: function () {
      if (!Ember.get(this, "media.isDesktop")) {
        Ember.run.schedule('afterRender', this, function () {
          Ember.$('.ui.sidebar').sidebar({
            context: Ember.$('body > .ember-view')
          }).sidebar('hide');
        });
      }
    }.observes('currentPath')
  });

  _exports.default = _default;
});