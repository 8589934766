define("mvp/pods/surveillance/sentry/isolates/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/AowY67s",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"surveillance.sentry.isolates.index\"],null,{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"left arrow icon\"],[9],[10],[0,\"\\n  Back to all isolates\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"ui divider\"],[9],[10],[0,\"\\n\"],[1,[27,\"ui-surveillance/isolates/show\",null,[[\"isolate\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/sentry/isolates/show/template.hbs"
    }
  });

  _exports.default = _default;
});