define("mvp/authenticators/jwt", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(bearerToken) {
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(bearerToken);
      });
    },
    invalidate: function invalidate(data) {
      return Ember.RSVP.Promise.resolve(data);
    }
  });

  _exports.default = _default;
});