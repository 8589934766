define("mvp/pods/admin/sentry/containers/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service('current-user'),
    queryParams: {
      year: {
        refreshModel: true
      },
      continent: {
        refreshModel: true
      },
      sites: {
        refreshModel: true
      }
    },
    model: function model(params) {
      // Reset Modal Params whenever returning via browser back button
      this.controllerFor('admin.sentry.containers.index').resetModalParams();
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        self.store.unloadAll('organization');
        self.store.unloadAll('sentry-site-container');
        self.store.query('sentry-site-container', params).then(self._resolvePromise.bind(self, resolve));
      });
    },
    _resolvePromise: function _resolvePromise(resolve) {
      resolve(this.store.peekAll('organization'));
    }
  });

  _exports.default = _default;
});