define("mvp/pods/admin/users/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    q: '',
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'queryParams', ['page', 'q']);
    },
    actions: {
      pageChanged: function pageChanged(page) {
        Ember.$('body').scrollTop(0);
        Ember.set(this, 'page', page);
      },
      search: function search(e) {
        if (event.key === 'Enter') {
          Ember.set(this, 'page', 1);
          Ember.set(this, 'q', e.target.value);
        }
      },
      addUser: function addUser() {
        var router = this.get('router');
        router.transitionTo("admin.users.add");
      },
      editUser: function editUser(user) {
        this.transitionToRoute("admin.users.edit", user.id);
      }
    }
  });

  _exports.default = _default;
});