define("mvp/pods/components/ui-surveillance/adhoc/drugs/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    store: Ember.inject.service("store"),
    selectDeselectAllText: Ember.computed("drug-classes.length", "drugs_to_include.length", function () {
      var text = "";

      if (Ember.get(this, "drug-classes.length") === Ember.get(this, "drugs_to_include.length")) {
        text = "Deselect All";
      } else {
        text = "Select All";
      }

      return text;
    }),
    observeProjectDrugs: function () {
      var self = this;
      var store = this.store;
      Ember.run.scheduleOnce('afterRender', this, function () {
        store.findAll("drug").then(function () {
          Ember.get(self, "drug-classes").forEach(function (drug_class) {
            self._updateDrugCheckbox(drug_class);
          });
        });
      });
    }.observes("drug-classes.length"),
    init: function init() {
      this._super.apply(this, arguments);

      this['project-drugs'] = [];
      this.drugs_to_include = [];
      this['drug-classes'] = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var ajax = this.ajax;
      var store = this.store; // const self = this;

      var project = this.project;
      var include_drugs = Ember.get(this, "filter.include_drugs"); // If the filter has included drugs... set the drugs_to_include to be those

      if (include_drugs !== undefined && include_drugs.length > 0) {
        Ember.set(this, "drugs_to_include", include_drugs);
      } // Clear out loaded project-drugs and drugs


      store.unloadAll("project-drug");
      store.unloadAll("drug"); // Get the drug-classes and drugs for this project

      var url = "/api/projects/".concat(project.id, "/drug-classes");
      ajax.request(url).then(this._handleDrugClasses.bind(this)).catch();
    },
    _handleDrugClasses: function _handleDrugClasses(drugClasses) {
      var drug_class_lookup = drugClasses.reduce(function (accumulator, currentValue) {
        if (!accumulator.hasOwnProperty(currentValue["drug_class_name"])) {
          accumulator[currentValue["drug_class_name"]] = [];
        }

        accumulator[currentValue["drug_class_name"]].push(currentValue);
        return accumulator;
      }, {});
      Ember.set(this, "drug-class-lookup", drug_class_lookup);
      Ember.set(this, "drug-class-keys", Object.keys(drug_class_lookup));
      Ember.set(this, "drug-classes", drugClasses);
    },
    actions: {
      setIncludeDrugs: function setIncludeDrugs() {
        this.setIncludeDrugs(this.drugs_to_include);
      },
      addDrug: function addDrug(drug) {
        var drugs_to_include = this.drugs_to_include;
        drugs_to_include.addObject(Ember.get(drug, "name"));
        this.send("setIncludeDrugs");
      },
      removeDrug: function removeDrug(drug) {
        var drugs_to_include = this.drugs_to_include;
        drugs_to_include.removeObject(Ember.get(drug, "name"));
        this.send("setIncludeDrugs");
      },
      "toggle-all-drugs": function toggleAllDrugs() {
        var action;

        if (this.selectDeselectAllText === "Select All") {
          action = "check";
        } else {
          action = "uncheck";
        }

        this["drug-classes"].forEach(function (drug_class) {
          Ember.$(".ui.checkbox.project_drug_".concat(drug_class.drug_id)).checkbox(action);
        });
      },
      "toggle-drug-class-drugs": function toggleDrugClassDrugs(drug_class_name) {
        var action = "uncheck";
        this["drug-classes"].forEach(function (drug_class) {
          if (drug_class.drug_class_name === drug_class_name && Ember.$(".ui.checkbox.project_drug_".concat(drug_class.drug_id, ".checked")).length === 0) {
            action = "check";
          }
        });
        this["drug-classes"].forEach(function (drug_class) {
          if (drug_class.drug_class_name === drug_class_name) {
            Ember.$(".ui.checkbox.project_drug_".concat(drug_class.drug_id)).checkbox(action);
          }
        });
      }
    },
    _updateDrugCheckbox: function _updateDrugCheckbox(drug_class) {
      var index = drug_class.drug_id;
      var drugs_to_include = this.drugs_to_include;
      var include_drugs = Ember.get(this, "filter.include_drugs");
      var store = this.store;
      var drug = store.peekRecord("drug", index);
      var self = this;
      Ember.$(".ui.checkbox.project_drug_" + index).checkbox({
        onChecked: function onChecked() {
          self.send("addDrug", drug);
        },
        onUnchecked: function onUnchecked() {
          self.send("removeDrug", drug);
        }
      });
      var selector = ".ui.checkbox.project_drug_".concat(index);

      if (include_drugs === undefined || include_drugs.length === 0) {
        drugs_to_include.addObject(Ember.get(drug, "name"));
        Ember.$(selector).checkbox("set checked");
      } else {
        if (include_drugs.includes(Ember.get(drug, "name"))) {
          Ember.$(selector).checkbox("set checked");
        }
      }
    },
    _saveDrugs: function _saveDrugs(store, payload) {
      store.pushPayload(payload);
      Ember.set(this, "project-drugs", store.peekAll("project-drug"));
    }
  });

  _exports.default = _default;
});