define("mvp/models/mic-distribution-chart", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    drugs: _emberData.default.attr("array"),
    'charts': _emberData.default.attr("array")
  });

  _exports.default = _default;
});