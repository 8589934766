define("mvp/pods/components/ui-admin/sentry-containers/received-containers-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4pbTzruU",
    "block": "{\"symbols\":[\"container\"],\"statements\":[[4,\"each\",[[23,[\"received_containers\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,1,[\"item_id\"]],false],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-admin/sentry-containers/received-containers-cell/template.hbs"
    }
  });

  _exports.default = _default;
});