define("mvp/pods/surveillance/show/isolates/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    canGoBack: false,
    canGoForward: false,
    previousIsolate: undefined,
    nextIsolate: undefined,
    backRoute: undefined,
    backFilter: undefined,
    surveillanceShowController: Ember.inject.controller("surveillance.show"),
    project: Ember.computed.alias("surveillanceShowController.model"),
    observeIsolates: function () {
      var _self = this;

      if (this.isolates !== undefined && this.isolate !== undefined) {
        this.isolates.forEach(function (item, index) {
          if (item.id === Ember.get(_self, "isolate.id")) {
            if (index > 0) {
              Ember.set(_self, "canGoBack", true);
              Ember.get(_self, "store").find("isolate", Ember.get(_self, "isolates").objectAt(index - 1).id).then(_self._updatePreviousIsolate.bind(_self));
            } else {
              Ember.set(_self, "canGoBack", false);
              Ember.set(_self, "previousIsolate", undefined);
            }

            if (index < Ember.get(_self, "isolates.length") - 1) {
              Ember.set(_self, "canGoForward", true);
              Ember.get(_self, "store").find("isolate", Ember.get(_self, "isolates").objectAt(index + 1).id).then(_self._updateNextIsolate.bind(_self));
            } else {
              Ember.set(_self, "canGoForward", false);
              Ember.set(_self, "nextIsolate", undefined);
            }
          }
        });
      }
    }.observes("isolates", "isolate"),
    actions: {
      "back-to-route": function backToRoute() {
        this.transitionToRoute("surveillance.loading");
        this.replaceRoute(this.backRoute, Ember.get(this, "project.slug"), this.backFilter);
      },
      "go-back": function goBack() {
        var previousIsolate = this.previousIsolate;
        Ember.set(this, "isolate", previousIsolate);
        this.transitionToRoute("surveillance.loading");
        this.replaceRoute("surveillance.show.isolates.show", Ember.get(this, "project.slug"), previousIsolate);
      },
      "go-forward": function goForward() {
        var nextIsolate = this.nextIsolate;
        Ember.set(this, "isolate", nextIsolate);
        this.transitionToRoute("surveillance.loading");
        this.replaceRoute("surveillance.show.isolates.show", Ember.get(this, "project.slug"), nextIsolate);
      }
    },
    _updatePreviousIsolate: function _updatePreviousIsolate(isolate) {
      Ember.set(this, "previousIsolate", isolate);
    },
    _updateNextIsolate: function _updateNextIsolate(isolate) {
      Ember.set(this, "nextIsolate", isolate);
    }
  });

  _exports.default = _default;
});