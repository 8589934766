define("mvp/helpers/array-to-sentence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalizeString = capitalizeString;
  _exports.default = void 0;

  function capitalizeString(params
  /*, hash*/
  ) {
    var arr = params[0];
    return arr.reduce(function (prev, curr, i) {
      return prev + curr + (i === arr.length - 2 ? ' and ' : ', ');
    }, '').slice(0, -2);
  }

  var _default = Ember.Helper.helper(capitalizeString);

  _exports.default = _default;
});