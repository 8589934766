define("mvp/pods/programs/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service("store"),
    currentUser: Ember.inject.service("current-user"),
    year: 2015,
    // Default year to show
    years_url: Ember.computed("model.id", function () {
      var program = this.model;
      return "api/programs/" + program.id + "/years";
    }),
    actions: {
      yearChanged: function yearChanged(year) {
        var _self = this;

        if (year !== "") {
          Ember.set(this, "year", year);
        } else {
          Ember.set(this, "year", undefined);
        }

        Ember.$(".ui.dimmer").removeClass("hidden");
        Ember.$(".ui.dimmer").addClass("active");
        this.updateFilterGroups().then(_self._updateDOM.bind(_self));
      }
    },
    updateFilterGroups: function updateFilterGroups() {
      var _self = this;

      var params;

      if (this.year !== undefined) {
        params = {
          program_id: Ember.get(this, "model.id"),
          organization_id: Ember.get(this.currentUser, "organization.id"),
          year: Ember.get(_self, "year")
        };
      } else {
        params = {
          program_id: Ember.get(this, "model.id"),
          organization_id: Ember.get(this.currentUser, "organization.id")
        };
      }

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.get(_self, "store").query("filter-group", params).then(_self._saveFilterGroups.bind(_self, resolve));
      });
    },
    _updateDOM: function _updateDOM() {
      Ember.$(".ui.dimmer").removeClass("active");
      Ember.$(".ui.dimmer").addClass("hidden");
      this.notifyPropertyChange("filter_group");
    },
    _resolvePromise: function _resolvePromise(resolve) {
      resolve();
    },
    _saveFilterGroups: function _saveFilterGroups(resolve, filter_groups) {
      var promisesArray = [];
      Ember.set(this, "filter_groups", filter_groups);
      filter_groups.forEach(function (filter_group) {
        promisesArray.push(Ember.get(filter_group, "filters"));
      });
      promisesArray.push(Ember.get(this, "model.sponsor.name"));
      Ember.RSVP.all(promisesArray).then(this._resolvePromise.bind(this, resolve));
    }
  });

  _exports.default = _default;
});