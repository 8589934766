define("mvp/pods/components/ui-surveillance/publications/show/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortedFilters: Ember.computed.sort('publication.filters', 'filterSorting'),
    init: function init() {
      this._super.apply(this, arguments);

      this.filterSorting = ['sort_order'];
    },
    actions: {
      "filter-selected": function filterSelected(filter) {
        this.filterSelected(filter);
      }
    }
  });

  _exports.default = _default;
});