define("mvp/pods/components/ui-demo-data/site/sample-details/component", ["exports", "mvp/pods/components/ui-demo-data/site/sample-details/source-of-bsi", "mvp/pods/components/ui-demo-data/site/sample-details/service", "mvp/pods/components/ui-demo-data/site/sample-details/sex", "mvp/pods/components/ui-demo-data/site/sample-details/specimen-type", "mvp/pods/components/ui-demo-data/site/sample-details/primary-diagnosis", "mvp/pods/components/ui-demo-data/site/sample-details/boolean", "lodash"], function (_exports, _sourceOfBsi, _service, _sex, _specimenType, _primaryDiagnosis, _boolean, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    sourceOfBSI: _sourceOfBsi.default,
    services: _service.default,
    sex: _sex.default,
    primaryDiagnosis: _primaryDiagnosis.default,
    specimenType: _specimenType.default,
    boolean: _boolean.default,
    tagName: "tr",
    didInsertElement: function didInsertElement() {
      this.$(".fadeIn").css('opacity', 0).animate({
        opacity: 1
      }, 1000);
    },
    specimenTypeByObj: Ember.computed("objective", function () {
      var objs = _lodash.default.keys(_specimenType.default);

      if (objs.includes(this.objective)) {
        return _specimenType.default[this.objective];
      } else {
        return _specimenType.default.other;
      }
    }),
    actions: {
      updateAge: function updateAge(changeset, e) {
        var age = parseInt(e.target.value);

        if (isNaN(age)) {
          this.actions.updateDemo(changeset, "age", undefined);
        } else if (age > 125 || age < 0) {
          this.actions.updateDemo(changeset, "age", null);
          return alert("Selection must be a number between 0 and 125");
        } else {
          this.actions.updateDemo(changeset, "age", age);
        }
      },
      updateDemo: function updateDemo(changeset, field, value) {
        if (value !== changeset.get("demographics")[field]) {
          Ember.set(changeset, "demographics", _objectSpread({}, changeset.get("demographics"), _defineProperty({}, field, value)));
        }
      }
    }
  });

  _exports.default = _default;
});