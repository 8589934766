define("mvp/pods/surveillance/show/heatmap/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var ajax = this.ajax;
      var project = this.modelFor('surveillance.show');
      var heatmap_url = "api/projects/".concat(project.id, "/heatmap");
      var promises = {
        pathogens: ajax.request("".concat(heatmap_url, "/pathogens")),
        phenotypes: ajax.request("".concat(heatmap_url, "/phenotypes")),
        years: ajax.request("".concat(heatmap_url, "/years"))
      };
      return Ember.RSVP.hash(promises);
    }
  });

  _exports.default = _default;
});