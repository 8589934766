define("mvp/pods/components/ui-user/auth0-login/component", ["exports", "mvp/config/environment", "auth0-lock"], function (_exports, _environment, _auth0Lock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    eventTracker: Ember.inject.service(),
    lock: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    allowedConnections: Ember.computed(function () {
      switch (INSTANCE) {
        case "mvpinternal":
          return ['jmilabs-waad'];

        default:
          return ['Automatic-migration-db', 'jmilabs-waad' // 'google-oauth2',
          ];
      }
    }),
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    }),
    client: Ember.computed(function () {
      switch (INSTANCE) {
        case "mvppublic":
          return "X5wrab5SN9aN6kAO087qrN55IznmDgTm";

        case "mvpparatek":
          return "de6zekkGb3B4ssjsYEUOGymV2OHJIhci";

        default:
          // this is the 'mvp' auth0 client id
          return "CL1tnoMzDkfDI13LFabRdNpipo6XkfKl";
      }
    }),
    allowSignUp: Ember.computed(function () {
      return INSTANCE !== 'mvpinternal' // && INSTANCE !== 'mvpparatek'
      ;
    }),
    additionalSignUpFields: Ember.computed(function () {
      if (INSTANCE === 'mvpparatek') {
        return [{
          name: 'name',
          placeholder: 'Name'
        }, {
          name: 'Accreditation',
          placeholder: 'Accreditation (Dr., Phd, MD, etc.)'
        }, {
          name: "institution",
          placeholder: 'Institution'
        }];
      } else {
        return [];
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var lock = new _auth0Lock.default(this.client, 'auth.jmilabs.com', {
        configurationBaseUrl: 'https://cdn.auth0.com',
        auth: {
          responseType: 'token id_token'
        },
        additionalSignUpFields: this.additionalSignUpFields,
        container: 'root',
        avatar: null,
        allowSignUp: this.allowSignUp,
        mustAcceptTerms: true,
        allowedConnections: this.allowedConnections,
        languageDictionary: {
          signUpTerms: 'Do you accept the following <a href="/terms" target="_blank">Terms & Conditions</a> and our <a href="/privacy" target="_blank">Privacy Policy</a>'
        }
      });
      lock.on("authenticated", this._onAuthenticated.bind(this));
      Ember.set(this, 'lock', lock);
    },
    didInsertElement: function didInsertElement() {
      this.lock.show();
      this.$(".ui.sticky").sticky({
        context: "#" + this.elementId,
        observeChanges: true,
        offset: 0
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.lock.hide();
    },
    _onAuthenticated: function _onAuthenticated(authResult) {
      var session = this.session;
      session.authenticate('authenticator:jwt', {
        token: authResult.idToken
      });
      this.eventTracker.record('login', {
        email: authResult.idTokenPayload.email
      });
    }
  });

  _exports.default = _default;
});