define("mvp/pods/manage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HceLqYv/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui grid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fourteen wide centered column\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"ui secondary pointing menu\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"manage.users\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"users icon\"],[9],[10],[0,\"\\n        Users\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"manage.projects\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"lab icon\"],[9],[10],[0,\"\\n        Projects\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"manage.organizations\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"building icon\"],[9],[10],[0,\"\\n        Organizations\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/manage/template.hbs"
    }
  });

  _exports.default = _default;
});