define("mvp/pods/components/ui-application/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5GSLeOLE",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"ui grid container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sixteen wide column\"],[11,\"id\",\"header-column\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"menu\"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"right menu\"],[9],[0,\"\\n        \"],[1,[21,\"ui-application/nav\"],false],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-application/header/template.hbs"
    }
  });

  _exports.default = _default;
});