define("mvp/pods/components/ui-demo-data/site/sample-details/sex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "value": "M",
    "name": "M"
  }, {
    "value": "F",
    "name": "F"
  }, {
    "value": "",
    "name": "NULL"
  }];
  _exports.default = _default;
});