define("mvp/models/ammap-susceptibility-result", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    country_code: _emberData.default.attr("string"),
    country_name: _emberData.default.attr("string"),
    resistant_percentage: _emberData.default.attr("number"),
    isolates_tested: _emberData.default.attr("number"),
    year: _emberData.default.attr("number")
  });

  _exports.default = _default;
});