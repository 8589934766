define("mvp/helpers/should-show-summary-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shouldShowSummaryRow = shouldShowSummaryRow;
  _exports.default = void 0;

  function shouldShowSummaryRow(params
  /*, hash*/
  ) {
    var is_summary_row = params[1].is_summary_row;
    var include_summary = params[0];

    if (include_summary === undefined) {
      include_summary = [];
    }

    var summary_row_for = params[1].summary_row_for;

    if (is_summary_row) {
      var results = summary_row_for.map(function (item) {
        if (!include_summary.includes(item)) {
          return false;
        }

        return true;
      });
      return !results.includes(false);
    }
  }

  var _default = Ember.Helper.helper(shouldShowSummaryRow);

  _exports.default = _default;
});