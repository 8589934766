define("mvp/pods/components/ui-filter-result/mic-distribution/cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authorization: Ember.inject.service(),
    tagName: 'td',
    classNameBindings: ['centered', 'aligned', 'display-both', 'resistant', 'intermediate', 'susceptible'],
    centered: true,
    aligned: true,
    'display-both': Ember.computed('display', function () {
      return this.display === 'both';
    }),
    resistant: Ember.computed('position', 'drug', 'colorize-lookup', 'drug-alias-lookup', function () {
      var drug_alias = this.drug;
      var colorize_lookup = this['colorize-lookup'];
      var drug_alias_lookup = this['drug-alias-lookup'];
      var header = parseFloat(this.get('position.header'));

      if (this.get('position.header') === ">") {
        header = this.get('position.header');
      }

      if (Array.isArray(drug_alias_lookup)) {
        var drug_lookup = drug_alias_lookup.reduce(function (acc, cv) {
          acc[cv['alias']] = cv['name'];
          return acc;
        }, {});
        var drug = drug_lookup[drug_alias];

        if (colorize_lookup[drug] !== undefined && colorize_lookup[drug] !== null) {
          if (colorize_lookup[drug]['r'] !== undefined && colorize_lookup[drug]['r'] !== null) {
            var r_breakpoint = colorize_lookup[drug]['r'];

            if (header >= r_breakpoint) {
              return true;
            } else if (header === ">") {
              return true;
            }
          } else if (colorize_lookup[drug]['s'] !== undefined && colorize_lookup[drug]['s'] !== null) {
            var ns_breakpoint = colorize_lookup[drug]['s'];

            if (header > ns_breakpoint) {
              return true;
            } else if (header === ">") {
              return true;
            }
          }
        }
      }

      return false;
    }),
    susceptible: Ember.computed('position', 'drug', 'colorize-lookup', 'drug-alias-lookup', function () {
      var drug_alias = this.drug;
      var colorize_lookup = this['colorize-lookup'];
      var drug_alias_lookup = this['drug-alias-lookup'];
      var header = parseFloat(this.get('position.header'));

      if (this.get('position.header') === ">") {
        header = this.get('position.header');
      }

      if (Array.isArray(drug_alias_lookup)) {
        var drug_lookup = drug_alias_lookup.reduce(function (acc, cv) {
          acc[cv['alias']] = cv['name'];
          return acc;
        }, {});
        var drug = drug_lookup[drug_alias];

        if (colorize_lookup[drug] !== undefined && colorize_lookup[drug] !== null) {
          if (colorize_lookup[drug]['s'] !== undefined && colorize_lookup[drug]['s'] !== null) {
            var ns_breakpoint = colorize_lookup[drug]['s'];

            if (header <= ns_breakpoint) {
              return true;
            }
          }
        }
      }

      return false;
    }),
    intermediate: Ember.computed('position', 'drug', 'colorize-lookup', 'drug-alias-lookup', function () {
      var drug_alias = this.drug;
      var colorize_lookup = this['colorize-lookup'];
      var drug_alias_lookup = this['drug-alias-lookup'];
      var header = parseFloat(this.get('position.header'));

      if (this.get('position.header') === ">") {
        header = this.get('position.header');
      }

      if (Array.isArray(drug_alias_lookup)) {
        var drug_lookup = drug_alias_lookup.reduce(function (acc, cv) {
          acc[cv['alias']] = cv['name'];
          return acc;
        }, {});
        var drug = drug_lookup[drug_alias];

        if (colorize_lookup[drug] !== undefined && colorize_lookup[drug] !== null) {
          if (colorize_lookup[drug]['r'] !== undefined && colorize_lookup[drug]['r'] !== null) {
            var r_breakpoint = colorize_lookup[drug]['r'];

            if (colorize_lookup[drug]['s'] !== undefined && colorize_lookup[drug]['s'] !== null) {
              var s_breakpoint = colorize_lookup[drug]['s'];

              if (header > s_breakpoint && header < r_breakpoint) {
                return true;
              }
            }
          }
        }
      }

      return false;
    }),
    actions: {
      'view-isolates-at': function viewIsolatesAt(drug, groupings, dilution) {
        this['view-isolates-at'](drug, groupings, dilution);
      }
    }
  });

  _exports.default = _default;
});