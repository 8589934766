define("mvp/pods/surveillance/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    surveillanceIndex: Ember.inject.controller("surveillance.show.adhoc"),
    surveillanceShow: Ember.inject.controller("surveillance.show"),
    ajax: Ember.inject.service(),
    poller: Ember.inject.service(),
    media: Ember.inject.service(),
    store: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    router: Ember.inject.service(),
    redux: Ember.inject.service(),
    original_filter: undefined,
    selected_filter: undefined,
    downloadingLineList: false,
    dontResetSelectedFilter: false,
    project: Ember.computed.alias('surveillanceShow.model'),
    shouldShowBackTo: Ember.computed("surveillanceIndex.filter", "selected_filter", function () {
      var loaded_filter = Ember.get(this, "original_filter");
      var selected_filter = Ember.get(this, "selected_filter");

      if (loaded_filter !== undefined && loaded_filter !== null && selected_filter !== undefined && selected_filter !== null) {
        if (Ember.get(loaded_filter, "criteria") !== Ember.get(selected_filter, "criteria") || Ember.get(loaded_filter, "group_by") !== Ember.get(selected_filter, "group_by")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }),
    actions: {
      viewFilter: function viewFilter(filter) {
        var project_slug = Ember.get(this, 'project.slug');
        this.transitionToRoute('surveillance.show.results.overview', project_slug, filter.id);
      }
    }
  });

  _exports.default = _default;
});