define("mvp/pods/components/ui-surveillance/project-selection/component", ["exports", "ember-redux"], function (_exports, _emberRedux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProjectSelection = Ember.Component.extend({
    classNames: ['ui', 'fluid', 'dropdown', 'search', 'selection'],
    router: Ember.inject.service(),
    didRender: function didRender() {
      var _this = this;

      this.$().dropdown({
        onChange: function onChange(slug) {
          Ember.get(_this, 'router').transitionTo("surveillance.show.dashboard", slug);
        }
      });
    }
  });

  var stateToComputed = function stateToComputed(state) {
    return {
      selectedProject: state.session.selected_project,
      projectOptions: state.session.available_projects
    };
  };

  var _default = (0, _emberRedux.connect)(stateToComputed)(ProjectSelection);

  _exports.default = _default;
});