define("mvp/helpers/empty-surveillance-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emptySurveillanceResults = emptySurveillanceResults;
  _exports.default = void 0;

  function emptySurveillanceResults(params
  /*, hash*/
  ) {
    var surveillance_results = params[0];
    return surveillance_results === undefined || surveillance_results === null || Object.keys(surveillance_results).length === 0;
  }

  var _default = Ember.Helper.helper(emptySurveillanceResults);

  _exports.default = _default;
});