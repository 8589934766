define("mvp/pods/components/ui-surveillance/header/component", ["exports", "ember-redux"], function (_exports, _emberRedux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UISurveillanceHeader = Ember.Component.extend({
    router: Ember.inject.service(),
    projectDropdown: undefined,
    hasSetupDropdown: false,
    shouldShowBackTo: Ember.computed("router.currentRouteName", "surveillanceIndex.filter", "selected_filter", "original_filter", function () {
      var loaded_filter = Ember.get(this, "original_filter");
      var selected_filter = Ember.get(this, "selected_filter");
      var path = Ember.get(this, "router.currentRouteName");

      if (path.includes("surveillance.show.results") && loaded_filter !== undefined && loaded_filter !== null && selected_filter !== undefined && selected_filter !== null) {
        if (Ember.get(loaded_filter, "criteria") !== Ember.get(selected_filter, "criteria") || Ember.get(loaded_filter, "group_by") !== Ember.get(selected_filter, "group_by")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }),
    hideSurveillanceNav: Ember.computed("router.currentRouteName", function () {
      var path = Ember.get(this, "router.currentRouteName");
      return ["surveillance.index", "surveillance.show.dashboard", "surveillance.loading"].includes(path);
    }),
    actions: {
      viewFilter: function viewFilter(filter) {
        Ember.get(this, 'view-filter')(filter);
      }
    }
  });

  var stateToComputed = function stateToComputed(state) {
    return {
      projectOptions: state.session.available_projects
    };
  };

  var _default = (0, _emberRedux.connect)(stateToComputed)(UISurveillanceHeader);

  _exports.default = _default;
});