define("mvp/pods/components/ui-program/viewer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Injections
    store: Ember.inject.service("store"),
    ajax: Ember.inject.service("ajax"),
    currentUser: Ember.inject.service("current-user"),
    authorization: Ember.inject.service(),
    classNames: ["ui", "grid"],
    viewType: 'distribution',
    siteDropdown: undefined,
    sectionDropdown: undefined,
    yearDropdown: undefined,
    regionDropdown: undefined,
    canChangeSite: Ember.computed("authorization.isJMIUser", function () {
      return Ember.get(this, 'authorization.isJMIUser');
    }),
    canChangeRegion: Ember.computed("authorization.{is_a_hospital,isJMIUser,role}", function () {
      var isJMIUser = Ember.get(this, 'authorization.isJMIUser');
      var is_a_hospital = Ember.get(this, 'authorization.is_a_hospital');
      return !isJMIUser && !is_a_hospital;
    }),
    sectionsUrl: Ember.computed("program.id", function () {
      return "/api/programs/" + Ember.get(this, "program.id") + "/group-names";
    }),
    observeSectionDropdown: function () {
      var _self = this;

      Ember.get(_self, "sectionDropdown").send("set-selected", Ember.get(_self, "program_group.name"));
    }.observes("sectionDropdown"),
    yearsUrl: Ember.computed("program.id", function () {
      return "/api/programs/" + Ember.get(this, "program.id") + "/years";
    }),
    observeYearDropdown: function () {
      var _self = this;

      var year = this.year;
      Ember.get(_self, "yearDropdown").send("set-selected", year);
    }.observes("yearDropdown"),
    regionsUrl: Ember.computed("program.id", function () {
      return "/api/programs/" + Ember.get(this, "program.id") + "/us-census-regions";
    }),
    observeRegionDropdown: function () {
      var _self = this;

      var region = this.region;
      Ember.get(_self, "regionDropdown").send("set-selected", region);
    }.observes("regionDropdown"),
    sitesUrl: Ember.computed("program.id", function () {
      return "/api/programs/" + Ember.get(this, "program.id") + "/sites";
    }),
    observeSiteDropdown: function () {
      var _self = this;

      var site = this.site;
      Ember.get(_self, "siteDropdown").send("set-selected", site);
    }.observes("siteDropdown"),
    init: function init() {
      this._super.apply(this, arguments);

      this.staticYearOptions = [{
        value: "",
        name: "All Years"
      }];
    },
    actions: {
      yearChanged: function yearChanged(year) {
        this.changeYear(year);
      },
      sectionChanged: function sectionChanged(program_group_id) {
        this.changeProgramGroup(program_group_id);
      },
      regionChanged: function regionChanged(region) {
        this.changeRegion(decodeURIComponent(region));
      },
      siteChanged: function siteChanged(site) {
        this.changeSite(decodeURIComponent(site));
      },
      changeView: function changeView(viewType) {
        Ember.set(this, "viewType", viewType);
      }
    }
  });

  _exports.default = _default;
});