define("mvp/pods/site-management/canisters/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rRrY5s/G",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[7,\"h1\"],[9],[0,\"Create New Canister Shipment\"],[10],[0,\"\\n\"],[4,\"with\",[[27,\"changeset\",[[23,[\"model\",\"changeset\"]]],null]],null,{\"statements\":[[7,\"form\"],[11,\"class\",\"ui form\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"ui grid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"ten wide column\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"ui grid\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"eight wide column\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"field\"],[9],[0,\"\\n            \"],[7,\"label\"],[9],[0,\"Site Code:\"],[10],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"required\",\"value\"],[true,[22,1,[\"siteCode\"]]]]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"eight wide column\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"field\"],[9],[0,\"\\n            \"],[7,\"label\"],[9],[0,\"Canister Barcode:\"],[10],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"required\",\"value\"],[true,[22,1,[\"canisterBarcode\"]]]]],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"floated right\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"site-management.canisters.index\"],[[\"class\"],[\"ui button\"]],{\"statements\":[[0,\"              Cancel\\n\"]],\"parameters\":[]},null],[0,\"            \"],[7,\"button\"],[11,\"class\",\"ui primary button\"],[11,\"type\",\"submit\"],[9],[0,\"Submit\"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"six wide column\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"handleSubmit\",[22,1,[]]],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/site-management/canisters/new/template.hbs"
    }
  });

  _exports.default = _default;
});