define("mvp/pods/surveillance/sentry/isolates/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    year: "2017",
    actions: {
      pageChanged: function pageChanged(page) {
        Ember.set(this, "page", page);
      },
      yearChanged: function yearChanged(year) {
        Ember.set(this, "year", year);
      }
    }
  });

  _exports.default = _default;
});