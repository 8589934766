define("mvp/pods/components/ui-sentry/sub-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aI27dw+h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui fluid small vertical secondary pointing sentry menu\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"surveillance.sentry.index\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"home icon\"],[9],[10],[0,\"\\n    Overview\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"surveillance.sentry.downloads\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"download icon\"],[9],[10],[0,\"\\n    Downloads\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"surveillance.sentry.uploads\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"upload icon\"],[9],[10],[0,\"\\n    Uploads\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"surveillance.sentry.demo\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"edit icon\"],[9],[10],[0,\"\\n    Demo Entry\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"surveillance.sentry.shipments\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"truck icon\"],[9],[10],[0,\"\\n    Isolate Shipments\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-sentry/sub-nav/template.hbs"
    }
  });

  _exports.default = _default;
});