define("mvp/pods/surveillance/show/results/charts/finlandogram/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['drugs'],
    showResultsController: Ember.inject.controller('surveillance.show.results'),
    filter: Ember.computed.alias('showResultsController.model'),
    observeFilterId: function () {
      Ember.set(this, 'leftDrugs', []);
      Ember.set(this, 'bottomDrugs', []);
    }.observes('filter.id'),
    init: function init() {
      this._super.apply(this, arguments);

      this.drugs = [];
    },
    actions: {
      'drugs-changed': function drugsChanged(drugs) {
        Ember.set(this, 'drugs', drugs);
      }
    }
  });

  _exports.default = _default;
});