define("mvp/pods/components/ui-surveillance/isolates/antibiogram/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    store: Ember.inject.service("store"),
    didReceiveAttrs: function didReceiveAttrs() {
      var ajax = this.ajax;
      var store = this.store;
      var isolate = this.isolate;
      var url = "api/isolates/" + isolate.id + "/susceptibility-results";
      store.unloadAll('susceptibility-results');
      ajax.request(url).then(this._saveSusceptibilityResults.bind(this, store));
    },
    didInsertElement: function didInsertElement() {
      Ember.$("#" + this.elementId + " table").stickyTableHeaders();
    },
    _saveSusceptibilityResults: function _saveSusceptibilityResults(store, payload) {
      store.pushPayload(payload);
      var susceptibilityResults = [];
      payload.data.forEach(function (sr) {
        susceptibilityResults.pushObject(store.peekRecord('susceptibility-result', sr.id));
      });
      Ember.set(this, 'susceptibilityResults', susceptibilityResults);
    }
  });

  _exports.default = _default;
});