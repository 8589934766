define("mvp/pods/components/ui-sentry/hospital-recruitment/banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5wGuTpDI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"recruiting\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"ui message\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\\n      \"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"viewInvite\"],null]],[11,\"class\",\"ui view-invite button right\"],[9],[0,\"\\n          View Invitation\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n        \"],[7,\"a\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"viewInvite\"],null]],[11,\"href\",\"#\"],[9],[1,[22,0,[\"year\"]],false],[0,\" SENTRY Recruitment\"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"You have been invited to participate in the \"],[1,[22,0,[\"year\"]],false],[0,\" SENTRY Antimicrobial Resistance Program.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-sentry/hospital-recruitment/banner/template.hbs"
    }
  });

  _exports.default = _default;
});