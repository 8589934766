define("mvp/pods/components/ui-program/report-stacked/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    classNames: ["ui", "grid", "equal", "width"],
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    }),
    getChartData: function () {
      Ember.set(this, "charts", undefined);
      Ember.set(this, "error", false);

      if (this.component !== undefined) {
        var ajax = this.ajax;
        var component = this.component; // Assemble data for uri request

        var data = {};

        if (this.year !== undefined) {
          data.year = this.year;
        }

        if (this.region !== undefined) {
          data.region = this.region;
        }

        if (this.site !== undefined) {
          data.site = this.site;
        }

        var url = "/api/program-components/" + component.id + "/stacked-chart-data";

        var _self = this;

        ajax.request(url, {
          data: data
        }).then(_self._saveCharts.bind(_self), _self._saveError.bind(_self));
      }
    }.observes("year", "region", "site", "component"),
    didReceiveAttrs: function didReceiveAttrs() {
      var _self = this;

      Ember.run("afterRender", function () {
        _self.getChartData();
      });
    },
    actions: {},
    _saveCharts: function _saveCharts(res) {
      if (!this.isDestroyed) {
        Ember.set(this, "loading", false);
        Ember.set(this, "charts", res);
      }
    },
    _saveError: function _saveError() {
      if (!this.isDestroyed) {
        Ember.set(this, "error", true);
      }
    }
  });

  _exports.default = _default;
});