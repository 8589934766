define("mvp/pods/components/ui-surveillance/nav/component", ["exports", "ember-redux", "mvp/config/environment"], function (_exports, _emberRedux, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;
  var UISurveillanceNav = Ember.Component.extend({
    classNames: ['ui', 'fixed', 'top', 'sicky', 'surveillance-nav'],
    authorization: Ember.inject.service(),
    store: Ember.inject.service(),
    adhocName: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek' ? 'Search' : 'Ad Hoc';
    }),
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    }),
    // This is a hack until we move everything over
    // to redux...
    project: Ember.computed('selectedProject.id', function () {
      var projectID = Ember.get(this, 'selectedProject.id');
      var store = Ember.get(this, 'store');
      return store.findRecord('project', projectID);
    }),
    projectPublicationsCount: Ember.computed("selectedProject.id", function () {
      var project = Ember.get(this, 'selectedProject');

      if (project) {
        return project.relationships.publications.data.length;
      } else {
        return 0;
      }
    }),
    showHeatmap: Ember.computed("selectedProject.hasHeatmap", function () {
      return Ember.get(this, 'selectedProject.attributes.hasHeatmap');
    })
  });

  var mapStateToComputed = function mapStateToComputed(state) {
    return {
      selectedProject: state.session.selected_project
    };
  };

  var _default = (0, _emberRedux.connect)(mapStateToComputed)(UISurveillanceNav);

  _exports.default = _default;
});