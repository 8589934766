define("mvp/pods/surveillance/show/isolates/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    authorization: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (Ember.get(this, 'authorization.isPublicUser')) {
        // Public users should not be able to see details of isolates
        // so redirect them to the dashboard page
        this.transitionTo("surveillance.show.dashboard");
      }
    }
  });

  _exports.default = _default;
});