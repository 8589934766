define("mvp/pods/surveillance/show/programs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    authorization: Ember.inject.service(),
    model: function model(params) {
      return this.store.queryRecord("program", {
        slug: params.program_slug
      });
    },
    afterModel: function afterModel(model) {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.get(model, "program_groups").then(self._setProgramGroupAndGetOrganization.bind(self, resolve, model));
      });
    },
    _setProgramGroupAndGetOrganization: function _setProgramGroupAndGetOrganization(resolve, program, program_groups) {
      var ajax = this.ajax;
      var controller = this.controllerFor("surveillance.show.programs");
      Ember.set(controller, "program_group", program_groups.objectAt(0));

      if (!Ember.get(this, 'authorization.isAHospital')) {
        var url = "/api/programs/".concat(program.id, "/sites");
        var data = {
          us_census_region: "1:  New England",
          year: 2016
        };
        ajax.request(url, {
          data: data
        }).then(this._updateSite.bind(this, resolve));
      } else {
        resolve();
      }
    },
    _updateSite: function _updateSite(resolve, results) {
      var controller = this.controllerFor("surveillance.show.programs");
      var site = results.results.objectAt(0).options.objectAt(0).value;
      Ember.set(controller, "site", site);
      resolve();
    }
  });

  _exports.default = _default;
});