define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/infection-types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "69O5LLpX",
    "block": "{\"symbols\":[\"infection_type\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ui small basic buttons\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"infection-types\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[22,1,[\"name\"]],[23,[\"selected-infection-type\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"class\",\"ui button active\"],[9],[0,\"\\n          \"],[1,[22,1,[\"name\"]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"update-selected-infection-type\",[22,1,[\"name\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"class\",\"ui button\"],[9],[0,\"\\n          \"],[1,[22,1,[\"name\"]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"update-selected-infection-type\",[22,1,[\"name\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[22,0,[]],\"update-selected-infection-type\",[22,1,[\"name\"]]]],[10],[0,\"\\n    |\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/infection-types/template.hbs"
    }
  });

  _exports.default = _default;
});