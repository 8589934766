define("mvp/pods/surveillance/sentry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ZRBXHOE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui grid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"three wide column\"],[9],[0,\"\\n    \"],[1,[21,\"ui-sentry/sub-nav\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"thirteen wide column\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/sentry/template.hbs"
    }
  });

  _exports.default = _default;
});