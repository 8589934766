define("mvp/pods/site-management/canisters/index/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/canisters/index/sites_with_canisters"], function (_exports, _emberApolloClient, _sites_with_canisters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var variables = {
        order: "id ASC",
        page: params.page,
        perPage: params.perPage
      };
      return this.apollo.watchQuery({
        query: _sites_with_canisters.default,
        variables: variables,
        fetchPolicy: "cache-and-network"
      }, "sitesWithCanisters");
    },
    queryParams: {
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});