define("mvp/pods/surveillance/show/programs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bR+4ZFpG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"currentUser\",\"organization\",\"is_a_hospital\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[27,\"ui-program/viewer\",null,[[\"program\",\"year\",\"changeYear\",\"program_group\",\"changeProgramGroup\"],[[23,[\"model\"]],[23,[\"year\"]],[27,\"action\",[[22,0,[]],\"changeYear\"],null],[23,[\"program_group\"]],[27,\"action\",[[22,0,[]],\"changeProgramGroup\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[1,[27,\"ui-program/viewer\",null,[[\"program\",\"year\",\"changeYear\",\"program_group\",\"changeProgramGroup\",\"region\",\"changeRegion\",\"site\",\"changeSite\"],[[23,[\"model\"]],[23,[\"year\"]],[27,\"action\",[[22,0,[]],\"changeYear\"],null],[23,[\"program_group\"]],[27,\"action\",[[22,0,[]],\"changeProgramGroup\"],null],[23,[\"region\"]],[27,\"action\",[[22,0,[]],\"changeRegion\"],null],[23,[\"site\"]],[27,\"action\",[[22,0,[]],\"changeSite\"],null]]]],false],[0,\"\\n\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/programs/template.hbs"
    }
  });

  _exports.default = _default;
});