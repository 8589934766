define("mvp/pods/surveillance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PM2xN8G5",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[27,\"ui-surveillance/header\",null,[[\"selected_filter\",\"original_filter\",\"surveillanceIndex\",\"project\",\"view-filter\"],[[23,[\"selected_filter\"]],[23,[\"original_filter\"]],[23,[\"surveillanceIndex\"]],[23,[\"project\"]],[27,\"action\",[[22,0,[]],\"viewFilter\"],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"ui grid container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"main-content\"],[12,\"class\",[28,[\"sixteen wide column\",[23,[\"media\",\"classNames\"]]]]],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/template.hbs"
    }
  });

  _exports.default = _default;
});