define("mvp/pods/components/ui-admin/sentry-compliance/status-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupCell();
    },
    actions: {
      'view-non-viable': function viewNonViable() {
        // console.log(`viewing non-viable for ${get(this, 'objective')} on ${get(this, 'organization.site_code')}`);
        this['show-non-viable-modal'](this.organization, this.objective);
      }
    },
    setupCell: function setupCell() {
      Ember.set(this, 'status', undefined);
      var self = this;
      this.statuses.forEach(function (status) {
        if (Ember.get(status, 'objective_code') === Ember.get(self, 'objective')) {
          Ember.set(self, 'status', status);

          if (Ember.get(Ember.get(self, 'status'), 'isolates_non_viable_count') > 0 && Ember.get(self, 'show_nv_links') === true) {
            Ember.set(self, 'show_modal_link', true);
          } else {
            Ember.set(self, 'show_modal_link', false);
          }
        }
      });
    }
  });

  _exports.default = _default;
});