define("mvp/helpers/mailto-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mailtoLink = mailtoLink;
  _exports.default = void 0;

  function mailtoLink(input) {
    var mailTo = '<a href="mailto:' + input + '">';
    mailTo += input + '</a>';
    return new Ember.Handlebars.SafeString(mailTo);
  }

  var _default = Ember.Handlebars.makeBoundHelper(mailtoLink);

  _exports.default = _default;
});