define("mvp/pods/components/d3-charts/susceptibility-rate/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var INSTANCE = _environment.default.EmberENV.INSTANCE;
  /* globals d3 */

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'pathogens', []);
      Ember.set(this, 'drugs', []);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.debounce(this, this._calculateSusceptibilityRate, 1250);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      Ember.run.debounce(this, this._calculateSusceptibilityRate, 1250);
    },
    actions: {
      'exportTitle': function exportTitle() {
        var pathogen = this.pathogen;
        var drug = this.drug;
        var year = this.year;
        var output = "".concat(pathogen, " - ").concat(drug, " % susceptible");

        if (year !== 'All years') {
          output = output + " - ".concat(year);
        }

        output = output.replace("<em>", "");
        output = output.replace("</em>", "");
        return output;
      },
      'colorize': function colorize(countries) {
        var rates = this.rates;
        var maptype = this.maptype;
        var self = this;

        if (rates && maptype === 'usa') {
          countries // select all the countries
          .transition().duration(800).attr('fill', function (d) {
            var rate = rates[d.id];

            if (rate) {
              var pct = rate.s_count / rate.total_count;
              return self._colorPct(pct);
            } else {
              return "rgba(0,0,0,.2)";
            }
          });
        } else if (rates) {
          countries // select all the countries
          .transition().duration(800).attr("stroke", function (d) {
            if (rates[d.id]) {
              return "rgba(0,0,0,.5)";
            } else {
              return "rgba(0,0,0,0)";
            }
          }).attr('fill', function (d) {
            var rate = rates[d.id];

            if (rate) {
              var pct = rate.s_count / rate.total_count;
              return self._colorPct(pct);
            } else {
              return "rgba(0,0,0,.2)";
            }
          });
        }
      },
      'legend': function legend(g, viewBoxHeight) {
        var ext_color_domain = [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0];
        var color;

        if (INSTANCE === 'mvpparatek') {
          color = d3.scaleOrdinal().domain([0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]).range(["rgba(202, 56, 23, 1)", "rgba(202, 56, 23, .9)", "rgba(202, 56, 23, .8)", "rgba(202, 56, 23, .7)", "rgba(202, 56, 23, .6)", "rgba(202, 56, 23, .5)", "rgba(202, 56, 23, .4)", "rgba(252, 255, 63, 1)", "rgba(252, 255, 63, .75)", "rgba(252, 255, 63, .5)", "rgba(118, 166, 64,  1)"]);
        } else {
          color = d3.scaleOrdinal().domain([0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]).range(["rgba(202, 56, 23, 1)", "rgba(202, 56, 23, .8)", "rgba(202, 56, 23, .6)", "rgba(202, 56, 23, .4)", "rgba(202, 56, 23, .2)", "rgba(118, 166, 64, .0)", "rgba(118, 166, 64, .2)", "rgba(118, 166, 64, .4)", "rgba(118, 166, 64, .6)", "rgba(118, 166, 64, .8)", "rgba(118, 166, 64,  1)"]);
        }

        var ls_w = 20,
            ls_h = 20;
        g.append("rect").attr("x", 10).attr("y", viewBoxHeight - 13 * ls_h).attr("width", ls_w * 4.5).attr("height", ls_h * 13).style("fill", "#FFFFFF").style("stroke", "#000000").attr("class", "legendBackground");
        var legend = g.selectAll("g.legend").data(ext_color_domain).enter().append("g").attr("class", "legend");
        legend.append("rect").attr("x", 20).attr("y", function (d, i) {
          return viewBoxHeight - i * ls_h - 2 * ls_h;
        }).attr("width", ls_w).attr("height", ls_h).style("fill", function () {
          return "rgba(0,0,0,.2)";
        });
        legend.append("rect").attr("x", 20).attr("y", function (d, i) {
          return viewBoxHeight - i * ls_h - 2 * ls_h;
        }).attr("width", ls_w).attr("height", ls_h).style("fill", function (d) {
          return color(d);
        });
        legend.append("text").attr("x", 50).attr("y", function (d, i) {
          return viewBoxHeight - i * ls_h - ls_h - 4;
        }).style("font-family", "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif").text(function (d, i) {
          if (i === 0) {
            return "0%";
          } else if (i === 10) {
            return "100%";
          } else {
            return "";
          }
        });
      },
      'guide': function guide(_ref, d, _guide) {
        var _ref2 = _slicedToArray(_ref, 2),
            x = _ref2[0],
            y = _ref2[1];

        var rates = this.rates;
        var maptype = this.maptype;

        if (rates && rates[d.id]) {
          var rate = rates[d.id];
          var name;

          if (maptype === 'usa') {
            name = rate.us_census_region;
          } else {
            name = rate.country_name;
          }

          _guide.append("text").attr("dy", ".35em").attr("pointer-events", "none").attr("text-anchor", "left").attr("fill", "rgba(255,255,255,.9)").attr("font-size", 26).attr("x", x + 25).attr("y", y - 10).text(name);

          var pct = rate.s_count / rate.total_count;
          var red = d3.rgb(202, 56, 23);
          var green = d3.rgb(118, 166, 64);
          var yellow = d3.rgb(252, 255, 63);
          var color;
          var lighter;

          if (INSTANCE === 'mvpparatek') {
            if (pct >= 0.9) {
              color = green;
              lighter = 0;
            } else if (pct > 0.7) {
              color = yellow;
              lighter = Math.round((0.9 - pct) * 5);
            } else {
              color = red;
              lighter = Math.round(pct * 5);
            }
          } else {
            if (pct >= 0.5) {
              color = green;
              lighter = 0;
            } else {
              color = red;
              lighter = Math.round(pct * 5);
            }
          }

          _guide.append("text").attr("dy", ".35em").attr("pointer-events", "none").attr("text-anchor", "left").attr("fill", color.brighter(lighter).toString()).attr("font-size", 18).attr("x", x + 25).attr("y", y + 14).text("".concat((pct * 100).toFixed(2), "% susceptible"));

          _guide.append("text").attr("dy", ".35em").attr("pointer-events", "none").attr("text-anchor", "left").attr("fill", "rgba(255,255,255,.7)").attr("font-size", 18).attr("x", x + 25).attr("y", y + 32).text("".concat(rate.total_count, " isolates tested"));
        }
      }
    },
    _calculateSusceptibilityRate: function _calculateSusceptibilityRate() {
      var pathogen = this.pathogen;
      var drug = this.drug;
      var authority = this.authority;
      var deliveryMechanism = this.deliveryMechanism;
      var infectionType = this.infectionType;
      Ember.set(this, 'data', null);

      if (pathogen && drug && authority) {
        // console.log(`DATA: ${pathogen}, ${drug}, ${authority}, ${deliveryMechanism}, ${infectionType}`)
        this._requestResults(pathogen, drug, authority, deliveryMechanism, infectionType);
      }
    },
    _requestResults: function _requestResults(pathogen, drug, authority, deliveryMechanism, infectionType) {
      var _this = this;

      var ajax = this.ajax;
      var project = this.project;
      var url = "api/projects/".concat(project.id, "/heatmap/susceptibility_rates") + "?pathogen=".concat(encodeURIComponent(pathogen)) + "&drug=".concat(encodeURIComponent(drug)) + "&authority=".concat(encodeURIComponent(authority)) + "&delivery_mechanism=".concat(encodeURIComponent(deliveryMechanism)) + "&infection_type=".concat(encodeURIComponent(infectionType));
      var maptype = this.maptype;

      if (maptype === 'usa') {
        url = url + "&census_division=true";
      }

      var year = this.year;

      if (year !== 'All years') {
        url = url + "&year=".concat(year);
      }

      ajax.request(url).then(this._handleResults.bind(this)).catch(function () {
        _this._handleResults([]);
      });
    },
    _handleResults: function _handleResults(payload) {
      var rate_lookup = {};
      var maptype = this.maptype;

      if (maptype === 'usa') {
        payload.forEach(function (rate) {
          rate_lookup[parseInt(rate.id)] = rate;
        });
      } else {
        payload.forEach(function (rate) {
          rate_lookup[rate.iso_numeric_code] = rate;
        });
      }

      Ember.set(this, 'rates', rate_lookup);
    },
    _colorPct: function _colorPct(pct) {
      var green_color = d3.scaleThreshold().domain([0.6, 0.7, 0.8, 0.9, 1.0]).range(["rgba(118, 166, 64, .2)", "rgba(118, 166, 64, .4)", "rgba(118, 166, 64, .6)", "rgba(118, 166, 64, .8)", "rgba(118, 166, 64,  1)"]);
      var red_color = d3.scaleThreshold().domain([0.0, 0.1, 0.2, 0.3, 0.4]).range(["rgba(202, 56, 23, 1)", "rgba(202, 56, 23, .8)", "rgba(202, 56, 23, .6)", "rgba(202, 56, 23, .4)", "rgba(202, 56, 23, .2)"]);

      if (INSTANCE === 'mvpparatek') {
        if (pct >= 0.9) {
          return "rgba(118, 166, 64,  1)";
        } else if (pct > 0.7) {
          var yellow_color = d3.scaleThreshold().domain([0.7, 0.8, 0.9]).range(["rgba(252, 255, 63, 1)", "rgba(252, 255, 63, 0.75)", "rgba(252, 255, 63, 0.5)"]);
          return yellow_color(pct);
        } else {
          red_color = d3.scaleThreshold().domain([0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7]).range(["rgba(202, 56, 23, 1)", "rgba(202, 56, 23, .9)", "rgba(202, 56, 23, .8)", "rgba(202, 56, 23, .7)", "rgba(202, 56, 23, .6)", "rgba(202, 56, 23, .5)", "rgba(202, 56, 23, .4)"]);
          return red_color(pct);
        }
      } else {
        if (pct >= 0.5) {
          return green_color(pct);
        } else {
          return red_color(pct);
        }
      }
    }
  });

  _exports.default = _default;
});