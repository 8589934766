define("mvp/pods/manage/organizations/edit/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zeTMyKyR",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"edit-organization-settings\",null,[[\"all_projects\",\"organization\"],[[23,[\"model\",\"all_projects\"]],[23,[\"model\",\"organization\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/manage/organizations/edit/settings/template.hbs"
    }
  });

  _exports.default = _default;
});