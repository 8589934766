define("mvp/pods/components/ui-surveillance/dashboard/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    classNames: ['surveillance-dashboard'],
    authorization: Ember.inject.service(),
    adhocName: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek' ? 'Search' : 'Ad Hoc';
    }),
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    }),
    projectPublicationsCount: Ember.computed("project.id", function () {
      return Ember.get(this, "project").hasMany("publications").ids().length;
    }),
    showHeatmap: Ember.computed("project.has_heatmap", function () {
      return Ember.get(this, 'project.has_heatmap');
    })
  });

  _exports.default = _default;
});