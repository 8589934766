define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/pathogens/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var pathogens = this.pathogens;
      var pathogen = this['selected-pathogen'];

      if (pathogen === '') {
        Ember.run.scheduleOnce('actions', this, function () {
          _this['update-selected-pathogen'](Ember.get(pathogens.objectAt(0), 'name'));
        });
        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.$("#".concat(_this.elementId, " .ui.dropdown")).dropdown('set selected', Ember.get(pathogens.objectAt(0), 'name'));
        });
        Ember.$("#".concat(this.elementId, " .ui.dropdown")).dropdown('set selected', this['selected-pathogen']);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$("#".concat(_this2.elementId, " .ui.dropdown")).dropdown();
        Ember.$("#".concat(_this2.elementId, " .ui.dropdown")).dropdown('set selected', _this2['selected-pathogen']);
      });
    },
    actions: {
      'update-selected-pathogen': function updateSelectedPathogen(pathogen) {
        this['update-selected-pathogen'](pathogen);
      }
    }
  });

  _exports.default = _default;
});