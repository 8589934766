define("mvp/pods/components/ui-site-management/form/site-address/contact-multiselect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0pQJtCqP",
    "block": "{\"symbols\":[\"select_option\"],\"statements\":[[7,\"select\"],[12,\"name\",[22,0,[\"name\"]]],[11,\"multiple\",\"\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"setSelection\"],null]],[9],[0,\"\\n\"],[4,\"each\",[[22,0,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"selected\",[27,\"if\",[[27,\"array-contains\",[[22,0,[\"changesetValue\"]],[22,1,[\"id\"]]],null],\"true\"],null]],[12,\"value\",[22,1,[\"id\"]]],[9],[0,\"\\n    \"],[1,[22,1,[\"contact\",\"firstName\"]],false],[0,\" \"],[1,[22,1,[\"contact\",\"lastName\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-site-management/form/site-address/contact-multiselect/template.hbs"
    }
  });

  _exports.default = _default;
});