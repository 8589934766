define("mvp/pods/site-management/sites/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        params: params,
        changeset: {}
      });
    }
  });

  _exports.default = _default;
});