define("mvp/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AC09fba5",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"head-layout\"],false],[0,\"\\n\"],[1,[21,\"ember-load-remover\"],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"or\",[[27,\"eq\",[[23,[\"currentPath\"]],\"auth0.login\"],null],[27,\"or\",[[27,\"or\",[[27,\"or\",[[27,\"or\",[[27,\"eq\",[[23,[\"currentPath\"]],\"forgot\"],null],[27,\"eq\",[[23,[\"currentPath\"]],\"loading\"],null]],null],[27,\"eq\",[[23,[\"currentPath\"]],\"recover\"],null]],null],[27,\"eq\",[[23,[\"currentPath\"]],\"login\"],null]],null],[27,\"eq\",[[23,[\"currentPath\"]],\"signup\"],null]],null]],null]],null,{\"statements\":[[0,\"  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"currentPath\"]],\"surveillance.show.dashboard.index\"],null]],null,{\"statements\":[[0,\"  \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[1,[21,\"ui-application/footer\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"ui sidebar inverted vertical menu\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"media\",\"isDesktop\"]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"nav-items\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"pusher\"],[9],[0,\"\\n\"],[4,\"unless\",[[27,\"eq\",[[23,[\"currentPath\"]],\"gatekeeper\"],null]],null,{\"statements\":[[0,\"      \"],[1,[21,\"ui-application/header\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"content-container\"],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[21,\"ui-application/footer\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});