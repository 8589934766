define("mvp/helpers/array-length-greater-than-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayLengthGreaterThanOne = arrayLengthGreaterThanOne;
  _exports.default = void 0;

  function arrayLengthGreaterThanOne(params
  /*, hash*/
  ) {
    return params[0].length > 1;
  }

  var _default = Ember.Helper.helper(arrayLengthGreaterThanOne);

  _exports.default = _default;
});