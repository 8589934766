define("mvp/models/organization", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    city: _emberData.default.attr("string"),
    state_province: _emberData.default.attr("string"),
    postal_code: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),
    continent: _emberData.default.attr("string"),
    longitude: _emberData.default.attr("number"),
    latitude: _emberData.default.attr("number"),
    created_at: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date"),
    site_code: _emberData.default.attr("number"),
    is_a_hospital: _emberData.default.attr("boolean"),
    us_census_division_short: _emberData.default.attr('string'),
    european_region_name: _emberData.default.attr('string'),
    projects: _emberData.default.hasMany("project", {
      async: true
    }),
    programs: _emberData.default.hasMany("program", {
      async: true
    }),
    sponsored_programs: _emberData.default.hasMany("program", {
      async: true,
      inverse: 'sponsor'
    }),
    'objective_compliance_statuses': _emberData.default.hasMany('site-objective-compliance-status'),
    'sentry_site_containers': _emberData.default.hasMany('sentry-site-containers')
  });

  _exports.default = _default;
});