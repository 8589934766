define("mvp/pods/site-management/sites/edit/route", ["exports", "ember-apollo-client", "mvp/pods/site-management/sites/index/sites", "mvp/pods/site-management/sites/show/show"], function (_exports, _emberApolloClient, _sites, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      var variables = {
        id: params.id
      };
      var site = this.apollo.watchQuery({
        query: _show.default,
        variables: variables
      }, "site");
      var sites = this.apollo.watchQuery({
        query: _sites.default
      }, "sites");
      return Ember.RSVP.hash({
        params: params,
        site: site,
        sites: sites,
        changeset: {}
      });
    }
  });

  _exports.default = _default;
});