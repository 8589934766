define("mvp/pods/components/ui-surveillance/publications/index/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    eventTracker: Ember.inject.service(),
    sortedPublications: Ember.computed.sort('publications', 'publicationSortDefinition'),
    init: function init() {
      this._super.apply(this, arguments);

      this.publicationSortDefinition = ['name'];
    },
    actions: {
      download_loader: function download_loader() {
        var download_url = Ember.get(this, 'project.download_url');
        window.open(download_url, '_blank');
      },
      download: function download(publication) {
        window.open(Ember.get(publication, "download_url"), '_blank');
        this.eventTracker.record('download_publication', {
          publication: publication
        });
      },
      view: function view(publication) {
        window.open(Ember.get(publication, "view_url"), '_blank');
        this.eventTracker.record('view_publication', {
          publication: publication
        });
      }
    }
  });

  _exports.default = _default;
});