define("mvp/pods/programs/show/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service("current-user"),
    model: function model(params) {
      var _self = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _self.store.query("program", {
          name: params.program_name
        }).then(_self._resolveFirstProgram.bind(_self, resolve));
      });
    },
    afterModel: function afterModel(model) {
      var controller = this.controllerFor("programs.show");

      var _self = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _self.store.query("filter-group", {
          program_id: Ember.get(model, "id"),
          organization_id: Ember.get(Ember.get(_self, "currentUser"), "organization.id"),
          year: Ember.get(_self.controllerFor("programs.show"), "year")
        }).then(_self._saveAndResolveFilters.bind(_self, controller, model, resolve));
      });
    },
    actions: {
      loading: function loading() {
        // Called while the model loads, but throws an exception
        this.intermediateTransitionTo('loading'); // This would not throw an exception: this.intermediateTransitionTo('loading');
      }
    },
    reloadFilter: function reloadFilter(filter) {
      return new Ember.RSVP.Promise(function (resolve) {
        filter.reload().then( // Has surveillance results
        this._resolvePromise.bind(this, resolve), // Error in calculating results, or no results found
        this._resolvePromise.bind(this, resolve));
      });
    },
    _resolveFirstProgram: function _resolveFirstProgram(resolve, programs) {
      // Get the first program with that name
      // Really there should only be one do to unique index
      // In the database
      resolve(Ember.get(programs, "firstObject"));
    },
    _resolvePromise: function _resolvePromise(resolve) {
      resolve();
    },
    _resolveFilterPromises: function _resolveFilterPromises(promisesArray, resolve, filters) {
      filters.forEach(function (filter) {
        promisesArray.push(this.reloadFilter(filter));
      });
      Ember.RSVP.all(promisesArray).then(this._resolvePromise.bind(this, resolve));
    },
    _saveAndResolveFilters: function _saveAndResolveFilters(controller, model, resolve, filter_groups) {
      var promisesArray = [];
      Ember.set(controller, "filter_groups", filter_groups);
      promisesArray.push(Ember.get(model, "sponsor.name"));
      filter_groups.forEach(function (filter_group) {
        Ember.get(filter_group, "filters").then(this._resolveFilterPromises.bind(this, promisesArray, resolve));
      });
    }
  });

  _exports.default = _default;
});