define("mvp/adapters/application", ["exports", "ds-improved-ajax/adapters/json-api", "mvp/config/environment"], function (_exports, _jsonApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    session: Ember.inject.service(),
    schema: Ember.inject.service(),
    host: _environment.default.APP.host,
    namespace: '/api',
    headers: Ember.computed('schema.header', 'session.data.authenticated', function () {
      var _EmberGet = Ember.get(this, 'session.data.authenticated'),
          token = _EmberGet.token;

      return {
        'Schema': Ember.get(this, 'schema.header'),
        'Authorization': "Bearer ".concat(token)
      };
    })
  });

  _exports.default = _default;
});