define("mvp/pods/demo-data/index/pending/PendingDemos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "sentrySampleShipmentsPending"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "page"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "perPage"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        },
        "directives": []
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "siteCode"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        },
        "directives": []
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "sentrySampleShipmentsPending"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "page"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "page"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "perPage"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "perPage"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "siteCode"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "siteCode"
              }
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "records"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "createdAt"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "demoNumber"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "receivedAt"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "samples"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "sitePosition"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "positionPrefix"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "siteObjective"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "objective"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "name"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "site"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "id"
                          },
                          "arguments": [],
                          "directives": []
                        }, {
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "code"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }]
                  }
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "pageInfo"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "hasNext"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "hasPrev"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "totalRecords"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "totalPages"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 563
    }
  };
  var _default = doc;
  _exports.default = _default;
});