define("mvp/pods/components/ui-surveillance/isolates/show/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    authorization: Ember.inject.service(),
    currentUser: Ember.inject.service("current-user"),
    eventTracker: Ember.inject.service(),
    classNames: ['ui', 'grid'],
    demoVersion: Ember.computed(function () {
      return INSTANCE === 'mvpdemo';
    }),
    historicalInfoKeys: Ember.computed("isolate.historical_info", function () {
      return Object.keys(Ember.get(this, 'isolate.historical_info'));
    }),
    didInsertElement: function didInsertElement() {
      Ember.$(".ui.dimmer").removeClass('active').removeClass('visible').addClass('hidden');
      Ember.$("body").removeClass('undetached').removeClass('dimmed');
    },
    actions: {
      "view-runs": function viewRuns() {
        this.eventTracker.record('view_runs', {
          collection_no: Ember.get(this, "isolate.accession_number")
        });

        if (Ember.get(this, "isolate.surveillance_year") >= 2020) {
          window.open("https://react.jmilabs.com/microbiology/triage/" + Ember.get(this, "isolate.accession_number"));
        } else {
          window.open("http://apps.jmilabs.com/clinicalTrialApp/index.cfm?fuseaction=projects.reviewIsolate&collection_no=" + Ember.get(this, "isolate.accession_number"));
        }
      },
      "view-bio": function viewBio() {
        this.eventTracker.record('view_bio', {
          collection_no: Ember.get(this, "isolate.accession_number")
        });
        window.open("http://apps.jmilabs.com/apps/index.cfm?fuseaction=banking.isolate&bank=freezer&idType=C&idValue=" + Ember.get(this, "isolate.accession_number") + "&runQuery=Lookup+Isolate");
      },
      "view-speedy": function viewSpeedy() {
        this.eventTracker.record('view_speedy', {
          collection_no: Ember.get(this, "isolate.accession_number")
        });
        window.open("http://speedy:3000/isolates/perform_search?isolate[name]=" + this.get("isolate.accession_number"));
      },
      "view-act": function viewAct() {
        var accession_number = this.get("isolate.accession_number");
        this.eventTracker.record('view_act', {
          collection_no: accession_number
        });
        window.open("https://act.jmilabs.com/molecular/overview/".concat(accession_number));
      }
    }
  });

  _exports.default = _default;
});