define("mvp/models/site-objective-compliance-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    objective_code: _emberData.default.attr("string"),
    isolates_promised_count: _emberData.default.attr("number"),
    isolates_viable_count: _emberData.default.attr("number"),
    isolates_pending_count: _emberData.default.attr("number"),
    isolates_non_viable_count: _emberData.default.attr("number"),
    organization: _emberData.default.belongsTo("organization")
  });

  _exports.default = _default;
});