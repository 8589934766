define("mvp/models/filter-header", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    'organism-counts': _emberData.default.attr('json'),
    disclaimer: _emberData.default.attr('string')
  });

  _exports.default = _default;
});