define("mvp/pods/surveillance/show/publications/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TA/JvumR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isParatek\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"ui-surveillance/publications/paratek\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"authorization\",\"isJMIUser\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ui-surveillance/publications/index\",null,[[\"project\",\"publications\"],[[23,[\"project\"]],[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"ui-surveillance/publications/rss-feed\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/publications/index/template.hbs"
    }
  });

  _exports.default = _default;
});