define("mvp/models/breakpoint", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    doc_order: attr('number'),
    source: attr('string'),
    test_group: attr('string'),
    questions: attr('string'),
    surrogate_drugs: attr('array'),
    r_if_surrogate_is: attr('string'),
    ni_if_surrogate_is: attr('string'),
    delivery_mechanism: attr('string'),
    infection_type: attr('string'),
    footnote: attr('string'),
    mic_s_lte_ordinal: attr('string'),
    mic_r_gte_ordinal: attr('string'),
    disk_s_gte_ordinal: attr('string'),
    disk_r_lte_ordinal: attr('string'),
    master_group_include: attr('string'),
    organism_group_include: attr('string'),
    viridans_group_include: attr('string'),
    genus_include: attr('string'),
    genus_exclude: attr('string'),
    organism_code_include: attr('string'),
    organism_code_exclude: attr('string'),
    gram_include: attr('string'),
    level_1_include: attr('string'),
    level_1_exclude: attr('string'),
    level_2_include: attr('string'),
    level_2_exclude: attr('string'),
    level_3_include: attr('string'),
    level_3_exclude: attr('string'),
    level_4_include: attr('string'),
    level_4_exclude: attr('string'),
    description: attr('string'),
    drug: belongsTo('drug')
  });

  _exports.default = _default;
});