define("mvp/pods/site-management/canisters/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iJc5u22N",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ui form col-sm-offset-2\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"Receive Canister\"],[10],[0,\"\\n\"],[4,\"with\",[[27,\"changeset\",[[23,[\"model\",\"canister\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"form\"],[11,\"class\",\"ui form\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"ui grid\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"ten wide column\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"field\"],[9],[0,\"\\n            \"],[7,\"label\"],[9],[0,\"Canister Barcode:\"],[10],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"autofocus\",\"required\",\"value\"],[\"autofocus\",true,[22,1,[\"barcode\"]]]]],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"floated right\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"site-management.canisters.index\"],[[\"class\"],[\"ui button\"]],{\"statements\":[[0,\"              Cancel\\n\"]],\"parameters\":[]},null],[0,\"            \"],[7,\"button\"],[11,\"class\",\"ui primary button\"],[11,\"type\",\"submit\"],[9],[0,\"Mark as Received\"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"handleSubmit\",[22,1,[]]],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/site-management/canisters/edit/template.hbs"
    }
  });

  _exports.default = _default;
});