define("mvp/pods/surveillance/sentry/recruitment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ldC4xpSt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui grid centered\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"ten wide column\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"assets/images/logo_white.png\"],[11,\"height\",\"90px\"],[9],[10],[0,\"\\n\\n    \"],[1,[27,\"ui-sentry/hospital-recruitment\",null,[[\"info\"],[[23,[\"model\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/sentry/recruitment/template.hbs"
    }
  });

  _exports.default = _default;
});