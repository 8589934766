define("mvp/pods/components/ui-filter-result/nav/split-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5gT37RtI",
    "block": "{\"symbols\":[\"split\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ui vertical text menu\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header item\"],[9],[0,\"Split Results By\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"filter\",\"available_splits\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"item\"],[9],[0,\"\\n      \"],[1,[22,1,[\"description\"]],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"split-by\",[22,1,[\"key\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-filter-result/nav/split-results/template.hbs"
    }
  });

  _exports.default = _default;
});