define("mvp/pods/surveillance/show/pathogens/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ozYskpzU",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n\\n\"],[1,[27,\"d3-charts/organism-burst\",null,[[\"project\"],[[23,[\"project\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/pathogens/template.hbs"
    }
  });

  _exports.default = _default;
});