define("mvp/pods/components/ui-sentry/demo-entry/choose-objective/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0/QhFNNs",
    "block": "{\"symbols\":[\"objective\"],\"statements\":[[7,\"div\"],[11,\"class\",\"mt-3 mb-3 text-white font-lg\"],[9],[0,\"\\n  Select an objective\\n\"],[10],[0,\"\\n\\n\"],[7,\"table\"],[11,\"class\",\"ui compact table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Name\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Description\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Actions\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"    \\n\"],[4,\"each\",[[22,0,[\"acceptedObjectives\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n        \"],[7,\"td\"],[9],[1,[22,1,[\"description\"]],false],[10],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"w-32\"],[9],[0,\"\\n\\n\"],[4,\"link-to\",[\"surveillance.sentry.demo.show\",[22,1,[\"name\"]]],[[\"tagName\",\"class\"],[\"button\",\"ui primary button\"]],{\"statements\":[[0,\"            Select\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[11,\"colspan\",\"3\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"text-lg text-center py-3\"],[9],[0,\"\\n            No recruitments have been accepted for \"],[1,[21,\"demoEntryYear\"],false],[0,\", please complete your recruitment first.\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-sentry/demo-entry/choose-objective/template.hbs"
    }
  });

  _exports.default = _default;
});