define("mvp/pods/admin/sentry/containers/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["year", "continent", "sites"],
    year: "2017",
    continent: "North America",
    sites: "core",
    index: Ember.computed.alias('model'),
    observeYearSelect: function () {
      var _this = this;

      Ember.run.debounce(this, function () {
        var year = _this['year-select'];

        if (year.length > 0) {
          _this.resetModalParams();

          Ember.set(_this, 'year', year);
        }
      }, 250);
    }.observes('year-select'),
    observeContinentSelect: function () {
      var _this2 = this;

      Ember.run.debounce(this, function () {
        var continent = _this2['continent-select'];

        if (continent.length > 0) {
          _this2.resetModalParams();

          Ember.set(_this2, 'continent', continent);
          Ember.set(_this2, 'sites', "core");
        }
      }, 250);
    }.observes('continent-select'),
    observeSitesSelect: function () {
      var _this3 = this;

      Ember.run.debounce(this, function () {
        var sites = _this3['sites-select'];

        if (sites.length > 0) {
          _this3.resetModalParams();

          Ember.set(_this3, 'sites', sites);
        }
      }, 250);
    }.observes('sites-select'),
    actions: {
      'show-site-objective-modal': function showSiteObjectiveModal(site) {
        Ember.set(this, "modal-site", site);
        var year = this.year;
        var continent = this.continent;
        var sites = this.sites;
        var site_code = Ember.get(site, 'site_code');
        var str = "" + site_code;
        var pad = "000";
        var padded_site_code = pad.substring(0, pad.length - str.length) + str;
        var self = this;
        this.store.query('site-objective-compliance-status', {
          year: year,
          continent: continent,
          sites: sites,
          site_number: padded_site_code
        }).then(self._showModal.bind(self));
      }
    },
    resetModalParams: function resetModalParams() {
      Ember.set(this, "modal-site", undefined);
      Ember.set(this, "modal-site-objective-compliance-statuses", undefined);
    },
    _showModal: function _showModal(results) {
      Ember.set(this, "modal-site-objective-compliance-statuses", results);
      Ember.$(".ui.modal").modal({
        detachable: false,
        observeChanges: true
      }).modal("show");
    }
  });

  _exports.default = _default;
});