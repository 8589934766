define("mvp/pods/admin/statistics/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service("ajax"),
    model: function model() {
      var _this = this;

      var ajax = this.ajax;
      return new Promise(function (resolve) {
        ajax.request('https://metrics.jmilabs.com/api/statistics').then(_this.success.bind(_this, resolve));
      });
    },
    success: function success(resolve, payload) {
      resolve(payload);
    }
  });

  _exports.default = _default;
});