define("mvp/pods/components/ui-admin/edit-organization-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    didRender: function didRender() {
      var _self = this;

      if (Ember.get(this, "organization.projects") !== undefined) {
        Ember.get(this, "organization.projects").forEach(function (item) {
          Ember.$(".dropdown.projects").dropdown("set selected", Ember.get(item, "name"));
        });
      }

      Ember.$(".ui.checkbox").checkbox();
      Ember.$(".dropdown.projects").dropdown({
        action: "combo",
        fullTextSearch: true,
        onChange: function onChange(value) {
          var organization_id = Ember.get(_self, "organization.id");
          Ember.get(_self, "ajax").post("/api/organizations/update-projects", {
            data: {
              id: organization_id,
              project_ids: value
            }
          }).then(_self._reloadOrganization.bind(_self));
        }
      });
    },
    actions: {
      update: function update() {
        Ember.$("form.settings").addClass("loading");
        this.organization.save().then(this._removeLoading.bind(this));
      }
    },
    _reloadOrganization: function _reloadOrganization() {
      this.organization.reload();
    },
    _removeLoading: function _removeLoading() {
      Ember.$("form.settings").removeClass("loading");
    }
  });

  _exports.default = _default;
});