define("mvp/pods/components/ui-application/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5v1Pcj4J",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ui grid container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sixteen wide column\"],[9],[0,\"\\n\\n\\n    \"],[7,\"div\"],[11,\"class\",\"version\"],[9],[0,\"\\n      \"],[1,[21,\"version\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"ui-application/footer/copyright\",null,[[\"inverted\"],[[23,[\"reverse-copy\"]]]]],false],[0,\"\\n\\n    \"],[14,1],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-application/footer/template.hbs"
    }
  });

  _exports.default = _default;
});