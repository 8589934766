define("mvp/authorizers/jwt", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    session: Ember.inject.service('session'),
    authorize: function authorize(data, block) {
      var token = data.token;

      if (Ember.get(this, 'session.isAuthenticated') && token) {
        block('Authorization', "Bearer ".concat(token));
      }
    }
  });

  _exports.default = _default;
});