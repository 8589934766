define("mvp/helpers/is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isObject = isObject;
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function isObject(params
  /*, hash*/
  ) {
    var possible_object = params[0];
    return possible_object !== null && _typeof(possible_object) === 'object';
  }

  var _default = Ember.Helper.helper(isObject);

  _exports.default = _default;
});