define("mvp/services/redux", ["exports", "ember-redux/services/redux", "mvp/reducers/index", "mvp/enhancers/index", "mvp/middleware/index"], function (_exports, _redux, _index, _index2, _index3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _redux.default.extend({
    reducers: _index.default,
    enhancers: _index2.default,
    middlewares: _index3.default
  });

  _exports.default = _default;
});