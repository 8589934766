define("mvp/pods/demo-data/logging/route", ["exports", "ember-apollo-client", "mvp/pods/demo-data/logging/query"], function (_exports, _emberApolloClient, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberApolloClient.RouteQueryManager, {
    model: function model(params) {
      return this.apollo.watchQuery({
        fetchPolicy: "cache-and-network",
        query: _query.default,
        variables: {
          id: params.id
        }
      }, "sentrySampleBatch");
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        switchingSample: null,
        activeID: null,
        objectiveOrganism: null,
        objectiveSampleOrganism: null,
        objectiveRowOrganism: null,
        orgToAdd: null
      });
    }
  });

  _exports.default = _default;
});