define("mvp/pods/components/ui-admin/reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ummR2lvH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui items\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"item\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"header\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"downloadingIsolateCountReprot\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"ui active tiny inline loader\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        Isolates Count Report\\n      \"],[3,\"action\",[[22,0,[]],\"isolate-count-report\"]],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"meta\"],[9],[0,\"\\n        \"],[7,\"span\"],[9],[0,\"A summary of isolate counts by project and by year.\"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"description\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-admin/reports/template.hbs"
    }
  });

  _exports.default = _default;
});