define("mvp/pods/surveillance/show/quality-control/runs/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lf9jOa1z",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ui-surveillance/qc-runs\",null,[[\"project\",\"surveillanceYear\",\"page\",\"pageChanged\"],[[23,[\"model\"]],[23,[\"surveillanceYear\"]],[23,[\"page\"]],[27,\"action\",[[22,0,[]],\"pageChanged\"],null]]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/quality-control/runs/index/template.hbs"
    }
  });

  _exports.default = _default;
});