define("mvp/pods/surveillance/sentry/downloads/controller", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service("current-user"),
    year: _environment.default.APP.SETTINGS.RECRUITMENT_YEAR,
    objectives: Ember.computed("model", "year", function () {
      var year = this.year;
      return Ember.get(this, "model.objective_data_for_year")[year];
    }),
    yearOptions: Ember.computed('model.years.length', function () {
      return [{
        name: 2022,
        value: 2022
      }, {
        name: 2021,
        value: 2021
      }, {
        name: 2020,
        value: 2020
      }, {
        name: 2019,
        value: 2019
      }]; // return get(this, 'model.years').map(function(item) {
      //   return {
      //     name: item,
      //     value: item
      //   };
      // });
    }),
    observeYearsDropdown: function () {
      if (this.yearsDropdown !== undefined) {
        this.yearsDropdown.send('set-selected', this.year);
      }
    }.observes('yearsDropdown'),
    actions: {
      yearChanged: function yearChanged(year) {
        var _this = this;

        Ember.set(this, 'yearBump', true);
        Ember.set(this, 'year', year);
        Ember.run.next(this, function () {
          Ember.set(_this, 'yearBump', false);
        });
      }
    }
  });

  _exports.default = _default;
});