define("mvp/pods/manage/organizations/index/route", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    // optional. default is 10
    perPage: 10,
    model: function model(params) {
      return this.findPaged('organization', params);
    }
  });

  _exports.default = _default;
});