define("mvp/pods/surveillance/show/pathogens/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({// renderTemplate: function() {
    //   this.render('surveillance.show.dashboard.index', { into: 'application' });
    // }
  });

  _exports.default = _default;
});