define("mvp/pods/components/ui-admin/sentry-containers/site-objective-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'modal', 'large'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // console.log(get(this, 'statuses'));


      Ember.set(this, 'objective_list', ['A', 'B', 'C', 'D', 'E', 'Q', 'X', 'F']);
      Ember.set(this, 'show_nv_links', false);

      if (typeof this.statuses === 'undefined') {
        Ember.set(this, 'status_available', false);
      } else {
        Ember.set(this, 'status_available', true);
      }
    },
    actions: {}
  });

  _exports.default = _default;
});