define("mvp/pods/surveillance/show/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MWliXhsf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"error\"],[11,\"class\",\"ui center aligned compact piled segment\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"warning sign icon left\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"ui header\"],[9],[0,\"\\n    Oops!\\n    \"],[7,\"div\"],[11,\"class\",\"sub header\"],[9],[0,\"\\n      It looks like something went wrong with your request.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    Our IT Staff has been notified of the error. In the meantime, try adjusting your filter criteria.\\n  \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"surveillance.index\"],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"ui button primary\"],[9],[0,\"\\n      Adjust Filter\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/surveillance/show/error/template.hbs"
    }
  });

  _exports.default = _default;
});