define("mvp/pods/components/ui-admin/sentry-compliance/non-viable-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'modal'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    actions: {
      'view-demo-page': function viewDemoPage(isolate_demo_detail) {
        // console.log(`viewing demo page for ${get(isolate_demo_detail, 'demo_id')}`);
        this['show-demo-page'](isolate_demo_detail);
      }
    }
  });

  _exports.default = _default;
});