define("mvp/helpers/join-seperator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(options) {
    if (options.data.last) {
      return options.inverse();
    } else {
      return options.fn();
    }
  }
});