define("mvp/pods/components/ui-demo-data/new-show/row/component", ["exports", "mvp/pods/components/ui-demo-data/site/sample-details/source-of-bsi", "mvp/pods/components/ui-demo-data/site/sample-details/service", "mvp/pods/components/ui-demo-data/site/sample-details/sex", "mvp/pods/components/ui-demo-data/site/sample-details/specimen-type", "mvp/pods/components/ui-demo-data/site/sample-details/primary-diagnosis", "mvp/pods/components/ui-demo-data/site/sample-details/boolean"], function (_exports, _sourceOfBsi, _service, _sex, _specimenType, _primaryDiagnosis, _boolean) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /* globals moment */
  var _default = Ember.Component.extend({
    tagName: "tr",
    line: undefined,
    sourceOfBSI: _sourceOfBsi.default,
    services: _service.default,
    sex: _sex.default,
    primaryDiagnosis: _primaryDiagnosis.default,
    specimenType: _specimenType.default,
    boolean: _boolean.default,
    specimenTypeByObj: Ember.computed("objective", function () {
      var objs = _.keys(_specimenType.default);

      if (objs.includes(this.objective)) {
        return _specimenType.default[this.objective];
      } else {
        return _specimenType.default.other;
      }
    }),
    invalidDates: Ember.computed('line.{admission_date,culture_date}', function () {
      var admit_date = Ember.get(this, 'line.admission_date');
      var culture_date = Ember.get(this, 'line.culture_date');
      var output = false;

      if (admit_date !== '' && culture_date !== '') {
        if (moment(admit_date, 'YYYY-MM-DD').isAfter(culture_date)) {
          output = true;
        }
      }

      return output;
    }),
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.demo.form' + ' #' + this.elementId).form({
        on: 'change',
        inline: true,
        fields: {
          age: {
            identifier: 'age',
            optional: true,
            rules: [{
              type: 'integer[0..125]',
              prompt: 'Please enter an age between 0 and 125'
            }]
          }
        },
        selector: {
          field: 'input.age'
        }
      });
    },
    actions: {
      "updateDemo": function updateDemo(field, value) {
        if (value !== Ember.get(this, "line.demographics")[field]) {
          Ember.set(this, "line.demographics", _objectSpread({}, Ember.get(this, "line.demographics"), _defineProperty({}, field, value)));

          if (!value) {
            Ember.$("#" + field + "-id-" + Ember.get(this, "line.sitePosition")).text('');
          }
        }
      },
      ageCheck: function ageCheck(e) {
        var age = parseInt(e.target.value);

        if (age > 125 || age < 0) {
          e.target.value = "";
          return alert("Selection must be a number between 0 and 125");
        } else {
          this.set("line.demographics.age", age);
        }
      }
    }
  });

  _exports.default = _default;
});