define("mvp/pods/components/ui-sentry/breakdown-by-objective/component", ["exports", "ember-redux"], function (_exports, _emberRedux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UISentryBreakdownByObjective = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set("target_counts", []);
      this.set("viable_counts", []);
      this.set("non_viable_counts", []);
      this.set("pending_counts", []);
      this.set("shipment_dates", []);
    },
    total_target_count: Ember.computed.sum('target_counts'),
    total_viable_count: Ember.computed.sum('viable_counts'),
    total_non_viable_count: Ember.computed.sum('non_viable_counts'),
    total_pending_count: Ember.computed.sum('pending_counts'),
    latest_shipment_date: Ember.computed.max('shipment_dates'),
    yearOptions: [{
      name: 2022,
      value: 2022
    }, {
      name: 2021,
      value: 2021
    }, {
      name: 2020,
      value: 2020
    }, {
      name: 2019,
      value: 2019
    }],
    didReceiveAttrs: function didReceiveAttrs() {
      var objectives = Ember.get(this, 'objectives');

      if (objectives) {
        this._calcaulateTotalCounts();
      }
    },
    actions: {
      yearChanged: function yearChanged(year) {
        Ember.get(this, 'change-year')(year);
      }
    },
    _calcaulateTotalCounts: function _calcaulateTotalCounts() {
      var objectives = Ember.get(this, 'objectives');
      Ember.set(this, "target_counts", objectives.getEach("target_count"));
      Ember.set(this, "viable_counts", objectives.getEach("viable_count"));
      Ember.set(this, "non_viable_counts", objectives.getEach("non_viable_count"));
      Ember.set(this, "pending_counts", objectives.getEach("pending_count"));
      Ember.set(this, "shipment_dates", objectives.getEach("last_shipment_received_date"));
      var shipment_dates = Ember.get(this, "shipment_dates").map(function (item) {
        return new Date(item);
      });
      Ember.set(this, 'shipment_dates', shipment_dates);
    }
  });

  var mapStateToComputed = function mapStateToComputed(state) {
    return {
      site: state.siteManagement.site
    };
  };

  var _default = (0, _emberRedux.connect)(mapStateToComputed)(UISentryBreakdownByObjective); // observeYearsDropdown: function() {
  //   if (get(this, 'yearsDropdown') !== undefined) {
  //     get(this, 'yearsDropdown').send('set-selected', get(this, 'selected-year'));
  //   }
  // }.observes('yearsDropdown'),


  _exports.default = _default;
});