define("mvp/pods/components/ui-program/group-viewer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yHfLYiuF",
    "block": "{\"symbols\":[\"component\"],\"statements\":[[4,\"each\",[[23,[\"program_group\",\"components\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"ui horizontal divider header\"],[9],[0,\"\\n      \"],[1,[22,1,[\"name\"]],true],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"ui-program/component-viewer\",null,[[\"component\",\"viewType\",\"year\",\"region\",\"site\"],[[22,1,[]],[23,[\"viewType\"]],[23,[\"year\"]],[23,[\"region\"]],[23,[\"site\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-program/group-viewer/template.hbs"
    }
  });

  _exports.default = _default;
});