define("mvp/pods/components/ui-demo-data/site/sample-details/source-of-bsi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "value": "AB",
    "name": "AB - Abscess (pus)"
  }, {
    "value": "BON",
    "name": "BON - Bone/Joint Fluid"
  }, {
    "value": "CT",
    "name": "CT - Catheter tip"
  }, {
    "value": "DIA",
    "name": "DIA - Dialysis Line"
  }, {
    "value": "END",
    "name": "END - Endocarditis"
  }, {
    "value": "ENT",
    "name": "ENT - Ear, nose, throat"
  }, {
    "value": "GEN",
    "name": "GEN - Genital tract"
  }, {
    "value": "GI",
    "name": "GI - GI tract/Bowel"
  }, {
    "value": "IV",
    "name": "IV - Intravenous/IV Line/ Catheter tip"
  }, {
    "value": "LRT",
    "name": "LRT - Lower respiratory tract (pneumonia)"
  }, {
    "value": "UR",
    "name": "UR -  Urine/Urinary Tract"
  }, {
    "value": "WD",
    "name": "WD - Skin/Skin structure/Wound/Drainage/Ulcer/ Abscess"
  }, {
    "value": "OTH",
    "name": "OTH - Other or Unknown"
  }, {
    "value": "",
    "name": "NULL"
  }];
  _exports.default = _default;
});