define("mvp/pods/manage/projects/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll("project"); // return this.store.findAll("project").then(function(items) {
      //   items = Ember.ArrayProxy.extend(Ember.SortableMixin).create(items);
      //   set(items, 'sortProperties', ['sort_ordinal', 'alias']);
      // });
    }
  });

  _exports.default = _default;
});