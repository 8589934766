define("mvp/pods/surveillance/show/medical-centers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    surveillanceShow: Ember.inject.controller("surveillance.show"),
    project: Ember.computed.alias("surveillanceShow.model")
  });

  _exports.default = _default;
});