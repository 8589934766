define("mvp/pods/components/ui-site-management/nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tzn0Gx7g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ui vertical fluid pointing menu sticky secondary\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header item\"],[9],[0,\"\\n    Site Management\\n  \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"site-management.sites.index\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"hospital icon\"],[9],[10],[0,\"\\n    Sites\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"site-management.site-contacts\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"user icon\"],[9],[10],[0,\"\\n    Site Contacts\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"site-management.objectives.index\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"check icon\"],[9],[10],[0,\"\\n    Objectives\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"site-management.canisters.index\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"boxes icon\"],[9],[10],[0,\"\\n    Canister Management\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"site-management.shipments.index\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"truck icon\"],[9],[10],[0,\"\\n    Shipments\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"site-management.compliance-summary.by-site\"],[[\"class\"],[\"item\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"file alternate icon\"],[9],[10],[0,\"\\n    Sentry Compliance Summary\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/components/ui-site-management/nav/template.hbs"
    }
  });

  _exports.default = _default;
});