define("mvp/services/schema", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    schema: 'public',
    setSchema: function setSchema(schema) {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.set(self, 'schema', schema);
        resolve();
      });
    },
    getSchema: function getSchema() {
      this.schema;
    },
    header: Ember.computed('schema', function () {
      var schema = this.schema;
      return schema;
    })
  });

  _exports.default = _default;
});