define("mvp/pods/surveillance/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    surveillanceController: Ember.inject.controller('surveillance'),
    actions: {
      projectChanged: function projectChanged(slug) {
        this.surveillanceController.send('projectChanged', slug);
      }
    }
  });

  _exports.default = _default;
});