define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/infection-types/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      'update-selected-infection-type': function updateSelectedInfectionType(infection_type) {
        // console.log(`update selected infection type ${infection_type}`);
        this['update-selected-infection-type'](infection_type);
      }
    }
  });

  _exports.default = _default;
});