define("mvp/pods/demo-data/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZSy//AM7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"gte\",[[23,[\"model\",\"id\"]],20000],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ui-demo-data/new-show\",null,[[\"demo\",\"doneSaving\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"doneSaving\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"ui-demo-data/show\",null,[[\"demo\",\"doneSaving\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"doneSaving\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mvp/pods/demo-data/show/template.hbs"
    }
  });

  _exports.default = _default;
});