define("mvp/pods/components/ui-program/report-distribution/component", ["exports", "mvp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INSTANCE = _environment.default.EmberENV.INSTANCE;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    classNames: ["ui", "grid"],
    loading: true,
    selectedDrug: undefined,
    chartData: undefined,
    old_data: undefined,
    isParatek: Ember.computed('', function () {
      return INSTANCE === 'mvpparatek';
    }),
    setupSelectedDrug: function () {
      if (Ember.get(this, "component.drugs") !== undefined) {
        if (this.selectedDrug === undefined && Ember.get(this, "component.drugs").length > 0) {
          var firstDrug = Ember.get(this, "component.drugs").objectAt(0);
          Ember.set(this, "selectedDrug", firstDrug);
        }
      }
    }.observes("year", "region", "site", "component", "component.drugs.length"),
    getChartData: function () {
      // set(this, "charts", undefined);
      Ember.set(this, "error", false);

      if (this.selectedDrug !== undefined) {
        var ajax = this.ajax;
        var component = this.component;
        var data = {};

        if (this.year !== undefined) {
          data.year = this.year;
        }

        if (this.region !== undefined) {
          data.region = this.region;
        }

        if (this.site !== undefined) {
          data.site = this.site;
        }

        data.selected_drug = this.selectedDrug;
        var url = "/api/program-components/" + component.id + "/frequency-distribution-chart-data";

        var _self = this;

        ajax.request(url, {
          data: data
        }).then(_self._saveCharts.bind(_self), _self._saveError.bind(_self));
      }
    }.observes("year", "region", "site", "component", "selectedDrug"),
    didReceiveAttrs: function didReceiveAttrs() {
      var _self = this;

      Ember.run("afterRender", function () {
        _self.setupSelectedDrug();

        _self.getChartData();
      });
    },
    actions: {
      setSelectedDrug: function setSelectedDrug(drug) {
        Ember.set(this, "selectedDrug", drug);
      }
    },
    _saveCharts: function _saveCharts(res) {
      var _this = this;

      res = res.map(function (chart) {
        chart[0][0] = _this.selectedDrug;
        return chart;
      });

      if (!this.isDestroyed) {
        Ember.set(this, "loading", false);
        Ember.set(this, "charts", res);
      }
    },
    _saveError: function _saveError() {
      if (!this.isDestroyed) {
        Ember.set(this, "error", true);
      }
    }
  });

  _exports.default = _default;
});