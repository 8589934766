define("mvp/pods/components/ui-filter-result/line-list/component", ["exports", "mvp/config/environment", "downloadjs"], function (_exports, _environment, _downloadjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service("ajax"),
    poller: Ember.inject.service("poller"),
    page: 1,
    data: undefined,
    // Need to grab new table data
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, "data", undefined);
      var ajax = this.ajax;
      var filter = this.filter;
      var per = this.per;
      var page = this.page;
      var query = "/api/filters/" + filter.id + "/line_list_results" + "?per=" + per + "&page=" + page;

      var _self = this;

      ajax.request(query).then(_self._saveLineListData.bind(_self, page, per));
    },
    didRender: function didRender() {
      Ember.$('.button.download-line-list').popup({
        inline: true,
        on: 'click',
        hoverable: true
      });
    },
    actions: {
      "page-clicked": function pageClicked(page) {
        Ember.set(this, "page", page);
        this.didReceiveAttrs();
      },
      "export": function _export(type) {
        var _self = this;

        var filter = this.filter;
        var ajax = this.ajax;
        Ember.set(this, "downloadingLineList", true);
        var url = "/api/filters/" + filter.id + "/line_list" + "?type=" + type;
        ajax.request(url).then(_self._downloadOrPoll.bind(_self, filter, type, _downloadjs.default, ajax));
      }
    },
    _saveLineListData: function _saveLineListData(page, per, result) {
      Ember.set(this, "data", result);
      Ember.set(this, "totalPagesBinding", {
        page: page,
        perPage: per,
        totalPages: result.total_pages
      });
    },
    _downloadLineList: function _downloadLineList(filter, type, url, download) {
      // Once the line list has been generated in the backend download the file
      var today = new Date();
      var datestamp = today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();
      var file_name = filter.id + "_line_list_" + type + "_" + datestamp + ".csv";
      Ember.$.ajax({
        url: url,
        success: download.bind(true, "text/csv", file_name),
        complete: function complete() {
          Ember.set(this, "downloadingLineList", false);
        }
      });
    },
    _pollUntilCompletion: function _pollUntilCompletion(filter, type, url, download, response) {
      this.poller.pollUntilJobCompleted(response.job_id).then(this._downloadLineList.bind(this, filter, type, url, download));
    },
    _downloadOrPoll: function _downloadOrPoll(filter, type, download, ajax, response) {
      var url = _environment.default.APP.host + "/api/line-lists/" + response.data.id + "/file" + "?uniq_key=" + encodeURIComponent(response.data.attributes.uniq_key);

      if (response.data.attributes.is_exported) {
        this._downloadLineList(this, filter, type, url, download);
      } else {
        ajax.request(url).then(this._pollUntilCompletion.bind(this, filter, type, url, download));
      }
    }
  });

  _exports.default = _default;
});