define("mvp/pods/components/ui-surveillance/heatmap-old/resistance-rates/usa-map/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals fabric */
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.us_census_lookup = {
        "1:  New England": ["US-CT", "US-ME", "US-MA", "US-NH", "US-RI", "US-VT"],
        "2:  Middle Atlantic": ["US-NJ", "US-NY", "US-PA"],
        "3:  East North Central": ["US-IL", "US-IN", "US-MI", "US-OH", "US-WI"],
        "4:  West North Central": ["US-IA", "US-KS", "US-MN", "US-MO", "US-NE", "US-ND", "US-SD"],
        "5:  South Atlantic": ["US-DE", "US-DC", "US-FL", "US-GA", "US-MD", "US-NC", "US-SC", "US-VA", "US-WV"],
        "6:  East South Central": ["US-AL", "US-KY", "US-MS", "US-TN"],
        "7:  West South Central": ["US-AR", "US-LA", "US-OK", "US-TX"],
        "8:  Mountain": ["US-AZ", "US-CO", "US-ID", "US-MT", "US-NV", "US-NM", "US-UT", "US-WY"],
        "9:  Pacific": ["US-AK", "US-CA", "US-HI", "US-OR", "US-WA"]
      };
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupMap();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setupMap();
    },
    actions: {
      'update-selected-year': function updateSelectedYear(year, playing) {
        // console.log(`update selected year ${year}`);
        this['update-selected-year'](year, playing);
      }
    },
    rgbToHex: function rgbToHex(r, g, b) {
      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      }

      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    },
    legend_gradient_data: function legend_gradient_data() {
      var gradient_data = [];

      for (var i = 16; i >= 0; i--) {
        var r_value = Math.ceil(i / 15.0 * (124 - 226)) + 226;
        var g_value = Math.ceil(i / 15.0 * (169 - 221)) + 221;
        var b_value = Math.ceil(i / 15.0 * (64 - 68)) + 68;
        gradient_data.push({
          "color": this.rgbToHex(r_value, g_value, b_value)
        });
      }

      for (var _i = 15; _i >= 0; _i--) {
        var _r_value = Math.ceil(_i / 15.0 * (226 - 202)) + 202;

        var _g_value = Math.ceil(_i / 15.0 * (221 - 56)) + 56;

        var _b_value = Math.ceil(_i / 15.0 * (68 - 23)) + 23;

        gradient_data.push({
          "color": this.rgbToHex(_r_value, _g_value, _b_value)
        });
      }

      return gradient_data;
    },
    setupMap: function setupMap() {
      var self = this;
      Ember.set(self, 'map_displayed', false);
      Ember.set(self, 'pathogen_text', "");
      Ember.set(self, 'antibiotic_text', "");
      Ember.set(self, 'authority_text', "");
      Ember.set(self, 'year_text', "");

      if (this['resistance-rate-group'] && Object.keys(this['resistance-rate-group']).length !== 0 && this["selected-map-type"]) {
        Ember.set(self, 'map_displayed', true);
        Ember.$("#info").hide();
        var resistance_rate_group = this['resistance-rate-group'];
        var year_text = "All Years";

        if (Ember.get(resistance_rate_group, "year") !== 0) {
          year_text = Ember.get(resistance_rate_group, "year");
        }

        var tag_remove_regex = /(<([^>]+)>)/ig;
        var export_filename = Ember.get(resistance_rate_group, "name") + "_" + Ember.get(resistance_rate_group, "drug_name") + "_" + Ember.get(resistance_rate_group, "authority") + "_" + year_text + "_" + this["selected-map-type"];
        export_filename = export_filename.replace(tag_remove_regex, "");
        var export_label = Ember.get(resistance_rate_group, "name") + " | " + Ember.get(resistance_rate_group, "drug_name") + " | " + Ember.get(resistance_rate_group, "authority") + " | " + year_text;
        export_label = export_label.replace(tag_remove_regex, "");
        Ember.get(resistance_rate_group, 'resistance_rates').then(self._buildResistanceRates.bind(self, resistance_rate_group, year_text, export_filename, export_label));
      }
    },
    _buildResistanceRates: function _buildResistanceRates(resistance_rate_group, year_text, export_filename, export_label, resistance_rates) {
      var _this = this;

      var areas = resistance_rates.reduce(function (accumulator, resistance_rate) {
        var s_count = 0;
        var i_count = 0;
        var r_count = 0;
        var total_count = 0;

        if (Ember.get(resistance_rate, "s_count")) {
          s_count = Ember.get(resistance_rate, "s_count");
        }

        if (Ember.get(resistance_rate, "i_count")) {
          i_count = Ember.get(resistance_rate, "i_count");
        }

        if (Ember.get(resistance_rate, "r_count")) {
          r_count = Ember.get(resistance_rate, "r_count");
        }

        if (Ember.get(resistance_rate, "total_count")) {
          total_count = Ember.get(resistance_rate, "total_count");
        }

        var s_percent = 0;

        if (s_count > 0 && total_count > 0) {
          s_percent = s_count / total_count * 100.0;
        }

        s_percent = s_percent.toFixed(2);
        Ember.set(_this, 'pathogen_text', Ember.get(resistance_rate_group, "name") + " &nbsp; | &nbsp; ");
        Ember.set(_this, 'antibiotic_text', Ember.get(resistance_rate_group, "drug_name") + " &nbsp; | &nbsp; ");
        Ember.set(_this, 'authority_text', Ember.get(resistance_rate_group, "authority") + " &nbsp; | &nbsp; ");
        Ember.set(_this, 'year_text', year_text);

        if (Ember.get(resistance_rate, "us_census_region") !== "All") {
          var us_census_region = Ember.get(resistance_rate, "us_census_region");
          var states_in_region = _this.us_census_lookup[us_census_region];

          if (states_in_region) {
            states_in_region.forEach(function (state) {
              // s 0% - R255 G0 B0
              // s 50% - R255 G255 B0
              // s 100% - R0 G255 B0
              var color = "#000000";
              var b_value = 0;
              var r_value = 255;
              var g_value = 255;

              if (s_percent >= 50) {
                r_value = Math.ceil((s_percent - 50.0) / 50.0 * (124 - 226)) + 226;
                g_value = Math.ceil((s_percent - 50.0) / 50.0 * (169 - 221)) + 221;
                b_value = Math.ceil((s_percent - 50.0) / 50.0 * (64 - 68)) + 68;
              } else {
                r_value = Math.ceil(s_percent / 50.0 * (226 - 202)) + 202;
                g_value = Math.ceil(s_percent / 50.0 * (221 - 56)) + 56;
                b_value = Math.ceil(s_percent / 50.0 * (68 - 23)) + 23;
              }

              color = _this.rgbToHex(r_value, g_value, b_value);
              var balloon_text = "\n              <div>\n                <span class='regioninfo'> ".concat(us_census_region, " </span>\n              </div>\n              <div>\n                <span class='isolatesinfo'>\n                  ").concat(total_count, " Isolates<br>\n                  <span style='color: ").concat(color, ";'>").concat(s_percent, "% Susceptible</span>\n                </span><br>\n                <span class='isolatesinfo'>\n                  S:").concat(s_count, " / I:").concat(i_count, " / R:").concat(r_count, "\n                </span>\n              </div>\n              ");

              if (Ember.get(resistance_rate, "delivery_mechanism")) {
                balloon_text = balloon_text + "<br> Delivery Mechanism:\n            ".concat(Ember.get(resistance_rate, "delivery_mechanism"));
              }

              if (Ember.get(resistance_rate, "infection_type")) {
                balloon_text = balloon_text + "<br>Infection Type: ".concat(Ember.get(resistance_rate, "infection_type"));
              }

              accumulator.push({
                "id": state,
                "groupId": us_census_region,
                "color": color,
                "customData": balloon_text
              });
            });
          }
        }

        return accumulator;
      }, []);

      this._displayResistanceData(export_filename, export_label, areas);
    },
    _displayResistanceData: function _displayResistanceData(export_filename, export_label, areas) {
      var data_provider = {
        "map": "usaLow"
      };
      var unlisted_areas_alpha = 1.0;
      var areas_to_display = areas;
      data_provider["areas"] = areas_to_display;
      Ember.run.scheduleOnce('afterRender', this, function () {
        var map = window.AmCharts.makeChart("chartdiv", {
          "type": "map",
          "theme": "light",
          "balloon": {
            "textAlign": "left"
          },
          "colorSteps": 25,
          "dataProvider": data_provider,
          "areasSettings": {
            "balloonText": "",
            // "balloonText": "[[title]]",
            "unlistedAreasColor": "#F3F3F3",
            "alpha": 1.0,
            "unlistedAreasAlpha": unlisted_areas_alpha,
            "rollOverColor": null,
            "outlineColor": "#ffffff",
            "rollOverOutlineColor": "#6a6a6a",
            "rollOverOutlineThickness": 1.5,
            "selectedColor": undefined
          },
          "allLabels": [{
            "text": "Susceptibility",
            "bold": true,
            "align": "right",
            "x": "!108",
            "y": 480
          }, {
            "text": "100%",
            "bold": true,
            "align": "right",
            "x": "!255",
            "y": 480
          }, {
            "text": "0%",
            "bold": true,
            "align": "right",
            "x": "!10",
            "y": 480
          }],
          "legend": {
            "width": "100%",
            "align": "right",
            "marginRight": 0,
            "marginLeft": 0,
            "equalWidths": false,
            "backgroundAlpha": 0.0,
            "backgroundColor": "#FFFFFF",
            "borderColor": "#FFFFFF",
            "borderAlpha": 0.0,
            "top": 480,
            "right": 10,
            "horizontalGap": 0,
            "spacing": 0,
            "markerSize": 8,
            "data": this.legend_gradient_data()
          },
          "export": {
            "enabled": true,
            "fileName": export_filename,
            "afterCapture": function afterCapture() {
              var text = new fabric.Text(export_label, {
                top: 10,
                left: 75,
                fontFamily: "Arial",
                fontSize: this.setup.chart.fontSize * 1.5
              });
              this.setup.fabric.add(text);
            }
          },
          "allowClickOnSelectedObject": false,
          "zoomOnDoubleClick": false,
          "dragMap": false,
          "zoomControl": {
            "homeButtonEnabled": false,
            "zoomControlEnabled": false
          }
        });
        map.addListener("rollOverMapObject", function (event) {
          if (event.mapObject.customData !== undefined) {
            document.getElementById("info").innerHTML = event.mapObject.customData;
            document.getElementById("info").style.display = "block";
          }
        });
      });
    }
  });

  _exports.default = _default;
});